import React from 'react'
import {
  Settings as SettingsIcon,
  Timeline as TimelineIcon,
  CheckBox as CheckBoxIcon,
  //
  HomeWork as HomeWorkIcon,
  Work as WorkIcon,
  ZoomOutMap as ZoomOutMapIcon,
  CreditCard as CreditCardIcon,
  Euro as EuroIcon,
  ShoppingCart as ShoppingCartIcon,
  Group as GroupIcon,
  Description as DescriptionIcon,
  AccountBalance as AccountBalanceIcon,
  Mail as MailIcon,
  EventNote as EventNoteIcon,
  FileCopy as FileCopyIcon,
  Contacts as ContactsIcon,
  Folder as FolderIcon,
  Lock as LockIcon,
  Help as HelpIcon,
} from '@material-ui/icons/'

// ----------------------------------------------------------------------

const PATHS = {
  home: '/',
  operations: '/operations',
  settings: '/settings',
  lots: '/lots',
  expenses: '/expenses', // - depenses
  budgets: '/budgets', // ?
  suppliers: '/suppliers', // - fournisseurs
  tenants: '/tenants', // Locataire
  leases: '/leases', // ?? - loyers / bailles
  invoices: '/invoices',
  payments: '/payments',
  tasks: '/tasks',
  agenda: '/agenda', // Calendrier
  timeline: '/timeline', // Calendrier
  reports: '/reports',
  profile: '/profile',
  contacts: '/contacts',
  files: '/files',
  landlords: '/landlords', // Propriétaires
  suppliersPortal: '/supplier', // ?
  landlordsPortal: '/landlord', // ?
  accountant: '/accountant', // ??
  mortgages: '/mortgages',
  investors: '/investors',
  help: '/help',
}

const NAV_HOME_CONFIG = [
  {
    items: [
      {
        icon: <HomeWorkIcon />,
        title: ['home'],
        href: PATHS.home,
        isInside: false,
      },
      {
        icon: <WorkIcon />,
        title: ['operations'],
        href: PATHS.operations,
        isInside: false,
      },
      {
        icon: <SettingsIcon />,
        title: ['setting'],
        href: PATHS.profile,
        isInside: false,
      },
      {
        icon: <GroupIcon />,
        title: ['navigation_landlords'],
        href: PATHS.landlords,
        isInside: true,
      },
    ],
  },
  {
    subheader: ['costs'],
    items: [
      {
        icon: <ShoppingCartIcon />,
        title: ['suppliers'],
        href: PATHS.suppliers,
        isInside: true,
      },
      {
        icon: <CreditCardIcon />,
        title: ['expenses'],
        href: PATHS.expenses,
        isInside: true,
      }
    ],
  },
  {
    subheader: ['income'],
    items: [
      {
        icon: <GroupIcon />,
        title: ['tenants'],
        href: PATHS.tenants,
        isInside: true,
      },
      {
        icon: <DescriptionIcon />,
        title: ['invoicing'],
        href: PATHS.invoices,
        isInside: true,
      },
      {
        icon: <AccountBalanceIcon />,
        title: ['payments'],
        href: PATHS.payments,
        isInside: true,
      },
    ],
  },
  {
    subheader: ['app'],
    items: [
      {
        icon: <CheckBoxIcon />,
        title: ['tasks'],
        href: PATHS.tasks,
        isInside: true,
      },
      {
        icon: <EventNoteIcon />,
        title: ['calendar'],
        href: PATHS.agenda,
        isInside: true,
      },
      {
        icon: <ContactsIcon />,
        title: ['contacts'],
        href: PATHS.contacts,
        isInside: false,
      },
      {
        icon: <FolderIcon />,
        title: ['files'],
        href: PATHS.files,
        isInside: true,
      },
    ],
  },
  {
    items: [
      {
        icon: <GroupIcon />,
        title: ['investors'],
        href: PATHS.investors,
        isInside: true,
        isFinance: true,
      },
    ],
  },
  {
    items: [
      {
        icon: <SettingsIcon />,
        title: ['settings'],
        href: PATHS.profile,
        isInside: false,
      },
    ],
  },

]

// const NAV_HOME_CONFIG = [
//   {
//     items: [
//       {
//         icon: <SettingsIcon />,
//         title: ['setting'],
//         href: PATHS.profile,
//         isInside: false,
//       },
//       // {
//       //   icon: <HomeWorkIcon />,
//       //   title: ['home'],
//       //   href: PATHS.home,
//       //   isInside: false,
//       // },
//       {
//         icon: <WorkIcon />,
//         title: ['operations'],
//         href: PATHS.operations,
//         isInside: false,
//       },
//     ]
//   },
//   {
//     subheader: ['management'],
//     items: [
//       // {
//       //   icon: <ZoomOutMapIcon />,
//       //   title: ['lots'],
//       //   href: PATHS.lots,
//       //   isOperation: true,
//       //   isInside: true,
//       // },
//       // {
//       //   icon: <i className="fas fa-file-signature fa-fw"></i>,
//       //   title: ['leases'],
//       //   href: PATHS.leases,
//       //   isOperation: true,
//       //   isInside: true,
//       // },
//       {
//         icon: <CreditCardIcon />,
//         title: ['transactions'],
//         href: PATHS.expenses,
//         isInside: true,
//       },
//       {
//         icon: <GroupIcon />,
//         title: ['finance'],
//         href: PATHS.investors,
//         isInside: true,
//         // isFinance: true,
//       },
//     ]
//   },
//   {
//     subheader: ['collaboration'],
//     items: [
//       // {
//       //   icon: <DescriptionIcon />,
//       //   title: ['invoicing'],
//       //   href: PATHS.invoices,
//       //   isInside: true,
//       // },
//       {
//         icon: <EventNoteIcon />,
//         title: ['calendar'],
//         href: PATHS.agenda,
//         isInside: true,
//       },
//       {
//         icon: <CheckBoxIcon />,
//         title: ['tasks'],
//         href: PATHS.tasks,
//         isInside: true,
//       },
//       {
//         icon: <ContactsIcon />,
//         title: ['contacts'],
//         href: PATHS.contacts,
//         isOperation: true,
//         isInside: false,
//       },
//     ]
//   },
//   {
//     subheader: ['admin'],
//     items: [
//       {
//         icon: <FileCopyIcon />,
//         title: ['reports'],
//         href: PATHS.reports,
//         // isOperation: true,
//         isInside: true,
//       },
//       {
//         icon: <FolderIcon />,
//         title: ['files'],
//         href: PATHS.files,
//         isInside: true,
//       },
//       {
//         icon: <SettingsIcon />,
//         title: ['settings'],
//         href: PATHS.profile,
//         isInside: false,
//       },
//     ],
//   },
// ]

const NAV_OPERATION_CONFIG = [
  {
    items: [
      {
        icon: <HomeWorkIcon />,
        title: ['home'],
        href: PATHS.home,
        isOperation: false,
        isInside: false,
      },
      {
        icon: <WorkIcon />,
        title: ['operations'],
        href: PATHS.operations,
        isOperation: false,
        isInside: false,
      },
      {
        icon: <SettingsIcon />,
        title: ['settings'],
        href: PATHS.settings,
        isOperation: true,
        isInside: false,
      },
      {
        icon: <GroupIcon />,
        title: ['navigation_landlords'],
        href: PATHS.landlords,
        isOperation: true,
        isInside: true,
      },
      {
        icon: <ZoomOutMapIcon />,
        title: ['lot_units'],
        href: PATHS.lots,
        isOperation: true,
        isInside: true,
      },
    ],
  },
  {
    subheader: ['costs'],
    items: [
      {
        icon: <ShoppingCartIcon />,
        title: ['suppliers'],
        href: PATHS.suppliers,
        isOperation: true,
        isInside: true,
      },
      {
        icon: <CreditCardIcon />,
        title: ['expenses'],
        href: PATHS.expenses,
        isOperation: true,
        isInside: true,
      },
      {
        icon: <EuroIcon />,
        title: ['budget'],
        href: PATHS.budgets,
        isOperation: true,
        isInside: true,
      },

    ],
  },
  {
    subheader: ['income'],
    items: [
      {
        icon: <GroupIcon />,
        title: ['tenants'],
        href: PATHS.tenants,
        isOperation: true,
        isInside: true,
      },
      {
        icon: <i className="fas fa-file-signature fa-fw"></i>,
        title: ['leases'],
        href: PATHS.leases,
        isOperation: true,
        isInside: true,
      },
      {
        icon: <DescriptionIcon />,
        title: ['invoicing'],
        href: PATHS.invoices,
        isOperation: true,
        isInside: true,
      },
      {
        icon: <AccountBalanceIcon />,
        title: ['payments'],
        href: PATHS.payments,
        isOperation: true,
        isInside: true,
      },
    ],
  },
  {
    subheader: ['app'],
    items: [
      {
        icon: <CheckBoxIcon />,
        title: ['tasks'],
        href: PATHS.tasks,
        isOperation: true,
        isInside: true,
      },
      {
        icon: <EventNoteIcon />,
        title: ['calendar'],
        href: PATHS.agenda,
        isOperation: true,
        isInside: true,
      },
      {
        icon: <TimelineIcon />,
        title: ['timeline'],
        href: PATHS.timeline,
        isOperation: true,
        isInside: true,
      },
      {
        icon: <FileCopyIcon />,
        title: ['reports'],
        href: PATHS.reports,
        isOperation: true,
        isInside: true,
      },
      {
        icon: <ContactsIcon />,
        title: ['contacts'],
        href: PATHS.contacts,
        isOperation: true,
        isInside: false,
      },
      {
        icon: <FolderIcon />,
        title: ['files'],
        href: PATHS.files,
        isOperation: true,
        isInside: true,
      },
    ],
  },
  {
    items: [
      {
        icon: <i className="fas fa-university fa-fw"></i>,
        title: ['navigation_mortgages'],
        href: PATHS.mortgages,
        isOperation: true,
        isInside: true,
        isFinance: true,
      },
      {
        icon: <GroupIcon />,
        title: ['investors'],
        href: PATHS.investors,
        isOperation: true,
        isInside: true,
        isFinance: true,
      },
    ],
  },
  {
    items: [
      {
        icon: <SettingsIcon />,
        title: ['setting'],
        href: PATHS.settings,
        isOperation: true,
        isInside: true,
      },
    ]
  },
]


// const NAV_OPERATION_CONFIG = [
//   {
//     items: [
//       {
//         icon: <SettingsIcon />,
//         title: ['settings'],
//         href: PATHS.profile,
//         isInside: false,
//       },
//       // {
//       //   icon: <SettingsIcon />,
//       //   title: ['setting'],
//       //   href: PATHS.profile,
//       //   isInside: false,
//       // },
//       // {
//       //   icon: <HomeWorkIcon />,
//       //   title: ['home'],
//       //   href: PATHS.home,
//       //   isInside: false,
//       // },
//       {
//         icon: <WorkIcon />,
//         title: ['operations'],
//         href: PATHS.operations,
//         isInside: false,
//       },
//     ]
//   },
//   {
//     subheader: ['management'],
//     items: [
//       {
//         icon: <ZoomOutMapIcon />,
//         title: ['lots'],
//         href: PATHS.lots,
//         isOperation: true,
//         isInside: true,
//       },
//       {
//         icon: <i className="fas fa-file-signature fa-fw"></i>,
//         title: ['leases'],
//         href: PATHS.leases,
//         isOperation: true,
//         isInside: true,
//       },
//       {
//         icon: <CreditCardIcon />,
//         title: ['transactions'],
//         href: PATHS.expenses,
//         isInside: true,
//       },
//       {
//         icon: <GroupIcon />,
//         title: ['finance'],
//         href: PATHS.investors,
//         isInside: true,
//         // isFinance: true,
//       },
//     ]
//   },
//   {
//     subheader: ['collaboration'],
//     items: [
//       // {
//       //   icon: <DescriptionIcon />,
//       //   title: ['invoicing'],
//       //   href: PATHS.invoices,
//       //   isInside: true,
//       // },
//       {
//         icon: <EventNoteIcon />,
//         title: ['calendar'],
//         href: PATHS.agenda,
//         isInside: true,
//       },
//       {
//         icon: <CheckBoxIcon />,
//         title: ['tasks'],
//         href: PATHS.tasks,
//         isInside: true,
//       },
//       {
//         icon: <ContactsIcon />,
//         title: ['contacts'],
//         href: PATHS.contacts,
//         isOperation: true,
//         isInside: false,
//       },
//     ]
//   },
//   {
//     subheader: ['admin'],
//     items: [
//       {
//         icon: <FileCopyIcon />,
//         title: ['reports'],
//         href: PATHS.reports,
//         isOperation: true,
//         isInside: true,
//       },
//       {
//         icon: <FolderIcon />,
//         title: ['files'],
//         href: PATHS.files,
//         isInside: true,
//       },
//     ],
//   },
// ]

export { NAV_HOME_CONFIG, NAV_OPERATION_CONFIG }
