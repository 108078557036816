import React, { useState, useEffect } from 'react'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
} from '@material-ui/core'

import { useTranslation } from '@contexts/translation'
import { merge } from 'lodash'
import dynamic from 'next/dynamic'
import { useFetchData } from '@api/fetchData'


const ReactApexChart = dynamic(() => import('react-apexcharts'), { ssr: false })
import ApexChartsOption from '@components/dashboard/charts/ApexChartsOption'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles((theme, props) => ({
  content: {
    padding: `0 ${theme.spacing(3)}`,
  },
  containerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    color: theme.palette.grey[500],
  },
  selectYear: {
    width: 100,
    height: '100%',
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  filter: {
    '& div': {
      paddingTop: '6.5px',
      paddingBottom: '6.5px',
    },
  },
}))

function IncomeExpenseDetail({ operationId, changeYear, year, currentYear }) {
  const classes = useStyles()
  const theme = useTheme()
  const { dictionary } = useTranslation()

  const [expenses, setExpenses] = useState([])
  const [income, setIncome] = useState([])
  const [revenue, setRevenue] = useState([])
  // use time series year to get all years
  const [pastYears, setPastYears] = useState([])


  const { data, isLoading, isError } = useFetchData(`/stats/indicators/financials/operation/${operationId}/?year=${year}`, null, { revalidateOnFocus: false, revalidateOnReconnect: false })


  useEffect(() => {
    if (data) {
      let filteredByYear = data.time_series.filter((item) => item.year === year)
      if (filteredByYear.length > 0) {
        let expensesData = filteredByYear[0].time_series.map(item => Math.round(item.expenses * 100) / 100)
        let incomeData = filteredByYear[0].time_series.map(item => Math.round(item.income * 100) / 100)
        let revenueData = filteredByYear[0].time_series.map(item => Math.round(item.revenue * 100) / 100)
        setExpenses(expensesData)
        setIncome(incomeData)
        setRevenue(revenueData)
      }
      else {
        setExpenses([])
        setIncome([])
        setRevenue([])
      }
    }
  }, [data, year])

  const chartData = [
    // {
    //   name: dictionary['gross_margin'],
    //   type: 'column',
    //   data: income,
    // },
    
    {
      name: dictionary['revenue'],
      type: 'column',
      data: revenue,
    },
    {
      name: dictionary['expenses'],
      type: 'column',
      data: expenses,
    },


  ]

  const chartOptions = merge(ApexChartsOption(), {
    stroke: { width: [2, 2, 3] },
    // plotOptions: {
    //   bar: {
    //     columnWidth: '12%',
    //     borderRadius: 8,
    //   },
    // },
    fill: {
      type: ['solid', 'solid', 'solid'],
      // colors: ["#fff"],
    },
    legend: {
      show: true,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        strokeColor: '#fff',
        fillColors: [theme.palette.secondary.main],
      },
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
    },
  })

  return (
    <Card>
      <div className={classes.containerHeader}>
        <div>
          <CardHeader title={dictionary['overview_revenue_expenses_month']} />
        </div>
        <FormControl variant="outlined" className={classes.selectYear}>
          <InputLabel id="select-year-label">{dictionary['year']}</InputLabel>
          <Select
            labelId="select-year-label"
            id="select-year"
            value={year}
            className={classes.filter}
            onChange={(e) => changeYear(e.target.value)}
            label={dictionary['year']}
          >
            <MenuItem value={currentYear - 1}>{currentYear - 1}</MenuItem>
            <MenuItem value={currentYear}>{currentYear}</MenuItem>
            <MenuItem value={currentYear + 1}>{currentYear + 1}</MenuItem>
          </Select>
        </FormControl>
      </div>
      <CardContent>
        <Box>
          <ReactApexChart
            type="line"
            series={chartData}
            options={chartOptions}
            height={364}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default IncomeExpenseDetail
