import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack';

import { useFetchData } from '@api/fetchData'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import SaveIcon from '@material-ui/icons/Save'

import { RoleSelect } from "@components/common"

import { useTranslation } from '@contexts/translation'
import { Grid } from '@material-ui/core';
import { onCreateContracts } from '@api/contracts';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
  formRadioGroupContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: theme.spacing(4),
  },
  formCheckbox: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
  },
}))

export default function RoleForm({ name, handleClose, operationId, updateItem, onSuccess, successKey, actionLabel, onAction }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    setError,
    errors,
    control
  } = useForm({
    mode: 'onBlur',
    shouldUnregister: false
  })


  const onSubmit = async (data) => {
    setLoading(true)
    let response = await updateItem({ operations: [Number(operationId)] }, data[name] )
    if (response) {
      if (response.status === 200) {
          setLoading(false)
          onSuccess()
          handleClose()
          enqueueSnackbar(dictionary[successKey], {
            variant: "success"
          })
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
          <Grid xs="12" className={classes.center}>
            <RoleSelect
              control={control}
              error={errors[name]}
              helperText={errors[name] && errors[name].message}
              defaultValue=''
              name={name}
              operationId={operationId}
            />
          </Grid>
        <Grid xs="12" className={classes.buttonContainer}>
        <Button
            variant="contained"
            color="secondary"
            disabled={loading}
            onClick={onAction}
            className={classes.submit}
          >
           {actionLabel}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
            startIcon={<SaveIcon />}
            className={classes.submit}
          >
            {dictionary["submit"]}
          </Button>
        </Grid>
      </Grid>
    </form >
  )
}
