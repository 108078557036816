import client from '@utils/client'

export const onCreateSMTP = (payload) => {
  return client
    .post('/emails/smtp/', payload)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onDeleteSMTP = (id) => {
  return client
    .delete(`/emails/smtp/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onUpdateSMTP = (id, payload) => {
  return client
    .patch(`/emails/smtp/${id}/`, payload)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetSMTP = (id) => {
  return client
    .get(`/emails/smtp/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onSendSMTPTest = (id) => {
  return client
    .get(`/emails/smtp/${id}/send-test/`)
    .then((response) => response)
    .catch((error) => { return error.response })
}