import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'

import { Modal } from '.'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { Select } from "@components/form"
import { onUpdateTenant } from '@api/tenants';
import { updateSupplier } from '@api/suppliers'

import { useFetchData } from '@api/fetchData'

import { useTranslation } from '@contexts/translation'
import { onUpdateLandlord } from '@api/landlords'

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerButtons: {
    height: 'fit-content',
    display: "flex",
    padding: theme.spacing(4),
  },
  container: {
    height: 'fit-content',
    maxWidth: 350,
    padding: theme.spacing(4)
  },
  buttonCancel: {
    marginRight: theme.spacing(8),
  },
  form: {
    display: 'flex',
    flexDirection: "column",
    zIndex: 99999,
  }
}))

export default function ModalPaidDate({ onClose, fetchUrl, type, operations, id, operationId }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()


  const operationUrl = `/operations/`
  const { data, isLoading, isError } = useFetchData(operationUrl)


  const {
    handleSubmit,
    errors,
    setValue,
    register,
    control,
    setError
  } = useForm({
    mode: 'onBlur',
    shouldUnregister: false
  })

  const operationsId = () => {
    let operationsArray = []
    data && data.results && data.results.length > 1 && data.results.map(operation => {
      operationsArray.push(operation.id)
      return operationsArray
    })
    return operationsArray
  }

  const operationsIdArray = operationsId()

  const getOperations = (operations) => operations.filter(operation => operation.id).map(operation => operation.id)

  useEffect(() => {
    if (operations) {
      setValue('operations', getOperations(operations))
    }
  }, [operations]);

  const onSubmit = async (data) => {
    setLoading(true)

    const response = type === "tenant" ? await onUpdateTenant(data, id) : type === 'supplier' ? await updateSupplier(data, id) : await onUpdateLandlord(data, id)

    if (response) {
      if (response.status === 200) {
        enqueueSnackbar(dictionary[`${type}_edited`], {
          variant: "success"
        })
        mutate(fetchUrl)
        onClose()
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <Modal className={classes.modalContainer} onClose={onClose}>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.container}>
          <Select
            variant="outlined"
            required
            margin="normal"
            fullWidth
            id="operations"
            label={dictionary["operations"]}
            name="operations"
            inputRef={register}
            isLoading={isLoading}
            error={errors.operations}
            control={control}
            helperText={errors.operations && errors.operations.message}
            multiple={true}
            arrayValue={operationsIdArray}
            defaultValue={[]}
            MenuProps={{
              getContentAnchorEl: () => null,
            }}
          >
            {data && data.results && data.results?.map(operation => (
              <MenuItem disabled={operationId && operation.id == operationId ? true : false} key={operation?.id} value={operation?.id}>{operation?.name}</MenuItem>
            ))}
          </Select>
          <div className={classes.containerButtons}>
            <Button
              className={classes.buttonCancel}
              onClick={onClose}
              type="button"
              variant="contained"
              color="inherit"
            >
              {dictionary["cancel"]}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              {dictionary["confirm"]}
            </Button>
          </div>
        </Paper>
      </form>
    </Modal>
  )
}
