import useSWR from 'swr'

import { fetcher } from '@lib/fetcher'
import client from '@utils/client';


export const useGetCurrencies = () => {
  const { data, error } = useSWR(`/currencies/default/`, fetcher)

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}


export const onDeleteCurrency = (id) => {
  return client
    .delete(`/currencies/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onCreateCurrency = (payload) => {
  return client
    .post('/currencies/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
