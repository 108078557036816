import client from '@utils/client'

export const onCreateStyleguide = (payload) => {
  return client
    .post('/styleguides/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onDeleteStyleguide = (id) => {
  return client
    .delete(`/styleguides/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onUpdateStyleguide = (id, payload) => {
  return client
    .patch(`/styleguides/${id}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
