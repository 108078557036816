import client from '@utils/client'


export const onCreateExpense = (payload) => {
  return client
    .post('/expenses/', payload)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onDeleteExpense = (id) => {
  return client
    .delete(`/expenses/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onUpdateExpense = (id, payload) => {
  return client
    .patch(`/expenses/${id}/`, payload)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetExpense = (id) => {
  return client
    .get(`/expenses/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onFetchExpenses = (params = {}) => {
  return client
    .get('/expenses/', { params })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}


export const onUploadFileOCR = (payload) => {
  return client
    .post('/ocr/extract-data/', payload)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}