import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { useFetchData } from '@api/fetchData'

import { Select } from '.'

import { useTranslation } from '@contexts/translation'

export default function OperationSelect({ control, error, errorMessage, fullWidth, rules, children, defaultValue }) {
  const { dictionary } = useTranslation()
  const { data, isLoading, isError } = useFetchData('/operations/')

  if (isError) return <p>{dictionary["failed_to_load_data"]}...</p>

  return (
    <Select
      id="operation"
      name="operation"
      label={dictionary["operation"]}
      control={control}
      defaultValue={defaultValue}
      variant="outlined"
      isLoading={isLoading}
      margin="normal"
      error={error}
      rules={rules}
      fullWidth={fullWidth}
      errorMessage={errorMessage}
    >
      {children}
      {data &&
        data.results.map((operation) => {
          return <MenuItem value={operation.id}>{operation.name}</MenuItem>
        })}
    </Select>
  )
}
