import client from '@utils/client'

export const onGetLeases = () => {
  return client
    .get('/leases/')
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
export const onGetLeasesActive = (id) => {
  return client
    .get(`/leases/?operation=${id}&status=active`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
export const onCreateLease = (payload) => {
  return client
    .post('/leases/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onDeleteLease = (id) => {
  return client
    .delete(`/leases/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onUpdateLease = (id, payload) => {
  return client
    .patch(`/leases/${id}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetLeasesPeriods = () => {
  return client
    .get(`/leases/periods/all/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}


export const onGetLease = (id) => {
  return client
    .get(`/leases/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onCreateLeaseArticle = (id, payload) => {
  return client
    .post(`/leases/${id}/articles/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}


export const onDeleteLeaseArticle = (id, articleId) => {
  return client
    .delete(`/leases/${id}/articles/${articleId}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}


export const onUpdateLeaseArticle = (id, articleId, payload) => {
  return client
    .patch(`/leases/${id}/articles/${articleId}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetLeaseArticles = (id) => {
  return client
    .get(`/leases/${id}/articles/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetLeasesFilter = (operationId) => {
  return client
    .get(`/leases/${operationId ? `?operation=${operationId}&page_size=9999` : '?page_size=9999'}`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const addLeaseFile = (id, fileId, files) => {
  return client
    .patch(`/leases/${id}/`, {
      files: files ? [...files, fileId] : [fileId]
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetLeasesOperation = (id) => {
  return client
    .get(`/leases/?operation=${id}&page_size=9999`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
