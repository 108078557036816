import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack';

import { useFetchData } from '@api/fetchData'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'

import { QuillTableEditor, StyleguideEditor } from '@components/form'

import { useTranslation } from '@contexts/translation'
import { Grid } from '@material-ui/core';
import { onCreateStyleguide, onUpdateStyleguide } from '@api/styleguides';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
  },
}))

export default function StyleguidesForm({ id, isEdit, handleClose, fetchUrl }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    setError,
    errors,
    setValue,
    control
  } = useForm({
    mode: 'onBlur',
  })

  const { data, isLoading, isError } = isEdit
    ? useFetchData('/styleguides/', id)
    : {
      data: null,
      isLoading: null,
      isError: null,
    }

  useEffect(() => {
    if (isEdit && data) {
      setValue('name', data?.name)
      setValue('header', data?.header)
      setValue('footer', data?.footer)
    }
  }, [data, isEdit]);

  const onSubmit = async (data) => {

    setLoading(true)
    let response = await onCreateStyleguide(data)
    if (response) {
      if (response.status === 201) {
        handleClose()
        enqueueSnackbar(dictionary["styleguide_created"], {
          variant: "success"
        })
        mutate(fetchUrl)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  const onSubmitEdit = async (data) => {

    setLoading(true)
    let response = await onUpdateStyleguide(id, data)
    if (response) {
      if (response.status === 200) {
        handleClose()
        enqueueSnackbar(dictionary["styleguide_edited"], {
          variant: "success"
        })
        mutate(fetchUrl)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(isEdit ? onSubmitEdit : onSubmit)}
    >
      <Grid container>

        {/* Name */}

        <Grid xs="12">
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            required
            id="name"
            label={dictionary["name"]}
            name="name"
            inputRef={register}
            error={errors.name}
            helperText={errors.name && errors.name.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* Header */}

        <Grid item xs={12}>
          <StyleguideEditor
            id="header"
            name="header"
            label={dictionary["header"]}
            control={control}
            isStyleguide
            isLoading={isLoading}
            error={errors.header}
            defaultValue=""
            errorMessage={errors.header && errors.header.message}
            initialValue={isEdit ? data?.header : null}
          />
        </Grid>

        {/* Footer */}

        <Grid item xs={12}>
          <StyleguideEditor
            id="footer"
            name="footer"
            label={dictionary["footer"]}
            control={control}
            isStyleguide
            isLoading={isLoading}
            error={errors.footer}
            defaultValue={""}
            errorMessage={errors.footer && errors.footer.message}
            initialValue={isEdit ? data?.footer : null}
          />
        </Grid>


        <Grid xs="12" className={classes.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
            startIcon={<SaveIcon />}
            className={classes.submit}
          >
            {dictionary["submit"]}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
