import clsx from 'clsx'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from '@contexts/translation'
import { useRouter } from "next/router";

import OverviewCard from '@components/dashboard/Overview/OverviewCard'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',

    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  gridContent: {
    '&:first-of-type > div': {
      background: 'rgba(255, 206, 206)',
    },

    '&:last-of-type > div': {
      background: '#D8E2FA',
    },

    '@media (max-width: 600px)': {
      marginBottom: theme.spacing(3),
    },
  },
  gridContentLarge: {
    maxWidth: '25%',
    '@media (max-width: 1025px)': {
      maxWidth: '50%',
    },
    '@media (max-width: 600px)': {
      maxWidth: '100%',
    },
  },
  gridContentSmall: {
    maxWidth: '20%',
    '@media (max-width: 1025px)': {
      maxWidth: '50%',
    },
    '@media (max-width: 600px)': {
      maxWidth: '100%',
    },
  },
}))

export default function OverviewViewDashboard({ operationId, landlordId }) {
  const classes = useStyles()
  const { dictionary } = useTranslation()
  const router = useRouter();


  return (
    <Grid container className={classes.content}>
      <Grid
        item
        xs={12}
        sm={6}
        className={clsx(
          classes.gridContent, classes.gridContentSmall
        )}
      >
        <OverviewCard
          key={'billable'}
          label={'billable'}
          title={dictionary['billable_dashboard']}
          operationId={operationId}
          toLink={() => router.push({ pathname: `/operations/${operationId}/invoices`, query: { tab: 4 } }, `/operations/${operationId}/invoices`)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        className={clsx(
          classes.gridContent, classes.gridContentLarge
        )}
      >
        <OverviewCard
          key={'receivable'}
          label={'invoices/receivable'}
          title={dictionary['receivable_dashboard']}
          operationId={operationId}
          toLink={() => router.push({ pathname: `/operations/${operationId}/invoices`, query: { tab: 3 } }, `/operations/${operationId}/invoices`)}
          chart={[
            {
              data: [2532, 6632, 4132, 8932, 6332, 2532],
            },
          ]}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        className={clsx(
          classes.gridContent, classes.gridContentLarge
        )}
      >
        <OverviewCard
          key={"payable"}
          label={'expenses/payable'}
          title={dictionary['payable_dashboard']}
          operationId={operationId}
          toLink={() => router.push({ pathname: `/operations/${operationId}/expenses`, query: { tab: 1 } }, `/operations/${operationId}/expenses`)}
          chart={[
            {
              data: [2532, 6632, 4132, 8932, 6332, 2532],
            },
          ]}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        className={clsx(
          classes.gridContent, classes.gridContentSmall
        )}
      >
        <OverviewCard
          key={'balance'}
          label={landlordId && `landlord/balance/${landlordId}`}
          title={dictionary['landlord_balance']}
          operationId={operationId}
          toLink={() => landlordId && router.push(`/operations/${operationId}/landlords/${landlordId}`)}
        />
      </Grid>
    </Grid>
  )
}


