import React, {useEffect, useState} from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "@contexts/translation";
import {Button, CircularProgress, FormControlLabel, MenuItem, Switch, TextField} from "@material-ui/core";
import {onGetOperationList} from "@api/operations";
import SaveIcon from "@material-ui/icons/Save";
import {onCreateExport} from "@api/exports";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2.5),
    paddingTop: theme.spacing(4),
    overflowY: 'auto',
    maxHeight: 'calc(100% - 80px)',
    marginTop: theme.spacing(-4)
  },
  margin: {
    marginBottom: theme.spacing(3)
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4)
  }
}));

export default function AddExportForm({onClose}) {
  const classes = useStyles();
  const { dictionary } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isOperationsLoading, setIsOperationsLoading] = useState(false);
  const [operationList, setOperationList] = useState([]);

  const exportTypeList = [
    {key: 'expenses', label: dictionary['expenses']},
    {key: 'lots', label: dictionary['lots']},
    {key: 'budgets', label: dictionary['budgets']},
    {key: 'categories', label: dictionary['categories']},
    {key: 'contacts', label: dictionary['contacts']},
    {key: 'discounts', label: dictionary['discounts']},
    {key: 'leases', label: dictionary['leases']},
    {key: 'lease_articles', label: dictionary['lease_articles']},
    {key: 'payments', label: dictionary['payments']},
    {key: 'investors', label: dictionary['investors']},
    {key: 'invoices', label: dictionary['invoices']},
    {key: 'invoice_items', label: dictionary['invoice_items']},
    {key: 'mortgages', label: dictionary.mortgages.mortgages },
    {key: 'operations', label: dictionary['operations']},
    {key: 'tenants', label: dictionary['tenants']},
    {key: 'suppliers', label: dictionary['suppliers']},
  ];

  useEffect(() => {
    setIsOperationsLoading(true);
    onGetOperationList().then(response => {
      setOperationList(response.data.results)
    }).finally(() => {
      setIsOperationsLoading(false);
    });
  }, []);

  const getInitialValues = () => (
    {
      include_documents: true,
      operation: 0,
      type: exportTypeList[0].key
    }
  );

  const formik = useFormik({
    initialValues: getInitialValues(),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const newExport = {
          type: values.type,
          operation: values.operation || '',
          include_documents: values.include_documents
        };
        const response = await onCreateExport(newExport);
        const resultLog = response.status === 201 ? 'success' : 'error';
        enqueueSnackbar(dictionary[resultLog], {
          variant: resultLog,
        });
        onClose();
        resetForm();
        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const {
    values,
    handleSubmit,
    isSubmitting,
    getFieldProps,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit} className={classes.root}>

        <TextField
          select
          fullWidth
          className={classes.margin}
          disabled={isOperationsLoading}
          label={dictionary['type']}
          variant='outlined'
          {...getFieldProps('type')}
          value={values.type}
          required
        >
          {exportTypeList.map(type =>
            <MenuItem value={type.key} key={`type-${type.key}`}>
              {type.label}
            </MenuItem>
          )}
        </TextField>

        <TextField
          select
          fullWidth
          className={classes.margin}
          disabled={isOperationsLoading}
          label={dictionary['operation']}
          variant='outlined'
          {...getFieldProps('operation')}
          value={values.operation || 0}
        >
          <MenuItem value={0}>{dictionary['all_operations']}</MenuItem>
          {operationList.map(operation =>
            <MenuItem value={operation.id} key={`operation-${operation.id}`}>
              {operation.name}
            </MenuItem>
          )}
        </TextField>

        <FormControlLabel
          control={
            <Switch checked={values.include_documents} {...getFieldProps('include_documents')} />
          }
          label={dictionary['includes_documents']}
        />

        <div className={classes.actionButtons}>
          {isOperationsLoading ?
            <CircularProgress /> :
            <Button
              type='submit'
              variant='contained'
              color="secondary"
              disabled={isSubmitting}
              startIcon={<SaveIcon />}
            >
              {dictionary['submit']}
            </Button>
          }
        </div>
      </Form>
    </FormikProvider>
  )
}
