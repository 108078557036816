import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'

import { Layout, HeaderPage, StickyHeader, Copyright } from '@components/common'
import { InvoicesTable } from '.'

import { useOperation } from "@contexts/operation"
import { useTranslation } from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    maxHeight: '100vh',
  },
  container: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, .8)',
    textTransform: 'uppercase',
  },
  sticky: {
    position: 'sticky',
    zIndex: '999',
    top: '40px'
  }
}))

export default function InvoicesView({ id, isOperation }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const { operation } = useOperation()

  return (
    <Layout isSidebar={isOperation} operationId={id}>
      <main className={classes.content}>
        <Container maxWidth={false} className={classes.container}>
          <Grid container spacing={3}>
            {!isOperation ?
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <HeaderPage heading={dictionary["invoices"]}
                    links={
                      [
                        { name: dictionary["home"], href: `/` },
                        { name: dictionary["invoices"] }
                      ]} />
                </Paper>
              </Grid>
              :
              <StickyHeader
                operationName={operation?.name}
                breadcrumb={[
                  { name: dictionary["operations"], href: `/operations/` },
                  { name: operation?.name, href: `/operations/${id}` },
                  { name: dictionary["invoices"] }
                ]}
              />
            }
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <InvoicesTable operationId={id} isOperation={isOperation} />
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>

        </Container>
      </main>
    </Layout>
  )
}
