import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import { onInviteUser } from '@api/profile';
import { Select } from "@components/form"
import MenuItem from '@material-ui/core/MenuItem'
import { useForm } from 'react-hook-form'
import upperFirst from 'lodash/upperFirst'
import { useTranslation } from '@contexts/translation'


function FormDialogs({ title, getUsers, defaultType = '', id = '', isGlobal }) {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [type, setType] = useState(null);
  const { dictionary } = useTranslation()
  const {
    control,
    getValues
  } = useForm({
    mode: 'onBlur',
  })

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeType = (type) => {
    setType(type)
  }

  const inviterUser = async () => {
    const params = {}
    if (defaultType) {
      params[defaultType] = id
      params['type'] = defaultType
    }
    if (!defaultType && type) {
      params['type'] = type
    }
    await onInviteUser(email, params)
    handleClose()
    await getUsers()
  }

  const roleList = isGlobal ? ['accountant', 'manager'] : ['tenant', 'landlord', 'supplier', 'accountant', 'manager']

  return (
    <div>
      <Button
        variant='contained'
        color='primary'
        onClick={handleClickOpen}
      >
        {title || dictionary['invite_user']}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dictionary['new_invitation']}</DialogTitle>
        <DialogContent
          sx={{ width: 500 }}
        >
          <TextField
            autoFocus
            fullWidth
            type="email"
            margin="dense"
            variant="outlined"
            label={dictionary['email']}
            value={email}
            onChange={ev => {
              setEmail(ev.target.value)
            }}
          />
          <Select
            id="type"
            name="type"
            label={dictionary['select_type']}
            labelId={"types"}
            variant="outlined"
            margin="normal"
            fullWidth
            customOnChange={changeType}
            control={control}
            defaultValue={defaultType}
            disabled={!!defaultType}
            MenuProps={{
              getContentAnchorEl: () => null,
            }}
          >
            {roleList.map(category => (
              <MenuItem key={category} value={category}>{upperFirst(category)}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {dictionary['cancel']}
          </Button>
          <Button onClick={inviterUser} variant="contained" disabled={!email}>
            {dictionary['send_invitation']}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDialogs;
