import { makeStyles } from '@material-ui/core/styles';
import { useGridSlotComponentProps } from '@material-ui/data-grid';
import Pagination from '@material-ui/lab/Pagination';
// import { numberWithSpaces } from '@utils/numberWithSpaces';
import { numberWithSpacesNoDecimalAdded } from '@utils/numberWithSpaces'
import useBreakpoints from '@hooks/useBreakpoints';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 7,
  },
}));

export const TotalRow = () => {
  const classes = useStyles();

  const { state, apiRef, columns, rows } = useGridSlotComponentProps();
  const isMobile = useBreakpoints('down', 'sm');

  const totalRow = () => {
    if (rows?.length > 0) {
      const forceReduce = rows.push({
        investorName: '',
        operationName: '',
        ownership: 0,
      });
      const totalRow = rows?.reduce((acc, value) => {
        return {
          investorName: 'Total',
          ownership: acc.ownership + value.ownership,
        };
      });
      return [totalRow];
    }
  };

  const itemStyleName = {
    width: columns[0].computedWidth,
    padding: '0',
    fontWeight: 'bold',
  };
  const itemStyle = {
    width: columns[1].computedWidth,
    paddingRight: 10,
    fontWeight: 'bold',
    textAlign: 'right',
  };

  return (
    <div className={`${classes.root} MuiDataGrid-footer`}>
      {rows?.length > 0 && columns.length > 2 && (
        <div>
          {totalRow().map((item, index) => (
            <div
              className={classes.list}
              key={`${item?.investorName}-${index}`}
            >
              <div style={itemStyleName}>
                {state.pagination.pageCount > 1 && (
                  <Pagination
                    color="primary"
                    count={state.pagination.pageCount}
                    page={state.pagination.page + 1}
                    size="small"
                    siblingCount={0}
                    onChange={(event, value) => value && apiRef.current.setPage(value - 1)} />
                )}
              </div>
              <div style={itemStyle}>{numberWithSpacesNoDecimalAdded(item?.ownership)}%</div>
            </div>
          ))}
        </div>
      )}

    </div>
  );
};
