import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import createAvatar from '@utils/createAvatar';

const MyAvatar = ({ className, name, photoURL = '', ...other }) => (
  <Avatar
    src={photoURL}
    alt={name}
    color={photoURL ? 'default' : createAvatar(name).color}
    className={className}
    {...other}
  >
    {createAvatar(name).name}
  </Avatar>
);

MyAvatar.propTypes = {
  className: PropTypes.string,
};

export default MyAvatar;
