import React from 'react'

import { useFetchData } from '@api/fetchData'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'


import { useTranslation } from '@contexts/translation'

export default function TemplatesSelect({ fullWidth, handleChange, defaultValue, control }) {
  const { dictionary } = useTranslation()
  const { data, isLoading, isError } = useFetchData(`/emails/`)

  if (isError) return <p>{dictionary["failed_to_load_data"]}...</p>

  return (
    <FormControl style={{ width: fullWidth ? '100%' : '195px' }} margin="normal" size="medium" variant="outlined">
      <InputLabel id="templates">{dictionary["templates"]}</InputLabel>
      <Select
        id="invoice_email_template"
        name="invoice_email_template"
        label={dictionary["templates"]}
        labelId={"templates"}
        variant="outlined"
        margin="normal"
        isLoading={isLoading}
        defaultValue={defaultValue}
        fullWidth={fullWidth}
        control={control}
        onChange={e => {
          handleChange(data.results?.find(template => e.target?.value === template.id))
        }
        }
        MenuProps={{
          getContentAnchorEl: () => null,
        }}
      >
        {data && data.count ?
          data.results.map(template => {
            return (
              <MenuItem key={template.id} value={template.id}>
                {template.name}
              </MenuItem>
            )
          }) : (
            <MenuItem disabled value="">{dictionary["no_data"]}</MenuItem>
          )}
      </Select>
    </FormControl>
  )
}
