import React from 'react'

import VacationCostsDetail from '@components/dashboard/VacationCosts/VacationCostsDetail'
import { useFetchData } from '@api/fetchData'


function VacationCostsView({ className, operationId, year }) {
  // const fetchUrl = `/stats/indicators/financials/operation/summary/${operationId}/?year=${year}`
  const fetchUrl = `/stats/indicators/financials/operation/summary/${operationId}/`
  const { data, isLoading, isError } = useFetchData(fetchUrl, null, { revalidateOnFocus: false, revalidateOnReconnect: false })

  return <VacationCostsDetail data={data} />
}

export default VacationCostsView
