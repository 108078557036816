import React from 'react'

import { useFetchData } from '@api/fetchData'

import MenuItem from '@material-ui/core/MenuItem'

import { Select } from '.'

import { useTranslation } from '@contexts/translation'

export default function LotsSelect({ control, error, errorMessage, fullWidth, children }) {
  const { dictionary } = useTranslation()
  const { data, isLoading, isError } = useFetchData(`/lots/?is_private=false`)

  if (isError) return <p>{dictionary["failed_to_load_data"]}...</p>

  return (
    <Select
      id="lots"
      name="lots"
      label={dictionary["common_lots"]}
      control={control}
      variant="outlined"
      margin="normal"
      error={error}
      isLoading={isLoading}
      fullWidth={fullWidth}
      errorMessage={errorMessage}
      multiple={true}
      defaultValue={[]}
      MenuProps={{
        getContentAnchorEl: () => null,
      }}
    >
      {children}
      {data &&
        data.results.map((lot) => {
          return (
            <MenuItem key={lot.id} value={lot.id}>
              {lot.name}
            </MenuItem>
          )
        })}
    </Select>
  )
}
