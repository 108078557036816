import clsx from 'clsx'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MAvatar } from '@theme/@material-extend'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography, Card } from '@material-ui/core'
import { format } from 'date-fns'
import UnformatDate from '@utils/unformatDate'
import RoomIcon from '@material-ui/icons/Room'
import PublishIcon from '@material-ui/icons/Publish'
import DateRangeIcon from '@material-ui/icons/DateRange'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'
import { InfoCardSub, ModalUpload } from '@components/common'
import { enUS, fr } from 'date-fns/locale';
import { useTranslation } from '@contexts/translation'
import { useFetchData } from '@api/fetchData'


// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '16px',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
      padding: `${(theme.spacing(2), theme.spacing(4))}`,
      zIndex: 2,
    },
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'initial',
      justifyContent: 'initial',
    },
  },
  userInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
  },
  content: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: "1.6rem",
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.3rem'
    },
  },
  textStyle: {
    color: theme.palette.common.black,
    marginLeft: theme.spacing(0.5),
  },
  containerInfo: {
    color: theme.palette.common.white,
    "& div": {
      display: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      color: theme.palette.common.white,
      "& div": {
        display: 'inherit'
      },
    },
  },
  info: {
    width: 'max-content',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    borderRadius: '50rem',
    backgroundColor: theme.palette.grey[400],
    marginRight: theme.spacing(1),

    '&:last-of-type': {
      marginRight: 0,
    },

    '& svg': {
      width: '16px',
      height: '16px',
      fill: theme.palette.primary.main,
      marginRight: theme.spacing(0.5),
      marginTop: '2px',
    },
  },
  info_blue: {
    backgroundColor: '#3A5EB2',
    '& p': {
      color: theme.palette.common.white,
    },
  },
  infoText: {
    fontSize: '12px',
    color: theme.palette.primary.main,
  },
  operationDetailsContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  operationDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

// ----------------------------------------------------------------------

InfoCard.propTypes = {
  myProfile: PropTypes.object,
  authUser: PropTypes.object,
  className: PropTypes.string,
}

function InfoCard({
  photo,
  className,
  name,
  address,
  startDate,
  typeDisplay,
  usefulContacts,
  surface,
  operationId,
  fetchOperation,
}) {
  const classes = useStyles()
  const { lang, dictionary } = useTranslation()
  const [uploadOpen, setUploadOpen] = useState(false)


  const handleUploadClose = () => {
    setUploadOpen(false)
  }

  const fetchUrl = `/lots/?operation${operationId}&page_size=1`
  const { data, isLoading, isError } = useFetchData(fetchUrl, null, { revalidateOnFocus: false, revalidateOnReconnect: false })

  return (
    <Card
      className={classes.wrapper}
      sx={{
        mb: 3,
        height: 174,
        position: 'relative',
      }}
    >
      <div className={clsx(classes.root, className)}>
        <div className={classes.userInfo}>
          <div className={classes.userInfoContainer}>
            <MAvatar
              sx={{
                mx: 'auto',
                width: { xs: 80, md: 120 },
                height: { xs: 80, md: 120 },
                cursor: photo ? 'default' : 'pointer'
              }}
              onClick={() => photo ? null : setUploadOpen(true)}
            >
              {photo ? <img src={photo} /> : <PublishIcon fontSize={'large'} />}
            </MAvatar>
          </div>
          <Box className={classes.containerInfo}>
            <Typography className={classes.title} variant="h4">
              {name}
            </Typography>
            <div className={classes.operationDetailsContainer}>
              <div className={classes.operationDetails}>
                <div className={classes.content}>
                  <RoomIcon />
                  <Typography className={classes.textStyle} sx={{ opacity: 1 }}>
                    {address}
                  </Typography>
                </div>
                <div className={classes.content}>
                  <DateRangeIcon />
                  <Typography className={classes.textStyle} sx={{ opacity: 1 }}>
                    {startDate && format(UnformatDate(startDate), 'd LLLL yyyy',
                      { locale: lang === 'fr' ? fr : enUS })}
                  </Typography>
                </div>
              </div>
              <div>
                <div className={clsx(classes.info, classes.info_blue)}>
                  <Typography className={clsx(classes.infoText)}>
                    {typeDisplay}
                  </Typography>
                </div>
                <div className={classes.info}>
                  <ZoomOutMapIcon />{' '}
                  <Typography className={classes.infoText}>{data && data.count}{' '}{dictionary['lots']}</Typography>
                </div>
                <div className={classes.info}>
                  {' '}
                  <Typography className={classes.infoText}>{surface}m2</Typography>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </div>
      <InfoCardSub usefulContacts={usefulContacts} />
      {uploadOpen &&
        <ModalUpload onClose={handleUploadClose} id={operationId} fetchUrl={fetchOperation} />
      }
    </Card>
  )
}

export default InfoCard
