import React from "react";
import { Editor } from "@tinymce/tinymce-react";

import { Controller } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { FormHelperText, Button } from '@material-ui/core'

import { useTranslation } from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    marginTop: theme.spacing(3)
  },
  editor: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    minHeight: 320
  },
  variablesBtn: {
    position: "absolute",
    right: 0,
    top: '-10px',
    color: theme.palette.text.primary
  },
  inputLabel: {
    position: "relative",
    transform: "none"
  },
}))


const StyleguideEditor = ({
  id,
  data,
  name,
  label,
  control,
  handleChange,
  error,
  errorMessage,
  defaultValue,
  children,
  handleClick,
  isLoading,
  initialValue,
  isVariable,
  ...props
}) => {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const labelId = `${name}-label`
  return (
    <FormControl
      {...props}
      margin="normal"
      size="medium"
      error={error}
      style={{ width: '100%' }}
      helperText={errorMessage}
    >
      <InputLabel className={classes.inputLabel} id={labelId}>{label}</InputLabel>
      {isVariable &&
        <Button
          size="small"
          type="button"
          className={classes.variablesBtn}
          onClick={handleClick}
        >
          {dictionary["?_variables"]}
        </Button>
      }
      <Controller
        render={({ onChange, value }) => {
          const handleEditorChange = (editor) => onChange(editor);
          return (
            <Editor
              apiKey={process.env.NEXT_PUBLIC_TINY_MCE_KEY}
              init={{
                plugins: "image textpattern lists table ",
                toolbar: 'undo redo | image',
                branding: false,
                elementpath: false,
                body_class: classes.editor,
                file_picker_types: 'image',
                automatic_uploads: true,
                // view : https://www.tiny.cloud/docs/demo/file-picker/#
                file_picker_callback: function (cb, value, meta) {
                  var input = document.createElement('input');
                  input.setAttribute('type', 'file');
                  input.setAttribute('accept', 'image/*');

                  input.onchange = function () {
                    var file = this.files[0];

                    var reader = new FileReader();
                    reader.onload = function () {
                      /*
                        Note: Now we need to register the blob in TinyMCEs image blob
                        registry. In the next release this part hopefully won't be
                        necessary, as we are looking to handle it internally.
                      */
                      var id = 'blobid' + (new Date()).getTime();
                      var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                      var base64 = reader.result.split(',')[1];
                      var blobInfo = blobCache.create(id, file, base64);
                      blobCache.add(blobInfo);

                      /* call the callback and populate the Title field with the file name */
                      cb(blobInfo.blobUri(), { title: file.name });
                    };
                    reader.readAsDataURL(file);
                  };

                  input.click();
                },
                min_height: 320
              }}
              className={classes.editor}
              onEditorChange={handleEditorChange}
              value={value}
            />
          )
        }}
        name={name}
        control={control}
        className={classes.editor}
        defaultValue={defaultValue}
      />
      {errorMessage && <FormHelperText className={classes.errorMessage}>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

export default StyleguideEditor;