import client from '@utils/client'
import useSWR from 'swr'
import { fetcher } from '@lib/fetcher'

export const useOnAddLot = () => {
  const { data, error } = useSWR(`/lots/`, fetcher)

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}

export const useOnAddOneLot = (id) => {
  const { data, error } = useSWR(`/lots/${id}`, fetcher)

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}

export const sendAddLot = (payload) => {
  return client
    .post('/lots/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const updateLot = (payload, id) => {
  return client
    .patch(`/lots/${id}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const deleteLot = (id) => {
  return client
    .delete(`/lots/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const getLots = (params = {}) => {
  return client
    .get('/lots/', { params })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const getLot = (id) => {
  return client
    .get(`/lots/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const getLotGroups = (params) => {
  return client
    .get(`/lots/groups/`, { params })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const createLotsGroup = (payload) => {
  return client
    .post('/lots/groups/', {
      ...payload
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const updateLotsGroup = (payload, id) => {
  return client
    .patch(`/lots/groups/${id}/`, {
      ...payload
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const deleteLotGroup = (id) => {
  return client
    .delete(`/lots/groups/${id}`,)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}



export const onGetPrivateLots = (id) => {
  return client
    .get(`/lots/?page_size=999&operation=${id}&is_private=true`)
    .then((response) => response?.data?.results || [])
    .catch((error) => {
      return error.response
    })
}