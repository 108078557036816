import React from 'react'
import {
  Badge,
  Button,
  Checkbox,
  Tab
} from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import FilterListIcon from '@material-ui/icons/FilterList'
import { ActionTable, Table, CellExpand } from '@components/common'
import { useTranslation } from '@contexts/translation'
import { isBefore } from 'date-fns'
import { fDate } from '@utils/formatTime'
import AddIcon from '@material-ui/icons/Add'
import UnformatDate from '@utils/unformatDate'
import clsx from 'clsx'
import { useSnackbar } from "notistack";
import { useRouter } from 'next/router'
import { onEditTask } from "@api/tasks";
import useBreakpoints from '@hooks/useBreakpoints'
import { MLabel } from 'theme'
import { TabContext, TabList } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  tableNav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    flexWrap: 'wrap',
    rowGap: theme.spacing(1),
  },
  submit: {
    '@media (max-width: 600px)': {
      minWidth: 'initial',
      padding: theme.spacing(1),
      '& span': {
        marginRight: '0',
        marginLeft: '0',
      },
    },
  },
  containerTable: {
    width: '100%',
  },
  filterContainer: {
    display: 'flex',
    maxWidth: '100%',
  },
  tabList: {
    minHeight: 36,
    marginLeft: theme.spacing(2),
  },
  tab: {
    minHeight: 36,
  },
  marginSide: {
    margin: theme.spacing(0, 1),
  },
  redText: {
    color: theme.palette.error.main,
  },
  filterButton: {
    maxWidth: '100%',
  }
}))

export default function TasksTable({
  isLoading,
  handlePageChange,
  page,
  count,
  taskList,
  onSelectTask,
  onOpenTaskForm,
  onOpenDeleteModal,
  onOpenFiltersModal,
  isFiltersSelected,
  onModeSwitch,
  isProfilePage,
  value,
  taskCallback,
  handleChange,
  tasksTab,
  isLightTable = false,
  title,
}) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar();
  const { dictionary } = useTranslation()
  const router = useRouter()
  const theme = useTheme()
  const { id: operationId } = router.query
  const isMobile = useBreakpoints('down', 'sm');

  const actions = (task) => [
    {
      name: dictionary['edit'],
      onClick: () => {
        onSelectTask(task)
        onOpenTaskForm()
      },
    },
    {
      name: dictionary['delete'],
      onClick: () => {
        onSelectTask(task)
        onOpenDeleteModal()
      },
    },
  ]

  const completeTask = async (checkedState, taskId) => {
    const updateTask = { is_completed: !checkedState }
    let response = await onEditTask(taskId, updateTask)
    if (response) {
      if (response.status === 200) {
        taskCallback()
        enqueueSnackbar(dictionary['task_updated'], {
          variant: 'success',
        })
      }
    }
  };


  const operationColumnArr = !operationId
    ? [
      {
        field: 'operation',
        headerName: dictionary['operation'],
        valueGetter: ({ row }) => row?.operation ? row.operation.display : '-',
        renderCell: CellExpand,
        minWidth: 160,
      },
    ]
    : []

  const columns = [
    {
      field: 'is_completed',
      headerName: dictionary['done'],
      minWidth: 110,
      renderCell: (params) => {
        return (
          <Checkbox checked={params.row.is_completed} onChange={() => completeTask(params.row.is_completed, params.row.id)} name="checked" />
        )
      },
    },
    {
      field: 'title',
      headerName: dictionary['title'],
      headerClassName: isLightTable && 'round_corner',
      minWidth: 200,
      renderCell: CellExpand,
    },
    {
      field: 'due_date',
      headerName: dictionary['due_date'],
      minWidth: 150,
      renderCell: (params) => {
        const isPastDate = params.row?.due_date
          ? isBefore(new Date(UnformatDate(params.row.due_date)), new Date())
          : false
        return (
          <span className={clsx({ [classes.redText]: isPastDate })}>
            {params.row.due_date ? fDate(params.row.due_date) : '-'}
          </span>
        )
      },
    },
    ...operationColumnArr,
    {
      field: 'list_task',
      headerName: dictionary['list'],
      valueFormatter: (params) => params.value?.title || '-',
      minWidth: 160,
    },
    {
      field: 'assigned_to',
      headerName: dictionary['assigned_to'],
      valueFormatter: (params) => params.value?.display || '-',
      minWidth: 160,
    },
    {
      field: "linked",
      headerName: "",
      headerClassName: "no_text",
      minWidth: 100,
      sortable: false,
      renderCell: (params) => (
        <>
          {params.row.supplier &&
            <MLabel
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            >
              {dictionary['supplier']}
            </MLabel>
          }
          {params.row.tenant &&
            <MLabel
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            >
              {dictionary['tenant']}
            </MLabel>
          }
          {params.row.landlord &&
            <MLabel
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            >
              {dictionary['landlord']}
            </MLabel>
          }
          {params.row.investor &&
            <MLabel
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            >
              {dictionary['investor']}
            </MLabel>
          }
          {params.row.lots && params.row.length > 0 &&
            <MLabel
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            >
              {dictionary['lots']}
            </MLabel>
          }
        </>
      )
    },
    {
      field: 'action',
      headerName: dictionary['actions'],
      flex: 1,
      width: 24,
      cellClassName: 'actions_cell',
      sortable: false,
      renderCell: (params) => <ActionTable actions={actions(params.row)} />,
    },
  ]

  const filteredColumns = isLightTable ? [columns[0], columns[1]] : columns
  return (
    <div className={classes.containerTable}>
      <TabContext value={value}>
        {!isLightTable && (
          <div className={classes.tableNav}>
            <div className={classes.filterContainer}>
              <Badge
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                invisible={!isFiltersSelected}
                color="primary"
                className={classes.filterButton}
                variant="dot"
              >
                <Button
                  endIcon={<FilterListIcon />}
                  color={isFiltersSelected ? 'primary' : 'inherit'}
                  onClick={onOpenFiltersModal}
                >
                  {!isMobile && dictionary['filters']}
                </Button>
                <TabList
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  className={classes.tabList}
                  onChange={handleChange}
                >
                  {tasksTab.map((tab) => {
                    return (
                      <Tab
                        className={classes.tab}
                        key={tab?.value}
                        label={tab?.label}
                        value={tab?.value}
                      />
                    )
                  })}
                </TabList>
              </Badge>
            </div>
            <div>
              {!isProfilePage && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onModeSwitch}
                  className={classes.marginSide}
                >
                  {dictionary['manage_lists']}
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                className={classes.submit}
                onClick={onOpenTaskForm}
              >
                {!isMobile && dictionary['add_task']}
              </Button>
            </div>
          </div>
        )}

        <Table
          loading={isLoading}
          rows={taskList}
          page={page}
          handlePageChange={handlePageChange}
          count={count}
          title={isLightTable && title}
          columns={filteredColumns}
        />
      </TabContext>
    </div>
  )
}
