import client from '@utils/client'

export const onFetchImportList = () => {
  return client
    .get('/imports/')
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onFetchImportById = (importId) => {
  return client
    .get(`/imports/${importId}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onFetchImportExample = name => {
  return client
    .get(`/imports/examples/${name}/`, { responseType: 'blob' })
    .then((response) => {
      if (response?.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
    .catch((error) => {
      return error.response
    })
}

export const onCreateImport = (payload, setProgressFirstPart) => {
  return client
    .post('/imports/', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: progressEvent => {
        setProgressFirstPart(progressEvent.loaded * 50 / progressEvent.total)
      }
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onRunImport = (importId, setProgressSecondPart) => {
  return client
    .post(`/imports/${importId}/run/`, {}, {
      onUploadProgress: progressEvent => {
        setProgressSecondPart(50 + progressEvent.loaded * 50 / progressEvent.total)
      }
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
