import client from '@utils/client'

export const onGetMyOrganization = () => {
  return client
    .get('/profiles/profile/')
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onUpdateProfile = (payload) => {
  return client
    .patch('/profiles/profile/', { ...payload })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const generateDemo = () => {
  return client
    .post('/demos/generate_account/')
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onUpdateUser = (payload) => {
  return client
    .patch('/profiles/user/', { ...payload })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
export const onInviteUser = (email, otherParams = {}) => {
  return client
    .post('/invitations/', { email, ...otherParams })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const deleteOrguser = (id) => {
  return client
    .delete(`/orgusers/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const updateOrguser = (id, payload) => {
  return client
    .patch(`/orgusers/${id}/`, { ...payload })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const getOrguser = () => {
  return client
    .get(`/orgusers/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const getOrguserByType = (type, id) => {
  return client
    .get(`/orgusers/?${type}=${id}`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}