import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  requestMetricsDashboard,
  requestMetricsOverviewLine,
  requestMetricsByOperationId,
  // requestMetricsFinancial,
} from '@api/metrics'
import { Grid, Card } from '@material-ui/core'
import MapIcon from '@material-ui/icons/Map'
import GoogleMapStreetView from '@components/googleMap/GoogleMapStreetView.js'
import OccupancyRadialChart from './charts/OccupancyRadialChart'
import { useTranslation } from '@contexts/translation'
// Budget
import BudgetDashboard from '@components/dashboard/Budget'

// Revenue et dépenses
import IncomeExpenseView from '@components/dashboard/IncomeExpense/IncomeExpenseView'

// Line 'A facturer'
import OverviewView from '@components/dashboard/Overview/OverviewView'

// Margin Brut
import MarginLine from '@components/dashboard/metrics/MarginLine'

// Vacation Cost + Tenants
import VacationCostsView from '@components/dashboard/VacationCosts/VacationCostsView'
import TenantsView from '@components/dashboard/Tenants/TenantsView'

// Echances + Documents Plans

import DueDateView from '@components/dashboard/DueDate/DueDateView'
import DocumentsPlansView from '@components/dashboard/DocumentsPlans/DocumentsPlansView'

// Candidatures
import PotentialCandidatesView from '@components/dashboard/PotentialCandidates/PotentialCandidatesView'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  budgetLine: {
    marginBottom: theme.spacing(2),
    height: 'fit-content',
  },
  metricCards: {
    paddingLeft: theme.spacing(2),
    height: 'fit-content',
  },
  metricCard: {
    height: 'fit-content',
  },
  margin: {
    width: '100%',
    height: '100%',
  },
  map: {
    zIndex: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
  },
  mapWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
  },
  chart: {
    maxWidth: '65%',
    flexBasis: '65%',
  },
  actionsTimeline: {
    paddingTop: '0 !important',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  containerContent: {
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
  mapContainer: {
    height: '100%',
  },
}))

export default function DashboardOperationsView({ operationId, data }) {
  const classes = useStyles()
  const [metrics, setMetrics] = useState({})
  const currentYear = new Date().getFullYear()

  const [year, setYear] = useState(currentYear)
  const [isLoading, setIsLoading] = useState(false)

  const newYearFilter = (year) => {
    setYear(year)
  }


  const { dictionary } = useTranslation()
  useEffect(() => {
    const getMetrics = async () => {
      setIsLoading(true)
      try {
        const data = await requestMetricsDashboard(operationId)

        setMetrics({ data })
      } catch (error) {
        console.error(error)
      }
      setIsLoading(false)
    }
    getMetrics()
  }, [])


  return (
    <>
      <Grid item xs={12}>
        <Grid container justify="center">
          {/* 4 blocks billable etc */}
          <Grid
            xs={12}
            md={12}
            className={classes.containerContent}
            spacing={4}
          >
            <OverviewView
              operationId={operationId}
              landlordId={
                data &&
                data?.landlords &&
                data.landlords.length > 0 &&
                data.landlords[0].id
              }
            />
          </Grid>

          <Grid container className={classes.containerContent} spacing={4}>
            <Grid item xs={12} lg={4} md={12}>
              <OccupancyRadialChart
                isLoading={isLoading}
                occupancyRate={metrics?.data?.operations?.occupancy_rate}
                occupancyRateSurface={
                  metrics?.data?.operations?.occupancy_rate_surface
                }
                actualRent={metrics?.data?.operations?.actual_rent}
                potentialRent={metrics?.data?.operations?.potential_rent}
                occupiedSurface={metrics?.data?.operations?.occupied_surface}
                occupancySurface={metrics?.data?.operations?.occupancy_surface}
                surfaceArea={metrics?.data?.operations?.surface_area}
                surfaceAreaManual={
                  metrics?.data?.operations?.surface_area_manual
                }
                lotsAvailable={metrics?.data?.operations?.lots_available}
                parkingAvailable={metrics?.data?.operations?.parking_available}
              />
            </Grid>

            <Grid item xs={12} lg={5} md={12}>
              <BudgetDashboard operationId={operationId} />
            </Grid>

            <Grid item xs={12} lg={3} md={5}>
              <Card className={clsx(classes.root)}>
                {data.latitude && data.longitude ? (
                  <GoogleMapStreetView
                    className={classes.map}
                    center={{ lat: data.latitude, lng: data.longitude }}
                  />
                ) : (
                  <MapIcon sx={{ fontSize: 100 }} color="disabled" />
                )}
              </Card>
            </Grid>

            {/* Marge brut + graph */}
            <Grid item xs={12} lg={4} md={7}>
              <MarginLine operationId={operationId} year={year} />
            </Grid>
            <Grid xs={12} lg={8} md={12} item>
              <IncomeExpenseView operationId={operationId} changeYear={newYearFilter} year={year} currentYear={currentYear} />
            </Grid>
          </Grid>


          {/* Couts de vacances + Locataires  */}
          <Grid container className={classes.containerContent} spacing={4}>
            <Grid item xs={12} md={12} lg={7}>
              <Grid container className={classes.containerContent} spacing={4}>
                <Grid item xs={12} md={12} lg={12}>
                  <VacationCostsView operationId={operationId} year={year} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <DueDateView operationId={operationId} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={5} md={12}>
              <Grid container className={classes.containerContent} spacing={4}>
                <Grid item xs={12} lg={12} md={12}>
                  <TenantsView operationId={operationId} />
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                  <DocumentsPlansView data={[]} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </>
  )
}
