// ----------------------------------------------------------------------

export default function DataGrid({ theme }) {
  const isRTL = theme.direction === 'rtl'
  const thLeft = {
    paddingLeft: 24,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`,
  }
  const thRight = {
    paddingRight: 24,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`,
  }

  // Small table: 160px
  // Medium Table: 240px / 260px / 280px
  // Large Table: 380px  // 440px ?

  // height is important to allow dynamic height on row see : (https://github.com/mui-org/material-ui-x/issues/417)

  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          marginTop: 24,
          boxShadow: theme.shadows[25].z16,
          borderRadius: theme.shape.borderRadiusMd,
          overflowX: 'auto',

          // Table Responsive ?

          //Col Container

          '& .MuiDataGrid-columnsContainer': {
            borderBottom: 'none',
            overflow: 'initial',

            '@media (max-width: 600px)': {
              width: 'fit-content',
              overflow: 'initial',
            },
          },

          // Row
          '& .MuiDataGrid-row': {
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
            '@media (max-width: 600px)': {},
          },

          // Cell Header
          // using data-field = action bc it should always be the last column
          // and DataGrid has issue with different size, adding or removing a blank column that make
          // specific item hard to target

          '& .round_corner': {
            paddingRight: 24,
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
            boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`,
          },

          '& .round_corner_start_no_shadow': {
            paddingLeft: 16,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
            boxShadow: 'none !important',
          },

          '& .round_corner_end_no_shadow': {
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
            boxShadow: 'none !important',
          },

          '& .one_col': {
            paddingRight: 24,
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
            boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}, inset 8px 0 0 ${theme.palette.background.paper} !important`,
          },

          '& .no_text': {
            color: 'transparent',
          },

          '& .MuiDataGrid-columnHeader': {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.neutral,
            '&:first-of-type': isRTL ? thRight : thLeft,
            // Used because there is no action currently on leaseTable
            '&[data-field="status"]': {
              paddingRight: 24,
              borderTopRightRadius: theme.shape.borderRadius,
              borderBottomRightRadius: theme.shape.borderRadius,
              boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`,
            },
            '&[data-field="action"]': {
              color: 'transparent',
              paddingRight: 24,
              borderTopRightRadius: theme.shape.borderRadius,
              borderBottomRightRadius: theme.shape.borderRadius,
              boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`,
            },

            '@media (max-width: 600px)': {
              overflow: 'initial',
            },
          },

          // Cell
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
            '&:first-of-type': {
              paddingLeft: !isRTL && 24,
              paddingRight: isRTL && 24,
            },
            '&:nth-last-of-type(2)': {
              paddingLeft: isRTL && 24,
            },

            '@media (max-width: 600px)': {
              overflow: 'initial',
            },
          },

          '& .actions_cell': {
            justifyContent: 'flex-end !important',

          },

          '& .MuiDataGrid-cellRight': {
            justifyContent: 'flex-end',
          },

          // Useless Icon not removable by props

          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },

          // Footer
          '& .MuiDataGrid-footer': {
            marginTop: -16,
            height: 64,
          },

          '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important',
            minWidth: 'auto !important',
          },
          '& .MuiDataGrid-window': {
            position: 'absolute',
            minWidth: 'fit-content',
            maxWidth: 'initial',
            overflowX: 'hidden',
            overflowY: 'overlay !important',
            borderBottom: `solid 1px ${theme.palette.divider}`,
          },
        },
        autoHeight: {
          '& .MuiDataGrid-window': {
            overflowY: 'hidden !important',
          },
        },
      },
    },
  }
}
