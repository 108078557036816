import clsx from 'clsx'
import React from 'react'
import {last} from 'lodash'
import PropTypes from 'prop-types'
import Link from 'next/link'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {
  Typography,
  Box,
  Link as LinkStyled,
  Breadcrumbs,
} from '@material-ui/core'

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {},
}))

// ----------------------------------------------------------------------

const Separator = (
  <Box
    component="span"
    sx={{
      width: 4,
      height: 4,
      borderRadius: '50%',
      bgcolor: 'text.disabled',
    }}
  />
)

const SeparatorBackground = (
  <Box
    component="span"
    sx={{
      width: 4,
      height: 4,
      borderRadius: '50%',
      bgcolor: 'text.white',
    }}
  />
)

function LinkItem({link, withBackground}) {
  const {href, name, icon} = link
  const theme = useTheme()
  return (
    <Link href={`${href}`} passHref>
      <LinkStyled
        component="a"
        to={href}
        key={name}
        variant="body2"
        sx={{
          lineHeight: 2,
          display: 'flex',
          color: (withBackground && false)
            ? theme.palette.text.white
            : theme.palette.text.primary,
          alignItems: 'center',
          cursor: 'pointer',
          '& > div': {display: 'inherit'},
        }}
      >
        {icon && (
          <Box
            sx={{
              mr: 1,
              '& svg': {width: 20, height: 20},
            }}
          >
            {icon}
          </Box>
        )}
        <p
          style={{
            color: (withBackground && false)
              ? theme.palette.text.white
              : theme.palette.text.primary,
          }}
        >
          {name}
        </p>
      </LinkStyled>
    </Link>
  )
}

MBreadcrumbs.propTypes = {
  links: PropTypes.array.isRequired,
  activeLast: PropTypes.bool,
  className: PropTypes.string,
}

function MBreadcrumbs({
  withBackground,
  links,
  activeLast = false,
  className,
  ...other
}) {
  const classes = useStyles()
  const currentLink = last(links).name
  const listDefault = links.map((link) => <LinkItem link={link} />)
  const listActiveLast = links.map((link) => (
    <div key={link.name}>
      {link.name !== currentLink ? (
        <LinkItem withBackground={withBackground} link={link} />
      ) : (
        <Typography
          variant="body2"
          sx={{
            maxWidth: 260,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: (withBackground && false) ? '#f5f5f5b3' : 'text.disabled',
            textOverflow: 'ellipsis',
          }}
        >
          {currentLink}
        </Typography>
      )}
    </div>
  ))

  return (
    <Breadcrumbs
      separator={(withBackground && false) ? SeparatorBackground : Separator}
      className={clsx(classes.root, className)}
      {...other}
    >
      {activeLast ? listDefault : listActiveLast}
    </Breadcrumbs>
  )
}

export default MBreadcrumbs
