import clsx from 'clsx'
import React, { useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import SimpleBar from 'simplebar-react'
import { alpha, makeStyles } from '@material-ui/core/styles'
import 'simplebar/dist/simplebar.min.css'
// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden',
  },
  scroll: {
    maxHeight: '100%',
    '& .simplebar-scrollbar': {
      '&:before': {
        backgroundColor: alpha(theme.palette.grey[600], 0.48),
      },
      '&.simplebar-visible:before': {
        opacity: 1,
      },
    },
    '& .simplebar-vertical': {
      width: 10,
    },
    '& .simplebar-horizontal .simplebar-scrollbar': {
      height: 6,
    },
    '& .simplebar-mask': {
      zIndex: 'inherit',
    },
  },
}))

// ----------------------------------------------------------------------
let lastScroll = {}

Scrollbars.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

function Scrollbars({ children, className, ...other }) {
  const classes = useStyles()

  const simpleBarRef = useRef()

  const componentId = useMemo(() => {
    if (!children) return ''
    return '_source' in children ? JSON.stringify(children['_source']) : children.toString()
  }, [])

  const scrollAmountRef = useRef()

  useEffect(() => { // Hacky way to keep track of simple bar scrolling (Layout is not persisting because of a design flaw)
    const element = simpleBarRef.current.getScrollElement()
    const lastData = lastScroll[componentId] || { x: 0, y: 0 }
    element.scrollTo(lastData.x, lastData.y)

    const u = () => {
      const element = simpleBarRef.current.getScrollElement()
      scrollAmountRef.current = {
        x: element.scrollLeft,
        y: element.scrollTop,
      }
    }
    simpleBarRef.current.getScrollElement().addEventListener('scroll', u)
    u()

    return () => {
      simpleBarRef.current.getScrollElement().removeEventListener('scroll', u)
      lastScroll[componentId] = scrollAmountRef.current
    }
  }, [])

  // const scrollableNodeRef = useRef()
  // const refScroll = useRef()

  // useEffect(() => {
  //   scrollableNodeRef.current.recalculate()
  //   console.log('scrollableNodeRef', scrollableNodeRef.current.el) // <- the root element you applied SimpleBar on
  //   // console.log('refScroll ??', refScroll?.current?.offsetHeight)
  //   // scrollableNodeRef.current.el.scrollTop =  refScroll
  // })

  // const Child = ({children, refScroll}) => {
  //   return <div ref={refScroll}>{children}</div>
  // }

  return (
    <div className={classes.root}>
      <SimpleBar
        // ref={scrollableNodeRef}
        ref={simpleBarRef}
        timeout={500}
        clickOnTrack={true}
        className={clsx(classes.scroll, className)}
        {...other}
      >
        {/* <Child refScroll={refScroll}>{children}</Child> */}
        {children}
      </SimpleBar>
    </div>
  )
}

export default Scrollbars
