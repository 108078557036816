import React from 'react'

import { useFetchData } from '@api/fetchData'

import MenuItem from '@material-ui/core/MenuItem'

import { Select } from '.'

import { useTranslation } from '@contexts/translation'

export default function TenantsSelect({ path, control, error, errorMessage, fullWidth, defaultValue, id, children }) {
  const { dictionary } = useTranslation()
  const { data, isLoading, isError } = useFetchData(path || `/tenants/?operations=${id}&page_size=999`)

  if (isError) return <p>{dictionary["failed_to_load_data"]}...</p>

  return (
    <Select
      id="tenant"
      name="tenant"
      label={dictionary["tenant"]}
      control={control}
      variant="outlined"
      margin="normal"
      error={error}
      isLoading={isLoading}
      fullWidth={fullWidth}
      defaultValue={defaultValue}
      errorMessage={errorMessage}
    >
      {children}
      {data &&
        data.results.map((tenant) => {
          return (
            <MenuItem key={tenant.id} value={tenant.id}>
              {tenant.display}
            </MenuItem>
          )
        })}
    </Select>
  )
}
