import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'


import { useTranslation } from '@contexts/translation'
import { Modal } from '@components/common'
import { PaymentsTable } from '@components/payments'

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'scroll',
  },
  container: {
    zIndex: 99999,
    display: 'flex',
    width: '100%',
    overflow: "scroll",
    flexDirection: "column",
    padding: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      maxWidth: 800,
    },
  },
  line: {
    display: 'flex',
    '&:not(:first-child)': {
      marginTop: theme.spacing(2)
    }
  }
}))

export default function InvoicePaymentsDetails({ onClose, operationId, invoiceId, balance, tenant, fetchInvoice }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()

  return (
    <Modal className={classes.modalContainer} onClose={onClose}>
      <Paper className={classes.container}>
        <div className={classes.line}>
          <Typography variant="body2">
            {dictionary["invoice_balance"]} : {balance}
          </Typography>
        </div>
        <PaymentsTable isInvoice isOperation invoiceId={invoiceId} operationId={operationId} balance={balance} tenant={tenant} fetchInvoice={fetchInvoice} />
      </Paper>
    </Modal>
  )
}
