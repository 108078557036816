import { format, formatDistanceToNow } from 'date-fns';
import UnformatDate from './unformatDate';

// ----------------------------------------------------------------------

export function fDate(date) {
  const formatableDate = typeof(date) === 'string' ? UnformatDate(date) : date;
  return format(new Date(formatableDate), 'dd MMMM yyyy');
}

export function fDateTime(date, dateFormat = 'dd MMM yyyy HH:mm') {
  return format(new Date(date), dateFormat);
}
export function fMonthAndYeah(date) {
  return format(new Date(date), 'MMM yyyy');
}
export function fMonth(date) {
  return format(new Date(date), 'MMMM');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}
