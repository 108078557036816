import client from '@utils/client';

export const onCreateLandlord = (payload) => {
  return client
    .post('/landlords/', payload)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const onDeleteLandlord = (id) => {
  return client
    .delete(`/landlords/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const onUpdateLandlord = (payload, id) => {
  return client
    .patch(`/landlords/${id}/`, payload)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const addLandlordFile = (id, fileId, files) => {
  return client
    .patch(`/landlords/${id}/`, {
      files: files ? [...files, fileId] : [fileId]
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}





export const onGetLandlords = (operationId) => {
  return client
    .get(`/landlords/${operationId ? `?operations=${operationId}` : ''}`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}