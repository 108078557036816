import clsx from 'clsx'
import React, {useState} from 'react'
import PropTypes from 'prop-types'

import {useTheme, makeStyles} from '@material-ui/core/styles'
import {Typography, Box, Card, CardHeader, CardContent} from '@material-ui/core'
import {useTranslation} from '@contexts/translation'
import DocumentsPlansDetail from '@components/dashboard/DocumentsPlans/DocumentsPlansDetail'

const useStyles = makeStyles((theme, props) => ({}))

function DocumentsPlansView({className, data}) {
  const classes = useStyles()
  const theme = useTheme()
  const {dictionary} = useTranslation()

  return <DocumentsPlansDetail />
}

export default DocumentsPlansView
