import React, { useState } from 'react';
import { Typography, Button, Grid, CircularProgress, Tooltip } from '@material-ui/core';
import { useTranslation } from '@contexts/translation'
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { makeStyles } from '@material-ui/core/styles';
import { Table, CellExpand } from '@components/common';
import { fDateTime } from "@utils/formatTime";
import { useFetchData } from '@api/fetchData'
import { mutate } from 'swr'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  reloadButton: {
    padding: 4,
    minWidth: 'auto',
    marginRight: theme.spacing(1)
  }
}));


export default function ImportList({ onNewImportClick, operationId }) {
  const { dictionary } = useTranslation();
  const classes = useStyles();
  const [pageCount, setPageCount] = useState(1)

  const handlePageChange = (params) => {
    setPageCount(params + 1)
  }

  const fetchUrl = operationId ? `/imports/?operation=${operationId}&page=${pageCount}` : `/imports/?page=${pageCount}`
  const { data, isLoading, isError } = useFetchData(fetchUrl)

  const operationColumnArr = !operationId
    ? [
      {
        field: 'operation',
        headerName: dictionary['operation'],
        valueGetter: ({ row }) => row?.operation ? row.operation.display : '-',
        renderCell: CellExpand,
        minWidth: 160,
      },
    ]
    : []

  const columns = [
    {
      field: 'type_display',
      headerName: dictionary['type'],
      minWidth: 160,
    },
    {
      field: 'file_name',
      headerName: dictionary['filename'],
      minWidth: 160,
      flex: 1,
      renderCell: CellExpand
    },
    ...operationColumnArr,
    {
      field: 'uploaded_at',
      headerName: dictionary['date'],
      valueFormatter: (params) => fDateTime(params.value),
      minWidth: 160,
    },
    {
      field: 'status_display',
      headerName: dictionary['status'],
      headerClassName: 'round_corner',
      minWidth: 180,
      renderCell: (params) => (
        params.row.status === 4 ?
          <span>
            <Tooltip title={params.row.data?.errors}>
              <span>{params.row.status_display}</span>
            </Tooltip>
          </span> :
          params.row.status_display
      ),
    }
  ];

  return (
    <Table
      title={
        <div className={classes.header}>
          <Typography variant='h5'>
            {dictionary['imports']}
          </Typography>
          {isLoading ?
            <CircularProgress size={30} /> :
            <div>
              <Button
                variant='outlined'
                size='small'
                color='primary'
                className={classes.reloadButton}
                onClick={() => mutate(fetchUrl)}
              >
                <AutorenewIcon fontSize='small' />
              </Button>
              <Button
                variant='contained'
                size='small'
                color='primary'
                onClick={onNewImportClick}
              >
                {dictionary['new']}
              </Button>
            </div>
          }
        </div>
      }
      page={pageCount}
      handlePageChange={handlePageChange}
      count={data && data.count}
      loading={isLoading}
      rows={data && data.results ? data.results : []}
      columns={columns}
    />
  );
}
