import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { OutlinedInput, InputAdornment, Box } from '@material-ui/core'
import searchFill from '@iconify/icons-eva/search-fill';
import { Icon } from '@iconify/react';
import { useTranslation } from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  search: {
    maxWidth: 240,
    height: 36,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.background.neutral,
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.shadows[25].z8 },
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`
    },
  },
}))


function Search({ value, onChange }) {
  const classes = useStyles()
  const { dictionary } = useTranslation()


  return (
    <OutlinedInput
      value={value}
      onChange={onChange}
      placeholder={dictionary['search']}
      startAdornment={
        <InputAdornment position="start">
          <Box
            component={Icon}
            icon={searchFill}
            sx={{ color: 'text.disabled' }}
          />
        </InputAdornment>
      }
      className={classes.search}
    />
  )
}

export default Search
