import client from '@utils/client'

export const onCreateOrganization = (payload) => {
  return client
    .post('/organizations/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetOrganizationsList = () => {
  return client
    .get('/organizations/')
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetOrganizationDetails = (id) => {
  return client
    .get(`/organizations/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}


export const onUpdateOrganization = (id, payload) => {
  return client
    .patch(`/organizations/${id}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetUsersList = () => {
  return client
    .get(`/orgusers/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}