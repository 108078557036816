import client from '@utils/client'

export const onFetchTasks = (params = {}) => {
  return client
    .get('/tasks/', { params })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onCreateTask = payload => {
  return client
    .post('/tasks/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onEditTask = (taskId, payload) => {
  return client
    .patch(`/tasks/${taskId}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onDeleteTask = taskId => {
  return client
    .delete(`/tasks/${taskId}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onFetchLists = (params = {}) => {
  return client
    .get('/tasks/lists/', { params })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onCreateList = payload => {
  return client
    .post('/tasks/lists/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onEditList = (listId, payload) => {
  return client
    .patch(`/tasks/lists/${listId}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onDeleteList = listId => {
  return client
    .delete(`/tasks/lists/${listId}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
