import { alpha } from '@material-ui/core/styles'

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`
}
// SETUP COLORS

// Dark Blue
const PRIMARY = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#043176',
  main_dark: '#043176',
  dark: '#A7B9D4',
  darker: '#005249',
}

const PRIMARY_DARK = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#043176',
  main_dark: '#add8e6',
  dark: '#A7B9D4',
  darker: '#005249',
}

const ALTERNATE_PRIMARY = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#2083A2',
  dark: '#C9E1E8',
  darker: '#005249',
}

// Light gree n
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#67BDB8',
  dark: '#DBEFEA',
  darker: '#091A7A',
}
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
}
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
}

// Yellow
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFBE2E',
  dark: '#B78103',
  darker: '#7A4F01',
}

//Red
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#E62929',
  dark: '#B72136',
  darker: '#7A0C2E',
}

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#302E2E',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
}

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
}

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  alternante_primary: { ...ALTERNATE_PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_48],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
}

const COMMON_DARK = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY_DARK, contrastText: '#fff' },
  alternante_primary: { ...ALTERNATE_PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_48],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
}

const palette = {
  // LIGHT MODE
  light: {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      white: '#fff',
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: {
      paper: '#fff',
      default: '#fff',
      neutral: GREY[200],
    },
    switch: {
      paper: GREY[800],
      color: '#fff',
    },
    action: {
      active: GREY[600],
      ...COMMON.action,
    },
    menu: {
      backgroundSettings: '#DBEFEA',
      text: '#637381',
    },
  },

  // DARK MODE
  dark: {
    ...COMMON_DARK,
    mode: 'dark',
    text: {
      primary: '#fff',
      white: '#fff',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: GREY[500_16],
    },
    switch: {
      paper: '#fff',
      color: GREY[800],
    },
    action: {
      active: GREY[500],
      ...COMMON.action,
    },
    menu: {
      text: '#919EAB',
      backgroundSettings: '#7fa59b',
    },
  },
}

export default palette
