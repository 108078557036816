import React, { useState } from 'react'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from '@contexts/translation'
import TenantsDetail from '@components/dashboard/Tenants/TenantsDetail'
import { useFetchData } from '@api/fetchData'

const useStyles = makeStyles((theme, props) => ({}))

function TenantsView({ className, operationId }) {
  const classes = useStyles()
  const theme = useTheme()
  const { dictionary } = useTranslation()
  const { data, isLoading, isError } = useFetchData(`/tenants/?operations=${operationId}&page_size=999`, null, { revalidateOnFocus: false, revalidateOnReconnect: false })


  return <TenantsDetail operationId={operationId} data={data && data.results} />
}

export default TenantsView
