import React, { useState } from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'

import { useAuth } from '@contexts/auth'

import Navbar from '@components/common/Navbar'
import useBreakpoints from '@hooks/useBreakpoints'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
}))

export default function LayoutNoSidebar({ children, isPortal }) {
  const classes = useStyles()

  const { logout } = useAuth()

  const isMobile = useBreakpoints('down', 'sm')

  const [open, setOpen] = useState(true)
  const [isOpenMobile, setIsOpenMobile] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
    setIsOpenMobile(true)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Navbar
        open={false}
        noSidebar
        isPortal={isPortal}
        handleDrawerOpen={handleDrawerOpen}
        logout={logout}
      />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {children}
      </main>
    </div>
  )
}
