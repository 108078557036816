import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'

import { Modal } from '.'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { useTranslation } from '@contexts/translation'
import TextField from '@material-ui/core/TextField'
import { createLotsGroup, updateLotsGroup } from "@api/lots";
import { useFetchData } from '@api/fetchData'




const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerButtons: {
    height: 'fit-content',
    display: "flex",
    padding: theme.spacing(4),
  },
  container: {
    height: 'fit-content',
    padding: theme.spacing(4)
  },
  buttonCancel: {
    marginRight: theme.spacing(8),
  },
  form: {
    display: 'flex',
    flexDirection: "column",
    zIndex: 99999,
  }
}))

export default function ModalLotGroup({ onClose, idGroup, operationId, isEdit }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const formRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar()

  const { data, isLoading, isError } = isEdit
    ? useFetchData('/lots/groups/', idGroup)
    : {
      data: null,
      isLoading: null,
      isError: null,
    }

  const {
    handleSubmit,
    errors,
    setValue,
    register
  } = useForm({
    mode: 'onBlur', shouldUnregister: false
  })

  useEffect(() => {
    if (isEdit && data) {
      setValue('name', data?.name)
    }
  }, [data, isEdit])


  const onSubmit = async (data) => {
    setLoading(true)
    if (operationId) data['operation'] = operationId
    const response = await createLotsGroup(data)
    if (response) {
      if (response.status === 201) {
        enqueueSnackbar(dictionary["lot_group_created"], {
          variant: "success"
        })
        mutate(`/lots/groups/?operation=${operationId}`)
        onClose()
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  const onSubmitEdit = async (data) => {
    setLoading(true)
    const response = await updateLotsGroup(data, idGroup)
    if (response) {
      if (response.status === 200) {
        enqueueSnackbar(dictionary["lot_group_updated"], {
          variant: "success"
        })
        mutate(`/lots/groups/?operation=${operationId}`)
        onClose()
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <Modal className={classes.modalContainer} onClose={onClose}>
      <form ref={formRef} className={classes.form} noValidate onSubmit={handleSubmit(isEdit ? onSubmitEdit : onSubmit)}>
        <Paper className={classes.container}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            id="name"
            label={dictionary["name"]}
            name="name"
            inputRef={register}
            fullWidth
            error={errors.name}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors.name && errors.name.message}
          />
          <div className={classes.containerButtons}>
            <Button
              className={classes.buttonCancel}
              onClick={onClose}
              type="button"
              variant="contained"
              color="inherit"
            >
              {dictionary["cancel"]}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              {dictionary["confirm"]}
            </Button>
          </div>
        </Paper>
      </form>
    </Modal>
  )
}
