import React, { createContext, useState, useContext, useEffect } from 'react'
import { useRouter } from 'next/router'

import { onGetOperation } from '@api/operations'

const OperationContext = createContext({})

export const OperationProvider = ({ children }) => {
  const [operation, setOperation] = useState(null)
  const [update, setUpdate] = useState(false)
  const router = useRouter()

  async function getAndSetCurrentOperation(id) {
    if (!id) {
      setOperation(null)
    } else {
      const operation = await onGetOperation(id)
      setOperation(operation?.data)
    }
  }

  const updateBreadcrumb = () => {
    setUpdate(true)
  }

  useEffect(() => {
    getAndSetCurrentOperation(router?.query?.id)
    if (update) {
      setUpdate(false)
    }
  }, [router?.query?.id, update]);

  return (
    <OperationContext.Provider
      value={{ operation, updateBreadcrumb }}
    >
      {children}
    </OperationContext.Provider>
  )
}

export const useOperation = () => useContext(OperationContext)