import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import { FormControl, TextField } from '@material-ui/core'
import { MobileTimePicker } from '@material-ui/lab';

const DatePicker = ({
  name,
  label,
  control,
  error,
  id,
  errorMessage,
  children,
  fullWidth,
  required,
  disabled,
  clearable,
  ...props
}) => {
  const [value, setValue] = useState(null);
  return (
    <FormControl
      {...props}
      margin="normal"
      size="medium"
      style={{ width: fullWidth ? '100%' : '195px' }}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        as={
          <MobileTimePicker
            value={value}
            disabled={disabled}
            ampm={false}
            clearable={clearable}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                id={id}
                disabled={disabled}
                name={name}
                error={error}
                helperText={errorMessage}
                required={required}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                margin="normal"
              />
            )}
          />
        }
      />
    </FormControl>

  )
}

export default DatePicker



