import React, { useState, useEffect } from 'react'

import {
  List,
  Button,
  Dialog,
  ListItem,
  DialogTitle,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import { Loader } from '@components/common'

import { onUpdateProfile, generateDemo, onGetMyOrganization } from '@api/profile'
import { useRouter } from 'next/router'

import { useTranslation } from '@contexts/translation'
import { useFetchData } from '@api/fetchData'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'block',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  selectedOrg: {
    color: theme.palette.primary.main_dark,
    opacity: '1 !important',
  },
}))

export default function UsersSelect({ generateDemo, onOpen, orgId = '' }) {
  const { dictionary } = useTranslation()
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const [isDemoLoading, setIsDemoLoading] = useState(false)
  const router = useRouter()

  const [userInfo, setUserInfo] = useState()
  const { data, isLoading } = useFetchData(`/organizations/`)

  const handleClickOpen = () => {
    setOpen(true)
    onOpen()
  }

  useEffect(() => {
    const getProfile = async () => {
      const response = await onGetMyOrganization()
      if (response.status === 200) {
        setUserInfo(response.data)
      }
    }
    getProfile()
  }, [])

  const handleClose = async (orgId) => {
    const response = await onUpdateProfile({ selected_org: orgId })
    if (response) {
      if (router.pathname === '/') {
        router.reload()
      } else {
        router.push(`/`)
        setOpen(false)
      }
    }
  }

  const createDemo = () => {
    generateDemo()
    setOpen(false)
  }

  return (
    <>
      <MenuItem onClick={handleClickOpen} component="a">
        {dictionary['switch_account']}
      </MenuItem>
      <Dialog
        className={classes.container}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle id="simple-dialog-title">
          {dictionary['switch_account']}
        </DialogTitle>
        {isLoading || isDemoLoading ? (
          <div
            style={{
              width: 400,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 100,
              overflow: 'hidden',
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <List sx={{ marginLeft: 2, marginRight: 2, width: 400 }}>
              {data &&
                data.map((org) => (
                  <ListItem
                    sx={{ marginLeft: 2 }}
                    button
                    onClick={() => handleClose(org.id)}
                    className={
                      userInfo?.selected_org === org.id
                        ? classes.selectedOrg
                        : classes.choiceOrg
                    }
                    key={org.id}
                    disabled={userInfo?.selected_org === org.id}
                  >
                    <ListItemText primary={org.display} />
                  </ListItem>
                ))}
            </List>
            <div className={classes.buttonContainer}>
              <Button
                variant="outlined"
                isDisabled={isDemoLoading}
                onClick={() => createDemo()}
                sx={{ margin: 2 }}
              >
                {dictionary['generate_demo']}
              </Button>
            </div>
          </>
        )}
      </Dialog>
    </>
  )
}
