import React from 'react'

import {Box} from '@material-ui/core'

// ----------------------------------------------------------------------

const ICON_SIZE = {width: 20, height: 20}

export default function TreeView({theme}) {
  return {
    MuiTreeItem: {
      styleOverrides: {
        label: {...theme.typography.body2},
        iconContainer: {width: 'auto'},
      },
    },
  }
}
