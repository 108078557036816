import React, { useState } from 'react';
import {Grid, Typography, Button, List, ListItem, ListItemText, ListItemIcon, CircularProgress} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTranslation } from '@contexts/translation';
import { makeStyles } from '@material-ui/core/styles';
import { onFetchImportExample } from '@api/imports';
import UploadSingleFile from "@components/common/UploadSingleFile";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2)
  },
  dropZone: {
    height: '100%'
  },
  sampleContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2)
  },
  stepItem: {
    paddingLeft: theme.spacing(2),
    alignItems: 'normal'
  },
  actionButtonsContainer: {
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

export default function UploadView({importType, onGoBack, onUploadConfirm}) {
  const [isSampleLoading, setIsSampleLoading] = useState(false);
  const [file, setFile] = useState(null);
  const { dictionary } = useTranslation();
  const classes = useStyles();

  const handleDownloadSampleClick = () => {
    setIsSampleLoading(true);
    onFetchImportExample(importType).finally(() => {setIsSampleLoading(false)});
  }

  const handleUploadConfirm = () => {
    onUploadConfirm(file.file);
  }

  const renderListStep = (index, textNode) => (
    <ListItem className={classes.stepItem}>
      <ListItemIcon>
        {index}.
      </ListItemIcon>
      <ListItemText>
        {textNode}
      </ListItemText>
    </ListItem>
  );

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={6}>
        <UploadSingleFile
          className={classes.dropZone}
          acceptFiles='.csv'
          value={file}
          onChange={setFile}
        />
      </Grid>
      <Grid item xs={6}>
        <div className={classes.sampleContainer}>
          <Typography variant='h6'>
            {dictionary['new_to_imports']}
          </Typography>
          <List>
            {renderListStep(1, <>
              {dictionary['download_sample_csv_full_text']}
              <br />
              <Button
                variant='contained'
                size='small'
                disabled={isSampleLoading}
                onClick={handleDownloadSampleClick}
                startIcon={isSampleLoading ? <CircularProgress size={18} /> : <GetAppIcon />}
              >
                {dictionary['download_sample_file']}
              </Button>
            </>)}
            {renderListStep(2, dictionary['replace_the_sample_file_data_full_text'])}
            {renderListStep(3, dictionary['upload_your_file_to_begin_import'])}
            {renderListStep(4, dictionary['preview_data'])}
            {renderListStep(5, dictionary['confirm_data_to_be_imported'])}
            {renderListStep(6, `${dictionary['done']}!`)}
          </List>
        </div>
      </Grid>
      <Grid container item className={classes.actionButtonsContainer}>
        <Button
          onClick={onGoBack}
          variant='outlined'
        >
          {dictionary['back']}
        </Button>
        <Button
          onClick={handleUploadConfirm}
          variant='contained'
          disabled={!file}
        >
          {dictionary['confirm']}
        </Button>
      </Grid>
    </Grid>
  )
}
