import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  investor: {},
};

const slice = createSlice({
  name: 'investors',
  initialState,
  reducers: {
    getInvestors(state, action) {
      state.data = action.payload;
    },
    getOneInvestor(state, action) {
      state.investor = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getInvestors, getOneInvestor } = slice.actions;
