export default function GetFormattedDate(time) {
  if (typeof time === 'string' || time instanceof String) {
    const splitDate = time.split('-')
    if (splitDate[0].length === 4) {
      time = unformattDateReverse(time)
    } else {
      time = unformattDate(time)
    }
  }
  const month = time.getMonth() + 1;
  const day = time.getDate();
  const year = time.getFullYear();
  return day + '-' + month + '-' + year;
}

export const unformatTime = (time) => {
  const todayDate = new Date()
  const splitDate = time.split(':');
  const hour = splitDate[0]
  const minutes = splitDate[1]
  return todayDate.setHours(hour, minutes)
}


export const formatDate = (dateString) =>
  dateString.split('-').reverse().join('-');

export function unformattDate(time) {
  const splitDate = time.split('-');
  const year = splitDate[2];
  const month = splitDate[1] - 1;
  const day = splitDate[0];
  return new Date(year, month, day);
}

export function unformattDateReverse(time) {
  const splitDate = time.split('-');
  const year = splitDate[0];
  const month = splitDate[1] - 1;
  const day = splitDate[2];
  return new Date(year, month, day);
}

export function getFormattedDateRange(time) {
  return time.toString().replace(/\//g, '-');
}

export function unformatTimeRange(time) {
  return time.replace(/-/g, '/');
}

export function unformatRange(time) {
  const splitDate = time.split('/');
  const year = splitDate[2];
  const month = splitDate[1] - 1;
  const day = splitDate[0];
  return new Date(year, month, day);
}
