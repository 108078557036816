import React, { useState } from 'react'
import { mutate } from 'swr'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Button } from '@material-ui/core'
import useBreakpoints from '@hooks/useBreakpoints'
import { numberWithSpaces } from '@utils/numberWithSpaces'
import { ModalOperationsSelect, Table, FooterRowOperationBalance } from '@components/common'
import AddIcon from '@material-ui/icons/Add'
import { useTranslation } from '@contexts/translation'
import Link from 'next/link'


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  containerTable: {
    width: '100%',
  },
  tableNav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  submit: {
    '@media (max-width: 600px)': {
      minWidth: 'initial',
      padding: theme.spacing(1),
      '& span': {
        marginRight: '0',
        marginLeft: '0',
      },
    },
  },
}))

export default function LinkedOperationPage({ id, data, fetchUrl, type, isLoading, operationId }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const isMobile = useBreakpoints('down', 'md')

  const [isOpenForm, setIsOpenForm] = useState(false)


  const handleFormOpen = () => {
    setIsOpenForm(true)
  }
  const handleFormClose = () => {
    setIsOpenForm(false)
  }

  const tenant_columns = [
    {
      field: 'name',
      headerName: dictionary['name'],
      flex: 1,
      minWidth: 240,
      renderCell: (params) => (
        <Link href={`/operations/${params.row.id}/`}>
          <a>{params.row.name}</a>
        </Link>
      )
    },
    {
      field: 'balance',
      headerName: dictionary['balance'],
      headerClassName: 'round_corner',
      type: 'number',
      valueFormatter: (params) => numberWithSpaces(params.value),
      flex: 1,
      minWidth: 160,
    },
  ]

  const supplier_columns = [
    {
      field: 'name',
      headerName: dictionary['name'],
      flex: 1,
      minWidth: 240,
      renderCell: (params) => (
        <Link href={`/operations/${params.row.id}/`}>
          <a>{params.row.name}</a>
        </Link>
      )
    },
    {
      field: 'balance',
      headerName: dictionary['balance'],
      headerClassName: 'round_corner',
      type: 'number',
      valueFormatter: (params) => numberWithSpaces(params.value),
      flex: 1,
      minWidth: 160,
    },
  ]

  return (

    <div className={classes.containerTable}>
      <div className={classes.tableNav}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleFormOpen}
        >
          {dictionary['manage_operations']}
        </Button>
      </div>
      <Table
        rows={data && data.operations ? data.operations : [{ id: 0 }]}
        columns={type === 'tenant' ? tenant_columns : supplier_columns}
        loading={isLoading}
        components={{
          Footer: FooterRowOperationBalance
        }}
      />
      {isOpenForm &&
        <ModalOperationsSelect
          operations={data && data.operations}
          onClose={handleFormClose}
          fetchUrl={fetchUrl}
          type={type}
          id={id}
          operationId={operationId}
        />
      }
    </div>
  )
}
