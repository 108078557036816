import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'

import { Modal } from '@components/common'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { Select } from "@components/form"

import { useFetchData } from '@api/fetchData'

import { useTranslation } from '@contexts/translation'
import { onCreateCurrency } from '@api/currencies'

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerButtons: {
    height: 'fit-content',
    display: "flex",
    padding: theme.spacing(4),
  },
  container: {
    height: 'fit-content',
    maxWidth: 350,
    padding: theme.spacing(4)
  },
  buttonCancel: {
    marginRight: theme.spacing(8),
  },
  form: {
    display: 'flex',
    flexDirection: "column",
    zIndex: 99999,
  }
}))

export default function ModalPaidDate({ onClose, fetchUrl }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()


  const currenciesUrl = `/currencies/default/?page_size=999`
  const { data, isLoading, isError } = useFetchData(currenciesUrl)

  const orgaCurrencies = `/currencies/?page_size=999`
  const { data: dataOrga, isLoading: isLoadingOrga, isError: isErrorOrga } = useFetchData(orgaCurrencies)



  const {
    handleSubmit,
    errors,
    register,
    control,
    setError
  } = useForm({
    mode: 'onBlur',
    shouldUnregister: false
  })


  let currencyNoDuplicate = (data, dataOrga) => [].concat(
    data.filter(obj1 => dataOrga.every(obj2 => obj1.name !== obj2.name)),
    dataOrga.filter(obj2 => data.every(obj1 => obj2.name !== obj1.name))
  );


  const onSubmit = async (newData) => {
    setLoading(true)
    let formData = {}
    if (newData.currency) {
      let currencyObject = data.results.filter(currency => currency.id === newData.currency)
      formData['name'] = currencyObject[0].name
      formData['longname'] = currencyObject[0].longname
      formData['symbol'] = currencyObject[0].symbol
    }

    const response = await onCreateCurrency(formData)


    if (response) {
      if (response.status === 201) {
        enqueueSnackbar(dictionary[`currency_added`], {
          variant: "success"
        })
        mutate(fetchUrl)
        onClose()
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <Modal className={classes.modalContainer} onClose={onClose}>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.container}>
          <Select
            variant="outlined"
            required
            margin="normal"
            fullWidth
            isLoading={isLoading}
            id="currency"
            label={dictionary["currencies"]}
            name="currency"
            inputRef={register}
            error={errors.currency}
            control={control}
            helperText={errors.currency && errors.currency.message}
            MenuProps={{
              getContentAnchorEl: () => null,
            }}
          >
            {data && data.results && dataOrga && dataOrga.results && currencyNoDuplicate(data.results, dataOrga.results)?.map(currency => (
              <MenuItem key={currency?.id} value={currency?.id}>
                {currency.display} - {currency.symbol}
              </MenuItem>
            ))}
          </Select>
          <div className={classes.containerButtons}>
            <Button
              className={classes.buttonCancel}
              onClick={onClose}
              type="button"
              variant="contained"
              color="inherit"
            >
              {dictionary["cancel"]}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              {dictionary["confirm"]}
            </Button>
          </div>
        </Paper>
      </form>
    </Modal>
  )
}
