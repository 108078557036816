import React from 'react'

import { useFetchData } from '@api/fetchData'

import MenuItem from '@material-ui/core/MenuItem'

import { Select } from '.'

import { useTranslation } from '@contexts/translation'

export default function LandlordsSelect({
  control, error, errorMessage, fullWidth, defaultValue, children, operationId
}) {
  const { dictionary } = useTranslation()
  const { data, isLoading, isError } = useFetchData(`/landlords/?operations=${operationId}`)

  if (isError) return <p>{dictionary["failed_to_load_data"]}...</p>

  return (
    <Select
      id="landlord"
      name="landlord"
      label={dictionary["navigation_landlord"]}
      control={control}
      variant="outlined"
      margin="normal"
      error={error}
      isLoading={isLoading}
      fullWidth={fullWidth}
      defaultValue={defaultValue}
      errorMessage={errorMessage}
    >
      {children}
      {data &&
        data.results.map((landlord) => {
          return (
            <MenuItem key={landlord.id} value={landlord.id}>
              {landlord.display}
            </MenuItem>
          )
        })}
    </Select>
  )
}
