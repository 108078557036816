export const numberWithSpaces = (x) =>
  x?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export const numberWithSpacesNoDecimalAdded = (x) => {
  const roundedNumber = Math.round(x * 100) / 100
  return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const numberWithSpaceInt = (x) => {
  const roundedNumber = Math.trunc(x)
  return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
