import React from 'react'

import { useFetchData } from '@api/fetchData'


import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'


import { useTranslation } from '@contexts/translation'
import { Modal } from '@components/common'
import { InvoicePaymentsTable } from '.'
import { numberWithSpaces } from '@utils/numberWithSpaces'

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    height: 'fit-content',
    minWidth: 800,
    zIndex: 99999,
    display: 'flex',
    flexDirection: "column",
    padding: theme.spacing(4),
    '@media (max-width: 600px)': {
      minWidth: 280,
      maxWidth: 280,
    },
  },
  line: {
    display: 'flex',
    '&:not(:first-child)': {
      marginTop: theme.spacing(2)
    }
  }
}))

export default function PaymentsDetails({ onClose, operationId, paymentId }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()


  const fetchUrl = `/payments/${paymentId}/`
  const { data, isLoading, isError } = useFetchData(fetchUrl)

  return (
    <Modal className={classes.modalContainer} onClose={onClose}>

      <Paper className={classes.container}>
        {data && <>
          <div className={classes.line}>
            <Typography variant="body2">
              {dictionary["date"]} : {data.date}
            </Typography>
          </div>
          <div className={classes.line}>
            <Typography variant="body2">
              {dictionary["amount"]} : {data.amount && numberWithSpaces(data.amount)}
            </Typography>
          </div>
          <div className={classes.line}>
            <Typography variant="body2">
              {dictionary["remaining_amount"]} : {data.remaining_amount && numberWithSpaces(data.remaining_amount)}
            </Typography>
          </div>
          <div className={classes.line}>
            <Typography variant="body2">
              {dictionary["type"]} : {data.type}
            </Typography>
          </div>
        </>
        }
        <InvoicePaymentsTable remainingAmount={data && data.remaining_amount} operationId={operationId ? operationId : data && data.operation.id} paymentId={paymentId} paymentFetchUrl={fetchUrl} />
      </Paper>
    </Modal>
  )
}
