import React, { useState } from 'react'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import FilterListIcon from "@material-ui/icons/FilterList";


import { ActionTable, ModalDelete, ModalForm, Table, CellExpand } from '@components/common'

import { onDeletePayment, onDeletePaymentInvoice } from '@api/payments'
import { useFetchData } from '@api/fetchData'

import { useTranslation } from '@contexts/translation'
import Page404View from 'pages/404'
import { PaymentsDetails, PaymentsFilterModal, PaymentsForm } from '.'
import useBreakpoints from '@hooks/useBreakpoints'
import { numberWithSpaces } from '@utils/numberWithSpaces'
import FooterRow from './FooterRow'
import FooterRowGlobal from './FooterRowGlobal'
import FooterRowInvoice from './FooterRowInvoice'
import { Badge } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  containerTable: {
    width: '100%',
  },
  tableNav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  tableNavInvoice: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
  link: {
    marginLeft: '10px',
  },
  gridContainer: {
    border: 'none',
  },
  overflow: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerDelete: {
    height: 'fit-content',
    padding: theme.spacing(4),
  },
  submit: {
    '@media (max-width: 600px)': {
      minWidth: 'initial',
      padding: theme.spacing(1),
      '& span': {
        marginRight: '0',
        marginLeft: '0',
      },
    },
  },
  buttonDelete: {
    marginRight: theme.spacing(8),
  },
}))

export default function PaymentsTable({ operationId, isOperation, isInvoice, invoiceId, balance, tenant, fetchInvoice, tenantId, location }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [isOpenForm, setIsOpenForm] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [idRow, setIdRow] = useState(null)
  const [pageCount, setPageCount] = useState(1)

  const initialFiltersState = { operation: operationId, tenant: tenantId }
  const [isFiltersModalOpened, setIsFiltersModalOpened] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(initialFiltersState);

  const isMobile = useBreakpoints('down', 'sm');


  const handleOpenFiltersModal = () => {
    setIsFiltersModalOpened(true)
  };

  const handleCloseFiltersModal = () => {
    setIsFiltersModalOpened(false);
  };

  const handleFiltersApply = filters => {
    setSelectedFilters(filters);
    setPageCount(1)
    handleCloseFiltersModal();
  };

  const isFiltersSelected = operationId || tenantId || selectedFilters.tenant || selectedFilters.operation || selectedFilters.date;


  const operationFilter = selectedFilters.operation !== undefined ? `&operation=${selectedFilters.operation}` : ''
  const tenantFilter = selectedFilters.tenant !== undefined ? `&tenant=${selectedFilters.tenant}` : ''
  const dateStartFilter = selectedFilters.start_date !== undefined ? `&date_start=${selectedFilters.start_date}` : ''
  const dateEndFilter = selectedFilters.end_date !== undefined ? `&date_end=${selectedFilters.end_date}` : ''

  const fetchUrl = isInvoice
    ? `/payments/invoice-payment/?invoice=${invoiceId}&page=${pageCount}`
    : tenantId && operationId ? `/payments/?operation=${operationId}&tenant=${tenantId}&page=${pageCount}`
      : isOperation ? `/payments/?operation=${operationId}&page=${pageCount}${tenantFilter}${dateStartFilter}${dateEndFilter}` : `/payments/?page=${pageCount}${tenantFilter}${operationFilter}${dateStartFilter}${dateEndFilter}`

  const { data, isLoading, isError } = useFetchData(fetchUrl)

  if (isError) return <Page404View />


  const handleFormOpen = () => {
    setIsOpenForm(true)
  }
  const handleFormClose = () => {
    setIsOpenForm(false)
  }

  const handleEditOpen = () => {
    setIsEdit(true)
  }
  const handleEditClose = () => {
    setIsEdit(false)
  }

  const handleDeleteOpen = () => {
    setIsDelete(true)
  }

  const handleDeleteClose = () => {
    setIsDelete(false)
  }

  const onDelete = async () => {
    const response = await onDeletePayment(idRow)

    if (response) {
      if (response.status === 204) {
        mutate(fetchUrl)
        if (isInvoice) mutate(fetchInvoice)
        enqueueSnackbar(dictionary['payment_deleted'], {
          variant: 'success',
        })
        handleDeleteClose()
      }
    }
  }

  const onDeleteInvoicePayment = async () => {
    const response = await onDeletePaymentInvoice(idRow)
    if (response.status === 204) {
      mutate(fetchUrl)
      if (isInvoice) mutate(fetchInvoice)
      enqueueSnackbar(dictionary['payment_deleted'], {
        variant: 'success',
      })
      handleDeleteClose()
    }
  }

  const handlePageChange = (params) => {
    setPageCount(params + 1)
  }

  const handleDetailsOpen = (id) => {
    setIsDetailsOpen(true)
    setIdRow(id)
  }

  const handleDetailClose = () => {
    setIsDetailsOpen(false)
  }

  const actions = (id) => [
    !isInvoice && {
      name: dictionary['invoice'],
      onClick: () => {
        handleDetailsOpen(id)
      }
    },
    {
      name: dictionary['edit'],
      onClick: () => {
        handleEditOpen()
        setIdRow(id)
      },
    },
    {
      name: dictionary['delete'],
      onClick: () => {
        handleDeleteOpen()
        setIdRow(id)
      },
    },
  ]

  const columns = [
    {
      field: 'display',
      headerName: dictionary['description'],
      minWidth: 240,
      renderCell: (params) => (
        <div className={classes.overflow}>
          {isInvoice ?
            (
              <>
                {params.row.display}
              </>
            ) : (
              <a onClick={() => handleDetailsOpen(params.row.id)} >
                {params.row.display}
              </a>
            )
          }
        </div>
      )
    },
    {
      field: 'date',
      headerName: dictionary['date'],
      type: 'date',
      minWidth: 160,
    },
    {
      field: 'operation',
      headerName: dictionary['operation'],
      minWidth: 260,
      valueGetter: ({ row }) => row?.operation?.display,
      renderCell: CellExpand,

    },
    {
      field: 'tenant',
      headerName: dictionary['tenant'],
      minWidth: 180,
      valueGetter: ({ row }) => row?.tenant ? row.tenant.display : '-',
      renderCell: CellExpand,
    },
    {
      field: 'type',
      headerName: dictionary['type'],
      minWidth: 160,
      renderCell: (params) => (
        <> {params.row.type && dictionary[params.row.type]} </>
      ),
    },
    {
      field: 'amount',
      headerName: dictionary['amount'],
      type: 'number',
      minWidth: 140,
      flex: 1,
      valueFormatter: (params) => numberWithSpaces(params.value)
    },
    {
      field: 'action',
      headerName: dictionary['actions'],
      cellClassName: 'actions_cell',
      width: 24,
      sortable: false,
      renderCell: (params) => <ActionTable actions={actions(params.row.id)} />,
    },
  ]

  const operation_columns = [
    {
      field: 'display',
      headerName: dictionary['description'],
      minWidth: 240,
      renderCell: (params) => (
        <div className={classes.overflow}>
          {isInvoice ?
            (
              <>
                {params.row.display}
              </>
            ) : (
              <a onClick={() => handleDetailsOpen(params.row.id)} >
                {params.row.display}
              </a>
            )
          }
        </div>
      )
    },
    {
      field: 'date',
      headerName: dictionary['date'],
      type: 'date',
      minWidth: 160,
    },
    {
      field: 'tenant',
      headerName: dictionary['tenant'],
      minWidth: 180,
      valueGetter: ({ row }) => row?.tenant ? row.tenant.display : '-',
      renderCell: CellExpand,
    },
    {
      field: 'type',
      headerName: dictionary['type'],
      minWidth: 160,
      renderCell: (params) => (
        <> {params.row.type && dictionary[params.row.type]} </>
      ),
    },
    {
      field: 'amount',
      headerName: dictionary['amount'],
      type: 'number',
      flex: 1,
      minWidth: 140,
      valueFormatter: (params) => numberWithSpaces(params.value)
    },
    {
      field: 'action',
      headerName: dictionary['actions'],
      cellClassName: 'actions_cell',
      width: 24,
      sortable: false,
      renderCell: (params) => <ActionTable actions={actions(params.row.id)} />,
    },
  ]


  const invoiceColumns = [
    {
      field: 'date',
      headerName: dictionary['date'],
      type: 'date',
      minWidth: 140,
      valueGetter: ({ row }) => row?.payment?.date
    },
    {
      field: 'description',
      headerName: dictionary['description'],
      minWidth: 240,
      valueGetter: ({ row }) => row?.payment?.description
    }, {
      field: 'type',
      headerName: dictionary['type'],
      minWidth: 140,
      valueGetter: ({ row }) => row?.payment?.type
    },
    {
      field: 'amount',
      headerName: dictionary['amount'],
      type: 'number',
      minWidth: 140,
      flex: 1,
      valueFormatter: (params) => numberWithSpaces(params.value)
    },
    {
      field: 'action',
      headerName: dictionary['actions'],
      width: 24,
      cellClassName: 'actions_cell',
      sortable: false,
      renderCell: (params) => <ActionTable actions={actions(params.row.id)} />,
    },
  ]

  return (
    <div className={classes.containerTable}>
      <div className={isInvoice ? classes.tableNavInvoice : classes.tableNav}>
        {!isInvoice &&
          <Badge
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            invisible={!isFiltersSelected}
            color='primary'
            className={classes.filterButton}
            variant='dot'
          >
            <Button
              endIcon={<FilterListIcon />}
              type="button"
              color={isFiltersSelected ? 'primary' : 'inherit'}
              onClick={handleOpenFiltersModal}
            >
              {!isMobile && dictionary['filters']}
            </Button>
          </Badge>
        }
        <Button
          variant="contained"
          color="primary"
          type="button"
          startIcon={<AddIcon />}
          className={classes.submit}
          onClick={handleFormOpen}
        >
          {!isMobile && dictionary['add_payment']}
        </Button>
      </div>
      <Table
        className={classes.gridContainer}
        rows={data && data.results ? data.results : []}
        loading={isLoading}
        page={pageCount}
        handlePageChange={handlePageChange}
        count={data && data.count}
        components={{
          Footer: isInvoice ? FooterRowInvoice : isOperation ? FooterRow : FooterRowGlobal,
        }}
        columns={isInvoice ? invoiceColumns : isOperation ? operation_columns : columns}
      />
      {(isOpenForm || isEdit) && (
        <ModalForm
          className={classes.modalContainer}
          isEdit={isEdit}
          type={dictionary['payment']}
          onClose={isEdit ? handleEditClose : handleFormClose}
        >
          <PaymentsForm
            handleClose={isEdit ? handleEditClose : handleFormClose}
            isEdit={isEdit}
            id={idRow}
            balance={balance}
            isInvoice={isInvoice}
            invoiceId={invoiceId}
            fetchUrl={fetchUrl}
            tenant={tenant}
            isOperation={isOperation}
            fetchInvoice={fetchInvoice}
            operationId={operationId}
            tenantId={tenantId}
            location={location}
          />
        </ModalForm>
      )}
      {isDelete && (
        <ModalDelete
          onClose={handleDeleteClose}
          onDelete={isInvoice ? onDeleteInvoicePayment : onDelete}
        />
      )}

      {isDetailsOpen &&
        <PaymentsDetails operationId={operationId} paymentId={idRow} onClose={handleDetailClose} />
      }


      {isFiltersModalOpened && (
        <ModalForm type={dictionary['filters']} onClose={handleCloseFiltersModal}>
          <PaymentsFilterModal
            tenantId={tenantId}
            filterValues={selectedFilters}
            onFiltersApply={handleFiltersApply}
          />
        </ModalForm>
      )}

    </div>
  )
}
