import clsx from 'clsx';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, StreetViewService, useJsApiLoader, Marker } from '@react-google-maps/api';
import useBreakpoints from '@hooks/useBreakpoints'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[25].z16,
    borderRadius: theme.shape.borderRadiusMd,
    [theme.breakpoints.down('md')]: {
      height: '330px !important'
    },
  }
}));

GoogleMapStreetView.propTypes = {
  themes: PropTypes.object,
  className: PropTypes.string
};

function GoogleMapStreetView({ themes, className, center, ...other }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    version: "3.43.8",
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY
  })

  const isMobile = useBreakpoints('down', 'md');

  const classes = useStyles();

  const mapOptions = {
    zoom: 14,
    minZoom: isMobile ? 1 : 2,
    center: center,
    controlSize: 24,
    styles: [
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#e9e9e9"
          },
          {
            lightness: 17
          }
        ]
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5"
          },
          {
            lightness: 20
          }
        ]
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ffffff"
          },
          {
            lightness: 17
          }
        ]
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#ffffff"
          },
          {
            lightness: 29
          },
          {
            weight: 0.2
          }
        ]
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff"
          },
          {
            lightness: 18
          }
        ]
      },
      {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff"
          },
          {
            lightness: 16
          }
        ]
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5"
          },
          {
            lightness: 21
          }
        ]
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#dedede"
          },
          {
            lightness: 21
          }
        ]
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            visibility: "on"
          },
          {
            color: "#ffffff"
          },
          {
            lightness: 16
          }
        ]
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            saturation: 36
          },
          {
            color: "#333333"
          },
          {
            lightness: 40
          }
        ]
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off"
          }
        ]
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [
          {
            color: "#f2f2f2"
          },
          {
            lightness: 19
          }
        ]
      },
      {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#fefefe"
          },
          {
            lightness: 20
          }
        ]
      },
      {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#fefefe"
          },
          {
            lightness: 17
          },
          {
            weight: 1.2
          }
        ]
      }
    ]
  };

  const onLoad = (streetViewService) => {
    streetViewService.getPanorama(
      { location: center, radius: 50 }
    );
  };

  return isLoaded ? (
    <div className={clsx(classes.root, className)}>
      <GoogleMap
        options={mapOptions}
        mapContainerStyle={{
          width: '100%',
          height: '100%'
        }}
        {...other}
      >
        <Marker
          icon={"/static/illustrations/googleMarker.png"}
          position={center}
        />
        <StreetViewService onLoad={onLoad} />
      </GoogleMap>
    </div>
  ) : <></>;
}

export default memo(GoogleMapStreetView);
