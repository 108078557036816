import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  organization: null,
};

const slice = createSlice({
  name: 'orgznizations',
  initialState,
  reducers: {
    getOrganization(state, action) {
      state.organization = action.payload.data.filter(organization => organization.id === action.payload.dataProfile.selected_org);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getOrganization } = slice.actions;
