import React from 'react'

import { useFetchData } from '@api/fetchData'

import MenuItem from '@material-ui/core/MenuItem'

import { Select } from '.'
import { Loader } from '@components/common'

import { useTranslation } from '@contexts/translation'

export default function InvoiceSelect({ children, control, error, errorMessage, fullWidth, defaultValue, operationId, filter = null,
}) {
  const { dictionary } = useTranslation()

  const fetchUrl = `/invoices/?operation=${operationId}${filter}`
  const { data, isLoading, isError } = useFetchData(fetchUrl)

  if (isError) return <p>{dictionary["failed_to_load_data"]}...</p>

  return (
    <Select
      id="invoice"
      name="invoice"
      label={dictionary["invoice"]}
      control={control}
      variant="outlined"
      margin="normal"
      error={error}
      isLoading={isLoading}
      fullWidth={fullWidth}
      defaultValue={defaultValue}
      errorMessage={errorMessage}
    >
      {children}
      {data &&
        data.results.map((invoice) => {
          return (
            <MenuItem key={invoice.id} value={invoice.id}>
              {invoice.code}
            </MenuItem>
          )
        })}
    </Select>
  )
}
