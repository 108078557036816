import React, { useState } from 'react'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import { useTheme, makeStyles } from '@material-ui/core/styles'

import { useFetchData } from '@api/fetchData'
import { Modal } from '@components/common';
import { Paper, Typography } from '@material-ui/core'

import { useTranslation } from '@contexts/translation'
import Page404View from 'pages/404'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CircularProgress } from '@material-ui/core';
import { onUpdateInvoiceItem } from '@api/invoices'


const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    height: 'fit-content',
    padding: theme.spacing(2),
    zIndex: 999,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  floorItem: {
    cursor: 'default',
    marginTop: theme.spacing(1),
    display: 'flex',
    minWidth: '280px',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 40,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }
}))

export default function ItemCard({ invoiceId, handleClose, invoiceUrl, fetchUrl }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)



  const { data, isLoading, isError } = useFetchData(fetchUrl)

  const onDragEnd = async result => {
    const { source, destination, draggableId } = result

    // dropped outside the list
    if (!destination) {
      return;
    }
    // dropped in the same list
    if (source.droppableId === destination.droppableId) {
      onChangeOrder(source.index, destination.index)
      return;
    }
  };


  if (isError) return <Page404View />

  const onChangeOrder = async (sourceIndex, destinationIndex) => {
    setLoading(true)
    let formData = {}
    let id = data.results[sourceIndex].id
    let sourceOrder = data.results[sourceIndex].order
    let destinationOrder = data.results[destinationIndex].order
    if (sourceOrder > destinationOrder) {
      formData['order'] = data.results[destinationIndex].order - 1
    }
    else {
      formData['order'] = data.results[destinationIndex].order
    }
    const response = await onUpdateInvoiceItem(invoiceId, id, formData)
    if (response) {
      if (response.status === 200) {
        enqueueSnackbar(dictionary["invoice_item_updated"], {
          variant: "success"
        })
        mutate(fetchUrl)
        mutate(invoiceUrl)
        setLoading(false)
      }
    }
  }

  return (
    <Modal className={classes.modalContainer} onClose={handleClose}>
      <Paper className={classes.container}>
        {data.count > 0 ?
          <DragDropContext onDragEnd={onDragEnd}>
            {(isLoading || loading) && <div className={classes.loading}><CircularProgress /></div>}
            {!isLoading && !loading && <Droppable droppableId={'item'}>
              {(provided, snapshot) => (
                <div className={classes.droppableZone} ref={provided.innerRef}>
                  {data && data.results && data.results.length > 0 &&
                    data.results.map((item, index) => {
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={item.id.toString()}
                          index={index}>
                          {(provided, snapshot) => (
                            <div
                              className={classes.floorItem}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Typography variant="body2">
                                {item.title}
                              </Typography>
                            </div>
                          )}
                        </Draggable>)

                    }
                    )
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            }
          </DragDropContext>
          :
          <>{dictionary['no_data']}</>
        }
      </Paper>
    </Modal>

  )

}