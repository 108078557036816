import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { useFetchData } from '@api/fetchData'

import { Select } from '.'
import { Loader } from '@components/common'

import { useTranslation } from '@contexts/translation'

export default function BuildingSelect({ control, error, errorMessage, fullWidth, children, operationId }) {
  const { dictionary } = useTranslation()
  const { data, isLoading, isError } = useFetchData(`/buildings/?operation=${operationId}`)

  if (isError) return <p>{dictionary["failed_to_load_data"]}...</p>

  return (
    <Select
      id="building"
      name="building"
      label={dictionary["building"]}
      control={control}
      defaultValue={''}
      variant="outlined"
      margin="normal"
      isLoading={isLoading}
      error={error}
      fullWidth={fullWidth}
      errorMessage={errorMessage}
    >
      {children}
      {data &&
        data.results.map((building) => {
          return <MenuItem value={building.id} key={building.id}>{building.name}</MenuItem>
        })}
    </Select>
  )
}
