import React, { useState } from 'react'
import Link from 'next/link'

import { Tab } from '@material-ui/core'
import { TabContext, TabList } from '@material-ui/lab'
import { differenceInMonths } from 'date-fns'
import UnformatDate from '@utils/unformatDate'

import { Table } from '@components/common'
import { useTheme, makeStyles } from '@material-ui/core/styles'

import { Typography, Box } from '@material-ui/core'

import { useFetchData } from '@api/fetchData'

import { useTranslation } from '@contexts/translation'
import { MLabel } from '@theme/@material-extend'
import { numberWithSpaceInt, numberWithSpacesNoDecimalAdded} from '@utils/numberWithSpaces'
import GetFormattedDate from "@utils/formatDate";

const useStyles = makeStyles((theme) => ({
  tabList: {
    width: 'fit-content',
    background: theme.palette.grey[200],
    padding: `0 ${theme.spacing(2)}`,
    borderRadius: '8px',
    marginBottom: theme.spacing(3),
  },
  tabListItem: {
    position: 'relative',
    padding: `0 ${theme.spacing(2)}`,
    '&.Mui-selected': {
      color: '#748BA3',
      backgroundColor: '#C2CBDD',
    },
  },
  overflow: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  tableTitle: {
    color: theme.palette.grey[500],
  },
  table: {
    '& > div:first-of-type': {
      background: 'none',
      boxShadow: 'initial',
    },
    '& .MuiDataGrid-footer': {
      display: 'none',
      visibility: 'hidden',
    },
  },
  headerTitleContainer: {
    background: 'white',
  },
  supplierDueTable: {
    marginTop: '40px'
  },
  revTag: {
    marginRight: '3px'
  }
}))

export default function LotsTable({ operationId }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  // const [value, setValue] = useState('lease')
  const today = new Date()

  const supplierfetchUrl = `/suppliers/contract/?operation=${operationId}`
  var { data, isLoading, supplierisError } = useFetchData(supplierfetchUrl)
  const supplierdata = supplierdata
  const supplierisLoading = isLoading

  const leasefetchUrl = `/stats/indicators/tenants/leases/expiring/operation/${operationId}/`
  var { data, isLoading, leaseisError } = useFetchData(leasefetchUrl)
  const leasedata = data
  const leaseisLoading = isLoading

  // const handleChange = (event, newValue) => {
  //   setValue(newValue)
  // }

  // const LOT_TAB = [
  //   {
  //     value: 'lease',
  //     type: 'lease',
  //     count: leasedata && leasedata.periods && leasedata.periods.length,
  //     label: dictionary['leases'],
  //     disabled: false,
  //   },
  //   {
  //     value: 'supplier',
  //     type: 'supplier',
  //     label: dictionary['supplier_contracts'],
  //     count: supplierdata && supplierdata.results && supplierdata.results.length,
  //     disabled: false,
  //   },
  // ]

  const columnsLease = [
    {
      field: 'tenant',
      headerName: dictionary['tenant'],
      headerClassName: 'no_background',
      flex: 1,
      minWidth: 150,
      // maxWidth: 300,
      renderCell: (params) => (
        <>
          {params.row.lease.tenant &&
            <Link href={`/operations/${operationId}/leases/${params.row.lease.id}`}>
              <a className={classes.overflow}>{params.row?.lease?.tenant?.display}</a>
            </Link>
          }
        </>
      )
    },
    
    {
      field: 'end_date',
      headerName: dictionary['due_date'],
      headerClassName: 'no_background',
      minWidth: 200,
      renderCell: (params) => {
        const endDate = UnformatDate(params.row.end_date)
        const getDifference = differenceInMonths(new Date(endDate), today)
        const colorStyle =
          getDifference >= 6
            ? 'info'
            : getDifference >= 3
              ? 'warning'
              : getDifference >= 0
                ? 'error'
                : 'default'

        return (
          <div>
            <Typography className={classes.overflow}>
              <MLabel
                className={classes.revTag}
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={colorStyle}>
                {`${getDifference} ${dictionary['months']}`}
              </MLabel>

              {`${GetFormattedDate(endDate)}`}
              
            </Typography>
            
          </div>
        )
      },
    },
    {
      field: 'rent',
      headerName: dictionary['rent'],
      headerClassName: 'round_corner',
      type: 'number',
      sortable: false,
      minWidth: 150,
      renderCell: (params) => (
        <div>
          <Typography className={classes.overflow}>
            <MLabel variant='light' className={classes.revTag}>
              {`${numberWithSpaceInt(params.row.lease && params.row.lease.rent_pct_of_total)}%`}
            </MLabel>
            {`${numberWithSpaceInt(params.row.lease && params.row.lease.rent)} €`}
          </Typography>
          
        </div>
      ),
    },
  ]
  const columnsSupplier = [
    {
      field: 'supplier',
      headerName: dictionary['suppliers'],
      headerClassName: 'no_background',
      flex: 1,
      minWidth: 160,
      renderCell: (params) => (
        <>
          {params.row.supplier &&
            <Link href={`/operations/${operationId}/suppliers/${params.row.supplier.id}`} passHref>
              <a className={classes.overflow}>{params.row?.supplier?.display}</a>
            </Link>}
        </>
      )
    },
    {
      field: 'notice_date',
      headerName: dictionary['due_date'],
      headerClassName: 'round_corner',
      minWidth: 160,
    },
  ]

  return (
  <>
      <div>
        <Box>
          <div>
            <Typography variant={'h6'} className={classes.tableTitle}>
                {leasedata && leasedata.periods ? leasedata.periods.length : 0} {dictionary['leases_due_date']}
            </Typography>
          </div>
        </Box>
        <div>
          <Table
            className={classes.tableContent}
            rows={leasedata && leasedata.periods ? leasedata.periods : []}
            columns={columnsLease}
            loading={leaseisLoading}
            disableColumnMenu={true}
            disableColumnSelector={true}
          />
        </div>
      </div>


      <div className={classes.supplierDueTable}>
        <Box>
          <div>
            <Typography variant={'h6'} className={classes.tableTitle}>
                {supplierdata && supplierdata.count || 0} {dictionary['supplier_due_date']}
            </Typography>
          </div>
        </Box>
        <div>
          <Table
            className={classes.tableContent}
            rows={supplierdata && supplierdata.results ? supplierdata.results : []}
            columns={columnsSupplier}
            loading={supplierisLoading}
            disableColumnMenu={true}
            disableColumnSelector={true}
          />
        </div>
      </div>

  </>
  )
}
