import client from '@utils/client'

export const onCreateRules = (payload) => {
  return client
    .post('/fees/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onDeleteRules = (id) => {
  return client
    .delete(`/fees/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onUpdateRules = (id, payload) => {
  return client
    .patch(`/fees/${id}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
