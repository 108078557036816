import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack';

import { useFetchData } from '@api/fetchData'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'
import MenuItem from '@material-ui/core/MenuItem'

import { DatePicker, Select, TenantsSelect, OperationsSelect } from "@components/form"

import { useTranslation } from '@contexts/translation'
import { Grid } from '@material-ui/core';
import { onCreatePayment, onCreatePaymentInvoice, onUpdatePayment, onUpdatePaymentInvoice } from '@api/payments';
import GetFormattedDate from '@utils/formatDate';
import UnformatDate from '@utils/unformatDate';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
  formRadioGroupContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: theme.spacing(4),
  },
  formCheckbox: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
  },
}))

export default function PaymentsForm({ id, isEdit, handleClose, isInvoice, invoiceId, operationId, fetchUrl, balance, tenant, fetchInvoice, location, tenantId, isOperation }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    setError,
    errors,
    setValue,
    control,
    watch
  } = useForm({
    mode: 'onBlur',
    shouldUnregister: false,
    defaultValues: {
      date: !isEdit ? new Date() : "",
    }
  })

  const watchOperation = watch('operation')


  const { data, isLoading, isError } = isEdit
    ? isInvoice ? useFetchData('/payments/invoice-payment/', id) : useFetchData('/payments/', id)
    : {
      data: null,
      isLoading: null,
      isError: null,
    }

  useEffect(() => {
    if (tenantId) {
      setValue('tenant', tenantId)
    }
    if (location === 'tenant_profile') {
      setValue('tenant', tenantId)
    }
    if (isEdit && data) {
      setValue('tenant', data?.tenant?.id)
      setValue('amount', data?.amount)
      setValue('type', data?.type)
      if (data?.date) setValue('date', UnformatDate(data?.date))
      setValue('description', data?.description)
      setValue('operation', data?.operation?.id)

    }
    if (isInvoice && !isEdit) {
      setValue('amount', balance)
    }
    if (isEdit && data && isInvoice) {
      setValue('amount', data?.amount)
      setValue('type', data?.payment && data.payment.type)
      if (data?.payment && data.payment.date) setValue('date', UnformatDate(data?.payment.date))
      setValue('description', data?.payment && data.payment.description)
    }
  }, [data, isEdit]);

  const onSubmit = async (data) => {
    setLoading(true)
    if (isOperation || isInvoice) data['operation'] = Number(operationId)
    if (data.date) {
      data['date'] = GetFormattedDate(data.date)
    }
    if (isInvoice) data['tenant'] = tenant.id
    let response = await onCreatePayment(data)
    if (response) {
      if (response.status === 201) {
        if (isInvoice) {
          setLoading(false)
          data['payment'] = response.data.id
          data['invoice'] = invoiceId
          const responseInvoice = await onCreatePaymentInvoice(data)
          if (responseInvoice.status === 201) {
            handleClose()
            enqueueSnackbar(dictionary["payment_created"], {
              variant: "success"
            })
            mutate(fetchUrl)
            mutate(fetchInvoice)
          }
        }
        else {
          setLoading(false)
          handleClose()
          enqueueSnackbar(dictionary["payment_created"], {
            variant: "success"
          })
          mutate(fetchUrl)
        }
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  const onSubmitEdit = async (data) => {
    setLoading(true)
    if (isOperation || isInvoice) data['operation'] = Number(operationId)
    if (data.date) {
      data['date'] = GetFormattedDate(data.date)
    }
    let response = isInvoice ? await onUpdatePaymentInvoice(id, data) : await onUpdatePayment(id, data)
    if (response) {
      if (response.status === 200) {
        handleClose()
        enqueueSnackbar(dictionary["payment_edited"], {
          variant: "success"
        })
        setLoading(false)
        mutate(fetchUrl)
        if (isInvoice) mutate(fetchInvoice)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(isEdit ? onSubmitEdit : onSubmit)}
    >
      <Grid container>

        {/* Operation */}
        {!isOperation && !isInvoice &&
          <Grid xs="12" className={classes.center}>
            <OperationsSelect
              fullWidth
              control={control}
              error={errors.lease}
              helperText={errors.lease && errors.lease.message}
              defaultValue=''
            />
          </Grid>
        }

        {((!isOperation && watchOperation) || isOperation) &&
          <>
            {!isInvoice && !tenantId &&
              <Grid xs={12} >
                <TenantsSelect
                  id={operationId ? operationId : watchOperation}
                  control={control}
                  defaultValue=''
                  error={errors.tenant}
                  fullWidth
                  helperText={errors.tenant && errors.tenant.message}
                />
              </Grid>
            }
            {/* Amount */}
            <Grid xs="12">
              <TextField
                variant="outlined"
                required
                margin="normal"
                fullWidth
                id="amount"
                label={dictionary["amount"]}
                name="amount"
                type="number"
                inputRef={register}
                error={errors.amount}
                helperText={errors.amount && errors.amount.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid xs="12">
              <DatePicker
                id="date"
                name="date"
                required
                label={dictionary["date"]}
                variant="outlined"
                margin="normal"
                fullWidth
                control={control}
                error={errors.date}
                errorMessage={errors.date && errors.date.message}
              />
            </Grid>

            {/* Description */}

            <Grid xs="12">
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="description"
                label={dictionary["description"]}
                name="description"
                inputRef={register}
                error={errors.description}
                helperText={errors.description && errors.description.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            {/* Type */}
            <Grid item xs="12">
              <Select
                id="type"
                name="type"
                required
                label={dictionary["type"]}
                control={control}
                defaultValue={''}
                variant="outlined"
                margin="normal"
                error={errors.type}
                fullWidth
                errorMessage={errors.type && errors.type.message}
              >
                <MenuItem value="cash">{dictionary["cash"]}</MenuItem>
                <MenuItem value="check">{dictionary["check"]}</MenuItem>
                <MenuItem value="card">{dictionary["card"]}</MenuItem>
                <MenuItem value="lcr">{dictionary["lcr"]}</MenuItem>
                <MenuItem value="transfer">{dictionary["transfer"]}</MenuItem>
                <MenuItem value="deposit">{dictionary["deposit"]}</MenuItem>
                <MenuItem value="rate">{dictionary["rate"]}</MenuItem>
                <MenuItem value="-">{dictionary["none"]}</MenuItem>
              </Select>
            </Grid>
          </>
        }



        <Grid xs="12" className={classes.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
            startIcon={<SaveIcon />}
            className={classes.submit}
          >
            {dictionary["submit"]}
          </Button>
        </Grid>
      </Grid>
    </form >
  )
}
