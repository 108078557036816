import React, { useState } from 'react';
import ImportList from "@components/profile/Import/ImportList";
import NewImport from "@components/profile/Import/NewImport";

export default function Import({ operationId }) {

  const handleNewImportGoBack = () => {
    setImportMode(importModeList[0].key)
  }
  const handleNewImportClick = () => {
    setImportMode(importModeList[1].key)
  }

  const importModeList = [
    { key: 'importList', component: <ImportList operationId={operationId} onNewImportClick={handleNewImportClick} /> },
    { key: 'newList', component: <NewImport operationId={operationId} onBackBtnClick={handleNewImportGoBack} /> }
  ];

  const [importMode, setImportMode] = useState(importModeList[0].key);

  const renderComponentByMode = () => {
    return importModeList.find(mode => mode.key === importMode).component;
  }

  return (
    <>{renderComponentByMode()}</>
  )
}
