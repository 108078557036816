import React, { createContext, useState, useContext, useEffect } from 'react'
import Cookies from 'js-cookie'
import Router, { useRouter } from 'next/router'

import client from '@utils/client'
import {
  onGetMyProfile,
  onRefreshToken,
  onLogin
} from '@api/auth'
import { onGetUsersList } from '@api/organizations'
import { onGetMyOrganization } from '@api/profile'

import { Loader } from '@components/common'

const AuthContext = createContext({})


const isProtectedRoute = (path) => {
  if (
    path === '/login' ||
    path === '/signup' ||
    path.includes('/reset-password') ||
    path === '/forgot' ||
    path.includes('/activation') ||
    path.includes('/accept_invite')
  ) {
    return false
  } else {
    return true
  }
}

// const getCurrentCurrencyID = async () => {
//   const currentOrganization = await onGetMyOrganization()
//   if (currentOrganization?.data) {
//     const organizationDetails = await onGetOrganizationDetails(currentOrganization?.data?.selected_org)
//     return organizationDetails?.data?.currency
//   }
//   return 1
// }

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  // const [currencyID, setCurrencyID] = useState(1)
  const [loading, setLoading] = useState(true)
  const [update, setUpdate] = useState(false)
  const [organization, setOrganization] = useState(null)
  const [membership, setMembership] = useState(null)

  const redirectToCreateOrganization = async () => {
    const organizationInfo = await onGetMyOrganization()
    const orgUsers = await onGetUsersList()

    if (organizationInfo) {

      if (orgUsers) {
          const _orguserInfo = orgUsers.data.results.find(
            o => o.org == organizationInfo?.data?.selected_org
          )
          if (_orguserInfo) {
            const orguserInfo = _orguserInfo
            setMembership({
              orguser: orguserInfo.id,
              is_readonly: orguserInfo.is_readonly,
              first_name: orguserInfo.first_name,
              last_name: orguserInfo.last_name
            })
          }
        }


      const isOrganizationActive = organizationInfo.data.selected_org
      if (!isOrganizationActive) {
        return null
      }
      else {
        return isOrganizationActive
      }
    }
  }


  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get('accessToken')
      const refreshToken = Cookies.get('refreshToken')
      if (token && refreshToken) {
        client.defaults.headers.Authorization = `JWT ${token}`
        const response = await onGetMyProfile()
        if (response) {
          if (response.status === 401) {
            const newToken = await onRefreshToken(refreshToken)
            if (newToken && newToken.status !== 401) {
              Cookies.set('accessToken', newToken.data.access, { expires: 60 })
              const response = await onGetMyProfile()
              if (response) {
                setUser(response.data)
                Cookies.set('email', response.data.email)
                const organization = await redirectToCreateOrganization()
                if (organization) {
                  setOrganization(organization)
                }
                // setCurrencyID(await getCurrentCurrencyID())
              }
            }
            else {
              Cookies.remove('accessToken')
              Cookies.remove('refreshToken')
              setUser(null)
              setOrganization(null)
              delete client.defaults.headers.Authorization
            }
          } else {
            setUser(response.data)
            Cookies.set('email', response.data.email)
            const organization = await redirectToCreateOrganization()
            if (organization) {
              setLoading(false)
              setOrganization(organization)
            }
            // setCurrencyID(await getCurrentCurrencyID())
          }
        }
      }
      setLoading(false)
    }
    loadUserFromCookies()
  }, [])

  const updateOrganization = () => {
    setUpdate(true)
  }


  const createDemo = () => {
    setLoading(true)
    setTimeout(() => {
      if (Router.pathname === '/') {
        Router.reload()
      } else {
        Router.push(`/`)
        setLoading(false)
      }
    }, 30000)
  }

  useEffect(() => {
    const organizationCheck = async () => {
      const organization = await redirectToCreateOrganization()
      if (organization) {
        Router.push('/')
        setOrganization(organization)
      }
    }
    if (update) {
      organizationCheck()
      setUpdate(false)
    }
  }, [update]);

  const login = async (data) => {
    const response = await onLogin(data)
    if (response) {
      if (response.status === 400) {
        const errors = response.data.errors
        return errors
      }
      if (response.status === 401) {
        const errors = { email: "Credentials are incorrect", password: "Credentials are incorrect" }
        return errors
      }
      else {
        setLoading(true)
        Cookies.set('accessToken', response.access, { expires: 60 })
        Cookies.set('refreshToken', response.refresh)
        client.defaults.headers.Authorization = `JWT ${response.access}`
        const { data: user } = await client.get('/auth/users/me/')
        setUser(user)
        Cookies.set('email', user.email)
        const organization = await redirectToCreateOrganization()
        if (organization) {
          setOrganization(organization)
          setLoading(false)
          Router.push('/')
        }
        else {
          setLoading(false)
          Router.push('/organization/create')
        }

        // setCurrencyID(await getCurrentCurrencyID())
      }
    }
  }

  const logout = (email, password) => {
    Cookies.remove('accessToken')
    Cookies.remove('refreshToken')
    setUser(null)
    setOrganization(null)
    delete client.defaults.headers.Authorization
    Router.push('/login')
  }

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: !!user,
        user,
        login,
        loading,
        logout,
        organization,
        updateOrganization,
        createDemo,
        membership
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)


export const ProtectRoute = ({ children }) => {
  const router = useRouter()
  const { isAuthenticated, loading, organization } = useAuth()

  if (loading) {
    return <Loader />
  }
  if (!isAuthenticated && isProtectedRoute(router.pathname) && router.pathname !== '/') {
    Router.push('/login')
    return <Loader />
  }

  if (isAuthenticated && !organization && router.pathname !== "/organization/create") {
    Router.push('/organization/create')
    return <Loader />
  }

  if (isAuthenticated && !isProtectedRoute(router.pathname)) {
    Router.push('/')
    return <Loader />
  }
  return children
}
