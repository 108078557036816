import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { mutate } from 'swr';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import Page404View from 'pages/404';
import { Loader } from '@components/common';
import {
  Button,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Radio,
  MenuItem,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { useFetchData } from '@api/fetchData';
import { RadioButton, Select } from '@components/form/';
import { getOneInvestor } from '@redux/slices/investors';
import { useTranslation } from '@contexts/translation';
import { onCreateInvestor, onUpdateInvestor } from '@api/investors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowY: 'auto',
  },
  form: {
    margin: 20,
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, .2)',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

export const InvestorsForm = ({ handleClose, isEdit, id, setSelectValue = null, fetchUrlSelect }) => {
  const { dictionary } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isCompany, setIsCompany] = useState(true);

  const investor = useSelector((state) => state.investors.investor);

  const {
    register,
    handleSubmit,
    setError,
    errors,
    control,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      is_company: true,
    },
  });

  const {
    data: currenciesData,
    isLoading: currenciesIsLoading,
    isError: currenciesIsError,
  } = useFetchData('/currencies/');

  const fetchUrl = `/investors/${id}/`;
  const {
    data: investorData,
    isLoading,
    isError,
  } = isEdit
      ? useFetchData(fetchUrl)
      : {
        data: null,
        isLoading: null,
        isError: null,
      };

  useEffect(() => {
    if (!isLoading && investorData) dispatch(getOneInvestor(investorData));
  }, [investorData, isLoading]);

  useEffect(() => {
    if (isEdit && investor) {
      setIsCompany(investor?.is_company);
      setValue(
        'is_company',
        typeof investor.is_company === 'boolean' &&
        investor.is_company.toString()
      );
      setValue('name', investor?.name);
      setValue('currency', investor?.currency);
      setValue('notes', investor?.notes);
    }
  }, [isEdit, investor]);

  const onSubmit = async (values) => {
    try {
      let response;
      if (isEdit) {
        response = await onUpdateInvestor(id, values);
      } else {
        response = await onCreateInvestor(values);
      }

      if (response.status === 200 || response.status === 201) {
        if (response.status === 201) {
          if (setSelectValue) setSelectValue(response?.data?.id)
          if (fetchUrlSelect) mutate(fetchUrlSelect)
          enqueueSnackbar(dictionary.created, {
            variant: 'success',
          });
        }
        if (response.status === 200) {
          enqueueSnackbar(dictionary.routes.investors['investor_updated'], {
            variant: 'success',
          });
        }
        reset(); // reset fields
        mutate(fetchUrl);
        //close modal
        handleClose();
      }

      if (response.status === 400) {
        const { errors } = response.data;
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            });
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const radioChangeHandle = (e) => {
    if (e.target.value === 'true') {
      setIsCompany(true);
    } else if (e.target.value === 'false') {
      setIsCompany(false);
    }
  };

  if (isError || currenciesIsError) return <Page404View />

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12}>
          <RadioButton
            fullWidth
            name="is_company"
            control={control}
            error={errors.is_company}
            errorMessage={errors.is_company && errors.is_company.message}
            onClick={radioChangeHandle}
          >
            <FormControlLabel
              value="true"
              label={dictionary['company']}
              labelPlacement="start"
              control={<Radio checked={isCompany} />}
            />
            <FormControlLabel
              value="false"
              label={dictionary['person']}
              labelPlacement="start"
              control={<Radio />}
            />
          </RadioButton>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            type="text"
            id="name"
            name="name"
            label={dictionary['name']}
            inputRef={register({ required: true })}
            InputLabelProps={{
              shrink: true,
            }}
            error={errors.name}
            helperText={errors.name && errors.name.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            fullWidth
            labelId="currency"
            name="currency"
            isLoading={currenciesIsLoading}
            label={dictionary.currency}
            variant="outlined"
            margin="normal"
            control={control}
            defaultValue={''}
          >
            {currenciesData &&
              currenciesData.results &&
              currenciesData.results.map(({ id, display }) => (
                <MenuItem key={id} value={id}>
                  {display}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            type="text"
            id="notes"
            name="notes"
            label={dictionary['note']}
            inputRef={register}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid xs="12" className={classes.buttonContainer}>
          <Button
            variant="contained"
            margin="normal"
            type="submit"
            color="secondary"
            startIcon={<SaveIcon />}
            disabled={isSubmitting}
          >
            {dictionary['submit']}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
