import React, { useState } from 'react'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import { useFetchData } from '@api/fetchData'

import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import { ModalDelete, ModalForm, Table, ActionTable } from '@components/common'
import { useTranslation } from '@contexts/translation'
import useBreakpoints from '@hooks/useBreakpoints'
import Page404View from 'pages/404'
import ManagerForm from './ManagerForm'
import { onDeleteCurrency } from '@api/currencies'
import { ModalCurrenciesForm } from '.'


const useStyles = makeStyles((theme) => ({
  containerTable: {
    width: '100%',
  },
  tableNav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },

  gridContainer: {
    border: 'none',
  },
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerDelete: {
    height: 'fit-content',
    padding: theme.spacing(4),
  },
  buttonDelete: {
    marginRight: theme.spacing(8),
  },
  submit: {
    '@media (max-width: 600px)': {
      minWidth: 'initial',
      padding: theme.spacing(1),
      '& span': {
        marginRight: '0',
        marginLeft: '0',
      },
    },
  },
}))

export default function CurrenciesTable() {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [isOpenForm, setIsOpenForm] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [idRow, setIdRow] = useState(null)
  const [pageCount, setPageCount] = useState(1)
  const isMobile = useBreakpoints('down', 'sm')

  const fetchUrl = `/currencies/?page=${pageCount}&page_size=50`

  const { data, isLoading, isError } = useFetchData(fetchUrl)

  const handlePageChange = (params) => {
    setPageCount(params + 1)
  };


  const handleFormOpen = () => {
    setIsOpenForm(true)
  }
  const handleFormClose = () => {
    setIsOpenForm(false)
  }

  const handleDeleteOpen = () => {
    setIsDelete(true)
  }

  const handleDeleteClose = () => {
    setIsDelete(false)
  }

  const onDelete = async () => {
    const response = await onDeleteCurrency(idRow)

    if (response) {
      if (response.status === 204) {
        mutate(fetchUrl)
        enqueueSnackbar(dictionary['currency_deleted'], {
          variant: 'success',
        })
        handleDeleteClose()
      }
      if (response.status === 400) {
        mutate(fetchUrl)
        enqueueSnackbar(dictionary[`currency_not_deleted`], {
          variant: "error"
        })
        handleDeleteClose()

      }
    }
  }

  const actions = (id) => [
    {
      name: dictionary['delete'],
      onClick: () => {
        handleDeleteOpen()
        setIdRow(id)
      },
    },
  ]

  const columns = [
    {
      field: 'name',
      headerName: dictionary['name'],
      flex: 1,
      minWidth: 160,
      renderCell: (params) => (
        <>
          {params.row.name} - {params.row.symbol}
        </>
      ),
    },
    {
      field: 'action',
      headerName: dictionary['actions'],
      width: 100,
      cellClassName: 'actions_cell',
      sortable: false,
      renderCell: (params) => <ActionTable actions={actions(params.row.id)} />,
    },
  ]

  if (isError) return <Page404View />


  return (
    <div className={classes.containerTable}>
      <div className={classes.tableNav}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          className={classes.submit}
          onClick={handleFormOpen}
        >
          {!isMobile && dictionary['add_currency']}
        </Button>
      </div>
      <Table
        className={classes.gridContainer}
        rows={data && data.results ? data.results : []}
        loading={isLoading}
        columns={columns}
        page={pageCount}
        handlePageChange={handlePageChange}
        count={data && data.count}
      />
      {isOpenForm && (
        <ModalCurrenciesForm fetchUrl={fetchUrl} onClose={handleFormClose} />
      )}
      {isDelete && (
        <ModalDelete onClose={handleDeleteClose} onDelete={onDelete} />
      )}
    </div>
  )
}
