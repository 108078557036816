import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import { useTranslation } from '@contexts/translation';
import NotificationsIcon from '@material-ui/icons/Notifications';
const useStyles = makeStyles({

});

function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, selectedValue, open, content } = props;
    const { dictionary } = useTranslation()

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            {content}
        </Dialog>
    );
}

export default function RecentActivityDialog({ content }) {
    const [open, setOpen] = React.useState(false);
    const { dictionary } = useTranslation()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <>
            <NotificationsIcon 
                onClick={handleClickOpen}
                sx={{
                    color: 'white',
                }}
            />
            <SimpleDialog open={open} onClose={handleClose} content={content} />
        </>
    );
}
