import client from '@utils/client'
import useSWR from 'swr'
import { fetcher } from '@lib/fetcher'

export const useOnAddSupplier = () => {
  const { data, error } = useSWR(`/suppliers/`, fetcher)

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}

export const useOnAddOneSupplier = (id) => {
  const { data, error } = useSWR(`/suppliers/${id}`, fetcher)

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}

export const sendAddSupplier = (payload) => {
  return client
    .post('/suppliers/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const updateSupplier = (payload, id) => {
  return client
    .patch(`/suppliers/${id}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const deleteSupplier = (id) => {
  return client
    .delete(`/suppliers/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const addSupplierFile = (id, fileId, files) => {
  return client
    .patch(`/suppliers/${id}/`, {
      files: files ? [...files, fileId] : [fileId]
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}


export const onGetSuppliers = (operationId) => {
  return client
    .get(`/suppliers/${operationId ? `?operations=${operationId}&page_size=999` : '?page_size=999'}`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetSuppliersFilter = (operationId) => {
  return client
    .get(`/suppliers/${operationId ? `?operations=${operationId}&page_size=9999` : '?page_size=9999'}`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetSuppliersOperation = (id) => {
  return client
    .get(`/suppliers/?operations=${id}&page_size=9999`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

