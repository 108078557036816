import React from 'react'

import { useFetchData } from '@api/fetchData'

// import { useAuth } from '@contexts/auth'

import MenuItem from '@material-ui/core/MenuItem'

import { Select } from '.'
import { Loader } from '@components/common'

import { useTranslation } from '@contexts/translation'

export default function CurrenciesSelect({ control, error, errorMessage, fullWidth, defaultValue }) {
  const { dictionary } = useTranslation()
  const { data, isLoading, isError } = useFetchData('/currencies/')
  // const { currencyID } = useAuth()

  if (isError) return <p>{dictionary["failed_to_load_data"]}...</p>

  return (
    <Select
      id="currency"
      name="currency"
      label={dictionary["currency"]}
      control={control}
      variant="outlined"
      margin="normal"
      error={error}
      isLoading={isLoading}
      fullWidth={fullWidth}
      defaultValue={defaultValue ? defaultValue : data && data.results && data.results.length > 0 && data.results[0].id}
      // defaultValue={defaultValue ? defaultValue : currencyID}
      errorMessage={errorMessage}
    >
      {data &&
        data.results.map((currency) => {
          return (
            <MenuItem key={currency.id} value={currency.id}>
              {currency.symbol} - {currency.display}
            </MenuItem>
          )
        })}
    </Select>
  )
}
