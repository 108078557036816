import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useGridSlotComponentProps } from '@material-ui/data-grid'
import Pagination from '@material-ui/lab/Pagination'

import { numberWithSpaces } from '@utils/numberWithSpaces'
import useBreakpoints from '@hooks/useBreakpoints'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  list: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function FooterRowGlobal(props) {
  const classes = useStyles()
  const { state, apiRef, columns, rows } = useGridSlotComponentProps()
  const isMobile = useBreakpoints('down', 'sm')

  const totalRow = () => {
    if (rows?.length > 0) {
      const forceReduce = rows.push({
        invoice: '',
        invoice_date: null,
        tenant: null,
        due_date: null,
        operation: null,
        subtotal: 0,
        balance: 0,
        amount: 0,
        status_display: null,
      })
      const totalRow = rows?.reduce((acc, value) => {
        return {
          invoice: 'Total',
          invoice_date: null,
          operation: null,
          tenant: null,
          due_date: null,
          amount: acc.amount + value.amount,
          subtotal: acc.subtotal + value.subtotal,
          balance: acc.balance + value.balance,
          status_display: null,
        }
      })
      return [totalRow]
    }
  }

  const itemStyleName = {
    width: columns[0].computedWidth,
    padding: '0',
    fontWeight: 'bold',
  }

  const itemStyleDisplay = {
    width: columns[1].computedWidth,
    padding: '0 10px',
  }

  const itemStyleOperation = {
    width: columns[1].computedWidth,
    padding: '0 10px',
    fontWeight: 'bold',
    textAlign: 'right',
  }


  const itemStyleTenant = {
    width: columns[2].computedWidth,
    padding: '0 10px',
    fontWeight: 'bold',
    textAlign: 'right',
  }

  const itemStyleDate = {
    width: columns[3].computedWidth,
    padding: '0 10px',
    fontWeight: 'bold',
    textAlign: 'right',
  }

  const itemStyleDueDate = {
    width: columns[4].computedWidth,
    padding: '0 10px',
    fontWeight: 'bold',
    textAlign: 'right',
  }

  const itemStyleTotal = {
    width: columns[5].computedWidth,
    padding: '0 10px',
    fontWeight: 'bold',
    textAlign: 'right',
  }

  const itemStyleSubtotal = {
    width: columns[6].computedWidth,
    padding: '0 10px',
    fontWeight: 'bold',
    textAlign: 'right',
  }

  const itemStyleBalance = {
    width: columns[7].computedWidth,
    padding: '0 10px',
    fontWeight: 'bold',
    textAlign: 'right',
  }

  return (
    <div className={`${classes.root} MuiDataGrid-footer`}>
      {rows?.length > 0 && columns.length > 4 && !state.options.loading && (
        <div>
          {totalRow().map((item, index) => (
            <div className={classes.list} key={`${item?.name}-${index}`}>
              <div style={itemStyleName}>
                {state.pagination.pageCount > 1 && (
                  <Pagination
                    color="primary"
                    count={state.pagination.pageCount}
                    page={state.pagination.page + 1}
                    size="small"
                    siblingCount={0}
                    onChange={(event, value) => value && apiRef.current.setPage(value - 1)} />
                )}
              </div>
              <div style={itemStyleOperation}>{item?.operation}</div>
              <div style={itemStyleTenant}>{item?.tenant}</div>
              <div style={itemStyleDate}>{item?.invoice_date}</div>
              <div style={itemStyleDueDate}>{item?.due_date}</div>
              <div style={itemStyleTotal}>{numberWithSpaces(item?.subtotal)}</div>
              <div style={itemStyleSubtotal}>{numberWithSpaces(item?.amount)}</div>
              <div style={itemStyleBalance}>{numberWithSpaces(item?.balance)}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
