import React from 'react';
import { Controller } from 'react-hook-form';

import { FormControl, FormHelperText, RadioGroup } from '@material-ui/core'

const ReactHookFormRadio = ({
  name,
  label,
  control,
  error,
  errorMessage,
  children,
  fullWidth,
  ...props
}) => {
  const labelId = `${name}-label`
  return (
    <FormControl
      {...props}
      margin="normal"
      size="medium"
      error={error}
      style={{ width: fullWidth ? '100%' : '195px' }}
      helperText={errorMessage}
    >
      <Controller
        name={name}
        control={control}
        as={
          <RadioGroup row labelId={labelId} label={label}>
            {children}
          </RadioGroup>
        }
      />
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>

  )
}

export default ReactHookFormRadio