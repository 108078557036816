import React from 'react'

import { useFetchData } from '@api/fetchData'

import MenuItem from '@material-ui/core/MenuItem'

import { Select } from '.'

import { useTranslation } from '@contexts/translation'

export default function StyleguidesSelect({ children, control, error, errorMessage, fullWidth, defaultValue, id }) {
  const { dictionary } = useTranslation()
  const { data, isLoading, isError } = useFetchData('/styleguides/?page_size=999')

  if (isError) return <p>{dictionary["failed_to_load_data"]}...</p>

  return (
    <Select
      id={id ? id : "styleguide"}
      name={id ? id : "styleguide"}
      label={dictionary["styleguide"]}
      control={control}
      variant="outlined"
      margin="normal"
      error={error}
      isLoading={isLoading}
      fullWidth={fullWidth}
      defaultValue={defaultValue}
      errorMessage={errorMessage}
    >
      {children}
      {data &&
        data.results.map((styleguide) => {
          return (
            <MenuItem key={styleguide.id} value={styleguide.id}>
              {styleguide.name}
            </MenuItem>
          )
        })}
    </Select>
  )
}
