import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { mutate } from 'swr';
import { useSnackbar } from 'notistack';
import { useFetchData } from '@api/fetchData';
import { Select } from '@components/form';
import { useTranslation } from '@contexts/translation';
import { ModalForm } from '@components/common'
import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { onCreateInvestorsShare, onUpdateInvestorsShare } from '@api/investors';
import Page404View from 'pages/404';
import { getParam, SHARE_URL } from './helpers';
import AddIcon from '@material-ui/icons/Add';
import { InvestorsForm } from '@components/Investors/InvestorsForm';

import { Loader } from '..';

const useStyles = makeStyles((theme) => ({
  form: {
    margin: 20,
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, .2)',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  loaderBlock: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
}));

export const SharesForm = ({ idShare, isEdit, handleClose, tableConfig }) => {
  const { dictionary } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [fieldValues, setFieldValues] = useState([]);
  const [open, setOpen] = useState(false)
  const param = getParam(tableConfig);

  const {
    handleSubmit,
    control,
    register,
    setValue,
    setError,
    errors,
    formState: { isSubmitting },
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      operation: '',
      investor: '',
      ownership: '',
    },
  });

  const {
    data: paramsData,
    isLoading: paramsIsLoading,
    isError: paramsIsError,
  } = useFetchData(`/${param}s/`);

  const {
    data: shareData,
    isLoading: shareIsLoading,
    isError: shareIsError,
  } = isEdit
      ? useFetchData(SHARE_URL, idShare)
      : {
        shareData: null,
        shareIsLoading: null,
        shareIsError: null,
      };

  useEffect(() => {
    setFieldValues(paramsData?.results);
  }, [paramsData]);

  useEffect(() => {
    if (isEdit && shareData && Object.keys(shareData).length) {
      setValue(`${param}`, shareData[param]?.id);
      setValue('ownership', shareData?.ownership);
    }
  }, [shareData, isEdit]);

  const onSubmit = async (values) => {
    if (!values[tableConfig.location]) {
      values[tableConfig.location] = tableConfig.id;
    }

    let response;
    if (isEdit) {
      response = await onUpdateInvestorsShare(idShare, values);
    } else {
      response = await onCreateInvestorsShare(values);
    }

    if (response.status === 200 || response.status === 201) {
      if (response.status === 201) {
        enqueueSnackbar(dictionary.created, {
          variant: 'success',
        });
      }
      if (response.status === 200) {
        enqueueSnackbar(dictionary.routes.investors.shares_updated, {
          variant: 'success',
        });
      }

      reset(); // reset fields
      mutate(SHARE_URL);
      handleClose();
    }

    if (response.status === 400) {
      const { errors } = response.data;
      if (errors.length > 0) {
        errors.map((error) => {
          setError(error.field, {
            message: error.message,
          });
        });
      }
    }
  };

  if (paramsIsLoading || shareIsLoading)
    return (
      <div className={classes.loaderBlock}>
        <CircularProgress />
      </div>
    );
  if (paramsIsError || shareIsError) return <Page404View />;

  return (
    <>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <Select
              fullWidth
              labelId={param}
              name={param}
              label={dictionary[param]}
              variant="outlined"
              margin="normal"
              control={control}
              defaultValue={''}
            >
              <MenuItem key={0} value={null} onClick={() => setOpen(true)}>
                <AddIcon /> {dictionary["create_new_investor"]}
              </MenuItem>
              {fieldValues &&
                fieldValues.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              type="number"
              name="ownership"
              label={dictionary.routes.investors.ownership}
              inputRef={register}
              error={errors.ownership}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors?.ownership?.message}
            />
          </Grid>
          <Grid item xs={12} className={classes.buttonContainer}>
            <Button
              variant="contained"
              margin="normal"
              type="submit"
              color="secondary"
              startIcon={<SaveIcon />}
              disabled={isSubmitting}
            >
              {dictionary.submit}
            </Button>
          </Grid>
        </Grid>
      </form>

      {open &&
        <ModalForm type={dictionary["investor"]} onClose={() => setOpen(false)}>
          <InvestorsForm
            handleClose={() => setOpen(false)}
            fetchUrlSelect={`/investors/`}
            setSelectValue={id => setValue('investor', id)}
          />
        </ModalForm>
      }
    </>
  );
};
