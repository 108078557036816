import React from 'react'
import Modal from '@material-ui/core/Modal'

export default function ModalCompo({ children, className, onClose }) {
  return (
    <Modal
      className={className}
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {children}
    </Modal>
  )
}
