import React from 'react'

import { useFetchData } from '@api/fetchData'

import MenuItem from '@material-ui/core/MenuItem'

import { Select } from '.'

import { useTranslation } from '@contexts/translation'

export default function LeasesSelect({ control, error, errorMessage, fullWidth, defaultValue, id, tenantId, children, onChange = null, ...props }) {
  const { dictionary } = useTranslation()
  const operationFilter =
    id !== undefined ? `&operation=${id}` : ''
  const tenantFilter =
    tenantId !== undefined ? `&tenant=${tenantId}` : ''
  // const isActiveFilter =
  //   isActive !== undefined ? `&is_active=true` : ''
  const { data, isLoading, isError } = useFetchData(`/leases/?page_size=999${operationFilter}${tenantFilter}&is_active=true&is_valid=true`)

  if (isError) return <p>{dictionary["failed_to_load_data"]}...</p>
  return (
    <FormControl variant="outlined" className={classes.selectYear}>
        <InputLabel id="select-lease-label">{dictionary["lease"]}</InputLabel>
        <Select
          labelId="select-lease-label"
          id="lease"
          name="lease"
          label={dictionary["lease"]}
          control={control}
          variant="outlined"
          margin="normal"
          error={error}
          isLoading={isLoading}
          customOnChange={onChange}
          fullWidth={fullWidth}
          errorMessage={errorMessage}
          defaultValue={defaultValue}
          {...props}
        >
          {children}
          {data &&
            data.results.map((lease) => {
              return (
                <MenuItem key={lease.id} value={lease.id}>
                  {lease.display}
                </MenuItem>
              )
            })}
        </Select>
    </FormControl>
  )
}
