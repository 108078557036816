import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { Grid, FormControlLabel, Switch } from '@material-ui/core';
import { useTranslation } from '@contexts/translation';
import { OrgusersTable } from '@components/profile';
import { useRouter } from 'next/router';
import { onEnablePortal } from '@api/tenants';

const useStyles = makeStyles((theme) => ({
  margins: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    }
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row'
    }
  }
}));

export default function PortalPage({
  id,
  type,
  portalEnabled,
}) {

  const { dictionary } = useTranslation();
  const classes = useStyles();
  const [checked, setChecked] = useState(portalEnabled);
  const [isLoading, setIsLoading] = useState(false);

  const toggleChecked = async () => {
    setChecked((prev) => !prev);
    setIsLoading(true)
    const response = await onEnablePortal(id, type, !checked)
    if (response) {
      if (response.status === 200) {
        setIsLoading(false)
      }
      else {
        setIsLoading(false)
      }
    }
  };

  const router = useRouter()

  return (
    <Grid item xs={12}>
      <Grid container >
        <Grid xs={12} md={3} lg={3} className={classes.flex}>
          <Button
            variant="contained"
            className={classes.margins}
            onClick={() => router.push(`/${type}`)}
          >
            {dictionary['preview_portal']}
          </Button>
          <FormControlLabel
            control={
              <Switch checked={checked} disabled={isLoading} onChange={toggleChecked} />
            }
            label={dictionary['enable_portal']}
          />
        </Grid>
        <Grid xs={12} md={9} lg={9}>
          <OrgusersTable
            id={id}
            type={type}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
