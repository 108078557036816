import React from 'react'

import {countries} from '@utils/constant'

import MenuItem from '@material-ui/core/MenuItem'

import {Select} from '.'

import {useTranslation} from '@contexts/translation'

export default function CountriesSelect({
  className,
  control,
  error,
  errorMessage,
  fullWidth,
}) {
  const {dictionary} = useTranslation()

  return (
    <Select
      className={className}
      id="country"
      name="country"
      label={dictionary['country']}
      control={control}
      defaultValue={'FR'}
      variant="outlined"
      margin="normal"
      error={error}
      fullWidth={fullWidth}
      errorMessage={errorMessage}
    >
      {countries &&
        countries.map((country, index) => {
          return (
            <MenuItem key={index} value={country}>
              {country}
            </MenuItem>
          )
        })}
    </Select>
  )
}
