import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import clsx from 'clsx'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import SaveIcon from '@material-ui/icons/Save'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { onCreateEmail, onUpdateEmail, onDeleteEmail, onSendTemplateTest } from '@api/emails'

import { useTranslation } from '@contexts/translation'

import { StyleguideEditor, SMTPSelect } from "@components/form"
import { ModalDelete } from "@components/common"

const useStyles = makeStyles((theme) => ({
  form: {
    margin: 20,
    marginTop: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: "space-between"
  },
  flexEnd: {
    justifyContent: 'flex-end'
  },
  emptyItem: {
    minHeight: '36px',
  },
  pictureContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    "& img": {
      width: '150px'
    }
  },
  sendTest: {
    margin: theme.spacing(3, 0, 2),
    marginRight: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  hiddenInput: {
    position: "absolute",
    zIndex: -1,
    opacity: 0
  }
}))

const variables = [
  "invoice_code",
  "project_title",
  "subtotal",
  "total",
  "currency_symbol",
  "currency_name",
  "invoice_date",
  "invoice_date_year",
  "invoice_date_month_display",
  "invoice_date_month",
  "invoice_date_day",
  "due_date",
  "due_date_year",
  "due_date_month_display",
  "due_date_month",
  "due_date_day",
  "client_name",
  "client_contact_name",
  "client_address"
]

export default function TemplateForm({ isEdit, id, formData, setCurrTemplateEdited }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const formRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = e => {
    e.target.firstElementChild.select();
    document.execCommand("copy");
    enqueueSnackbar(`${dictionary["variable_copied"]}: ${e.target.innerText}`, {
      variant: 'success',
    })
  }

  const { register, handleSubmit, setError, errors, control, setValue, reset } = useForm(
    { mode: 'onBlur' },
  )

  const setFormValue = () => {
    setValue('name', formData?.name)
    setValue('email_subject', formData?.email_subject)
    setValue('email_from', formData?.email_from)
    setValue('name_from', formData?.name_from)
    setValue('email_body', formData?.email_body)
    setValue('smtp_setting', formData?.smtp_setting)
  }

  useEffect(() => {
    if (isEdit && formData) {
      setFormValue()
    }
  }, [formData, isEdit])

  useEffect(() => {
    if (!id) reset()
  }, [id])

  const [isDelete, setIsDelete] = useState(false)
  const handleDeleteOpen = () => {
    setIsDelete(true)
  }
  const handleDeleteClose = () => {
    setIsDelete(false)
  }

  const onDelete = async () => {
    const response = await onDeleteEmail(id)

    if (response) {
      if (response.status === 204) {
        mutate("/emails/")
        enqueueSnackbar(dictionary["template_deleted"], {
          variant: 'success'
        })
        handleDeleteClose()
        setCurrTemplateEdited(null)
      }
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)

    const response = await onCreateEmail(data)
    if (response) {
      if (response.status === 201) {
        enqueueSnackbar(dictionary["template_created"], {
          variant: 'success',
        })
        mutate('/emails/')
        setLoading(false)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  const onSendTest = async () => {
    setLoading(true)
    const response = await onSendTemplateTest(id)
    if (response.status === 200) {
      enqueueSnackbar(dictionary["test_sent"], {
        variant: 'success',
      })
      setLoading(false)
    }
  }

  const onSubmitEdit = async (data) => {
    setLoading(true)

    const response = await onUpdateEmail(id, data)
    if (response) {
      if (response.status === 200) {
        setLoading(false)
        enqueueSnackbar(dictionary["template_edited"], {
          variant: 'success',
        })
        mutate('/emails/')
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <>
      <form ref={formRef}
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(isEdit ? onSubmitEdit : onSubmit)}
      >
        <Grid container>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              id="name"
              required
              label={dictionary["name"]}
              name="name"
              inputRef={register}
              fullWidth
              error={errors.name}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors.name && errors.name.message}
            />
          </Grid>

          <SMTPSelect
            fullWidth
            control={control}
            error={errors.smtp_setting}
            errorMessage={errors.smtp_setting && errors.smtp_setting.message}
            defaultValue=''
          >
            <MenuItem key={0} value={null} className={classes.emptyItem}>
              {' '}
            </MenuItem>
          </SMTPSelect>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              id="name_from"
              label={dictionary["name_from"]}
              name="name_from"
              inputRef={register}
              fullWidth
              error={errors.name_from}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors.name_from && errors.name_from.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              id="email_from"
              label={dictionary["email_from"]}
              name="email_from"
              inputRef={register}
              fullWidth
              error={errors.email_from}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors.email_from && errors.email_from.message}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              id="email_subject"
              label={dictionary["subject"]}
              name="email_subject"
              inputRef={register}
              fullWidth
              error={errors.email_subject}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors.email_subject && errors.email_subject.message}
            />
          </Grid>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {variables.map((variable) => (
              <MenuItem onClick={handleClickItem}>
                {dictionary[variable]
                }<input className={classes.hiddenInput} value={`{{${variable}}}`} />
              </MenuItem>
            ))}
          </Menu>

          <Grid item xs={12}>
            <StyleguideEditor
              defaultValue={""}
              isVariable
              name="email_body"
              label={dictionary["body"]}
              control={control}
              handleClick={handleClick} />
          </Grid>
        </Grid>

        <Grid xs="12" className={isEdit ? classes.buttonContainer : clsx(classes.buttonContainer, classes.flexEnd)}>
          {isEdit && (
            <Button
              onClick={handleDeleteOpen}
              type="button"
              variant="contained"
              color="error"
              className={classes.submit}
            >
              {dictionary["delete"]}
            </Button>
          )}
          <div>
            {isEdit && (
              <Button
                type="button"
                variant="contained"
                color="secondary"
                disabled={loading}
                onClick={() => onSendTest(id)}
                className={classes.sendTest}
              >
                {dictionary["send_test"]}
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
              startIcon={<SaveIcon />}
              className={classes.submit}
            >
              {dictionary["submit"]}
            </Button>
          </div>
        </Grid>
      </form>
      {
        isDelete && (
          <ModalDelete onClose={handleDeleteClose} onDelete={onDelete} />
        )
      }
    </>
  )
}
