import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useGridSlotComponentProps } from '@material-ui/data-grid'
import Pagination from '@material-ui/lab/Pagination'
import { Typography, Box } from '@material-ui/core'


import { numberWithSpaces } from '@utils/numberWithSpaces'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: `${theme.spacing(2)} !important`,
    marginBottom: theme.spacing(2)
  },
  list: {
    display: 'flex',
    alignItems: 'center',
  },
  red: {
    color: theme.palette.error.main
  },
  title: {
    textAlign: 'left',
    paddingRight: 10
  }
}))

export default function FooterRowDetails({ remainingAmount }) {
  const classes = useStyles()
  const { state, apiRef, columns, rows } = useGridSlotComponentProps()

  const totalRow = () => {
    if (rows?.length > 0) {
      const forceReduce = rows.push({
        display: '',
        date: null,
        amount: 0,
      })
      const totalRow = rows?.reduce((acc, value) => {
        return {
          display: '',
          date: null,
          amount: acc.amount + value.amount,
        }
      })
      return [totalRow]
    }
  }

  const itemStyleName = {
    width: columns[0].computedWidth,
    padding: '0',
    fontWeight: 'bold',
  }

  const itemStyle = {
    width: columns[2].computedWidth + columns[1].computedWidth,
    padding: '0 10px',
    fontWeight: 'bold',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end'
  }

  return (
    <div className={`${classes.root} MuiDataGrid-footer`}>
      {rows?.length > 0 && columns.length > 2 && !state.options.loading && (
        <div>
          {totalRow().map((item, index) => (
            <div className={classes.list} key={`${item?.name}-${index}`}>
              <div style={itemStyleName}>
                {state.pagination.pageCount > 1 && (
                  <Pagination
                    color="primary"
                    count={state.pagination.pageCount}
                    page={state.pagination.page + 1}
                    size="small"
                    siblingCount={0}
                    onChange={(event, value) => value && apiRef.current.setPage(value - 1)} />
                )}
              </div>
              <div style={itemStyle}>
                <div className={classes.title}>
                  <Typography variant="body1">Total</Typography>
                  <Box sx={{ mt: 1 }} />
                  <Typography variant="body1">Remaining Amount</Typography>
                </div>
                <div>
                  <Typography variant="body1">{numberWithSpaces(item?.amount)}</Typography>
                  <Box sx={{ mt: 1 }} />
                  <Typography variant="body1" className={classes.red}>{numberWithSpaces((remainingAmount))}</Typography>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
