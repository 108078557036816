import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import useSettings from '@hooks/useSettings';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  content: {
    textAlign: 'center',
    position: 'relative',

    [theme.breakpoints.up('md')]: {
      height: '100%',
      marginBottom: 0,
      textAlign: 'left',
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start'
    }
  },
  switch: {
    width: 56,
    height: 24,
    cursor: 'pointer',
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 0.5),
    borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: theme.palette.switch.paper
  },
  switchOn: {
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.switch.paper
  },
  handle: {
    width: 16,
    height: 16,
    boxShadow: theme.shadows[25].primary,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.switch.color
  },
  handleOn: { width: 20 }
}));

const spring = {
  type: 'spring',
  stiffness: 700,
  damping: 30
};


function ToggleSwitch({ isChecked, onToggleTheme }) {
  const classes = useStyles();
  return (
    <div
      onClick={onToggleTheme}
      className={clsx(classes.switch, { [classes.switchOn]: isChecked })}
    >
      <motion.div
        layout
        transition={spring}
        className={clsx(classes.handle, { [classes.handleOn]: isChecked })}
      />
    </div>
  );
}

DarkMode.propTypes = {
  className: PropTypes.string
};

function DarkMode({ className }) {
  const classes = useStyles();
  const { themeMode, toggleMode } = useSettings();
  const isLight = themeMode === 'light';

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth={false} sx={{ position: 'relative' }}>
        <Grid item xs={12} md={4}>
          <div className={classes.content}>
            <ToggleSwitch isChecked={!isLight} onToggleTheme={toggleMode} />
          </div>
        </Grid>
      </Container>
    </div>
  );
}

export default DarkMode;
