import client from '@utils/client';

export const onCreateContacts = (payload) => {
  return client
    .post('/contacts/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const onDeleteContact = (id) => {
  return client
    .delete(`/contacts/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const onUpdateContact = (id, payload) => {
  return client
    .patch(`/contacts/${id}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const onSearchCompany = (payload) => {
  return client
    .post('/contacts/company_search/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}