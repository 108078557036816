import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack';

import { useFetchData } from '@api/fetchData'
import { onCreateInvoiceItem, onUpdateInvoiceItem } from '@api/invoices'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'
import { Grid, MenuItem } from '@material-ui/core'


import { Select } from '@components/form'

import { useTranslation } from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
  formRadioGroupContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: theme.spacing(4),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  formCheckbox: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
  },
}))

export default function InvoiceItemsForm({ handleClose, isEdit, id, fetchUrl, invoiceId, invoiceFetchUrl, invoiceTaxRate }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    setError,
    errors,
    control,
    setValue,
    watch
  } = useForm({
    mode: 'onBlur'
  })

  const watchType = watch('type')


  const { data, isLoading, isError } = isEdit
    ? useFetchData(fetchUrl + id + '/')
    : {
      data: null,
      isLoading: null,
      isError: null,
    }

  useEffect(() => {
    if (isEdit && data) {
      setValue('type', data?.type)
      setValue('tax_rate', data?.tax_rate)
      setValue('title', data?.title)
      setValue('description', data?.description)
      setValue('subtotal', data?.subtotal)
    }
    if (!isEdit) {
      setValue('tax_rate', invoiceTaxRate)
    }
  }, [data, isEdit]);

  const onSubmit = async (data) => {
    setLoading(true)

    const response = await onCreateInvoiceItem(invoiceId, data)
    if (response) {
      if (response.status === 201) {
        handleClose()
        enqueueSnackbar(dictionary["invoice_item_updated"], {
          variant: 'success'
        })
        mutate(fetchUrl)
        mutate(invoiceFetchUrl)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  const onSubmitEdit = async (data) => {
    setLoading(true)

    const response = await onUpdateInvoiceItem(invoiceId, id, data)
    if (response) {
      if (response.status === 200) {
        handleClose()
        enqueueSnackbar(dictionary["invoice_item_updated"], {
          variant: 'success'
        })
        mutate(fetchUrl)
        mutate(invoiceFetchUrl)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(isEdit ? onSubmitEdit : onSubmit)}>
      <Grid container>

        {/* Type */}
        <Grid item xs="12">
          <Select
            id="type"
            name="type"
            label={dictionary["type"]}
            control={control}
            defaultValue={''}
            variant="outlined"
            margin="normal"
            error={errors.type}
            fullWidth
            errorMessage={errors.type && errors.type.message}
          >
            <MenuItem value="free">free</MenuItem>
            <MenuItem value="rent">rent</MenuItem>
            <MenuItem value="charges">charges</MenuItem>
            <MenuItem value="mgmt_fees">mgmt_fees</MenuItem>
            <MenuItem value="taxes">taxes</MenuItem>
            <MenuItem value="rent_discount">rent_discount</MenuItem>
            <MenuItem value="charges_adjustment">charges_adjustment</MenuItem>
            <MenuItem value="deposit">deposit</MenuItem>
            <MenuItem value="electric">electric</MenuItem>
            <MenuItem value="deposit_adjustment">deposit_adjustment</MenuItem>
          </Select>
        </Grid>


        {/* Title  */}
        <Grid xs="12">
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="title"
            label={dictionary["title"]}
            name="title"
            inputRef={register}
            error={errors.title}
            helperText={errors.title && errors.title.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* Description  */}
        <Grid xs="12">
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="description"
            label={dictionary["description"]}
            name="description"
            inputRef={register}
            error={errors.description}
            helperText={errors.description && errors.description.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* Subtotal */}
        {watchType === "free" &&
          <Grid xs="12">
            <TextField
              required={watchType === "free"}
              disabled={watchType !== "free"}
              type="number"
              variant="outlined"
              margin="normal"
              fullWidth
              id="subtotal"
              label={dictionary["subtotal"]}
              name="subtotal"
              inputRef={register}
              error={errors.subtotal}
              helperText={errors.subtotal && errors.subtotal.message}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        }

        {/* Tax rate */}
        <Grid xs="12">
          <TextField
            type="number"
            variant="outlined"
            margin="normal"
            fullWidth
            id="tax_rate"
            label={dictionary["tax_rate"]}
            name="tax_rate"
            inputRef={register}
            error={errors.tax_rate}
            helperText={errors.tax_rate && errors.tax_rate.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid xs="12" className={classes.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
            startIcon={<SaveIcon />}
            className={classes.submit}
          >
            {dictionary["submit"]}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
