import React, { createContext, useState, useContext, useEffect } from 'react'
import { onGetMyOrganization } from '@api/profile';

import dictionary from "@data/dictionary"

import { Loader } from '@components/common'

import { useAuth } from '@contexts/auth'

const TranslationContext = createContext({})

export const TranslationProvider = ({ children }) => {
  const { loading, user } = useAuth()
  const [lang, setLang] = useState(null)

  useEffect(() => {
    const getProfile = async () => {
      if (user) {
        const response = await onGetMyOrganization()
        if (response.status === 200 && response?.data?.locale) {
          setLang(response?.data?.locale)
        }
      }
      else {
        setLang("fr")
      }
    }

    if (!loading) getProfile()
  }, [loading, user]);

  if (!lang) return <Loader />

  return (
    <TranslationContext.Provider
      value={{ lang, setLang, dictionary: dictionary[lang] }}
    >
      {children}
    </TranslationContext.Provider>
  )
}

export const useTranslation = () => useContext(TranslationContext)