import React, { useState } from 'react'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from '@contexts/translation'
import DueDateDetail from '@components/dashboard/DueDate/DueDateDetail'

const useStyles = makeStyles((theme, props) => ({}))

function DueDateView({ className, operationId, data }) {


  return <DueDateDetail operationId={operationId} />
}

export default DueDateView
