import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import GetFormattedDate from '@utils/formatDate';
import { Modal } from '.'
import { onCreateLeaseArticle, onDeleteLeaseArticle } from '@api/leases';

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { Select } from "@components/form"

import { useFetchData } from '@api/fetchData'

import { useTranslation } from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerButtons: {
    height: 'fit-content',
    display: "flex",
    padding: theme.spacing(4),
  },
  container: {
    height: 'fit-content',
    maxWidth: 350,
    padding: theme.spacing(4)
  },
  buttonCancel: {
    marginRight: theme.spacing(8),
  },
  form: {
    display: 'flex',
    flexDirection: "column",
    zIndex: 99999,
  }
}))

export default function ModalPaidDate({ onClose, fetchUrl, operationId, id, dateInfo }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [referenceArticle, setReferenceArticle] = useState([])
  const { enqueueSnackbar } = useSnackbar()


  const lotsUrl = `/lots/?operation=${operationId}&is_private=true&page_size=999`
  const leaseArticleUrl = `/leases/${id}/articles/?page_size=999`

  const { data, isLoading, isError } = useFetchData(lotsUrl, null, { revalidateOnFocus: false, revalidateOnReconnect: false })
  const { data: leaseArticleData, isLoading: isLoadingLeaseArticle, isError: isErrorLeaseArticle } = useFetchData(leaseArticleUrl, null, { revalidateOnFocus: false, revalidateOnReconnect: false })


  const {
    handleSubmit,
    errors,
    setValue,
    register,
    control,
    setError
  } = useForm({
    mode: 'onBlur',
    shouldUnregister: false
  })

  const lotsId = () => {
    let lotsArray = []
    data && data.results && data.results.length > 1 && data.results.map(lot => {
      lotsArray.push(lot.id)
      return lotsArray
    })
    return lotsArray
  }

  const LotsArray = lotsId()

  const getLots = (lots) => lots.filter(lot => lot.lot).map(lot => lot.lot)

  useEffect(() => {
    if (leaseArticleData && leaseArticleData.results.length > 0) {
      setReferenceArticle(getLots(leaseArticleData.results))
      setValue('lease_article', getLots(leaseArticleData.results))
    }
  }, [leaseArticleData]);

  const onSubmitLeaseArticle = async (leaseId, data) => {
    let response = await onCreateLeaseArticle(leaseId, data)

    if (response) {
      if (response.status === 201) {
        return response.data
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }


  const leaseArticleLoop = async (data, dateInfo) => {
    setLoading(true)
    if (dateInfo.end_date) {
      data['end_date'] = GetFormattedDate(dateInfo.end_date)
    }
    if (dateInfo.start_date) {
      data['start_date'] = GetFormattedDate(dateInfo.start_date)
    }
    const promises = data.lease_article.map(async lot => {
      data['lot'] = lot
      if (referenceArticle.indexOf(lot) === -1) {
        const response = await onSubmitLeaseArticle(id, data)
        return response
      }
    })

    const createPromise = await Promise.all(promises)
    if (createPromise) {
      const promises = referenceArticle.map(async lot => {
        if (data.lease_article.indexOf(lot) === -1) {
          let leaseArticleId = leaseArticleData.results.filter(article => article.lot === lot)
          const response = await onDeleteLeaseArticle(id, leaseArticleId[0].id)
          return response
        }

      })
      const deletePromise = await Promise.all(promises)
      if (deletePromise) {
        enqueueSnackbar(dictionary["lease_articles_updated"], {
          variant: "success"
        })
        mutate(leaseArticleUrl)
        mutate(fetchUrl)
        setLoading(false)
        onClose()
      }
    }
  }


  const onSubmit = async (data) => {
    leaseArticleLoop(data, dateInfo)
  }

  return (
    <Modal className={classes.modalContainer} onClose={onClose}>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.container}>
          <Select
            variant="outlined"
            required
            margin="normal"
            fullWidth
            id="lease_article"
            label={dictionary["lease_article"]}
            name="lease_article"
            inputRef={register}
            error={errors.common_lots}
            control={control}
            isLoading={isLoading}
            helperText={errors.common_lots && errors.common_lots.message}
            multiple={true}
            arrayValue={LotsArray}
            defaultValue={[]}
            MenuProps={{
              getContentAnchorEl: () => null,
            }}
          >
            {data && data.results && data.results?.map(lot => (
              <MenuItem key={lot?.id} value={lot?.id}>{lot.building_display ? `${lot.building_display} - ` : null} {lot.floor_display ? `${lot.floor_display} - ` : null} {lot?.name}</MenuItem>
            ))}
          </Select>
          <div className={classes.containerButtons}>
            <Button
              className={classes.buttonCancel}
              onClick={onClose}
              type="button"
              variant="contained"
              color="inherit"
            >
              {dictionary["cancel"]}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              {dictionary["confirm"]}
            </Button>
          </div>
        </Paper>
      </form>
    </Modal>
  )
}
