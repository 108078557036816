import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack';

import { useFetchData } from '@api/fetchData'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'
import UnformatDate from '@utils/unformatDate'
import GetFormattedDate from '@utils/formatDate'

import { DatePicker, Switch, Select } from '@components/form'


import { useTranslation } from '@contexts/translation'
import { Grid, MenuItem } from '@material-ui/core';
import { onCreateRules, onUpdateRules } from '@api/fees';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
  },
}))

export default function ManagerForm({ id, isEdit, handleClose, fetchUrl, setSelectValue = null }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    setError,
    errors,
    setValue,
    watch,
    control
  } = useForm({
    mode: 'onBlur',
  })

  const watchType = watch("fee_type")
  const watchIsBounded = watch("is_bounded")



  const { data, isLoading, isError } = isEdit
    ? useFetchData('/fees/', id)
    : {
      data: null,
      isLoading: null,
      isError: null,
    }

  useEffect(() => {
    if (isEdit && data) {
      setValue('domain', data?.domain)
      setValue('metric', data?.metric)
      setValue('fee_type', data?.fee_type)
      setValue('fee_value', data?.fee_value)
      if (data?.start_date) setValue('end_date', UnformatDate(data?.start_date))
      if (data?.end_date) setValue('end_date', UnformatDate(data?.end_date))
      setValue('is_bounded', data?.is_bounded)
      setValue('lower_bound', data?.lower_bound)
      setValue('upper_bound', data?.upper_bound)

    }
  }, [data, isEdit]);

  const onSubmit = async (data) => {
    setLoading(true)

    if (data.end_date) {
      data['end_date'] = GetFormattedDate(data.end_date)
    }
    if (data.start_date) {
      data['start_date'] = GetFormattedDate(data.start_date)
    }

    let response = await onCreateRules(data)
    if (response) {
      if (response.status === 201) {
        handleClose()
        enqueueSnackbar(dictionary["rules_created"], {
          variant: "success"
        })
        mutate(fetchUrl)
        if (setSelectValue) setSelectValue(response?.data?.id)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  const onSubmitEdit = async (data) => {
    setLoading(true)

    if (data.end_date) {
      data['end_date'] = GetFormattedDate(data.end_date)
    }
    if (data.start_date) {
      data['start_date'] = GetFormattedDate(data.start_date)
    }

    let response = await onUpdateRules(id, data)
    if (response) {
      if (response.status === 200) {
        handleClose()
        enqueueSnackbar(dictionary["rules_updated"], {
          variant: "success"
        })
        mutate(fetchUrl)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(isEdit ? onSubmitEdit : onSubmit)}
    >
      <Grid container>

        {/* Domain */}

        <Grid item xs={12}>
          <Select
            id="domain"
            name="domain"
            label={dictionary["domain"]}
            control={control}
            defaultValue={'management'}
            variant="outlined"
            margin="normal"
            error={errors.domain}
            fullWidth
            errorMessage={errors.domain && errors.domain.message}
          >
            <MenuItem value="management">{dictionary['management']}</MenuItem>
            <MenuItem value="contruction">{dictionary['construction']}</MenuItem>
            <MenuItem value="sales">{dictionary['sales']}</MenuItem>
          </Select>
        </Grid>

        {/* Metric */}

        <Grid item xs={12}>
          <Select
            id="metric"
            name="metric"
            label={dictionary["metric"]}
            control={control}
            defaultValue={'gross_rent'}
            variant="outlined"
            margin="normal"
            error={errors.metric}
            fullWidth
            errorMessage={errors.metric && errors.metric.message}
          >
            <MenuItem value="gross_rent">{dictionary['gross_rent']}</MenuItem>
            <MenuItem value="effective_rent">{dictionary['effective_rent']}</MenuItem>
            <MenuItem value="active_tenants">{dictionary['active_tenants']}</MenuItem>
          </Select>
        </Grid>



        {/* Fee Value */}

        <Grid xs={8}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="fee_value"
            label={dictionary["fee_value"]}
            name="fee_value"
            inputRef={register}
            error={errors.fee_value}
            helperText={errors.fee_value && errors.fee_value.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>


        {/* Fee Type */}

        <Grid xs={4}>
          <Select
            id="fee_type"
            name="fee_type"
            label={dictionary["fee_type"]}
            control={control}
            defaultValue={'percentage'}
            variant="outlined"
            margin="normal"
            error={errors.fee_type}
            fullWidth
            errorMessage={errors.fee_type && errors.fee_type.message}
          >
            <MenuItem value="percentage">%</MenuItem>
            <MenuItem value="fixed">€</MenuItem>
          </Select>
        </Grid>

        {/* Start Date */}

        <Grid xs={6}>
          <DatePicker
            id="start_date"
            name="start_date"
            required
            label={dictionary["start_date"]}
            variant="outlined"
            margin="normal"
            fullWidth
            control={control}
            error={errors.start_date}
            errorMessage={errors.start_date && errors.start_date.message}
            clearable={true}
          />
        </Grid>

        {/* End Date */}

        <Grid xs={6}>
          <DatePicker
            id="end_date"
            name="end_date"
            required
            label={dictionary["end_date"]}
            variant="outlined"
            margin="normal"
            fullWidth
            control={control}
            error={errors.end_date}
            errorMessage={errors.end_date && errors.end_date.message}
            clearable={true}
          />
        </Grid>

        {/* Is Bounded */}
        <Grid xs={12}>
          <Switch
            margin="normal"
            id="is_bounded"
            label={dictionary["bounded"]}
            name="is_bounded"
            control={control}
            error={errors.is_bounded}
            helperText={errors.is_bounded && errors.is_bounded.message}
            labelPlacement="end"
            switchColor="primary"
          />
        </Grid>


        {watchIsBounded && <>
          {/* Lower Bound */}

          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              id="lower_bound"
              label={dictionary["lower_bound"]}
              name="lower_bound"
              type="number"
              inputRef={register}
              fullWidth
              error={errors.lower_bound}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors.lower_bound && errors.lower_bound.message}
            />
          </Grid>

          {/* Upper Bound */}

          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              id="upper_bound"
              label={dictionary["upper_bound"]}
              name="upper_bound"
              type="number"
              inputRef={register}
              fullWidth
              error={errors.upper_bound}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors.upper_bound && errors.upper_bound.message}
            />
          </Grid>

        </>}

        <Grid xs="12" className={classes.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
            startIcon={<SaveIcon />}
            className={classes.submit}
          >
            {dictionary["submit"]}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
