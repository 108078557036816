import client from '@utils/client'

export const OnCreateBank = (payload) => {
  return client
    .post('/banks/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onDeleteBank = (id) => {
  return client
    .delete(`/banks/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onUpdateBank = (id, payload) => {
  return client
    .patch(`/banks/${id}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
