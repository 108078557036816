import client from '@utils/client'

export const onFetchExportList = () => {
  return client
    .get('/exports/')
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onCreateExport = payload => {
  return client
    .post('/exports/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
