import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formAnimation: {
    width: '100%',
    maxWidth: '50vw',
    minHeight: '100vh',
    position: 'absolute',
    top: '0',
    right: '0',
    backgroundColor: theme.palette.background.paper,
    animation: `$fadeIn 1000ms ${theme.transitions.easing.easeInOut}`,
    '@media (max-width: 600px)': {
      maxWidth: '90vw',
    },
  },
  picture: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    animation: `$fadeIn 1000ms ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
  },
}))

export default function FormContainer({
  children,
  className,
  isPicture,
  ...props
}) {
  const classes = useStyles()

  return (
    <div
      className={
        isPicture
          ? `${classes.picture} ${className}`
          : `${classes.formAnimation} ${className}`
      }
      {...props}
    >
      {children}
    </div>
  )
}
