import React, { useState } from 'react';

import {
  Card,
  List,
  Button,
  Dialog,
  ListItem,
  Typography,
  DialogTitle,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { useRouter } from "next/router";
import { useFetchData } from '@api/fetchData'
import { onUpdateProfile } from '@api/profile'

import { useTranslation } from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}))

export default function OrganizationSelect({ orgName, orgId }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const { data } = useFetchData(`/organizations/`)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async (orgId) => {
    const response = await onUpdateProfile({ selected_org: orgId })
    if (response) {
      router.reload()
      setOpen(false);
    }
  };

  return (
    <>
      {data && data.length > 1 &&
        <Card className={classes.root}>
          <Typography variant="subtitle1" component="div">
            {dictionary["current_organization"]} : {orgName}
          </Typography>
          <br />
          <Button variant="outlined" onClick={handleClickOpen}>
            {dictionary["change_organization"]}
          </Button>
          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle id="simple-dialog-title">{dictionary["chose_your_organization"]}</DialogTitle>
            <List>
              {data && data.map((organization) => (
                <ListItem button onClick={() => handleClose(organization.id)} key={organization.id} disabled={organization.id === orgId ? true : false}>
                  <ListItemText primary={organization.name} />
                </ListItem>
              ))}
            </List>
          </Dialog>
        </Card>
      }
    </>
  );
}

