import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { makeStyles } from "@material-ui/core/styles";
import { onCreateImport, onRunImport, onFetchImportById } from '@api/imports';
import { LinearProgress, Typography, Button } from "@material-ui/core";
import { useTranslation } from "@contexts/translation";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Link from 'next/link'
import { v4 as uuidv4 } from 'uuid';
import { Table, CellExpand, } from '@components/common';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill'
import { Icon } from '@iconify/react'


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5, 0, 4),
    textAlign: 'center'
  },
  gridContainer: {
    marginTop: theme.spacing(2)
  },
  warning: {
    marginRight: theme.spacing(1),
    color: theme.palette['warning'].main,
  },
  warningContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center'
  },
  buttonContainer: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  uploading: {
    padding: theme.spacing(2, 0)
  },
  resultContainer: {
    marginTop: theme.spacing(4)
  },
  resultTitle: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  titleIcon: {
    marginRight: theme.spacing(1)
  },
  succesIcon: {
    marginRight: theme.spacing(1),
    color: `${theme.palette.primary.main_dark} !important`
  },
  succesTitle: {
    color: `${theme.palette.primary.main_dark} !important`
  },
  logsContainer: {
    padding: theme.spacing(2, 0, 0),
    textAlign: 'center'
  },
  importSucces: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  }
}));

export default function FinishView({ payload, file }) {
  const { dictionary } = useTranslation();
  const classes = useStyles();
  const [isUploading, setIsUploading] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [uploadResult, setUploadResult] = useState('');
  const [uploadInfo, setUploadInfo] = useState()
  const [uploadLogs, setUploadLogs] = useState([]);
  const [progress, setProgress] = useState(0);

  const [startUpload, setStartUpload] = useState(false)

  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  const handleImportErrors = (response) => {
    setUploadResult('error');
    let errors = response?.data?.errors || response?.data?.data?.errors || [];
    if (typeof errors === 'string') {
      errors = [errors];
    }
    setUploadLogs(errors);
  };

  const handleCheckResultsRequestLoop = (importId) => {
    setTimeout(() => {
      onFetchImportById(importId)
        .then(res => {
          if (res?.status === 200) { // request is OK
            switch (res.data.status) {
              case 2: // Pending
                handleCheckResultsRequestLoop(importId);
                break;
              case 3: // Successful
                setIsPending(false);
                setUploadInfo(res.data)
                setUploadResult('success');
                break;
              case 4: // Failed
                setIsPending(false);
                handleImportErrors(res);
                break;
              default:
                handleImportErrors(res);
                break;
            }
          }
          else {
            handleImportErrors(res);
            setIsPending(false);
          }
        })
        .catch(e => {
          console.error(e);
          setIsPending(false);
          handleImportErrors(e);
        });
    }, 2000);
  };

  const uploadDataToServer = async () => {
    const createResponse = await onCreateImport(payload, setProgress);
    if (!createResponse?.data || createResponse.data.errors) {
      handleImportErrors(createResponse);
    } else {
      const runResponse = await onRunImport(createResponse.data.id, setProgress);
      if (runResponse.status !== 201 || runResponse.data.errors) {
        handleImportErrors(runResponse);
      } else {
        setIsPending(true);
        handleCheckResultsRequestLoop(createResponse.data.id)
      }
    }
  };



  useEffect(() => {
    if (startUpload) {
      setIsUploading(true);
      uploadDataToServer().finally(() => { setIsUploading(false) });
    }

  }, [startUpload]);

  // process CSV data
  const processData = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows and add ID if needed / add for for better id ?
        if (Object.values(obj).filter(x => x).length > 0) {
          if (obj.id) {
            list.push(obj)
          }
          else {
            const objWithIdGenerated = { ...obj, id: obj.id ? obj.id : uuidv4() }
            list.push(objWithIdGenerated);
          }
        }
      }
    }

    // prepare columns list from headers
    const columns = headers.filter(col => col !== 'id').map((col, i, arr) => ({
      name: col,
      selector: col,
      field: col,
      width: 160,
      headerClassName: arr.length - 1 === i && "round_corner",
      renderCell: CellExpand,
      sortable: false,
    }));

    setData(list);
    setColumns(columns);
  }

  // handle file upload

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      processData(data);
    };
    reader.readAsBinaryString(file);
  }, [file]);


  return (
    <div className={classes.root}>
      <LinearProgress variant='determinate' value={progress} />
      {data && columns && !isUploading && !isPending && !uploadResult &&
        <>
          <div className={classes.warningContainer}>
            <Icon
              icon={alertTriangleFill}
              width={24}
              height={24}
              className={classes.warning}
            />
            {dictionary['warning_import']}
          </div>
          <div className={classes.buttonContainer}>
            <Button
              variant='contained'
              color='primary'
              type='button'
              onClick={() => setStartUpload(true)}
            >
              {dictionary['confirm_data']}
            </Button>
          </div>
          <Table
            className={classes.gridContainer}
            rows={data && data.length > 0 ? data : []}
            columns={columns}
            pageSize={100}
            pagination={false}
            disableColumnMenu={true}
          />
        </>
      }
      {
        (isUploading || isPending) &&
        <Typography variant='h5' className={classes.uploading} >
          {dictionary[`${isUploading ? 'uploading' : 'pending'}`]}...
        </Typography>
      }
      {
        uploadResult &&
        <div className={classes.resultContainer}>
          <div className={classes.resultTitle}>
            {uploadResult === 'error' ? (
              <div className={classes.importSucces}>
                <ErrorOutlineIcon color='error' className={classes.titleIcon} />
                <Typography color='error' variant='h5' >
                  {dictionary['errors_occurred']}
                </Typography>
              </div>
            ) : (
              <>
                <div className={classes.importSucces}>
                  <CheckCircleOutlineIcon color='primary' className={classes.succesIcon} />
                  <Typography color='primary' className={classes.succesTitle} variant='h5' >
                    {dictionary['import_successfully_finished']}!
                  </Typography>
                </div>
                <Link href={uploadInfo.type === 'contacts' ?
                  `/contacts`
                  : uploadInfo.type === 'operations'
                    ? `/operations/`
                    : uploadInfo.type === "categories" ?
                      `/operations/${uploadInfo.operation.id}/budgets/categories`
                      : uploadInfo.type === ('discounts' || 'lease_articles' || 'lease_periods') ?
                        `/operations/${uploadInfo.operation.id}/leases`
                        : uploadInfo.type === "invoice_items" ? `/operations/${uploadInfo.operation.id}/invoices`
                          : `/operations/${uploadInfo.operation.id}/${uploadInfo.type}`}
                  passHref>
                  <Button
                    variant='contained'
                    color='primary'
                    type='button'
                  >
                    {`${dictionary['go_to']} ${uploadInfo.type}`}
                  </Button></Link>
              </>
            )}
          </div>
          {uploadLogs.length ?
            <div className={classes.logsContainer}>
              {uploadLogs.map(log => (
                <Typography>
                  - {log}
                </Typography>
              ))}
            </div> : ''}
        </div>
      }
    </div >
  );
}
