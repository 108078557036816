import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import { useTranslation } from '@contexts/translation';
import NotificationsIcon from '@material-ui/icons/Notifications';
const useStyles = makeStyles({

});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, content } = props;
  const { dictionary } = useTranslation()

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      {content}
    </Dialog>
  );
}

export default function TableDialog({ content }) {
  const [open, setOpen] = React.useState(false);
  const { dictionary } = useTranslation()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <div style={{
        display: 'flex',
        height: '74px'
      }}>
      <InfoIcon onClick={handleClickOpen} sx={{
        cursor: 'pointer'
      }}/>
      </div>
      <SimpleDialog open={open} onClose={handleClose} content={content} />
    </>
  );
}
