import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack';

import { useFetchData } from '@api/fetchData'
import { onUpdateInvoice } from '@api/invoices'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'
import { Grid, MenuItem } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import { differenceInCalendarQuarters, getQuarter, getYear, lastDayOfQuarter, getDate, getMonth } from "date-fns";

import { onUpdateTenant } from '@api/tenants';
import { useTranslation } from '@contexts/translation'
import { BankSelect, Select, DatePicker, DateRangePicker, CurrenciesSelect, StyleguidesSelect } from '@components/form';
import UnformatDate from '@utils/unformatDate'
import GetFormattedDate, { getFormattedDateRange, unformatTimeRange } from '@utils/formatDate';
import { BanksForm } from '@components/banks';
import { ModalForm } from '@components/common';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
  formRadioGroupContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: theme.spacing(4),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  formCheckbox: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
  },
}))

export default function InvoiceSettingsForm({ handleClose, fetchUrl, invoiceId, isFree, tenantId, isCompany }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [startLeaseDate, setStartLeaseDate] = useState(null)
  const [endLeaseDate, setEndLeaseDate] = useState(null)
  const [quarterSelect, setQuarterSelect] = useState([])
  const [open, setOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    setError,
    errors,
    control,
    setValue,
    watch
  } = useForm({
    defaultValues: {
      billing_option: isFree ? "free" : ''
    },
    shouldUnregister: false,
    mode: 'onBlur'
  })

  const bankFetchUrl = `/banks/`
  const { data, isLoading, isError } = useFetchData(fetchUrl)

  const tenantUrl = `/tenants/${tenantId}/`
  const { data: dataTenant, isLoading: isLoadingTenant, isError: isErrorTenant } = useFetchData(tenantUrl)

  const { data: leaseData, isLoading: isLoadingLease, isError: isErrorLease } = data && data.lease ? useFetchData('/leases/', data.lease) : { leaseData: null, isLoadingLease: null, isErrorLease: null }


  const watchBillingOption = watch('billing_option')
  const watchPeriod = watch('periods')


  //TODO : Replace by switch case
  const getQuarterMonth = quarter => {
    if (quarter === 1) {
      return '01'
    }
    if (quarter === 2) {
      return '04'
    }
    if (quarter === 3) {
      return '07'
    }
    if (quarter === 4) {
      return '10'
    }
  }

  const formatMonth = month => {
    if (month + 1 < 10) {
      return `0${month + 1}`
    }
    else {
      return month + 1
    }
  }

  useEffect(() => {
    if (data) {
      setValue('bank', data.bank && data.bank.id)
      setValue('styleguide', data?.styleguide)
      setValue('language', data?.language)
      setValue('references', data?.references)
      if (data.invoice_date) setValue('invoice_date', UnformatDate(data?.invoice_date))
      if (data.period_start_date) setValue("period_start_date", unformatTimeRange(data?.period_start_date))
      if (data.period_end_date) setValue("period_end_date", unformatTimeRange(data?.period_end_date))
      if (data.due_date) setValue('due_date', UnformatDate(data?.due_date))
      setValue('tax_rate', data?.tax_rate)
      setValue('billing_option', data?.billing_option)
      setValue('currency', data.currency && data?.currency.id)
      setValue('client_name', data?.client_name)
      setValue('client_address', data?.client_address)
    }
    // if (dataTenant && isCompany) {
    //   setValue('company_name', dataTenant.company_name)
    //   setValue('client_address', dataTenant.client_address)
    // }

  }, [data, dataTenant, isCompany]);

  useEffect(() => {
    if (leaseData) {
      leaseData.start_date && setStartLeaseDate(leaseData.start_date)
      leaseData.end_date && setEndLeaseDate(leaseData.end_date)
    }

  }, [leaseData]);

  useEffect(() => {
    if (watchPeriod === 0 || watchPeriod) {
      if (quarterSelect[watchPeriod].quarter) {
        const quarter = quarterSelect[watchPeriod].quarter
        const year = quarterSelect[watchPeriod].year
        const startPeriod = `01/${getQuarterMonth(quarter)}/${year}`
        const endDay = lastDayOfQuarter(new Date(`${getQuarterMonth(quarter)}-01-${year}`))
        const endPeriod = `${getDate(endDay)}/${formatMonth(getMonth(endDay))}/${year}`

        setValue('period_start_date', startPeriod)
        setValue('period_end_date', endPeriod)
      }
      else {
        const year = quarterSelect[watchPeriod].year
        const startPeriod = `01/01/${year}`
        const endPeriod = `31/12/${year}`
        setValue('period_start_date', startPeriod)
        setValue('period_end_date', endPeriod)
      }

    }
  }, [watchPeriod]);

  useEffect(() => {
    if (startLeaseDate && endLeaseDate) {
      const startDate = new Date(UnformatDate(startLeaseDate))
      const endDate = new Date(UnformatDate(endLeaseDate))
      const getCurrentQuarter = getQuarter(new Date())
      const diffQuarter = differenceInCalendarQuarters(endDate, startDate)
      const diffWithToday = differenceInCalendarQuarters(endDate, new Date())

      quarterSelect.push({
        quarter: getCurrentQuarter === 1 ? 4 : getCurrentQuarter - 1,
        year: getCurrentQuarter === 1 ? getYear(new Date()) - 1 : getYear(new Date())
      })

      let startYear = getYear(startDate)
      for (let i = 1; i < diffWithToday + 1; i++) {

        if (quarterSelect[i - 1].quarter === 4) {
          startYear = startYear + 1;
        }

        setQuarterSelect([...quarterSelect])

        quarterSelect.push({
          year: startYear,
          quarter:
            quarterSelect[i - 1].quarter === 4
              ? 1
              : quarterSelect[i - 1].quarter + 1
        })
      }

      const startYearQuarter = getYear(startDate)
      let newQuarter = []

      const addYear = quarterSelect && quarterSelect.length > 0 && quarterSelect.map((quarter, index) => {
        if (index === 0) {
          newQuarter.push({
            year: startYearQuarter
          })
        }
        if (quarter.year !== startYearQuarter && quarter.quarter === 1) {
          newQuarter.push({
            year: quarter.year
          })
          newQuarter.push(quarter)

        }
        else {
          newQuarter.push(quarter)
        }
      })

      setQuarterSelect([...newQuarter])
    }
  }, [startLeaseDate, endLeaseDate]);


  const onSubmitEditTenantInfo = async (data) => {
    setLoading(true)
    let tenantInfo = {}
    // if (data.company_name) tenantInfo['company_name'] = data.company_name
    // if (data.first_name) tenantInfo['first_name'] = data.first_name
    // if (data.last_name) tenantInfo['last_name'] = data.last_name
    // tenantInfo['address'] = data.client_address
    const response = await onUpdateTenant(tenantInfo, tenantId)
    if (response.status === 200) {
      return true
    }
    if (response.status === 400) {
      setLoading(false)
      const { errors } = response.data
      if (errors.length > 0) {
        errors.map((error) => {
          setError(error.field, {
            message: error.message,
          })
        })
      }
      return false
    }
  }

  const formatQuarter = (quarter, year) => {
    return `Q${quarter} ${year}`
  }

  const onSubmitEdit = async (data) => {
    setLoading(true)
    const tenantRequest = await onSubmitEditTenantInfo(data)
    if (tenantRequest) {
      if (data.invoice_date) data["invoice_date"] = GetFormattedDate(data.invoice_date)
      if (data.due_date) data['due_date'] = GetFormattedDate(data.due_date)
      if (data.period_start_date && data.period_end_date) {
        data['period_start_date'] = getFormattedDateRange(data.period_start_date)
        data['period_end_date'] = getFormattedDateRange(data.period_end_date)
      }
      const response = await onUpdateInvoice(invoiceId, data)
      if (response) {
        if (response.status === 200) {
          handleClose()
          enqueueSnackbar(dictionary["invoice_updated"], {
            variant: 'success'
          })
          mutate(fetchUrl)
        }
        if (response.status === 400) {
          setLoading(false)
          const { errors } = response.data
          if (errors.length > 0) {
            errors.map((error) => {
              setError(error.field, {
                message: error.message,
              })
            })
          }
        }
      }
    }
  }

  return (
    <>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmitEdit)}>
        <Grid container>

            <Grid xs="12">
              <TextField
                required
                variant="outlined"
                margin="normal"
                fullWidth
                id="client_name"
                label={dictionary["client_name"]}
                name="client_name"
                inputRef={register}
                error={errors.client_name}
                helperText={errors.client_name && errors.client_name.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>


          <Grid xs="12">
            <TextField
              required
              variant="outlined"
              margin="normal"
              fullWidth
              id="client_adress"
              label={dictionary["client_adress"]}
              name="client_adress"
              multiline
              rows={4}
              inputRef={register}
              error={errors.client_adress}
              helperText={errors.client_adress && errors.client_adress.message}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid xs='12'>
            <BankSelect
              control={control}
              error={errors.bank}
              errorMessage={errors.bank && errors.bank.message}
              fullWidth
              defaultValue={''}
            >
              <MenuItem key={293983} value={null} onClick={() => setOpen(true)}>
                <AddIcon /> {dictionary["add_a_bank"]}
              </MenuItem>
            </BankSelect>
          </Grid>

          {/* Invoice date */}
          <Grid xs="12">
            <DatePicker
              id="invoice_date"
              name="invoice_date"
              required
              label={dictionary["invoice_date"]}
              variant="outlined"
              margin="normal"
              fullWidth
              control={control}
              error={errors.invoice_date}
              errorMessage={errors.invoice_date && errors.invoice_date.message}
              clearable={true}
            />
          </Grid>

          {/* Periods */}
          <Grid item xs="12">
            <Select
              id="periods"
              name="periods"
              label={dictionary["invoice_period"]}
              control={control}
              variant="outlined"
              defaultValue={''}
              margin="normal"
              error={errors.periods}
              fullWidth
              errorMessage={errors.periods && errors.periods.message}
            >
              {quarterSelect && quarterSelect.map((quarter, index) => {
                return (
                  <MenuItem key={index} value={index}>{quarter.quarter ? formatQuarter(quarter.quarter, quarter.year) : quarter.year}</MenuItem>
                )
              })}
            </Select>
          </Grid>


          <Grid xs="12">
            <DateRangePicker
              idStart="period_start_date"
              idEnd="period_end_date"
              nameStart="period_start_date"
              nameEnd="period_end_date"
              labelStart={dictionary["start_date"]}
              labelEnd={dictionary["end_date"]}
              variant="outlined"
              margin="normal"
              register={register}
              startDate={data && data.period_start_date && unformatTimeRange(data?.period_start_date)}
              endDate={data && data.period_end_date && unformatTimeRange(data?.period_end_date)}
              errorStart={errors.period_start_date}
              errorMessageStart={errors.period_start_date && errors.period_start_date.message}
              errorEnd={errors.period_end_date}
              errorMessageEnd={errors.period_end_date && errors.period_end_date.message}
            />
          </Grid>

          {/* Tax rate */}
          <Grid xs="12">
            <TextField
              required
              type="number"
              variant="outlined"
              margin="normal"
              fullWidth
              id="tax_rate"
              label={dictionary["tax_rate"]}
              name="tax_rate"
              inputRef={register}
              error={errors.tax_rate}
              helperText={errors.tax_rate && errors.tax_rate.message}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          {/* Billing Options */}
          <Grid item xs="12">
            <Select
              id="billing_option"
              name="billing_option"
              label={dictionary["billing_option"]}
              control={control}
              defaultValue={''}
              variant="outlined"
              margin="normal"
              error={errors.billing_option}
              fullWidth
              errorMessage={errors.billing_option && errors.billing_option.message}
            >
              <MenuItem value="30_days">{dictionary["30_days"]}</MenuItem>
              <MenuItem value="45_days">{dictionary["45_days"]}</MenuItem>
              <MenuItem value="60_days">{dictionary["60_days"]}</MenuItem>
              <MenuItem value="75_days">{dictionary["75_days"]}</MenuItem>
              <MenuItem value="90_days">{dictionary["90_days"]}</MenuItem>
              <MenuItem value="end_of_month">{dictionary["end_of_month"]}</MenuItem>
              <MenuItem value="on_reception">{dictionary["on_reception"]}</MenuItem>
              <MenuItem value="due_on">{dictionary["due_on"]}</MenuItem>
              <MenuItem value="free">{dictionary["free"]}</MenuItem>
            </Select>
          </Grid>

          {watchBillingOption === "free" &&
            <Grid xs="12">
              <DatePicker
                id="due_date"
                name="due_date"
                required
                label={dictionary["due_date"]}
                variant="outlined"
                margin="normal"
                fullWidth
                control={control}
                error={errors.due_date}
                errorMessage={errors.due_date && errors.due_date.message}
                clearable={true}
              />
            </Grid>
          }

          {/* References  */}
          <Grid xs="12">
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="references"
              label={dictionary["references"]}
              name="references"
              inputRef={register}
              error={errors.references}
              helperText={errors.references && errors.references.message}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          {/* Language  */}
          <Grid item xs="12">
            <Select
              id="language"
              name="language"
              label={dictionary["lang"]}
              control={control}
              defaultValue={''}
              variant="outlined"
              margin="normal"
              error={errors.language}
              fullWidth
              errorMessage={errors.language && errors.language.message}
            >
              <MenuItem value="en">{dictionary["english"]}</MenuItem>
              <MenuItem value="fr">{dictionary["french"]}</MenuItem>
            </Select>
          </Grid>

          <Grid xs='12'>
            <StyleguidesSelect
              control={control}
              error={errors.styleguide}
              errorMessage={errors.styleguide && errors.styleguide.message}
              fullWidth
              defaultValue={''}
            />
          </Grid>


          {/* Currency  */}
          <Grid item xs={12}>
            <CurrenciesSelect
              control={control}
              fullWidth
              error={errors.currency}
              errorMessage={errors.currency && errors.currency.message}
            />
          </Grid>



          <Grid xs="12" className={classes.buttonContainer}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
              startIcon={<SaveIcon />}
              className={classes.submit}
            >
              {dictionary["submit"]}
            </Button>
          </Grid>
        </Grid>
      </form>


      {
        open &&
        <ModalForm type={dictionary["bank"]} onClose={() => setOpen(false)}>
          <BanksForm handleClose={() => setOpen(false)} setSelectValue={id => setValue('bank', id)} fetchUrl={bankFetchUrl} />
        </ModalForm>
      }
    </>
  )
}
