import clsx from 'clsx'
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Typography, Box, Card, CardHeader, CardContent } from '@material-ui/core'
import { useTranslation } from '@contexts/translation'

import DueDateTable from '@components/dashboard/DueDate/DueDateTable'

const useStyles = makeStyles((theme, props) => ({
  container: {
    height: '100%',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  description: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: theme.palette.grey[500],
  },
  choiceStyle: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[300],
    borderRadius: '8px',
    padding: `0 ${theme.spacing(2)}`,
    marginBottom: theme.spacing(4),
  },
  choiceContainer: {
    position: 'relative',
    display: 'flex',
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,

    '&:first-of-type': {
      marginRight: theme.spacing(8),
    },

    '& p': {
      opacity: 0.8,
    },

    '& span': {
      position: 'absolute',
      top: '0em',
      right: '-.5em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '2em',
      height: '2em',
      padding: theme.spacing(0.5),
      fontSize: '.8em',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50rem',
    },
  },
  navIsActiveStyle: {
    backgroundColor: 'rgba(4,	49,	118, 0.6)',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  tableTitle: {
    color: theme.palette.grey[500],
    opacity: 0.8,
    marginBottom: theme.spacing(3),
  },
}))

function DueDateDetail({ className, operationId }) {
  const classes = useStyles()
  const theme = useTheme()
  const { dictionary } = useTranslation()

  return (
    <Card className={classes.container}>
      <div className={classes.titleContainer}>
        <CardHeader title={dictionary['overview_due_date']} />
      </div>
      <Typography className={classes.description}>
        {dictionary['overview_leases_contracts']}
      </Typography>
      <CardContent>
        <DueDateTable
          operationId={operationId}
        />
      </CardContent>
    </Card>
  )
}

export default DueDateDetail
