import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles, Box, Typography } from '@material-ui/core';
import clsx from "clsx";
import { useTranslation } from "@contexts/translation";

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  dropZone: {
    height: '100%',
    outline: 'none',
    display: 'flex',
    overflow: 'hidden',
    textAlign: 'center',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(5, 0),
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('padding'),
    backgroundColor: theme.palette.background.neutral,
    border: `1px dashed ${theme.palette.grey[500_32]}`,
    '&:hover': {
      opacity: 0.72,
      cursor: 'pointer'
    },
  },
  hasFile: {
    padding: '12% 0'
  },
  isDragActive: {
    opacity: 0.72
  },
  isDragReject: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.light,
    backgroundColor: theme.palette.error.light
  },
  dropZoneBg: {
    display: 'flex',
    padding: theme.spacing(0, 3)
  },
  dropZoneText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(2)
  },
  dropZoneTextItem: {
    textAlign: 'left'
  },
  fileName: {
    marginTop: theme.spacing(1)
  }
}));

export default function UploadSingleFile({
  error = false,
  acceptFiles = '',
  value: file,
  onChange: setFile,
  className,
  ...other
}) {
  const classes = useStyles();
  const { dictionary } = useTranslation();

  const handleDrop = useCallback(
    (acceptedFiles) => {
      let file = acceptedFiles[0];

      if (file) {
        setFile({
          name: file.name,
          file: file,
          preview: URL.createObjectURL(file)
        });
      }
    },
    [setFile]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept
  } = useDropzone({ onDrop: handleDrop, multiple: false, accept: acceptFiles });

  useEffect(
    () => () => {
      if (file) {
        URL.revokeObjectURL(file.preview);
      }
    },
    [file]
  );

  return (
    <div className={clsx(classes.root, className)} {...other}>
      <div
        className={clsx(classes.dropZone, {
          [classes.isDragActive]: isDragActive,
          [classes.isDragAccept]: isDragAccept,
          [classes.isDragReject]: isDragReject || error,
          [classes.hasFile]: file
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />

        <div className={classes.dropZoneBg}>
          <Box
            component='img'
            alt='select file'
            src={'/static/illustrations/illustration_upload.svg'}
            sx={{ height: 160 }}
          />
          {!file &&
            <Box className={classes.dropZoneText}>
              <Typography gutterBottom variant="h5" className={classes.dropZoneTextItem}>
                {dictionary['drop_or_select_file']}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} className={classes.dropZoneTextItem}>
                {dictionary['drop_file_here_or_click']}&nbsp;
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ color: 'primary.main' }}
                >
                  {dictionary['browse']}
                </Typography>
                &nbsp;{dictionary['thorough_your_machine']}
              </Typography>
            </Box>
          }
        </div>

        {file?.name &&
          <Typography variant='h3' className={classes.fileName}>
            {dictionary['file']}: {file?.name}
          </Typography>
        }

      </div>
    </div>
  );
}
