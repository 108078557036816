import React from 'react'

import { Modal } from '.'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'

import { useTranslation } from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerDelete: {
    height: 'fit-content',
    zIndex: 99999,
    display: 'flex',
    flexDirection: "column",
    padding: theme.spacing(4),
  },
  containerButton: {
    display: 'flex',
    minWidth: 240,
    justifyContent: 'space-evenly',
  },
  containerDescription: {
    display: 'flex',
    marginBottom: theme.spacing(4)
  },
}))

export default function ModalDelete({ onClose, onDelete, description, isYellow, isLoading }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()

  return (
    <Modal className={classes.modalContainer} onClose={onClose}>

      <Paper className={classes.containerDelete}>
        {description &&
          <Paper className={classes.containerDescription}>
            {description}
          </Paper>
        }
        <div className={classes.containerButton}>
          <Button
            onClick={onClose}
            type="button"
            variant="contained"
            disabled={isLoading}
            color="inherit"
          >
            {dictionary["cancel"]}
          </Button>
          <Button
            onClick={onDelete}
            type="button"
            variant="contained"
            disabled={isLoading}
            color={isYellow ? "warning" : "error"}
          >
            {dictionary["confirm"]}
          </Button>
        </div>
      </Paper>
    </Modal>
  )
}
