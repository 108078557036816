import clsx from 'clsx'
import React, {useState} from 'react'
import PropTypes from 'prop-types'

import {useTheme, makeStyles} from '@material-ui/core/styles'
import {Typography, Box, Card, CardHeader, CardContent} from '@material-ui/core'
import {useTranslation} from '@contexts/translation'
import dynamic from 'next/dynamic'

const ReactApexChart = dynamic(() => import('react-apexcharts'), {ssr: false})
import ApexChartsOption from '@components/dashboard/charts/ApexChartsOption'

const useStyles = makeStyles((theme, props) => ({
  content: {
    padding: `0 ${theme.spacing(3)}`,
  },
  description: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    color: theme.palette.grey[500],
  },
}))

function PotentialCandidatesDetail({className, data}) {
  const classes = useStyles()
  const theme = useTheme()
  const {dictionary} = useTranslation()

  const series = [
    {
      name: 'Fennel de vente 1',
      data: [100, 75, 54, 43, 27, 10, 2, 1.5],
    },
  ]

  const options = {
    chart: {
      height: 150,
      type: 'area',
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 100],
        colorStops: [
          {
            offset: 0,
            color: '#4986FB',
            opacity: 1,
          },
          {
            offset: 100,
            color: '#A168EE',
            opacity: 1,
          },
        ],
      },
    },
    xaxis: {
      type: 'categories',
      categories: series[0]?.data?.map((num) => num + '%'),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    labels: [''],
  }

  return (
    <Card>
      <CardHeader title={dictionary['overview_potential_candidates']} />
      <Typography className={classes.description}>
        {dictionary['overview_potential_tenants']}
      </Typography>
      <CardContent>
        <Box>
          <ReactApexChart
            type={'area'}
            series={series}
            options={options}
            height={250}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default PotentialCandidatesDetail
