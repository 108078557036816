const Badge = ({ children }) => (
  <span
    style={{
      color: '#212B36',
      cursor: 'default',
      height: 22,
      display: 'inline-flex',
      padding: '0px 8px',
      margin: 2,
      fontSize: '0.75rem',
      minWidth: 22,
      alignItems: 'center',
      whiteSpace: 'nowrap',
      borderRadius: 8,
      justifyContent: 'center',
      backgroundColor: '#DFE3E8',
    }}
  >
    {children}
  </span>
);
export default Badge;
