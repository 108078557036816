import React from 'react';

import { Modal } from '.';

import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import { FormContainer } from '@components/form';

import { useTranslation } from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "scroll"
  },
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    overflow: 'scroll',
    height: '100%'
  },
  modalContainerPicture: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    height: '100%'
  },
  formTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}))

export default function ModalForm({ children, onClose, type, isEdit, customTitle, isPicture }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()

  return (
    <Modal
      className={isPicture ? classes.modalContainerPicture : classes.modalContainer}
      onClose={onClose}
    >
      <FormContainer className={classes.root} isPicture={isPicture}>
        <div className={classes.formTitleContainer}>
          {customTitle ? (<p>{customTitle}</p>) : isEdit ? <p>{dictionary["edit"]} {type}</p> : <p>{dictionary["add"]} {type}</p>}
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {children}
      </FormContainer>
    </Modal>
  )
}
