import { makeStyles } from '@material-ui/core/styles';
import { useGridSlotComponentProps } from '@material-ui/data-grid';
import Pagination from '@material-ui/lab/Pagination';
import { numberWithSpaces } from '@utils/numberWithSpaces';
import useBreakpoints from '@hooks/useBreakpoints';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  list: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function TotalFooter({ fields, indexesOfFieldsToCalcTotal }) {
  const classes = useStyles();

  const { state, apiRef, columns, rows } = useGridSlotComponentProps();
  const isMobile = useBreakpoints('down', 'sm');

  const getTotalValues = () => {
    if (rows?.length > 0) {
      const _totalValues = {};
      rows?.reduce((acc, value) => {
        indexesOfFieldsToCalcTotal.map((item) => {
          _totalValues[fields[item]] = acc[fields[item]] + value[fields[item]];
        });

        return {
          ..._totalValues,
        };
      });
      return _totalValues;
    }
  };

  const itemStyleName = {
    width: columns[0].computedWidth,
    padding: '0 10px',
    fontWeight: 'bold',
  };
  const itemStyleCommon = (columnNumber) => {
    return {
      width: isMobile ? columns[columnNumber].computedWidth : columns[1].computedWidth,
      padding: '0 10px',
    };
  };
  const itemStyleTotal = (columnNumber) => {
    return {
      width: isMobile ? columns[columnNumber].computedWidth : columns[1].computedWidth,
      padding: '0 10px',
      fontWeight: 'bold',
      textAlign: 'right',
    };
  };

  const fieldsHtml = [];
  const generateFieldsHTML = () => {
    const totalValues = getTotalValues();
    fields.map((field, index) => {
      const fieldName = fields[index];
      if (index === 0) {
        fieldsHtml.push(<div style={itemStyleName}>Total</div>);
      }
      if (index !== 0 && indexesOfFieldsToCalcTotal.indexOf(index) !== -1) {
        fieldsHtml.push(
          <div style={itemStyleTotal(index)}>
            {numberWithSpaces(totalValues[fieldName])}
          </div>
        );
      } else if (index !== 0) {
        fieldsHtml.push(<div style={itemStyleCommon(index)}></div>);
      }
    });
  };
  generateFieldsHTML();

  return (
    <div className={`${classes.root} MuiDataGrid-footer`}>
      {rows?.length > 0 && (
        <div>
          <div className={classes.list}>{fieldsHtml}</div>
        </div>
      )}
      {state.pagination.pageCount > 1 && (
        <Pagination
          color="primary"
          count={state.pagination.pageCount}
          page={state.pagination.page + 1}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
      )}
    </div>
  );
}
