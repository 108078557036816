import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'

import { Modal } from '.'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'

import { useTranslation } from '@contexts/translation'

import { DatePicker } from '@components/form'

import UnformatDate from '@utils/unformatDate'

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerButtons: {
    height: 'fit-content',
    display: "flex",
    padding: theme.spacing(4),
  },
  container : {    
    height: 'fit-content',
    padding: theme.spacing(4)
  },
  form: {
    zIndex: 99999,
    display: 'flex',
    flexDirection : "column"
  },
  buttonCancel: {
    marginRight: theme.spacing(8),
  },
}))

export default function ModalDate({ onClose, onConfirm, prevDate, loading }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    if (prevDate) setTimeout(() => setValue('date', UnformatDate(prevDate)), 1)
  }, [prevDate]);

  const onSubmit = async (data) => onConfirm(data?.date)

  const {
    handleSubmit,
    errors,
    setValue,
    control
  } = useForm({
    mode: 'onBlur'
  })

  return (
    <Modal className={classes.modalContainer} onClose={onClose}>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.container}>
          <DatePicker
            id="date"
            name="date"
            required
            label={dictionary["date"]}
            variant="outlined"
            margin="normal"
            fullWidth
            control={control}
            error={errors.date}
            errorMessage={errors.date && errors.date.message}
            clearable={true}
          />

          <div className={classes.containerButtons}>
            <Button
              className={classes.buttonCancel}
              onClick={onClose}
              type="button"
              variant="contained"
              color="inherit"
            >
              {dictionary["cancel"]}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              {dictionary["confirm"]}
            </Button>
          </div>
        </Paper>
      </form>
    </Modal>
  )
}
