import clsx from 'clsx'
import React from 'react'
import { isString } from 'lodash'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography, Link } from '@material-ui/core'

import { MBreadcrumbs } from '@theme/index'

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  containerTitle: {
    display: 'flex',
    alignItems: 'center',
  },
}))

// ----------------------------------------------------------------------

HeaderDashboard.propTypes = {
  links: PropTypes.array,
  action: PropTypes.node,
  heading: PropTypes.string.isRequired,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
}

function HeaderDashboard({
  links,
  action,
  heading,
  status,
  cancelDate,
  moreLink = '' || [],
  className,
  ...other
}) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          {heading &&
            <div className={classes.containerTitle}>
              <Typography variant="h3" gutterBottom>
                {heading}
              </Typography>
              {status}
              {cancelDate}
            </div>
          }
          <MBreadcrumbs links={links} {...other} />
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>


      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link
            href={moreLink}
            target="_blank"
            variant="body2"
            sx={{ cursor: 'pointer' }}
          >
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              sx={{ display: 'table', cursor: 'pointer' }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </div>
  )
}

export default HeaderDashboard
