import React, { useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles'
import { Card, Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'

import { onChangePassword } from '@api/auth'

import { useTranslation } from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  form: {
    margin: 20,
    marginTop: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    padding: theme.spacing(3)
  }
}))

export default function ChangePasswordForm() {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, setError, errors, watch, reset } = useForm({
    mode: 'onBlur',
  })
  const { enqueueSnackbar } = useSnackbar();


  const password = useRef({})
  password.current = watch('new_password', '')

  const onSubmit = async (data) => {
    setLoading(true)

    let response = await onChangePassword(data)
    if (response.status === 204) {
      enqueueSnackbar(dictionary["password_updated"], {
        variant: 'success'
      })
      reset({ current_password: "", new_password: "", new_password2: "" })
      setLoading(false)
    }
    if (response.status === 400) {
      setLoading(false)
      const { errors } = response.data
      if (errors.length > 0) {
        errors.map((error) => {
          setError(error.field, {
            message: error.message,
          })
        })
      }
    }
  }

  return (
    <Card className={classes.root}>
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            name="current_password"
            label={dictionary["current_password"]}
            type="password"
            id="current_password"
            fullWidth
            inputRef={register}
            error={errors.current_password}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors.current_password && errors.current_password.message}
            autoComplete="password"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            name="new_password"
            label={dictionary["new_password"]}
            type="password"
            id="new_password"
            fullWidth
            inputRef={register}
            error={errors.new_password}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors.new_password && errors.new_password.message}
            autoComplete="new_password"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            name="new_password2"
            label={dictionary["confirm_new_password"]}
            type="password"
            id="new_password2"
            fullWidth
            inputRef={register({
              validate: value =>
                value === password.current || dictionary["errors_password_not_match"]
            })}
            error={errors.new_password2}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors.new_password2 && errors.new_password2.message}
            autoComplete="new_password"
          />
        </Grid>
        <Grid xs="12" className={classes.buttonContainer}>

          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
            startIcon={<SaveIcon />}
            className={classes.submit}
          >
            {dictionary["submit"]}
          </Button>
        </Grid>
      </form>
    </Card>
  )
}


