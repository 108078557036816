import reverse from 'lodash/reverse'
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import InboxIcon from '@material-ui/icons/Inbox'
import {useFetchData} from '@api/fetchData'
import PropTypes from 'prop-types'
import {fDateTime} from '@utils/formatTime'
import {makeStyles} from '@material-ui/core/styles'
import {Card, Typography, CardHeader, CardContent, Box} from '@material-ui/core'
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
} from '@material-ui/lab'
import {MTimelineDot} from '@theme/@material-extend/Timeline'
import {Loader} from '@components/common'
import {useTranslation} from '@contexts/translation'

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTimelineItem-missingOppositeContent:before': {
      display: 'none',
    },
    maxHeight: 1000,
    overflowY: 'auto',
  },
  cardContent: {
    width: '100%',
    height: '100%',
    '& > div': {
      width: 'inherit',
      height: 'inherit',
    },
  },
}))

function OrderItem({userDisplay, createdAt, isLastTime, typeDisplay}) {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <MTimelineDot color="primary" />
        {isLastTime ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="subtitle2">
          <>{userDisplay && `${userDisplay} - `}</>
          {`${typeDisplay}`}
        </Typography>
        <Typography variant="caption" sx={{color: 'text.secondary'}}>
          {createdAt ? fDateTime(createdAt) : '-'}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  )
}

ActionsTimeline.propTypes = {
  className: PropTypes.string,
}

function ActionsTimeline({className, path, ...other}) {
  const classes = useStyles()
  const [result, setResult] = useState(null)
  const {dictionary} = useTranslation()

  const {data, isLoading} = useFetchData(path)
  useEffect(() => {
    if (data) {
      setResult(reverse(data.results))
    }
  }, [data])
  return (
    <Card className={clsx(classes.root, className)} {...other}>
      <CardHeader title={dictionary['recent_activity']} />
      <CardContent className={classes.cardContent}>
        {isLoading ? (
          <Loader />
        ) : (
          <Timeline align="left" style={{alignItems: 'flex-start', padding: 0}}>
            {result &&
              result.map(
                (
                  {
                    id,
                    created_at: createdAt,
                    type_display: typeDisplay,
                    orguser,
                  },
                  index,
                ) => (
                  <OrderItem
                    key={id}
                    id={id}
                    userDisplay={orguser?.display}
                    createdAt={createdAt}
                    typeDisplay={typeDisplay}
                    isLastTime={index === data.results.length - 1}
                  />
                ),
              )}
          </Timeline>
        )}
        {!isLoading && data && data.results.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <InboxIcon sx={{fontSize: 100}} color="disabled" />
          </Box>
        ) : null}
      </CardContent>
    </Card>
  )
}

export default ActionsTimeline
