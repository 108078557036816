import React, { useState } from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Card, Grid, Typography, Box,
  Container, Link
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'

import { Copyright, Layout, HeaderPage, Loader } from '@components/common'
import { InvoiceItemsTable } from "./"
import { Toolbar } from '@components/common'
import Page404View from 'pages/404'

import { MLabel } from '@theme/@material-extend'

import { useFetchData } from '@api/fetchData'
import { mutate } from 'swr'
import { onDeleteInvoice, onUpdateInvoice, onUpdateInvoiceSchedule } from '@api/invoices';
import { useRouter } from 'next/router';

import { useOperation } from "@contexts/operation"
import { useTranslation } from '@contexts/translation'
import { useSnackbar } from 'notistack';

import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill'
import { Icon } from '@iconify/react'
import { format } from 'date-fns'
import UnformatDate from '@utils/unformatDate'
import { enUS, fr } from 'date-fns/locale';


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  paperToolbar: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  paperHeader: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  toolbarContainer: {
    paddingTop: `${theme.spacing(1)} !important`,
  },
  line: {
    display: 'flex',
    '&:not(:first-child)': {
      marginTop: theme.spacing(2)
    },
    '&>p': {
      display: "flex"
    }
  },
  warning: {
    marginLeft: theme.spacing(2),
    color: theme.palette['warning'].main,
  },
  infoDisplay: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  status: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
  },
  displayAddress: {
    whiteSpace: 'pre-line',
  },
  red: {
    color: theme.palette.error.main,
  }
}))



export default function InvoicesDetails({ operationId, invoiceId }) {
  const { lang, dictionary } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const { operation } = useOperation()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter()


  const fetchUrl = `/invoices/${invoiceId}/`
  const { data, isLoading, isError } = useFetchData(fetchUrl)

  const updateValidate = async () => {
    setLoading(true)
    const invoice = []
    invoice['is_valid'] = !data.is_valid
    const response = await onUpdateInvoice(invoiceId, invoice)
    if (response) {
      if (response.status === 200) {
        mutate(fetchUrl)
        enqueueSnackbar(dictionary["invoice_updated"], {
          variant: 'success'
        })
        setLoading(false)
      }
      else {
        mutate(fetchUrl)
        enqueueSnackbar(dictionary["invoice_updated"], {
          variant: 'success'
        })
        setLoading(false)
      }
    }
  }

  const updateSend = async () => {
    setLoading(true)
    const invoice = []
    invoice['is_sent'] = !data.is_sent
    const response = await onUpdateInvoice(invoiceId, invoice)
    if (response) {
      if (response.status === 200) {
        mutate(fetchUrl)
        enqueueSnackbar(dictionary["invoice_updated"], {
          variant: 'success'
        })
        setLoading(false)
      }
      else {
        mutate(fetchUrl)
        enqueueSnackbar(dictionary["invoice_updated"], {
          variant: 'success'
        })
        setLoading(false)
      }
    }
  }

  const deleteInvoice = async () => {
    setLoading(true)
    const response = await onDeleteInvoice(invoiceId)
    if (response) {
      if (response.status === 204) {
        router.push(`/operations/${operationId}/invoices`)
      }
    }
  }

  const updateCancel = async () => {
    setLoading(true)
    const invoice = []
    invoice['is_closed'] = !data.is_closed
    const response = await onUpdateInvoice(invoiceId, invoice)
    if (response) {
      if (response.status === 200) {
        mutate(fetchUrl)
        enqueueSnackbar(dictionary["invoice_updated"], {
          variant: 'success'
        })
        setLoading(false)
      }
      else {
        mutate(fetchUrl)
        enqueueSnackbar(dictionary["invoice_updated"], {
          variant: 'success'
        })
        setLoading(false)
      }
    }
  }

  const updatePay = async () => {
    setLoading(true)
    const invoice = []
    invoice['is_paid'] = !data.is_paid
    const response = await onUpdateInvoice(invoiceId, invoice)
    if (response) {
      if (response.status === 200) {
        mutate(fetchUrl)
        enqueueSnackbar(dictionary["invoice_updated"], {
          variant: 'success'
        })
        setLoading(false)
      }
      else {
        mutate(fetchUrl)
        enqueueSnackbar(dictionary["invoice_updated"], {
          variant: 'success'
        })
        setLoading(false)
      }
    }
  }

  const onUnschedule = async data => {
    setLoading(true)
    const formData = {}
    formData['is_scheduled'] = false
    formData["send_on_date"] = null
    formData["send_on_time"] = null
    const response = await onUpdateInvoiceSchedule(invoiceId, formData)
    if (response) {
      if (response.status === 200) {
        setLoading(false)
        mutate(fetchUrl)
        enqueueSnackbar(dictionary["invoice_unscheduled"], {
          variant: 'success'
        })
      }
      if (response.status === 400) {
        enqueueSnackbar(dictionary["error"], {
          variant: 'error'
        })
      }
    }
  }


  if (isLoading) return <Loader />
  if (isError) return <Page404View />

  return (
    <Layout isSidebar operationId={operationId}>
      <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paperHeader}>
              <HeaderPage
                heading={data.code}
                status={
                  <MLabel
                    className={classes.status}
                    variant={
                      theme.palette.mode === 'light' ? 'ghost' : 'filled'
                    }
                    color={(data?.status === 'paid' && 'success') ||
                      ((data?.status === 'late' || data?.status === "closed" || data?.status === "litigious") && 'error') ||
                      ((data?.status === 'canceled' || data?.status === 'not_valid') && 'default') ||
                      ((data?.status === 'not_sent') && 'info') ||
                      ((data?.status === 'not_paid' || data?.status === 'partial') && 'warning') ||
                      'default'}
                    sx={{ textTransform: 'uppercase', mb: 1 }}
                  >
                    {data.is_scheduled && !data.is_sent ? dictionary['scheduled'] : data?.status_display}
                  </MLabel>
                }
                links={[
                  { name: dictionary["operations"], href: `/operations/` },
                  { name: operation?.name, href: `/operations/${operationId}` },
                  { name: dictionary["invoices"], href: `/operations/${operationId}/invoices` },
                  { name: data.code }
                ]} />
            </Paper>
          </Grid>

          <Grid item xs={12} className={classes.toolbarContainer}>
            <Paper className={classes.paperToolbar}>
              <Toolbar isScheduled={data?.is_scheduled} isSent={data?.is_sent} tenantId={data?.tenant?.id} isCompany={data?.tenant?.is_company} code={data?.code} isFree={data?.billing_option === "free" ? true : false} amount={data?.amount} balance={data?.balance} status={data?.status} fetchUrl={fetchUrl} loading={loading} invoiceId={invoiceId} isSent={data && data.is_sent} handleValidate={updateValidate} handleDelete={deleteInvoice} handleCancel={updateCancel} handlePay={updatePay} handleSend={updateSend} isValid={data && data.is_valid} isClosed={data && data.is_closed} operationId={operationId} subtotal={data?.subtotal} tenant={data?.tenant} taxes={data?.tax} onUnschedule={onUnschedule} />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Card sx={{ pt: 5, px: 5 }}>

                <Grid container>
                  <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                    <Typography
                      paragraph
                      variant="overline"
                      sx={{ color: 'text.disabled' }}
                    >
                      {dictionary["invoice_info"]}
                    </Typography>
                    {data?.invoice_date && (<Typography variant="body2">{dictionary["invoice_date"]}: {data?.invoice_date}</Typography>)}
                    {data?.due_date && (<Typography variant="body2">{dictionary["due_date"]}: {data?.due_date}</Typography>)}
                    {data?.billing_option && (<Typography variant="body2">{dictionary["billing_option"]}: {dictionary[data.billing_option]}</Typography>)}
                    {data?.period_start_date && data?.period_end_date && <Typography variant="body2">{dictionary["invoice_period"]}: {format(UnformatDate(data.period_start_date), 'd LLL yyyy',
                      { locale: lang === 'fr' ? fr : enUS })} - {format(UnformatDate(data.period_end_date), 'd LLL yyyy',
                        { locale: lang === 'fr' ? fr : enUS })}</Typography>}
                    <Typography className={classes.infoDisplay} variant="body2">{dictionary["bank"]}: {data?.bank ? data.bank.name :
                      <Icon
                        icon={alertTriangleFill}
                        width={20}
                        height={20}
                        className={classes.warning}
                      />
                    }
                    </Typography>

                    <Typography className={classes.infoDisplay} variant="body2">{dictionary["currency"]}: {data?.currency ? data.currency.name :
                      <Icon
                        icon={alertTriangleFill}
                        width={20}
                        height={20}
                        className={classes.warning}
                      />
                    }
                    </Typography>

                    {data?.lease_detail &&
                    <Typography className={classes.infoDisplay} variant="body2">{dictionary["indexation"]}: 
                      {data?.lease_detail?.index_type} BASE : {data?.lease_detail?.index_base_year} {data?.lease_detail?.index_base_trimester}
                    </Typography>
                    }

                  </Grid>

                  {data?.tenant && (
                    <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                      <Typography
                        paragraph
                        variant="overline"
                        sx={{ color: 'text.disabled' }}
                      >
                        Tenant info
                      </Typography>
                      <Link href={`/operations/${data?.operation?.id}/leases/${data?.lease_detail?.id}`} passHref>
                       {data?.tenant?.display && (<Typography variant="body2">{data?.tenant?.display}</Typography>)}
                      </Link>
                      
                      {data?.tenant?.email && (<Typography variant="body2">{data?.tenant?.email}</Typography>)}
                      {data?.tenant?.phone && (<Typography variant="body2">{data?.tenant?.phone}</Typography>)}
                      {data?.tenant?.address && (<Typography className={classes.displayAddress} variant="body2">{data?.tenant?.address}</Typography>)}
                    </Grid>
                  )}
                </Grid>

                {data?.references && (
                  <Grid container>
                    <Grid item xs={12} md={9}>
                      <Typography paragraph
                        variant="overline"
                        sx={{ color: 'text.disabled' }}>
                        {dictionary["comments"]}
                      </Typography>
                      <Typography variant="body2">{data?.references}</Typography>
                    </Grid>
                  </Grid>
                )}

                <InvoiceItemsTable invoiceTaxRate={data?.tax_rate} invoiceId={invoiceId} invoiceFetchUrl={fetchUrl} subtotal={data?.subtotal} taxes={data?.tax} isSent={data?.is_sent} />
              </Card>
            </Paper>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </Layout>
  )
}
