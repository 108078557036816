import React, { useState, useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import clsx from 'clsx'
import { getHours, getMinutes } from 'date-fns'
import AddIcon from '@material-ui/icons/Add';
import Router from 'next/router'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { useTranslation } from '@contexts/translation'

import { StyleguideEditor, SMTPSelect, TemplatesSelect } from "@components/form"
import { onUpdateInvoiceSchedule, onUpdateInvoice, onSendMail } from '@api/invoices'
import GetFormattedDate from '@utils/formatDate'
import { ModalScheduleDate, ModalForm } from '@components/common'
import { SMTPForm } from '@components/smtp'

const useStyles = makeStyles((theme) => ({
  form: {
    margin: 20,
    marginTop: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: "space-between"
  },
  flexEnd: {
    justifyContent: 'flex-end'
  },
  pictureContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    "& img": {
      width: '150px'
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  unschedule: {
    marginLeft: theme.spacing(3)
  },
  send: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3)
  },
  attachment: {
    margin: theme.spacing(3, 0, 2),
  },

  hiddenInput: {
    position: "absolute",
    zIndex: -1,
    opacity: 0
  }
}))

export default function SendInvoiceMail({ operationId, invoiceId, data, fetchUrl }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [openSchedule, setOpenSchedule] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const formRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenSchedule = () => {
    setOpenSchedule(true)
  }

  const handleCloseSchedule = () => {
    setOpenSchedule(false)
  }


  const { register, handleSubmit, setError, errors, control, setValue, reset, watch } = useForm(
    { mode: 'onBlur', shouldUnregister: false },
  )

  const watchAllFields = watch();


  useEffect(() => {
    if (data) {
      setValue('invoice_email_to', data?.invoice_email_to)
      setValue('invoice_email_bcc', data?.invoice_email_bcc)
      setValue('invoice_email_cc', data?.invoice_email_cc)
      setValue('invoice_email_subject', data?.invoice_email_subject)
      setValue('invoice_email_body', data?.invoice_email_body)
      setValue('invoice_email_template', data?.invoice_email_template)
    }
  }, [data])

  const variables = [
    "invoice_code",
    "project_title",
    "subtotal",
    "total",
    "currency_symbol",
    "currency_name",
    "invoice_date",
    "invoice_date_year",
    "invoice_date_month_display",
    "invoice_date_month",
    "invoice_date_day",
    "due_date",
    "due_date_year",
    "due_date_month_display",
    "due_date_month",
    "due_date_day",
    "client_name",
    "client_contact_name",
    "client_address"
  ]

  const onSubmit = async (data) => {
    setLoading(true)
    const response = await onUpdateInvoice(invoiceId, data)
    if (response) {
      if (response.status === 200) {
        const mailResponse = await onSendMail(invoiceId)
        if (mailResponse) {
          if (mailResponse.status === 201) {
            setLoading(false)
            mutate(fetchUrl)
            enqueueSnackbar(dictionary["invoice_sent"], {
              variant: 'success'
            })
          }
        }
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }

  }

  const onSendTest = async data => {
    // TO-DO: Send mail to user profile mail
    // View with Hyppolite how is handle mail sending
  }
  const onSchedule = async data => {
    setLoading(true)
    const formData = watchAllFields
    formData['is_scheduled'] = false
    formData["send_on_date"] = null
    formData["send_on_time"] = null
    if (data.send_on_date) formData["send_on_date"] = GetFormattedDate(data.send_on_date)
    if (data.send_on_time) {
      const hour = getHours(data.send_on_time)
      const minutes = getMinutes(data.send_on_time)
      formData["send_on_time"] = `${hour}:${minutes}`
    }
    if (data.send_on_date || data.send_on_time) {
      formData['is_scheduled'] = true
    }
    const response = await onUpdateInvoiceSchedule(invoiceId, formData)
    if (response) {
      if (response.status === 200) {
        setLoading(false)
        handleCloseSchedule()
        mutate(fetchUrl)
        enqueueSnackbar(dictionary["invoice_scheduled"], {
          variant: 'success'
        })
        Router.push(`/operations/${operationId}/invoices/${invoiceId}`)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  const onUnschedule = async data => {
    setLoading(true)
    const formData = {}
    formData['is_scheduled'] = false
    formData["send_on_date"] = null
    formData["send_on_time"] = null
    const response = await onUpdateInvoiceSchedule(invoiceId, formData)
    if (response) {
      if (response.status === 200) {
        setLoading(false)
        mutate(fetchUrl)
        enqueueSnackbar(dictionary["invoice_unscheduled"], {
          variant: 'success'
        })
        Router.push(`/operations/${operationId}/invoices/${invoiceId}`)
      }
      if (response.status === 400) {
        enqueueSnackbar(dictionary["error"], {
          variant: 'error'
        })
      }
    }
  }


  const handleChangeTemplates = data => {
    setValue('invoice_email_subject', data?.email_subject)
    setValue('invoice_email_body', data?.email_body)
    setValue('invoice_email_template', data?.id)
    setValue('smtp_setting', data?.smtp_setting)
  }

  const handleClickItem = e => {
    e.target.firstElementChild.select();
    document.execCommand("copy");
    enqueueSnackbar(`${dictionary["variable_copied"]}: ${e.target.innerText}`, {
      variant: 'success',
    })
  }

  return (
    <>
      <form ref={formRef}
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container className={classes.centerContainer}>
          <Grid item xs={8}>
            <TextField
              variant="outlined"
              margin="normal"
              id="invoice_email_to"
              required
              label={"To"}
              name="invoice_email_to"
              type="email"
              inputRef={register}
              fullWidth
              error={errors.to}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors.invoice_email_to && errors.invoice_email_to.message}
            />
          </Grid>

          <Grid container className={classes.centerContainer} spacing={1}>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                id="invoice_email_cc"
                label={"CC"}
                name="invoice_email_cc"
                inputRef={register}
                fullWidth
                error={errors.invoice_email_cc}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={errors.invoice_email_cc && errors.invoice_email_cc.message}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                id="invoice_email_bcc"
                label={"BCC"}
                name="invoice_email_bcc"
                inputRef={register}
                fullWidth
                error={errors.invoice_email_bcc}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={errors.invoice_email_bcc && errors.invoice_email_bcc.message}
              />
            </Grid>
          </Grid>

          <Grid xs={8}>
            <TemplatesSelect control={control} defaultValue="" fullWidth handleChange={handleChangeTemplates} />
          </Grid>

          <Grid item xs={8}>
            <SMTPSelect
              fullWidth
              control={control}
              error={errors.smtp_setting}
              errorMessage={errors.smtp_setting && errors.smtp_setting.message}
              defaultValue=''
            >
              <MenuItem key={-1} value={null} onClick={() => setOpen(true)}>
                <AddIcon /> {dictionary["add_new_smtp"]}
              </MenuItem>
              <MenuItem key={0} value={null} className={classes.emptyItem}>
                {dictionary['no_smtp']}
              </MenuItem>
            </SMTPSelect>
          </Grid>

          <Grid item xs={8}>
            <TextField
              variant="outlined"
              margin="normal"
              id="invoice_email_subject"
              label={"Subject"}
              name="invoice_email_subject"
              inputRef={register}
              fullWidth
              error={errors.invoice_email_subject}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors.invoice_email_subject && errors.invoice_email_subject.message}
            />
          </Grid>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {variables.map((variable) => (
              <MenuItem onClick={handleClickItem}>
                {dictionary[variable]
                }<input className={classes.hiddenInput} value={`{{${variable}}}`} />
              </MenuItem>
            ))}
          </Menu>

          <Grid item xs={8}>
            <StyleguideEditor
              defaultValue={""}
              isVariable
              name="invoice_email_body"
              label={dictionary["body"]}
              control={control}
              handleClick={handleClick} />
          </Grid>

          <Grid item xs={8} className={classes.attachment}>
            <input ref={register} type="file" name="attachment" />
          </Grid>


        </Grid>

        <Grid xs={8} className={clsx(classes.buttonContainer, classes.flexEnd)}>
          <Button
            variant="contained"
            color="secondary"
            type="button"
            disabled={loading}
            className={classes.submit}
            onClick={handleOpenSchedule}
          >
            {dictionary["schedule"]}
          </Button>
          {data.is_scheduled &&
            <Button
              variant="contained"
              color="secondary"
              type="button"
              disabled={loading}
              className={clsx(classes.submit, classes.unschedule)}
              onClick={onUnschedule}
            >
              {dictionary["unschedule"]}
            </Button>
          }

          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
            className={clsx(classes.submit, classes.send)}
          >
            {dictionary["send"]}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="button"
            disabled={loading}
            className={classes.submit}
            onClick={onSendTest}
          >
            {dictionary["send_test"]}
          </Button>
        </Grid>
      </form>
      {openSchedule && (
        <ModalScheduleDate
          onClose={handleCloseSchedule}
          onSubmit={onSchedule}
          invoiceId={invoiceId}
          loading={loading}
        />
      )}

      {open &&
        <ModalForm type={dictionary["smtp"]} onClose={() => setOpen(false)}>
          <SMTPForm
            handleClose={() => setOpen(false)}
            setSelectValue={id => setValue('smtp_setting', id)}
          />
        </ModalForm>
      }
    </>
  )
}
