import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Grid, Paper, Tab } from '@material-ui/core';
import { TabContext, TabList } from '@material-ui/lab';

import { Copyright, Layout, HeaderPage } from '@components/common';
import {
  ChangePasswordForm,
  OrganizationsSettings,
  OrgusersTable,
  ProfileInfoForm,
  LangForm,
  DataView
} from '@components/profile';

import { onGetMyOrganization } from '@api/profile';

import { useTranslation } from '@contexts/translation'
import ImportList from "@components/profile/Import/ImportList";
import NewImport from "@components/profile/Import/NewImport";
import Export from "@components/profile/Export";
import Import from "@components/profile/Import";

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ProfileView() {
  const { dictionary } = useTranslation()
  const classes = useStyles();
  const [tabValue, setTabValue] = useState('profile');

  const [userInfo, setUserInfo] = useState()

  const PROFILE_TAB = [
    { value: 'profile', label: dictionary["profile"], disabled: false, component: <ProfileInfoForm /> },
    { value: 'password', label: dictionary["change_password"], disabled: false, component: <ChangePasswordForm /> },
    { value: 'organizations', label: dictionary["organization"], disabled: false, component: <OrganizationsSettings orgId={userInfo?.selected_org} /> },
    { value: 'exportList', label: dictionary["exports"], disabled: false, component: <Export /> },
    { value: 'importList', label: dictionary["imports"], disabled: false, component: <Import /> },
    { value: 'users', label: dictionary["users"], disabled: false, component: <OrgusersTable /> },
    { value: 'billing', label: dictionary["billing"], disabled: true }
  ];

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const getProfile = async () => {
      const response = await onGetMyOrganization()
      if (response.status === 200) {
        setUserInfo(response.data)
      }
    }
    getProfile()
  }, []);

  return (
    <Layout>
      <Container maxWidth={false} className={classes.container}>
        <TabContext value={tabValue}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <HeaderPage heading={dictionary["settings"]}
                  links={[
                    { name: dictionary["home"], href: `/` },
                    { name: dictionary["settings"] }
                  ]} />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <TabList onChange={handleChange} variant="scrollable">
                  {PROFILE_TAB.map((tab) => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                      disabled={tab.disabled}
                    />
                  ))}
                </TabList>
              </Paper>
            </Grid>

            {PROFILE_TAB.map((tab) => {
              const isMatched = tab.value === tabValue;
              return isMatched && <Grid item xs={12} key={tab.value}>{tab.component}</Grid>;
            })}
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </TabContext>
      </Container>
    </Layout>
  );
}
