import React, { useEffect, useState } from 'react';

import {
  DataGrid,
  GridOverlay,
  useGridSlotComponentProps,
} from '@material-ui/data-grid';
import { Card, CardHeader } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/Inbox';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import useWindowDimensions from '@hooks/useWindowDimension';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start !important',
    marginTop: theme.spacing(2),
  },
  table: {
    height: '700px',
  },
  icon: {
    fontSize: '4rem',
  },
}));

function CustomNoRowsOverlay() {
  const classes = useStyles();
  return (
    <GridOverlay>
      <InboxIcon className={classes.icon} />
    </GridOverlay>
  );
}

function FooterRow({ pagination }) {
  const classes = useStyles();
  const { state, apiRef, columns, rows } = useGridSlotComponentProps();

  return (
    <>
      {false && state.pagination.pageCount > 1 && pagination &&
        <div className={`${classes.root} MuiDataGrid-footer`}>
          {state.pagination.pageCount > 1 && pagination && (
            <Pagination
              color="primary"
              count={state.pagination.pageCount}
              page={state.pagination.page + 1}
              size="small"
              siblingCount={0}
              onChange={(event, value) => apiRef.current.setPage(value - 1)}
            />
          )}
        </div>
      }
    </>
  );
}

export default function Table({
  title,
  loading,
  rows,
  pageSize,
  page,
  handlePageChange,
  handleEditCellChange,
  onEditCellChangeCommitted,
  count,
  columns,
  rowHeight,
  fixedHeight,
  isLightTable = false,
  components,
  pagination = true,
  searchValue = null, // tag
  searchParams,
  columnsWidth,
  color,
  style,
  paginationMode = "server",
  disableColumnMenu = false,
  pinnedColumns = {},
  ...other
}) {
  const [filteredRows, setFilteredRows] = useState(rows);
  const classes = useStyles();
  const { height } = useWindowDimensions();

  useEffect(() => {
    if (rows && rows.length) {
      if (!searchValue) {
        return setFilteredRows(rows);
      }

      const filteredData = rows.filter((row) => {
        if (row.tags && row.tags.length) {
          const tags = row.tags.filter((tag) =>
            tag.name === searchValue.name ? tag : null
          );

          if (tags.length) {
            return tags;
          }
        }
      });
      setFilteredRows(filteredData);
    }
  }, [rows, searchValue]);

  let data = rows;
  if (searchParams) {
    if (searchParams.orguser) {
      data = data.filter(
        (d) => d.orguser && d.orguser.id.toString() === searchParams.orguser
      );
    }
    if (searchParams.operation) {
      data = data.filter((d) =>
        d.operation ? d.operation.name === searchParams.operation : null
      );
    }
    if (searchParams.contractOperation) {
      data = data.filter((d) => d.operation && d.operation.toString() === searchParams.contractOperation);
    }
    if (searchParams.type || searchParams.type === 0) {
      data = data.filter((d) => d.type === searchParams.type);
    }
    if (searchParams.is_active) {
      data = data.filter((d) => {
        const booleanIsActive = searchParams.is_active === 'Active';
        return d.is_active === booleanIsActive;
      });
    }
    if (searchParams.startDate) {
      data = data.filter(
        (d) => new Date(searchParams.startDate) < new Date(d.created_at)
      );
    }
    if (searchParams.endDate) {
      data = data.filter(
        (d) => new Date(d.created_at) < new Date(searchParams.endDate)
      );
    }

    if (searchParams.currency) {
      data = data.filter((d) => d.currency === searchParams.currency.id);
    }
    if (searchParams.supplier) {
      data = data.filter((d) => d.supplier?.display === searchParams.supplier);
    }
    if (searchParams.status) {
      data = data.filter((d) => d.status === searchParams.status);
    }
  }
  const formatPage = page ? page - 1 : 0;
  const calcHeight = height - 164
  return (
    <Card sx={{ width: columnsWidth, zIndex: 999, backgroundColor: color, ...style }}>
      {title && <CardHeader title={title} />}
      <div style={{ height: rowHeight ? null : fixedHeight ? fixedHeight : isLightTable ? `355px` : !loading && data.length < 10 ? null : data.length === 10 ? calcHeight < 610 ? calcHeight : null : calcHeight }}>
        <DataGrid
          rows={searchValue ? filteredRows : data}
          columns={columns}
          pageSize={pageSize ? pageSize : 50}
          page={formatPage}
          autoHeight={rowHeight ? true : isLightTable ? false : !loading && data.length < 10 ? true : data.length === 10 ? calcHeight < 610 ? false : true : false}
          paginationMode={paginationMode}
          rowCount={count}
          onEditCellChangeCommitted={onEditCellChangeCommitted}
          onEditCellChange={handleEditCellChange}
          onPageChange={handlePageChange}
          rowHeight={rowHeight}
          loading={loading}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
            Footer: () => <FooterRow pagination={pagination} />,
            ...components,
          }}
          disableSelectionOnClick
          disableColumnMenu={true}
          {...other}
        />
      </div>
    </Card>
  );
}
