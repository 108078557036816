import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import { Layout, HeaderPage, Loader } from '@components/common'
import { SendInvoiceMail } from '@components/invoices'
import Page404View from 'pages/404'

import { useOperation } from "@contexts/operation"
import { useTranslation } from '@contexts/translation'

import { useFetchData } from '@api/fetchData'
import { MLabel } from '@theme/@material-extend'

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    maxHeight: '100vh',
  },
  container: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  ScheduleLabel: {
    marginLeft: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, .8)',
    textTransform: 'uppercase',
  },
  sticky: {
    position: 'sticky',
    zIndex: '999',
    top: '40px'
  }
}))

export default function SendInvoiceView({ id, slug }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const { operation } = useOperation()
  const fetchUrl = `/invoices/${slug}/`
  const { data, isLoading, isError } = useFetchData(fetchUrl)

  if (isLoading) return <Loader />
  if (isError) return <Page404View />

  const PageHeader = ({ isScheduled }) => {
    return (
      <div className={classes.header} >
        {data && data?.code} <MLabel className={classes.ScheduleLabel} color="info" >{dictionary[isScheduled ? 'scheduled' : 'sent']}</MLabel>
      </div>
    )
  }

  return (
    <Layout isSidebar operationId={id}>
      <main className={classes.content}>
        <Container maxWidth={false} className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.sticky} >
              <Paper className={classes.paper}>
                <HeaderPage
                  heading={data && data?.sent ? <PageHeader /> : data?.is_scheduled ? <PageHeader isScheduled /> : data && data?.code}
                  links={[
                    { name: dictionary["operations"], href: `/operations/` },
                    { name: operation?.name, href: `/operations/${id}` },
                    { name: dictionary["invoices"], href: `/operations/${id}/invoices/` },
                    { name: data?.code, href: `/operations/${id}/invoices/${slug}` },
                    { name: dictionary["send"] }
                  ]}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <SendInvoiceMail fetchUrl={fetchUrl} operationId={id} invoiceId={slug} isSent={data && data?.is_sent} data={data} />
            </Grid>
          </Grid>
        </Container>
      </main>
    </Layout>
  )
}
