import client from '@utils/client'


export const onCreateTenant = (payload) => {
  return client
    .post('/tenants/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onDeleteTenant = (id) => {
  return client
    .delete(`/tenants/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onUpdateTenant = (payload, id) => {
  return client
    .patch(`/tenants/${id}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetTenant = (id) => {
  return client
    .get(`/tenants/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetTenants = (operationId) => {
  return client
    .get(`/tenants/${operationId ? `?operations=${operationId}` : ''}`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const addTenantFile = (id, fileId, files) => {
  return client
    .patch(`/tenants/${id}/`, {
      files: files ? [...files, fileId] : [fileId]
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}



export const onGetTenantsOperation = (id) => {
  return client
    .get(`/tenants/?operations=${id}`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onEnablePortal = (id, type, value) => {
  return client
    .patch(`/${type}s/${id}/`, {
      portal_enabled: value,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}