import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack';

import { useFetchData } from '@api/fetchData'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'

import { CountriesSelect, CurrenciesSelect } from '@components/form'


import { useTranslation } from '@contexts/translation'
import { Grid } from '@material-ui/core';
import { OnCreateBank, onUpdateBank } from '@api/banks';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
  },
}))

export default function BanksForm({ id, isEdit, handleClose, fetchUrl, setSelectValue = null }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    setError,
    errors,
    setValue,
    control
  } = useForm({
    mode: 'onBlur',
  })

  const { data, isLoading, isError } = isEdit
    ? useFetchData('/banks/', id)
    : {
      data: null,
      isLoading: null,
      isError: null,
    }

  useEffect(() => {
    if (isEdit && data) {
      setValue('name', data?.name)
      setValue('description', data?.description)
      setValue('rib', data?.rib)
      setValue('bic', data?.bic)
      setValue('iban', data?.iban)
      setValue('currency', data?.currency)
      setValue('address', data?.address)
      setValue('postal_code', data?.postal_code)
      setValue('city', data?.city)
      setValue('country', data?.country)

    }
  }, [data, isEdit]);

  const onSubmit = async (data) => {
    setLoading(true)
    let response = await OnCreateBank(data)
    if (response) {
      if (response.status === 201) {
        handleClose()
        enqueueSnackbar(dictionary["bank_created"], {
          variant: "success"
        })
        mutate(fetchUrl)
        if (setSelectValue) setSelectValue(response?.data?.id)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  const onSubmitEdit = async (data) => {
    setLoading(true)
    let response = await onUpdateBank(id, data)
    if (response) {
      if (response.status === 200) {
        handleClose()
        enqueueSnackbar(dictionary["bank_updated"], {
          variant: "success"
        })
        mutate(fetchUrl)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(isEdit ? onSubmitEdit : onSubmit)}
    >
      <Grid container>

        {/* Name */}

        <Grid xs="12">
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            required
            id="name"
            label={dictionary["name"]}
            name="name"
            inputRef={register}
            error={errors.name}
            helperText={errors.name && errors.name.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>


        {/* Description */}

        <Grid xs="12">
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="description"
            label={dictionary["description"]}
            name="description"
            inputRef={register}
            error={errors.description}
            helperText={errors.description && errors.description.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CurrenciesSelect
            control={control}
            fullWidth
            error={errors.currency}
            errorMessage={errors.currency && errors.currency.message}
          />
        </Grid>

        {/* RIB */}

        <Grid xs="12">
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="rib"
            label={dictionary["rib"]}
            name="rib"
            inputRef={register}
            error={errors.rib}
            helperText={errors.rib && errors.rib.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* IBAN */}

        <Grid xs="12">
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="iban"
            label={dictionary["iban"]}
            name="iban"
            inputRef={register}
            error={errors.iban}
            helperText={errors.iban && errors.iban.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* BIC */}

        <Grid xs="12">
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="bic"
            label={dictionary["bic"]}
            name="bic"
            inputRef={register}
            error={errors.bic}
            helperText={errors.bic && errors.bic.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        {/* Address */}

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            id="street"
            label={dictionary["address"]}
            name="address"
            inputRef={register}
            fullWidth
            error={errors.address}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors.address && errors.address.message}
          />
        </Grid>

        {/* Postal Code */}

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            id="postal_code"
            label={dictionary["postal_code"]}
            name="postal_code"
            inputRef={register}
            fullWidth
            error={errors.postal_code}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors.postal_code && errors.postal_code.message}
          />
        </Grid>

        {/* City */}

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            id="city"
            label={dictionary["city"]}
            name="city"
            inputRef={register}
            fullWidth
            error={errors.city}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors.city && errors.city.message}
          />
        </Grid>

        {/* Country */}

        <Grid item xs={12}>
          <CountriesSelect control={control} fullWidth />
        </Grid>

        <Grid xs="12" className={classes.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
            startIcon={<SaveIcon />}
            className={classes.submit}
          >
            {dictionary["submit"]}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
