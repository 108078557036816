import clsx from 'clsx'
import React from 'react'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Typography, Box, Card, CardHeader, CardContent } from '@material-ui/core'
import { useTranslation } from '@contexts/translation'
import { numberWithSpaceInt } from '@utils/numberWithSpaces'

const useStyles = makeStyles((theme, props) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',

    '@media (max-width: 600px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(1),
    },
  },
  titleContainer: {
    width: '100%',
    maxWidth: '15%',
    marginRight: theme.spacing(3),
    '@media (max-width: 600px)': {
      maxWidth: '100%',
    },
  },
  description: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    color: theme.palette.grey[500],
  },
  content: {
    width: '100%',
    maxWidth: '85%',
    display: 'grid',
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    overflowX: 'auto',
    '@media (max-width: 600px)': {
      maxWidth: '100%',
    },
  },
  contentContainer: {
    display: 'grid',
    gridTemplateColumns: '1.4fr repeat(3, minmax(100px,1fr)) ',
    gap: theme.spacing(4),
    marginBottom: theme.spacing(0.5),
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,

    '& h6': {
      color: theme.palette.primary.main,
    },

    '& p:last-of-type': {
      fontWeight: '700',
    },
  },
  contentTitle: {
    gridColumn: '2 / 3',
    textAlign: 'right',
  },
  rightAlign: {
    textAlign: 'right',
  },
  contentTotal: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: '50rem',
    marginBottom: theme.spacing(0),
    '& p': {
      fontWeight: '700',
    },
  },
}))

function VacationCostsDetail({ className, data }) {
  const classes = useStyles()
  const theme = useTheme()
  const { dictionary } = useTranslation()

  return (
    <Card className={classes.container}>
      <div className={classes.titleContainer}>
        <CardHeader title={dictionary['overview_vacation_costs']} />
        <Typography className={classes.description}>
          {dictionary['overview_measure_costs']}
        </Typography>
      </div>

      <CardContent className={classes.content}>
        <Box className={classes.contentContainer}>
          <Typography className={classes.contentTitle} variant={'h6'}>
            {dictionary['occupied']}
          </Typography>
          <Typography className={classes.rightAlign} variant={'h6'}>
            {dictionary['vacant']}
          </Typography>
          <Typography className={classes.rightAlign} variant={'h6'}>
            {dictionary['total']}
          </Typography>
        </Box>
        <Box className={classes.contentContainer}>
          <Typography>{dictionary['rents']}</Typography>
          {data && (
            <>
              <Typography className={classes.rightAlign}>
                {numberWithSpaceInt(data.rent_occupied)}
              </Typography>
              <Typography className={classes.rightAlign}>
                {numberWithSpaceInt(data.rent_vacant)}
              </Typography>
              <Typography className={classes.rightAlign}>
                {numberWithSpaceInt(data.rent_total)}
              </Typography>
            </>
          )}
        </Box>
        <Box className={classes.contentContainer}>
          <Typography>{dictionary['expenses']}</Typography>
          {data && (
            <>
              <Typography className={classes.rightAlign}>
                {numberWithSpaceInt(data.expenses_occupied)}
              </Typography>
              <Typography className={classes.rightAlign}>
                {numberWithSpaceInt(data.expenses_vacant)}
              </Typography>
              <Typography className={classes.rightAlign}>
                {numberWithSpaceInt(data.expenses_total)}
              </Typography>
            </>
          )}
        </Box>
        <Box className={clsx(classes.contentContainer, classes.contentTotal)}>
          <Typography>{dictionary['total']}</Typography>
          {data && (
            <>
              <Typography className={classes.rightAlign}>
                {numberWithSpaceInt(data.billable_occupied)}
              </Typography>
              <Typography className={classes.rightAlign}>
                {numberWithSpaceInt(data.billable_vacant)}
              </Typography>
              <Typography className={classes.rightAlign}>
                {numberWithSpaceInt(data.billable_total)}
              </Typography>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}

export default VacationCostsDetail
