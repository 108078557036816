import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import clsx from 'clsx'

import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import SaveIcon from '@material-ui/icons/Save'

import { onCreateSMTP, onUpdateSMTP, onDeleteSMTP, onSendSMTPTest } from '@api/smtp'

import { useTranslation } from '@contexts/translation'

import { Select } from '@components/form/';
import { ModalDelete } from "@components/common"

const useStyles = makeStyles((theme) => ({
  form: {
    margin: 20,
    marginTop: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: "space-between"
  },
  flexEnd: {
    justifyContent: 'flex-end'
  },
  pictureContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    "& img": {
      width: '150px'
    }
  },
  sendTest: {
    margin: theme.spacing(3, 0, 2),
    marginRight: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function SMTPForm({ handleClose, isEdit, id, formData, setCurrSMTPEdited, setSelectValue = null }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const formRef = useRef(null);

  const { register, handleSubmit, setError, errors, control, setValue, reset } = useForm(
    { mode: 'onBlur' },
  )

  const setFormValue = () => {
    setValue('name', formData?.name)
    setValue('from_email', formData?.from_email)
    setValue('from_name', formData?.from_name)
    setValue('protocol', formData?.protocol)
    setValue('username', formData?.username)
    setValue('password', formData?.password)
    setValue('host', formData?.host)
    setValue('port', formData?.port)
  }

  useEffect(() => {
    if (isEdit && formData) {
      setFormValue()
    }
  }, [formData, isEdit])

  useEffect(() => {
    if (!id) reset()
  }, [id])

  const [isDelete, setIsDelete] = useState(false)
  const handleDeleteOpen = () => {
    setIsDelete(true)
  }
  const handleDeleteClose = () => {
    setIsDelete(false)
  }

  const onDelete = async () => {
    const response = await onDeleteSMTP(id)

    if (response) {
      if (response.status === 204) {
        mutate("/emails/smtp/")
        enqueueSnackbar(dictionary["smtp_deleted"], {
          variant: 'success'
        })
        handleDeleteClose()
        setCurrSMTPEdited(null)
      }
    }
  }

  const onSendTest = async () => {
    setLoading(true)
    const response = await onSendSMTPTest(id)
    if (response.status === 200) {
      enqueueSnackbar(dictionary["test_sent"], {
        variant: 'success',
      })
      setLoading(false)
    }
  }


  const onSubmit = async (data) => {
    setLoading(true)

    const response = await onCreateSMTP(data)
    if (response) {
      if (response.status === 201) {
        enqueueSnackbar(dictionary["smtp_created"], {
          variant: 'success',
        })
        mutate('/emails/smtp/')
        if (handleClose) handleClose()
        if (setSelectValue) setSelectValue(response?.data?.id)
        setLoading(false)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  const onSubmitEdit = async (data) => {
    setLoading(true)

    const response = await onUpdateSMTP(id, data)
    if (response) {
      if (response.status === 200) {
        setLoading(false)
        enqueueSnackbar(dictionary["smtp_edited"], {
          variant: 'success',
        })
        mutate('/emails/smtp/')
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <>
      <form ref={formRef}
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(isEdit ? onSubmitEdit : onSubmit)}
      >
        <Grid container>
          {/* Name */}
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              id="name"
              required
              label={dictionary["name"]}
              name="name"
              inputRef={register}
              fullWidth
              error={errors.name}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={errors.name && errors.name.message}
            />
          </Grid>

          <Grid container spacing={3}>
            {/* From email */}
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                id="from_email"
                label={dictionary["from_email"]}
                name="from_email"
                inputRef={register}
                fullWidth
                error={errors.from_email}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={errors.from_email && errors.from_email.message}
                inputRef={register({
                  required: dictionary['errors_from_email_required'],
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: dictionary['errors_from_email_pattern'],
                  },
                })}
              />
            </Grid>

            {/* From name */}
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                id="from_name"
                label={dictionary["from_name"]}
                name="from_name"
                inputRef={register}
                fullWidth
                error={errors.from_name}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={errors.from_name && errors.from_name.message}
              />
            </Grid>
          </Grid>

          {/* Protocol */}
          <Grid item xs={12}>
            <Select
              fullWidth
              labelId="protocol"
              id="protocol"
              name="protocol"
              label={dictionary["protocol"]}
              variant="outlined"
              margin="normal"
              control={control}
            >
              <MenuItem value="TLS">{dictionary["TLS"]}</MenuItem>
              <MenuItem value="SSL">{dictionary["SSL"]}</MenuItem>
            </Select>
          </Grid>

          <Grid container spacing={3}>
            {/* Username */}
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                id="username"
                label={dictionary["username"]}
                name="username"
                inputRef={register}
                fullWidth
                error={errors.username}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={errors.username && errors.username.message}
              />
            </Grid>

            {/* Password */}
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label={dictionary['password']}
                type="password"
                id="password"
                inputRef={register({
                  required: dictionary['errors_password_required'],
                  minLength: {
                    value: 8,
                    message: dictionary['errors_password_minLength'],
                  },
                })}
                error={errors.password}
                helperText={errors.password && errors.password.message}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            {/* Host */}
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                id="host"
                label={dictionary["host"]}
                name="host"
                inputRef={register}
                fullWidth
                error={errors.host}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={errors.host && errors.host.message}
              />
            </Grid>

            {/* Port */}
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                id="port"
                label={dictionary["port"]}
                name="port"
                inputRef={register}
                fullWidth
                error={errors.port}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={errors.port && errors.port.message}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs="12" className={isEdit ? classes.buttonContainer : clsx(classes.buttonContainer, classes.flexEnd)}>
          {isEdit && (
            <Button
              onClick={handleDeleteOpen}
              type="button"
              variant="contained"
              color="error"
              className={classes.submit}
            >
              {dictionary["delete"]}
            </Button>
          )}
          <div>
            {isEdit && (
              <Button
                type="button"
                variant="contained"
                color="secondary"
                disabled={loading}
                onClick={() => onSendTest(id)}
                className={classes.sendTest}
              >
                {dictionary["send_test"]}
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
              startIcon={<SaveIcon />}
              className={classes.submit}
            >
              {dictionary["submit"]}
            </Button>
          </div>
        </Grid>
      </form>
      {
        isDelete && (
          <ModalDelete onClose={handleDeleteClose} onDelete={onDelete} />
        )
      }
    </>
  )
}
