import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import { Copyright, LayoutNoSidebar } from '@components/common'

import { useTranslation } from '@contexts/translation'
import WelcomeCard from './WelcomeCard'


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    textAlign: 'center',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  metrics: {
    marginTop: theme.spacing(2),
  }
}))

export default function PreviewDashboardView() {
  const { dictionary } = useTranslation()
  const classes = useStyles()

  return (
    <LayoutNoSidebar isPortal title={dictionary['organizations']}>
      <Container maxWidth={false} className={classes.container}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} md={8}>
            <WelcomeCard />
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </LayoutNoSidebar>
  )
}
