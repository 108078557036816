import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "@contexts/translation";
import {Button, CircularProgress, Container, MenuItem, TextField} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import {onGetOperationList} from "@api/operations";
import {useRouter} from "next/router";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2.5),
    paddingTop: theme.spacing(4),
    overflowY: 'auto',
    height: 'calc(100% - 80px)',
    marginTop: theme.spacing(-4)
  },
  margin: {
    marginBottom: theme.spacing(3)
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4)
  }
}));

export default function OperationFilterModal({filterValues, onFiltersApply}) {
  const { dictionary } = useTranslation();
  const router = useRouter();
  const { id: operationId } = router.query;
  const [isLoading, setIsLoading] = useState(false);
  const [operations, setOperations] = useState([]);
  const [selectedOperationId, setSelectedOperationId] = useState(operationId || filterValues.operation || 0);
  const classes = useStyles();

  useEffect(() => {
    setIsLoading(true);
    onGetOperationList()
      .then(response => {
        setOperations(response?.data?.results || []);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const handleChangeOperationFilter = event => {
    setSelectedOperationId(event.target.value);
  }

  const handleClearButtonClick = () => {
    setSelectedOperationId(operationId || 0);
  }

  const handleApplyClick = () => {
    const filter = {};
    if (selectedOperationId) {
      filter.operation = selectedOperationId;
    }
    onFiltersApply(filter);
  }

  return (
    <Container className={classes.root}>
      <TextField
        select
        fullWidth
        label={dictionary['operation']}
        variant='outlined'
        value={selectedOperationId}
        disabled={isLoading}
        onChange={handleChangeOperationFilter}
        className={classes.margin}
      >
        <MenuItem value={0}>{dictionary['all_operations']}</MenuItem>
        {operations.map(operation =>
          <MenuItem value={operation.id} key={`operation-${operation.id}`}>
            {operation.name}
          </MenuItem>
        )}
      </TextField>
      <div className={classes.actionButtons}>
        {isLoading ?
          <CircularProgress /> :
          <Button
            type='button'
            variant='contained'
            color='primary'
            startIcon={<SaveIcon />}
            onClick={handleApplyClick}
          >
            {dictionary['apply']}
          </Button>
        }
        {((!!selectedOperationId && !operationId)) &&
        <Button
          variant='contained'
          color='primary'
          type='button'
          startIcon={<ClearIcon />}
          onClick={handleClearButtonClick}
        >
          {dictionary['clear']}
        </Button>
        }
      </div>
    </Container>
  );
}
