import React, {useEffect, useState} from 'react'

import {makeStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import {Copyright, Layout} from '@components/common'
import {useTranslation} from '@contexts/translation'
// import ActionsTimeline from './ActionsTimeline'
import WelcomeCard from './WelcomeCard'
import {
  requestRevenueMetrics,
  requestVacantMetrics,
  requestReceivableMetrics,
  requestPayableMetrics,
} from '@api/metrics'
import MetricCardWithLine from './metrics/MetricCardWithLine'
import {transformMetricValues, transformMetricData} from './metrics/utils'
import TableCard from './TableCard'
import TableDialog from './TableDialog'

import TasksWrapper from './TasksWrapper'

const today = new Date()

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    textAlign: 'center',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  metrics: {
    marginTop: theme.spacing(2),
  },
}))

export default function DashboardView() {
  const {dictionary} = useTranslation()
  const [metrics, setMetrics] = useState({})
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const getMetrics = async () => {
    setIsLoading(true)
    try {
      const revenue = await requestRevenueMetrics()
      const vacant = await requestVacantMetrics()
      const payable = await requestPayableMetrics()
      const receivable = await requestReceivableMetrics()
      setMetrics({...metrics, revenue, payable, vacant, receivable})
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }
  useEffect(() => {
    getMetrics()
  }, [])

  return (
    <Layout title={dictionary['dashboard']}>
      <Container maxWidth={false} className={classes.container}>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} md={8}>
            <WelcomeCard />
            <Grid container xs={12} className={classes.metrics}>
              <Grid item xs={12} md={3}>
                <MetricCardWithLine
                  hasMargins
                  isLoading={isLoading}
                  title={dictionary['revenue']}
                  values={transformMetricValues(metrics, 'revenue')}
                  data={transformMetricData(metrics, 'revenue')}
                  total={metrics?.revenue?.latest}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCardWithLine
                  hasMargins
                  isLoading={isLoading}
                  title={dictionary['vacant_rent']}
                  total={metrics?.vacant?.latest}
                  values={transformMetricValues(metrics, 'vacant')}
                  data={transformMetricData(metrics, 'vacant')}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCardWithLine
                  hasMargins
                  isLoading={isLoading}
                  title={dictionary['payable']}
                  values={transformMetricValues(metrics, 'payable')}
                  data={transformMetricData(metrics, 'payable')}
                  total={metrics?.payable?.latest}
                  icon={
                    <TableDialog
                      content={
                        <TableCard
                          withLink
                          title={dictionary['payable']}
                          nameKey="display"
                          valueKey="balance"
                          path="/suppliers"
                        />
                      }
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCardWithLine
                  hasMargins
                  isLoading={isLoading}
                  title={dictionary['receivable']}
                  total={metrics?.receivable?.total}
                  values={transformMetricValues(metrics, 'receivable')}
                  data={transformMetricData(metrics, 'receivable')}
                  icon={
                    <TableDialog
                      content={
                        <TableCard
                          withLink
                          title={dictionary['receivable']}
                          nameKey="display"
                          valueKey="balance"
                          path="/tenants"
                        />
                      }
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <TasksWrapper />
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </Layout>
  )
}
