import { useState } from 'react';
import PropTypes from 'prop-types';
import { capitalCase } from 'capital-case';
import { Box, Card, Container, makeStyles, Tab, Tabs } from '@material-ui/core';
import { InvestorsForm } from '@components/Investors/InvestorsForm';
import { Layout, HeaderPage } from '..';
import ProfileCover from './ProfileCover';
import Copyright from '../Copyright';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBlock: theme.spacing(4),
  },
  tabBar: {
    zIndex: 9,
    bottom: 0,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(3),
    },
  },
}));

const Form = ({ id, handleEditToggle, isEditOpen }) => (
  <InvestorsForm handleClose={handleEditToggle} isEdit={isEditOpen} id={id} />
);

const ProfilePage = (props) => {
  const {
    operationId,
    isSidebar,
    heading = '',
    breadcrumbs = [],
    profile = {},
    tabs = [],
    form,
    type,
  } = props;

  const classes = useStyles();

  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  const handleChangeTab = (event, newValue) => setCurrentTab(newValue);

  return (
    <Layout isSidebar={isSidebar} operationId={operationId} title={heading}>
      <Container maxWidth={false} className={classes.container}>
        <HeaderPage links={breadcrumbs} />
        <Card
          sx={{
            mb: 3,
            height: 200,
            position: 'relative',
          }}
        >
          <ProfileCover profile={profile} form={form} type={type} />

          <div className={classes.tabBar}>
            <Tabs
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={handleChangeTab}
            >
              {tabs.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  value={tab.value}
                  icon={tab.icon}
                  label={capitalCase(tab.value)}
                  disabled={tab.disabled} // TODO remove in future
                />
              ))}
            </Tabs>
          </div>
        </Card>

        {tabs.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}

        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </Layout>
  );
};

ProfilePage.propTypes = {
  form: PropTypes.func,
};

export default ProfilePage;
