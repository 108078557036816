import client from '@utils/client'


export const onCreateOperation = (payload) => {
  return client
    .post('/operations/', payload)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onDeleteOperation = (id) => {
  return client
    .delete(`/operations/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onUpdateOperation = (id, payload) => {
  return client
    .patch(`/operations/${id}/`, payload)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetOperationList = () => {
  return client
    .get('/operations/?is_archived=false')
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetOperation = (id) => {
  return client
    .get(`/operations/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetOperationLeaseArticles = (id) => {
  return client
    .get(`/operations/${id}/lease_articles/?page_size=9999`)
    .then((response) => response?.data?.results || [])
    .catch((error) => {
      return error.response
    })
}

export const getAllOperations = () => {
  return client
    .get(`/operations/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const getAllUsers = (id) => {
  return client
    .get(`/operations/users/?operation=${id}`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const deleteUser = (id) => {
  return client
    .delete(`/operations/users/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const addUser = (data) => {
  return client
    .post(`/operations/users/`, data)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const editUser = (id, data) => {
  return client
    .patch(`/operations/users/${id}/`, data)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const getOperationFolders = (id) => {
  return client
    .get(`/files/folder/list/?operation=${id}`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const getAllFolders = (id) => {
  return client
    .get(`/files/folder/list/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const addFolder = (id, name, parent) => {
  return client
    .post(`/files/folder/list/`, {
      name,
      parent,
      operation: id
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
export const editFolder = (id, name, parent) => {
  return client
    .patch(`/files/folder/${id}/`, {
      name,
      parent
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const editFile = (id, name, parent) => {
  return client
    .patch(`/files/${id}/`, {
      name,
      parent
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
export const deleteFolder = (id) => {
  return client
    .delete(`/files/folder/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const addFile = (id, name, folder, file) => {
  return client
    .post(`/files/list/`, file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      content: file,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const getOperationFiles = (id) => {
  return client
    .get(`/files/list/?operation=${id}`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const getAllFiles = (id) => {
  return client
    .get(`/files/list/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}


export const deleteFile = (id) => {
  return client
    .delete(`/files/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}


export const getActions = (path) => {
  return client
    .get(path)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}