import client from '@utils/client';

export const onCreateInvestor = async (payload) => {
  try {
    const response = await client.post('/investors/', {
      ...payload,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const onDeleteInvestor = async (id) => {
  try {
    const response = await client.delete(`/investors/${id}/`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const onUpdateInvestor = async (id, payload) => {
  try {
    const response = await client.patch(`/investors/${id}/`, {
      ...payload,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const onCreateInvestorsShare = async (payload) => {
  try {
    const response = await client.post('/investors/share/', {
      ...payload,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const onUpdateInvestorsShare = async (id, payload) => {
  try {
    const response = await client.patch(`/investors/share/${id}/`, {
      ...payload,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const onDeleteInvestorShare = async (id) => {
  try {
    const response = await client.delete(`/investors/share/${id}/`);
    return response;
  } catch (error) {
    return error.response;
  }
};
