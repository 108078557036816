import React, { useState } from 'react'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack';
import Page404View from 'pages/404';

import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Typography, Box } from '@material-ui/core'
import useBreakpoints from '@hooks/useBreakpoints'

import { ActionTable, ModalDelete, ModalForm, Table } from '@components/common'
import { InvoiceItemsForm, FooterRowItems, ItemCard } from '.'

import { onDeleteInvoiceItem, onGenerateInvoiceItems } from '@api/invoices'
import { useFetchData } from '@api/fetchData'

import { useTranslation } from '@contexts/translation'
import { numberWithSpaces } from '@utils/numberWithSpaces';

const useStyles = makeStyles((theme) => ({
  containerTable: {
    width: '100%',
    paddingBlock: theme.spacing(3)
  },
  numberContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px',
    alignItems: 'flex-end',
    whiteSpace: 'pre'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px'
  },
  footerContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end'
  },
  tableNav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  gridContainer: {
    border: 'none',
  },
  submit: {
    marginLeft: theme.spacing(2),
    '@media (max-width: 600px)': {
      minWidth: 'initial',
      padding: theme.spacing(1),
      '& span': {
        marginRight: '0',
        marginLeft: '0',
      },
    },
  },
  titleCol: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 'initial',
    overflow: 'hidden',
    '& p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }
  },
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerDelete: {
    height: 'fit-content',
    padding: theme.spacing(4),
  },
  buttonDelete: {
    marginRight: theme.spacing(8),
  },
}))

export default function InvoiceItemsTable({ invoiceId, subtotal, taxes, isSent, invoiceFetchUrl, invoiceTaxRate }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false)
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [idRow, setIdRow] = useState(null)
  const [currentAction, setCurrentAction] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElAdd, setAnchorElAdd] = useState(null)
  const [anchorElManage, setAnchorElManage] = useState(null)
  const [isOpenItemOrder, setIsOpenItemOrder] = useState(false)


  const isMobile = useBreakpoints('down', 'sm')



  const fetchUrl = `/invoices/${invoiceId}/items/`
  const { data, isLoading, isError } = useFetchData(fetchUrl)


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setAnchorElAdd(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickAdd = (event) => {
    setAnchorElAdd(event.currentTarget)
  }

  const handleCloseAdd = () => {
    setAnchorElAdd(null)
  }

  const handleOpenGenerateAction = (action) => {
    setCurrentAction(action)
    handleClose()
  }

  const handleCloseGenerateAction = () => {
    setCurrentAction(null)
    handleClose()
  }

  const handleItemOrderOpen = () => {
    setIsOpenItemOrder(true)
  }

  const handleItemOrderClose = () => {
    setIsOpenItemOrder(false)
  }

  const onGenerateItems = async () => {
    const response = await onGenerateInvoiceItems(invoiceId, currentAction)
    setLoading(true)
    if (response) {
      if (response.status === 200) {
        mutate(fetchUrl)
        mutate(invoiceFetchUrl)
        setLoading(false)
        enqueueSnackbar(dictionary["invoice_item_generated"], {
          variant: 'success'
        })
        handleClose()
        handleCloseGenerateAction()
      }
    }
  }

  const ADD_ACTIONS = [
    {
      id: 'generate',
      name: dictionary['generate_items'],
      onClick: (event) => handleClick(event)
    },
    {
      id: 'add',
      name: dictionary['add_invoice_item'],
      onClick: () => handleFormOpen()
    }
  ]

  const GENERATE_ACTIONS = [
    {
      id: 'generate_rent_charges',
      name: dictionary['generate_rent_charges'],
      onClick: () => handleOpenGenerateAction("generate_trimester_items")
    },
    {
      id: 'generate_tax_items',
      name: dictionary['generate_tax'],
      onClick: () => handleOpenGenerateAction("generate_tax_items")
    },
    {
      id: 'generate_expense',
      name: dictionary['generate_expense'],
      onClick: () => handleOpenGenerateAction("generate_reconciliation")
    },
  ]


  if (isError) return <Page404View />

  const handleFormOpen = () => {
    setIsOpenForm(true)
    setAnchorElAdd(null)
  }
  const handleFormClose = () => {
    setIsOpenForm(false)
  }

  const handleEditOpen = () => {
    setIsEdit(true)
  }
  const handleEditClose = () => {
    setIsEdit(false)
  }

  const handleDeleteOpen = () => {
    setIsDelete(true)
  }

  const handleDeleteClose = () => {
    setIsDelete(false)
  }

  const handleOpenMenu = (event) => {
    setAnchorElManage(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElManage(null);
  };

  const onDelete = async () => {
    const response = await onDeleteInvoiceItem(invoiceId, idRow)

    if (response) {
      if (response.status === 204) {
        mutate(fetchUrl)
        mutate(invoiceFetchUrl)
        enqueueSnackbar(dictionary["invoice_item_deleted"], {
          variant: 'success'
        })
        handleDeleteClose()
      }
    }
  }

  const actions = (id) => [{
    name: dictionary["edit"],
    onClick: () => {
      handleEditOpen()
      setIdRow(id)
    },
  },
  {
    name: dictionary["delete"],
    onClick: () => {
      handleDeleteOpen()
      setIdRow(id)
    },
  }]

  const columns = [
    {
      field: 'title',
      headerName: dictionary["title"],
      minWidth: 180,
      flex: 1,
      renderCell: (params) => (
        <div className={classes.titleCol}>
          <p>{params.row.title}</p>
          <p>{params.row.description}</p>

        </div>
      ),
    },
    {
      field: 'subtotal',
      headerName: dictionary["subtotal"],
      type: "number",
      minWidth: 160,
      valueFormatter: (params) => numberWithSpaces(params.value)
    },
    {
      field: 'tax_rate',
      headerName: dictionary["tax"],
      type: "number",
      minWidth: 160,
      renderCell: (params) => (
        <>{params.row.tax_rate > 0
          ? (
            <>
              ({params.row.tax_rate}%) {numberWithSpaces(params.row.amount - params.row.subtotal)}
            </>
          )
          : numberWithSpaces(0)
        }</>
      )
    },
    {
      field: 'amount',
      headerName: dictionary["amount"], type: "number",
      minWidth: 160,
      valueFormatter: (params) => numberWithSpaces(params.value)
    },
    {
      field: 'action',
      headerName: dictionary["actions"],
      width: 24,
      cellClassName: 'actions_cell',
      sortable: false,
      renderCell: (params) => (
        <>
          {isSent ? <></> : <ActionTable actions={actions(params.row.id)} />}
        </>
      ),
    },
  ]

  return (
    <div className={classes.containerTable}>
      <div className={classes.tableNav}>
        <div></div>
        {!isSent &&
          <div>
            <Button
              onClick={handleOpenMenu}
              className={classes.orderMenu}
            >
              {dictionary['actions']}
            </Button>
            <Menu
              anchorEl={anchorElManage}
              open={Boolean(anchorElManage)}
              onClose={handleCloseMenu}
              elevation={0}
              getContentAnchorEl={null}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuItem onClick={() => {
                handleItemOrderOpen()
              }}>
                {dictionary['manage_item_order']}
              </MenuItem>
            </Menu>
            <Menu
              id="generate-actions"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {GENERATE_ACTIONS.map((action) => (
                <MenuItem
                  key={action.id}
                  value={action.id}
                  onClick={action?.onClick}
                >
                  {action.name}
                </MenuItem>
              ))}
            </Menu>
            <Menu
              id="invoice-items-actions"
              anchorEl={anchorElAdd}
              keepMounted
              open={Boolean(anchorElAdd)}
              onClose={handleCloseAdd}
            >
              {ADD_ACTIONS.map((action) => (
                <MenuItem
                  key={action.id}
                  value={action.id}
                  onClick={action?.onClick}
                >
                  {action.name}
                </MenuItem>
              ))}
            </Menu>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              className={classes.submit}
              onClick={handleClickAdd}
            >
              {!isMobile && dictionary["add_invoice_item"]}
            </Button>
          </div>
        }
      </div>
      <Table
        className={classes.gridContainer}
        rows={data && data.results ? data.results : []}
        loading={isLoading}
        columns={columns}
        components={{
          Footer: () => <FooterRowItems taxRate={invoiceTaxRate} subtotal={subtotal} taxes={taxes} />
        }}
      />
      <div className={classes.footerContainer}>
        <div className={classes.titleContainer}>
          <Typography variant="body1">{dictionary['subtotal']}</Typography>
          <Box sx={{ mt: 1 }} />
          <Typography variant="body1">{dictionary['tax']}</Typography>
          <Box sx={{ mt: 1 }} />
          <Typography variant="body1">{dictionary['total']}
          </Typography>
        </div>

        <div className={classes.numberContainer}>
          <Typography variant="body1">{numberWithSpaces(subtotal)} €</Typography>
          <Box sx={{ mt: 1 }} />
          <Typography variant="body1">{numberWithSpaces(taxes)} €</Typography>
          <Box sx={{ mt: 1 }} />
          <Typography variant="body1">{numberWithSpaces(Math.round((subtotal + taxes) * 100) / 100)} €</Typography>
        </div>

      </div>

      {
        (isOpenForm || isEdit) && (
          <ModalForm className={classes.modalContainer}
            isEdit={isEdit}
            type={dictionary["invoice_item"]}
            onClose={isEdit ? handleEditClose : handleFormClose}
          >
            <InvoiceItemsForm
              handleClose={isEdit ? handleEditClose : handleFormClose}
              isEdit={isEdit}
              id={idRow}
              invoiceFetchUrl={invoiceFetchUrl}
              fetchUrl={fetchUrl}
              invoiceId={invoiceId}
              invoiceTaxRate={invoiceTaxRate}
            />
          </ModalForm>
        )
      }
      {
        isDelete && (
          <ModalDelete onClose={handleDeleteClose} onDelete={onDelete} />
        )
      }

      {isOpenItemOrder &&
        <ItemCard
          invoiceId={invoiceId}
          fetchUrl={fetchUrl}
          invoiceUrl={invoiceFetchUrl}
          handleClose={handleItemOrderClose}
        />
      }

      {currentAction &&
        <ModalDelete onClose={handleCloseGenerateAction} description={dictionary["confirm_generate"]} isLoading={loading} isYellow onDelete={onGenerateItems} />
      }

    </div>
  )
}
