import { Controller } from 'react-hook-form'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { FormHelperText } from '@material-ui/core'

import { useTranslation } from '@contexts/translation'

const ReactHookFormSelect = ({
  name,
  label,
  control,
  required,
  error,
  errorMessage,
  defaultValue,
  children,
  isLoading,
  fullWidth,
  mediumWidth,
  arrayValue,
  rules,
  customOnChange = null,
  multiple = false,
  ...props
}) => {
  const { dictionary } = useTranslation()
  const labelId = `${name}-label`

  return (
    <FormControl
      {...props}
      margin="normal"
      size="medium"
      error={error}
      style={{ width: fullWidth ? '100%' : mediumWidth ? '232px' : '195px' }}
      helperText={errorMessage}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        render={({ onChange, onBlur, value, ref }) => (
          <Select
            onChange={(event) => {
              const value = event.target.value
              if (multiple && value.includes('all')) {
                onChange(arrayValue)
              }
              else {
                onChange(value)
                customOnChange && customOnChange(value)
              }
              // TODO : Add custom OnChangePossible
            }}
            labelId={labelId}
            label={label}
            value={isLoading && multiple ? [""] : isLoading ? "" : value}
            multiple={multiple}
            MenuProps={{
              getContentAnchorEl: () => null
            }}
          >
            {isLoading ? (<MenuItem disabled value="">{dictionary["loading"]}</MenuItem>
            ) : !children?.length ? (
              <MenuItem disabled value="">{dictionary["no_data"]}</MenuItem>
            ) : (
              children
            )}
          </Select>
        )}
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
      />
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}
export default ReactHookFormSelect
