import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from '@contexts/translation'
import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid,
  Tooltip
} from '@material-ui/core'
import BudgetRadialChart from '@components/dashboard/charts/BudgetRadialChart'
import { numberWithSpaceInt } from '@utils/numberWithSpaces'
import { useFetchData } from '@api/fetchData'
import Scrollbars from '@components/common/Scrollbars'
import Link from 'next/link'
import InboxIcon from '@material-ui/icons/Inbox';
import dynamic from 'next/dynamic'
const ReactApexChart = dynamic(() => import('react-apexcharts'), { ssr: false })

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.warning.main,
    height: '100%',
  },
  header: {
    '& span': {
      fontWeight: 'bold',
      color: '#665636',
      cursor: 'pointer',
      fontSize: '1.5rem',
      marginLeft: theme.spacing(3),
    }
  },
  content: {
    paddingTop: 0,
    maxHeight: '320px',
    overflow: 'hidden',
  },
  list: {
    height: '100%',
    maxHeight: '340px',
  },
  noBudget: {
    height: '100%',
    maxHeight: '340px',
    padding: `${theme.spacing(1)} ${theme.spacing(0)}`,
    display: 'flex',
    justifyContent: 'center',
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns:
      'minmax(100px, 100%) minmax(75px, auto)',
    justifyContent: 'space-between',
    padding: 0,
  },
  listItemIcon: {
    '& svg': {
      fill: theme.palette.common.white,
    },
  },
  listItemInfo: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  listItemTitleContianer: {
    '& h6': {
      fontSize: '12px',
      fontWeight: 'bold',
    },
    '& p': {
      fontSize: '12px',
    },
  },
  listItemChart: {},
  listItemDescriptionContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '80px',
    borderRadius: '16px',
    background: '#F9D27B',
    padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
    marginBottom: theme.spacing(2),
  },
  listItemDescriptionIcon: {
    position: 'absolute',
    top: '-.5em',
    left: '-.5em',
  },
  listItemDescription: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& span': {
      fontSize: '12px',
    },
  },
  listItemDescriptionName: {
    textAlign: 'left',
    width: '60%'
  },
  listItemDescriptionPrice: {
    textAlign: 'right',
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1),
  },
  icon: {
    fontSize: '4rem',
    color: 'black',
  },
  noBudgetContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 80
  },
  linkBudget: {
    color: 'black',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}))

export default function BudgetDashboard({ operationId }) {
  const classes = useStyles()
  const theme = useTheme()

  const fetchUrl = `/categories/group/?operation=${operationId}&page_size=999`
  const { data, isLoading, isError } = useFetchData(fetchUrl, null, { revalidateOnFocus: false, revalidateOnReconnect: false })

  const { dictionary } = useTranslation()


  const options = {
    fill: {
      colors: [theme.palette.secondary.main],
    },

    title: {
      text: dictionary['total_budget'],
      align: 'center',
      floating: true,
      offsetY: 20,
      style: {
        fontSize: 'bold',
        color: theme.palette.primary.main,
        fontSize: '1.3rem',
        fontFamily: 'Spartan,sans-serif',
      },
    },

    plotOptions: {
      radialBar: {
        offsetY: 30,
        hollow: {
          size: '70%',
        },

        dataLabels: {
          showOn: 'always',
          value: {
            color: theme.palette.primary.main,
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Spartan,sans-serif',
            show: true,
            offsetY: -12,
          },
        },
      },
    },

    stroke: {
      lineCap: 'round',
    },
    labels: [''],
  }

  const averageBudget = (data) => {
    let budgetValue = data.filter((budget) => budget.is_recuperable === true).reduce((reducer, data) => reducer + data.expenses_budgeted_before_tax, 0)
    let total = data.filter((budget) => budget.is_recuperable === true).reduce((reducer, data) => reducer + data.expenses_actual_before_tax, 0)
    let average = total * 100 / budgetValue
    if (!average) {
      return 0
    }
    return Math.round(average * 100) / 100
  }

  const totalExpenses = (data) => {
    return data.filter((budget) => budget.is_recuperable === true).reduce((reducer, data) => reducer + data.expenses_actual_before_tax, 0)
  }


  const totalBudget = (data) => {
    return data.filter((budget) => budget.is_recuperable === true).reduce((reducer, data) => reducer + data.expenses_budgeted_before_tax, 0)
  }
  return (
    <Card className={classes.card}>
      <Link href={`/operations/${operationId}/budgets`} passHref>
        <CardHeader title={dictionary['my_budgets']} className={classes.header} />
      </Link>
      {data && data.count > 0 ?
        <CardContent dir="ltr" className={classes.content}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Scrollbars>
                <List className={classes.list}>
                  {data && data.results.length > 0 && data.results?.map((item) => {
                    if (item.is_recuperable) {
                      return (
                        <ListItem key={item?.name} className={classes.listItem}>
                          <div className={classes.listItemInfo}>
                            <ListItemIcon className={classes.listItemIcon}>
                              {item?.icon}
                            </ListItemIcon>
                            <ListItemText className={classes.listItemTitleContianer}>
                              <Typography variant="h6">{item?.name}</Typography>
                              <Typography>
                                {`${numberWithSpaceInt(item?.expenses_actual_before_tax)}€`}
                              </Typography>
                            </ListItemText>
                          </div>
                          <div className={classes.listItemChart}>
                            <BudgetRadialChart expenses={item?.expenses_actual_before_tax} budget={item?.expenses_budgeted_before_tax} total={item?.budget_consumption} />
                          </div>
                        </ListItem>
                      )
                    }
                  })}
                </List>
              </Scrollbars>
            </Grid>
            <Grid item xs={12} md={6}>
              <Tooltip
                enterTouchDelay={200}
                title={
                  <>
                    {dictionary["actual"]} : {data && data.results ? numberWithSpaceInt(totalExpenses(data.results)) : 0}€
                    <br />
                    {dictionary["budgeted"]} : {data && data.results ? numberWithSpaceInt(totalBudget(data.results)) : 0}€
                  </>
                }
                placement="top"
              >
                <span>
                  <ReactApexChart
                    type="radialBar"
                    series={data && data.results ? [averageBudget(data.results)] : [0]}
                    options={options}
                    height={250}
                    className={classes.chart}
                  />
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
        :
        <CardContent className={classes.noBudgetContainer}>
          <InboxIcon className={classes.icon} />
          <Link href={`/operations/${operationId}/budgets`} passHref>
            <Typography className={classes.linkBudget}>{dictionary['no_budget']}</Typography>

          </Link>
        </CardContent>
      }
    </Card>
  )
}
