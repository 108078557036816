import { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { onGetOperationList } from '@api/operations';
import { useTranslation } from '@contexts/translation';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  select: {
    marginRight: theme.spacing(2),
    minWidth: 160,
  },
}));

export default function SelectType({
  onTypeConfirm,
  selectedType,
  selectedOperation,
  operationId = false,
}) {

  const { dictionary } = useTranslation();
  const classes = useStyles();
  const importTypeList = [
    ...operationId ? [] : [{ key: 'operations', label: dictionary['operations'] }],
    ...operationId ? [] : [{ key: 'contacts', label: dictionary['contacts'] }],
    
    { key: 'lots', label: dictionary['lots'] },
    { key: 'landlords', label: dictionary.landlords.landlords },

    { key: 'tenants', label: dictionary['tenants'] },
    { key: 'leases', label: dictionary['leases'] },
    { key: 'discounts', label: dictionary['discounts'] },
    { key: 'lease_articles', label: dictionary['lease_articles'] },
    { key: 'lease_periods', label: dictionary['lease_periods'] },

    { key: 'categories', label: dictionary['categories'] },
    { key: 'budgets', label: dictionary['budgets'] },
    { key: 'suppliers', label: dictionary['suppliers'] },
    { key: 'expenses', label: dictionary['expenses'] },
    { key: 'invoices', label: dictionary['invoices'] },
    { key: 'invoice_items', label: dictionary['invoice_items'] },  
    { key: 'payments', label: dictionary['payments'] },
  
    { key: 'mortgages', label: dictionary.mortgages.mortgages },
    { key: 'investors', label: dictionary['investors'] },

  ];
  const [selectedImportType, setSelectedImportType] = useState(
    selectedType.key || importTypeList[0].key
  );
  const [isOperationsLoading, setIsOperationsLoading] = useState(false);
  const [operationList, setOperationList] = useState([]);
  const [selectedOperationId, setSelectedOperationId] = useState(
    selectedOperation.id || ''
  );

  const noOperationTypes = ['contacts', 'operations'];

  useEffect(() => {
    setIsOperationsLoading(true);
    onGetOperationList()
      .then((response) => {
        setOperationList(response.data.results);
      })
      .finally(() => {
        setIsOperationsLoading(false);
      });
  }, []);

  const handleChangeImportType = (event) => {
    setSelectedImportType(event.target.value);
  };

  const handleChangeOperationFilter = (event) => {
    setSelectedOperationId(event.target.value);
  };

  const isOperationRequired = !noOperationTypes.includes(selectedImportType);

  const handleTypeConfirm = () => {
    onTypeConfirm(
      importTypeList.find((type) => type.key === selectedImportType),
      isOperationRequired
        ? operationList.find(
          (operation) => operation.id === parseInt(selectedOperationId)
        ) || {}
        : {}
    );
  };

  return (
    <div className={classes.root}>
      <div>
        <TextField
          select
          label={dictionary['type']}
          className={classes.select}
          variant="outlined"
          value={selectedImportType}
          onChange={handleChangeImportType}
        >
          {importTypeList.map((importType) => (
            <MenuItem
              value={importType.key}
              key={`import-type-${importType.key}`}
            >
              {importType.label}
            </MenuItem>
          ))}
        </TextField>
        {isOperationRequired && (
          <TextField
            select
            label={dictionary['operation']}
            className={classes.select}
            variant="outlined"
            SelectProps={{
              disabled: operationId ? true : false
            }}
            value={operationId ? operationId : selectedOperationId}
            onChange={handleChangeOperationFilter}
            disabled={isOperationsLoading}
          >
            {operationList.map((operation) => (
              <MenuItem value={operation.id} key={`operation-${operation.id}`}>
                {operation.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        {isOperationsLoading && isOperationRequired && <CircularProgress />}
      </div>
      <Button
        variant="contained"
        onClick={handleTypeConfirm}
        disabled={isOperationRequired && !selectedOperationId}
      >
        {dictionary['confirm']}
      </Button>
    </div>
  );
}
