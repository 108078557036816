import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { useFetchData } from '@api/fetchData'

import { Select } from '@components/form'
import { Loader } from '@components/common'

import { useTranslation } from '@contexts/translation'

export default function RoleSelect({ control, error, name, errorMessage, operationId }) {
  const { dictionary } = useTranslation()
  const { data, isLoading, isError } = useFetchData(`/${name}/?not_operation=${operationId}`)

  if (isError) return <p>{dictionary["failed_to_load_data"]}...</p>

  return (
    <Select
      id={name}
      name={name}
      control={control}
      defaultValue={''}
      variant="outlined"
      margin="normal"
      isLoading={isLoading}
      error={error}
      fullWidth
      errorMessage={errorMessage}
    >
      {data &&
        data.results.map((value) => {
          return <MenuItem value={value.id}>{value.display}</MenuItem>
        })}
    </Select>
  )
}
