import React, { useRef, useState } from 'react';
import Button from "@material-ui/core/Button";
import {
  Card,
  CardHeader,
  CardContent,
  ButtonBase,
  makeStyles,
  CircularProgress,
  Typography,
  Menu,
  MenuItem
} from "@material-ui/core";
import { useTranslation } from "@contexts/translation";
import { useRouter } from 'next/router';

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    display: 'flex'
  },
  menuButton: {
    textTransform: 'none'
  },
}));

export default function ActionsMenu({
  viewModeList,
  handleFormOpen,
  handleRoleFormOpen
}) {
  const router = useRouter();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { dictionary } = useTranslation();
  const classes = useStyles();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleOpenMenu}
        className={classes.menuButton}
        color="primary"
        variant="contained"
      >
        {dictionary['add']}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        elevation={0}
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => {
          handleFormOpen()
          handleCloseMenu();
        }}>
          {viewModeList[0]}
        </MenuItem>
        <MenuItem onClick={() => {
          handleRoleFormOpen()
          handleCloseMenu();

        }}>
          {viewModeList[1]}
        </MenuItem>
      </Menu>
    </>

  );
}
