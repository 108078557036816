import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Container, Typography, Link, Tooltip } from "@material-ui/core";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { makeStyles } from "@material-ui/core/styles";
import { onFetchExportList } from "@api/exports";
import { useTranslation } from "@contexts/translation";
import { fDateTime } from "@utils/formatTime";
import { fBytesSize } from "@utils/formatBytesSize";
import { ModalForm, Table, CellExpand } from "@components/common";
import AddExportForm from "@components/profile/Export/AddExportForm";

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  reloadButton: {
    padding: 4,
    minWidth: 'auto',
    marginRight: theme.spacing(1)
  },
  tooltipText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

export default function Export() {
  const [isExportsLoading, setIsExportsLoading] = useState(false);
  const [exportsList, setExportList] = useState([]);
  const [isExportFormOpened, setIsExportFormOpened] = useState(false);
  const classes = useStyles();
  const { dictionary } = useTranslation();

  const columns = [
    {
      field: 'type_display',
      headerName: dictionary['type'],
      minWidth: 160,
    },
    {
      field: 'operation',
      headerName: dictionary['operation'],
      valueGetter: ({ row }) => row?.operation ? row.operation.display : '-',
      renderCell: CellExpand,
      minWidth: 160,
      flex: 1,
    },
    {
      field: 'created_at',
      headerName: dictionary['date'],
      valueFormatter: (params) => fDateTime(params.value),
      minWidth: 160,
    },
    {
      field: 'items',
      headerName: dictionary['items'],
      minWidth: 160,
    },
    {
      field: 'size',
      headerName: dictionary['size'],
      valueFormatter: (params) => fBytesSize(params.value),
      minWidth: 160,
    },
    {
      field: 'file',
      headerName: dictionary['file'],
      renderCell: (params) =>
        params.row?.size ? (
          <Link href={params.value}>
            {dictionary['download']}
          </Link>
        ) : `${dictionary['generating']}...`,
      minWidth: 160,
    }
  ];

  const getExportList = () => {
    setIsExportsLoading(true);
    onFetchExportList().then(response => {
      setExportList(response?.data?.results || []);
    }).finally(() => {
      setIsExportsLoading(false);
    });
  };

  useEffect(() => {
    getExportList()
  }, []);

  const handleOpenExportForm = () => {
    setIsExportFormOpened(true)
  };

  const handleCloseExportForm = () => {
    setIsExportFormOpened(false);
  };

  const handleExportFormSubmitCallback = () => {
    getExportList();
    handleCloseExportForm();
  };

  return (
    <div>
      <Table
        title={
          <div className={classes.header}>
            <Typography variant='h5'>
              {dictionary['exports']}
            </Typography>
            {isExportsLoading ?
              <CircularProgress size={30} /> :
              <div>
                <Button
                  variant='outlined'
                  size='small'
                  color='primary'
                  className={classes.reloadButton}
                  onClick={getExportList}
                >
                  <AutorenewIcon fontSize='small' />
                </Button>
                <Button
                  variant='contained'
                  size='small'
                  color='primary'
                  onClick={handleOpenExportForm}
                >
                  {dictionary['new']}
                </Button>
              </div>
            }
          </div>
        }
        loading={isExportsLoading}
        rows={exportsList}
        columns={columns}
      />
      {isExportFormOpened && (
        <ModalForm type={dictionary['export']} onClose={handleCloseExportForm}>
          <AddExportForm onClose={handleExportFormSubmitCallback} />
        </ModalForm>
      )}
    </div>
  )
}
