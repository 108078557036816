import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { Card, List, ListItem, ListItemText } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

import { useFetchData } from '@api/fetchData'

import { TemplateForm } from '@components/templates'
import { Layout, HeaderPage } from '@components/common'
import Page404View from 'pages/404'

import { useTranslation } from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    maxHeight: '100vh',
  },
  container: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, .8)',
    textTransform: 'uppercase',
  },
  listItemTitle: {
    '&>span': {
      fontWeight: "bold"
    }
  },
  root: {
    flexGrow: 1,
  },
  formTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export default function TemplatesView() {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const { data, isLoading, isError } = useFetchData(`/emails/`)

  const [currTemplateEdited, setCurrTemplateEdited] = useState(null)

  if (isError) return <Page404View />

  const handleDisplayAdd = () => {
    setCurrTemplateEdited(null)
  }
  const handleDisplayEdit = id => {
    setCurrTemplateEdited(data?.results?.find(template => template?.id === id))
  }

  return (
    <main className={classes.content}>
      <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Paper className={classes.paper}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                className={classes.submit}
                onClick={handleDisplayAdd}
              >
                {dictionary["add_template"]}
              </Button>
            </Paper>

            {data?.count ? (
              <Paper className={classes.paper}>
                <Card>
                  <List
                    component="nav"
                    aria-labelledby=""
                    className={classes.tabNav}
                  >
                    <ListItem>
                      <ListItemText primary={dictionary["templates"]} className={classes.listItemTitle} />
                    </ListItem>

                    {data?.results?.map((item, index) => (
                      <ListItem
                        button
                        key={index}
                        onClick={() => handleDisplayEdit(item?.id)}
                      >
                        <ListItemText primary={item?.name} />
                      </ListItem>
                    ))}
                  </List>
                </Card>
              </Paper>
            ) : <></>}
          </Grid>
          <Grid item xs={9}>
            <Paper className={classes.paper}>
              <Card>
                {data && (
                  <div className={classes.root}>
                    <div className={classes.formTitleContainer}>
                      {currTemplateEdited ? (
                        <p>{dictionary["edit"]} {currTemplateEdited?.name}</p>
                      ) : (
                        <p>{dictionary["add_new_template"]}</p>
                      )}
                    </div>

                    <TemplateForm
                      isEdit={currTemplateEdited ? true : false}
                      id={currTemplateEdited ? currTemplateEdited.id : null}
                      formData={currTemplateEdited}
                      setCurrTemplateEdited={setCurrTemplateEdited}
                    />
                  </div>
                )}
              </Card>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </main>
  )
}
