import clsx from 'clsx';
import React from 'react';
import RouterLink from 'next/link';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Box, Card, CardContent } from '@material-ui/core';
import { useTranslation } from '@contexts/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    textAlign: 'center',
    backgroundColor: 'lightBlue',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      textAlign: 'left',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    [theme.breakpoints.up('xl')]: {
      height: 320
    }
  },
  link: {
    color: theme.palette.primary.main
  },
}));

function WelcomeCard({ displayName, className, ...other }) {
  const classes = useStyles();
  const { dictionary } = useTranslation()

  return (
    <Card className={clsx(classes.root, className)} {...other}>
      <CardContent
        sx={{
          p: { md: 0 },
          pl: { md: 5 }
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: 'grey.800',
            pb: { xs: 3, xl: 5 }
          }}
        >
          {dictionary.routes.home['welcome']}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: 'grey.800',
            pb: { xs: 3, xl: 5 }
          }}
        >
          {dictionary['get_started']}
          <RouterLink href="/operations">
            <span className={classes.link}>
              {dictionary['add_operation']}
            </span>
          </RouterLink>
        </Typography>

      </CardContent>

      <Box
        component="img"
        alt="welcome"
        src="/static/images/Summary.png"
        sx={{
          p: 2,
          height: 280,
          margin: { xs: 'auto', md: 'inherit' }
        }}
      />
    </Card>
  );
}

export default WelcomeCard;
