import clsx from 'clsx'
import React from 'react'
import PropTypes from 'prop-types'

import { MAvatar } from '@theme/@material-extend'
import { alpha, makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Typography,
  Card,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import ContactsIcon from '@material-ui/icons/Contacts'

import { useTranslation } from '@contexts/translation'

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      padding: `${(theme.spacing(2), theme.spacing(2))}`,
      paddingLeft: theme.spacing(4.5),
      marginLeft: `-${theme.spacing(2.5)}`,
      background: '#D2ECEA',
      zIndex: 1,
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),

    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  listContainer: {},
  listItem: {
    display: 'grid',
    gridTemplateColumns: 'auto minmax(120px, 1fr) minmax(120px, 1fr)',
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  listItemPhoto: {},
  listItemName: {
    fontWeight: 700,
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  listItemRole: {
    textTransform: 'capitalize',
    textAlign: 'center',
    borderRadius: '50rem',
    padding: `0 ${theme.spacing(0.5)}`,
    lineHeight: '1.2',

    '&_owner': {
      background: 'rgba(4, 49, 117, 0.31)',
    },
    '&_manager': {
      background: 'rgba(107, 198, 176, 1)',
    },
    '&_accountant': {
      background: 'rgba(245, 190, 76, 0.42)',
    },
  },
}))

// ----------------------------------------------------------------------

InfoCardSub.propTypes = {
  usefulContacts: PropTypes.array,
  className: PropTypes.string,
}

function InfoCardSub({ className, usefulContacts }) {
  const classes = useStyles()

  const { dictionary } = useTranslation()

  return (
    <Card className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography
          variant="h6"
          sx={{
            display: 'flex',
            alignItems: 'center',
            opacity: 0.8,
          }}
        >
          <ContactsIcon /> {dictionary['useful_contacts']}
        </Typography>
      </div>
      <List className={classes.listContainer}>
        {usefulContacts?.map((contact) => (
          <li key={contact?.name} className={clsx(classes.listItem)}>
            <MAvatar
              className={classes.listItemPhoto}
              sx={{
                mx: 'auto',
                width: { xs: 24, md: 24 },
                height: { xs: 24, md: 24 },
              }}
            >
              <img src={contact?.photo} />
            </MAvatar>
            <p className={classes.listItemName}>{contact?.name}</p>
            <p
              className={clsx(
                classes.listItemRole,
                `${classes.listItemRole}_${contact?.role?.toLowerCase()}`,
              )}
            >
              {contact?.role}
            </p>
          </li>
        ))}
      </List>
    </Card>
  )
}

export default InfoCardSub
