import clsx from 'clsx'
import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useTheme, makeStyles} from '@material-ui/core/styles'
import {Typography, Box, Card, CardHeader, CardContent} from '@material-ui/core'
import {useTranslation} from '@contexts/translation'
import FilterListIcon from '@material-ui/icons/FilterList'

const useStyles = makeStyles((theme, props) => ({
  container: {
    height: '473px',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  description: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: theme.palette.grey[500],
  },
  placeholder: {
    width: '60%',
    marginLeft: '20%'
  },
}))

function DocumentsPlansDetail({className, data}) {
  const classes = useStyles()
  const theme = useTheme()
  const {dictionary} = useTranslation()

  return (
    <Card className={classes.container}>
      <div className={classes.titleContainer}>
        <CardHeader title={dictionary['overview_documents_plans']} />
        <FilterListIcon
          style={{
            marginRight: theme.spacing(3),
            cursor: 'pointer',
          }}
        />
      </div>
      <Typography className={classes.description}>
        {dictionary['overview_find_document']}
      </Typography>
      <CardContent>
        <div>
          <img
            className={classes.placeholder}
            src={'/static/images/Report.png'}
            alt={dictionary['overview_documents_plans']}
          />
        </div>
      </CardContent>
    </Card>
  )
}

export default DocumentsPlansDetail
