import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { Box, Typography, Card, Grid } from '@material-ui/core'
import { MBreadcrumbs } from '@theme/index'

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    backdropFilter: 'blur(3px)',
    backgroundColor: '#2083A2',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  stickyGrid: {
    position: 'sticky',
    top: '40px',
    zIndex: '99',
  },
  stickyPadding: {
    position: 'sticky',
    zIndex: '99',
    top: '40px',
  },
  userInfo: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 2)
  },
  card: {
    marginBottom: theme.spacing(3),
    height: 130,
    position: 'relative',
  },
  stickyCard: {
    marginBottom: theme.spacing(3),
    height: 130,
    position: 'relative',
  },
  breadcrumbHeader: {
    paddingLeft: '20px'
  },
  breadcrumbTitle: {
    paddingTop: '0px',
  }
}))

// ----------------------------------------------------------------------

StickyHeader.propTypes = {
  myProfile: PropTypes.object,
  authUser: PropTypes.object,
  className: PropTypes.string,
}

function StickyHeader({ operationName, breadcrumb }) {
  const classes = useStyles()
  const [isSticky, setIsSticky] = useState(false)
  const ref = useRef()

  const title = breadcrumb[breadcrumb.length - 1]['name']

  return (
    <Grid
      item
      xs={12}
      // className={isSticky ? classes.stickyPadding : classes.stickyGrid}
      ref={ref}
    > 
      <div className={classes.breadcrumbHeader}>
        <MBreadcrumbs withBackground={true} links={breadcrumb} />
        <Typography className={classes.breadcrumbTitle} variant="h3">{title}</Typography>
      </div>
    </Grid>
  )
}

export default StickyHeader
