import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Table, ActionTable, CellExpand } from '@components/common'

import { useTranslation } from '@contexts/translation'
import InviteModal from './InviteModal'
import { getOrguser, deleteOrguser, getOrguserByType } from '@api/profile'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  gridContainer: {
    border: 'none',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  }
}))

export default function OrgusersTable({
  id,
  type
}) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false);

  const [orgusers, setOrgusers] = useState([]);

  const getUsers = async () => {
    try {
      setIsLoading(true)
      const response = await getOrguser()
      if (response && response.data && response.data.results) {
        setOrgusers(response.data.results)
      }
    } catch (error) {

    }
    setIsLoading(false)
  }

  const getUsersByType = async () => {
    try {
      setIsLoading(true)
      const response = await getOrguserByType(type, id)
      if (response && response.data && response.data.results) {
        setOrgusers(response.data.results)
      }
    } catch (error) {

    }
    setIsLoading(false)
  }
  useEffect(() => {
    type ? getUsersByType() : getUsers()
  }, []);

  const actions = (id) => [{
    name: 'Resend',
    onClick: async () => {

    },
  },
  {
    name: 'Cancel',
    onClick: async () => {
      await deleteOrguser(id)
      await type ? getUsersByType() : getUsers()
    },
  }
  ]
  const columns = [
    {
      field: 'first_name',
      headerName: dictionary["first_name"],
      minWidth: 170,
    },
    {
      field: 'last_name',
      headerName: dictionary["last_name"],
      minWidth: 220,
    },
    {
      field: 'email',
      headerName: dictionary["email"],
      minWidth: 160,
      flex: 1,
      renderCell: CellExpand,
    },
    {
      field: 'user',
      headerName: 'Status',
      minWidth: 160,
      renderCell: (params) => {
        return !params.row.user ? 'Still Pending' : '-'
      },
    },
    {
      field: 'role',
      headerName: dictionary["role"],
      minWidth: 160,
    },
    {
      field: 'action',
      headerName: dictionary["actions"],
      width: 24,
      cellClassName: 'actions_cell',
      sortable: false,
      renderCell: (params) => (
        <ActionTable actions={actions(params.row.id)} />
      ),
    }
  ]


  return (
    <>
      <Box className={classes.header}>
        <Box>{dictionary["users_list"]}</Box>
        <InviteModal getUsers={type ? getUsersByType : getUsers}
          id={id}
          isGlobal
          defaultType={type}
        />
      </Box>
      <Table
        rows={orgusers ? orgusers : [{ id: 0 }]}
        columns={columns}
        loading={isLoading}
        className={classes.gridContainer}
      />
    </>
  )
}


