import clsx from 'clsx'
import React from 'react'
import { fNumber } from '@utils/formatNumber'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from '@contexts/translation'
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core'
import dynamic from 'next/dynamic'
const ReactApexChart = dynamic(() => import('react-apexcharts'), { ssr: false })
import { numberWithSpaceInt } from '@utils/numberWithSpaces'
import { useFetchData } from '@api/fetchData'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
  },
  titleContainer: {
    position: 'relative',
    display: 'flex',
    width: 'fit-content',
  },
  notifStyle: {
    position: 'absolute',
    top: '0.8em',
    right: '-0.4em',
    width: '1.5em',
    height: '1.5em',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(183, 33, 54, .4)',
    color: theme.palette.common.white,
    padding: theme.spacing(0.5),
    borderRadius: '50rem',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  price: {
    fontSize: '1.6em',
    fontWeight: '700',
    whiteSpace: 'pre',
    '& span': {
      fontSize: '16px',
      fontWeight: 500,
    },
  },
  chartStyle: { alignSelf: 'flex-end' },
}))

export default function OverviewCardDashboard({
  label,
  title,
  notif,
  lateDescription,
  chart,
  operationId,
  toLink
}) {
  const classes = useStyles()
  const theme = useTheme()

  const fetchUrl = `/stats/indicators/${label}/operation/${operationId}/`
  const { data, isLoading, isError } = label ?
    useFetchData(fetchUrl, null, { revalidateOnFocus: false, revalidateOnReconnect: false }) :
    {
      data: null,
      isLoading: null,
      isError: null,
    }

  const chartOptions = {
    colors: [
      label === 'invoices/receivable'
        ? theme.palette.primary.light
        : label === 'expenses/payable'
          ? theme.palette.error.main
          : null,
    ],
    chart: {
      sparkline: { enabled: true },
    },
    plotOptions: {
      bar: {
        columnWidth: '68%',
        borderRadius: 4,
      },
    },
    labels: [1, 2, 3, 4, 5, 6, 7, 8],
    tooltip: {
      enabled: false,
      x: { show: false },
      y: {
        formatter: (seriesName) => '',
        title: {
          formatter: function (seriesName) {
            return ''
          },
        },
      },
      marker: { show: false },
    },
  }

  return (
    <Card className={classes.container}
      onClick={() => toLink()}
    >
      {title && (
        <div className={classes.titleContainer}>
          <CardHeader title={title} />
          {notif && <span className={classes.notifStyle}>{notif}</span>}
        </div>
      )}
      <CardContent className={classes.content}>
        <div>
          {data ? (
            <Typography
              className={classes.price}
              sx={{
                fontSize: '2em',
                fontWeight: '700',
              }}
            >
              {numberWithSpaceInt(data && data.value)} <span>€</span>
            </Typography>
          )
            :
            (
              <Typography
                className={classes.price}
                sx={{
                  fontSize: '2em',
                  fontWeight: '700',
                }}
              >
                0 <span>€</span>
              </Typography>
            )
          }
          {lateDescription && (
            <Typography
              sx={{
                fontSize: '14px',
                color: '#ED7D78',
              }}
            >
              {lateDescription}
            </Typography>
          )}
        </div>
      </CardContent>
    </Card>
  )
}
