import client from '@utils/client';

export const onFetchContracts = (params = {}) => {
  return client
    .get('/suppliers/contract/', { params })
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const onCreateContracts = (payload) => {
  return client
    .post('/suppliers/contract/', payload)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const onEditContracts = (contractId, payload) => {
  return client
    .patch(`/suppliers/contract/${contractId}`, payload)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};

export const onDeleteContracts = (contractId) => {
  return client
    .delete(`/suppliers/contract/${contractId}`)
    .then((response) => response)
    .catch((error) => {
      return error.response;
    });
};
