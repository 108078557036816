import React, { useState, useEffect, useCallback } from 'react'
import Link from 'next/link'
import { useSnackbar } from 'notistack'
import Page404View from 'pages/404'
import { mutate } from 'swr'
import { differenceInDays, format } from 'date-fns'
import Router, { useRouter } from 'next/router'
import { enUS, fr } from 'date-fns/locale'


import { makeStyles, useTheme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import FilterListIcon from "@material-ui/icons/FilterList";
import Button from '@material-ui/core/Button'
import { Badge } from '@material-ui/core'

import { debounce } from 'lodash';
import { ActionTable, ModalDelete, ModalForm, Table, Search, CellExpand } from '@components/common'
import { InvoicesForm, FooterRow, InvoicesFilterModal, InvoicePaymentsDetails } from '.'
import { Tab } from '@material-ui/core'
import { TabContext, TabList } from '@material-ui/lab'


import { useFetchData } from '@api/fetchData'

import { useTranslation } from '@contexts/translation'
import useBreakpoints from '@hooks/useBreakpoints'
import { MLabel } from '@theme/@material-extend'
import UnformatDate from '@utils/unformatDate'
import FooterRowGlobal from './FooterRowGlobal'
import { numberWithSpaces } from '@utils/numberWithSpaces'
import { onDeleteInvoice, onUpdateInvoice } from '@api/invoices'

const useStyles = makeStyles((theme) => ({
  containerTable: {
    width: '100%',
  },
  searchContainer: {
    display: 'flex',
  },
  tableNav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    flexWrap: 'wrap',
    rowGap: theme.spacing(1),
  },
  gridContainer: {
    border: 'none',
  },
  filterContainer: {
    display: 'flex',
    maxWidth: '100%',
  },
  filterButton: {
    marginRight: theme.spacing(2)
  },
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerDelete: {
    height: 'fit-content',
    padding: theme.spacing(4),
  },
  submit: {
    '@media (max-width: 600px)': {
      minWidth: 'initial',
      padding: theme.spacing(1),
      '& span': {
        marginRight: '0',
        marginLeft: '0',
      },
    },
  },
  invoiceLate: {
    color: theme.palette.error.main,
  },
  buttonDelete: {
    marginRight: theme.spacing(8),
  },
  tabList: {
    minHeight: 36,
  },
  tab: {
    minHeight: 36,
  },
}))

const locales = { enUS, fr }

export default function InvoicesTable({ operationId, isOperation, leaseId, tenantId, viewAll }) {
  const { dictionary, lang } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const isMobile = useBreakpoints('down', 'sm')

  const [isOpenForm, setIsOpenForm] = useState(false)
  const [idRow, setIdRow] = useState(null)
  const [invoiceData, setInvoiceData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [currentAction, setCurrentAction] = useState(null)
  const [pageCount, setPageCount] = useState(1)
  const [value, setValue] = useState(viewAll ? '' : '&status=not_valid')

  const [valueSearch, setValueSearch] = useState("")
  const [searchAPI, setSearchAPI] = useState("")
  const router = useRouter();

  const debouncedSave = useCallback(
    debounce((newValue) => setSearchAPI(newValue), 300),
    []
  );

  useEffect(() => {
    if (router.query && router.query.tab) {
      if (router.query.tab == 3) {
        setValue('&status=open')
      }
    } else {
      setValue('&status=open')
    }
  }, [router])

  const onFilterName = (event) => {
    setValueSearch(event.target.value)
    debouncedSave(event.target.value);
  };

  const formatDate = (date) => {
    if (date) {
      const [day, month, year] = date?.split('-')
      return format(new Date(year, month - 1, day), 'dd MMMM yyyy', {
        locale: lang === 'fr' ? locales['fr'] : locales['enUS'],
      })
    }
  }

  const searchFilter = searchAPI !== "" ? `&q=${searchAPI}` : ''

  const initialFiltersState = { operation: operationId, lease: leaseId }
  const [isFiltersModalOpened, setIsFiltersModalOpened] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(initialFiltersState);

  const handleOpenFiltersModal = () => {
    setIsFiltersModalOpened(true)
  };

  const handleCloseFiltersModal = () => {
    setIsFiltersModalOpened(false);
  };

  const handleFiltersApply = filters => {
    setSelectedFilters(filters);
    setPageCount(1)
    handleCloseFiltersModal();
  };

  const isFiltersSelected = operationId || leaseId || selectedFilters.lease || selectedFilters.operation || selectedFilters.date;


  const operationFilter = selectedFilters.operation !== undefined ? `&operation=${selectedFilters.operation}` : ''
  const leaseFilter = selectedFilters.lease !== undefined ? `&lease=${selectedFilters.lease}` : ''
  const dateStartFilter = selectedFilters.start_date !== undefined ? `&invoice_date_start=${selectedFilters.start_date}` : ''
  const dateEndFilter = selectedFilters.end_date !== undefined ? `&invoice_date_end=${selectedFilters.end_date}` : ''
  const tenantFilter = tenantId !== undefined ? `&tenant=${tenantId}` : ''

  const handleChange = (event, newValue) => {
    setValue(newValue)
    setPageCount(1)
  }

  const fetchUrl = isOperation ? `/invoices/?operation=${operationId}${value}${leaseFilter}${tenantFilter}${dateStartFilter}${dateEndFilter}${searchFilter}&page=${pageCount}` : `/invoices/?page=${pageCount}${value}${leaseFilter}${dateStartFilter}${dateEndFilter}${tenantFilter}${operationFilter}${searchFilter}`
  const { data, isLoading, isError } = useFetchData(fetchUrl)

  const fetchUrlAll = isOperation ? `/invoices/?operation=${operationId}${leaseFilter}${dateStartFilter}${dateEndFilter}${tenantFilter}${searchFilter}${searchFilter}` : `/invoices/?page=${pageCount}${leaseFilter}${dateStartFilter}${dateEndFilter}${operationFilter}${tenantFilter}${searchFilter}`
  const fetchUrlDraft = isOperation ? `/invoices/?operation=${operationId}&status=not_valid${leaseFilter}${dateStartFilter}${dateEndFilter}${tenantFilter}${searchFilter}` : `/invoices/?status=not_valid${leaseFilter}${dateStartFilter}${dateEndFilter}${operationFilter}${tenantFilter}${searchFilter}`
  const fetchUrlUnsent = isOperation ? `/invoices/?operation=${operationId}&status=not_sent${leaseFilter}${dateStartFilter}${dateEndFilter}${tenantFilter}${searchFilter}` : `/invoices/?status=not_sent${leaseFilter}${dateStartFilter}${dateEndFilter}${operationFilter}${tenantFilter}${searchFilter}`
  const fetchUrlOpen = isOperation ? `/invoices/?operation=${operationId}&status=open${leaseFilter}${dateStartFilter}${dateEndFilter}${tenantFilter}${searchFilter}` : `/invoices/?status=open${leaseFilter}${operationFilter}${dateStartFilter}${dateEndFilter}${tenantFilter}${searchFilter}`
  const fetchUrlPaid = isOperation ? `/invoices/?operation=${operationId}&status=paid${leaseFilter}${dateStartFilter}${dateEndFilter}${tenantFilter}${searchFilter}` : `/invoices/?status=paid${leaseFilter}${operationFilter}${dateStartFilter}${dateEndFilter}${tenantFilter}${searchFilter}`

  const { data: allCount, isLoading: isLoadingAll, isError: isErrorAll } = useFetchData(fetchUrlAll, null, { revalidateOnFocus: false, revalidateOnReconnect: false })
  const { data: draftCount, isLoading: isLoadingDraft, isError: isErrorDraft } = useFetchData(fetchUrlDraft, null, { revalidateOnFocus: false, revalidateOnReconnect: false })
  const { data: unsentCount, isLoading: isLoadingUnsent, isError: isErrorUnsent } = useFetchData(fetchUrlUnsent, null, { revalidateOnFocus: false, revalidateOnReconnect: false })
  const { data: openCount, isLoading: isLoadingOpen, isError: isErrorOpen } = useFetchData(fetchUrlOpen, null, { revalidateOnFocus: false, revalidateOnReconnect: false })
  const { data: paidCount, isLoading: isLoadingPaid, isError: isErrorPaid } = useFetchData(fetchUrlPaid, null, { revalidateOnFocus: false, revalidateOnReconnect: false })

  const draftLabel = `${dictionary['draft']} (${draftCount && draftCount.count ? draftCount.count : '0'})`
  const unsentLabel = `${dictionary['unsent']} (${unsentCount && unsentCount.count ? unsentCount.count : '0'})`
  const openLabel = `${dictionary['open']} (${openCount && openCount.count ? openCount.count : '0'})`
  const allLabel = `${dictionary['all']} (${allCount && allCount.count ? allCount.count : '0'})`
  const paidLabel = `${dictionary['paid']} (${paidCount && paidCount.count ? paidCount.count : '0'})`



  const INVOICE_TAB = [
    {
      value: `&status=not_valid`,
      label: draftLabel,
      disabled: false,
    },
    {
      value: '&status=not_sent',
      label: unsentLabel,
      disabled: false,
    },
    {
      value: '&status=open',
      label: openLabel,
      disabled: false,
    },
    {
      value: '&status=paid',
      label: paidLabel,
      disabled: false,
    },
    {
      value: '',
      label: allLabel,
      disabled: false,
    },
  ]


  if (isError) return <Page404View />

  const handleFormOpen = () => {
    setIsOpenForm(true)
  }
  const handleFormClose = () => {
    setIsOpenForm(false)
  }

  const handleOpenInvoiceAction = (id, status, data) => {
    setIdRow(id)
    setCurrentAction(status)
    setInvoiceData(data)
  }

  const handleCloseInvoiceAction = () => {
    mutate(fetchUrl)
    mutate(fetchUrlDraft)
    mutate(fetchUrlUnsent)
    mutate(fetchUrlAll)
    mutate(fetchUrlOpen)
    mutate(fetchUrlPaid)
    setIdRow(null)
    setInvoiceData(null)
    setCurrentAction(null)
  }

  const goToSend = (operationId, invoiceId) => {
    Router.push(`/operations/${operationId}/invoices/${invoiceId}/send`)
  }

  //Delete function
  const handleDelete = async () => {
    setLoading(true)
    const response = await onDeleteInvoice(idRow)
    if (response) {
      if (response.status === 204) {
        mutate(fetchUrl)
        mutate(fetchUrlDraft)
        mutate(fetchUrlUnsent)
        mutate(fetchUrlAll)
        mutate(fetchUrlOpen)
        mutate(fetchUrlPaid)
        enqueueSnackbar(dictionary["invoice_deleted"], {
          variant: 'success'
        })
        setLoading(false)
        handleCloseInvoiceAction()
      }
      else {
        mutate(fetchUrl)
        mutate(fetchUrlDraft)
        mutate(fetchUrlUnsent)
        mutate(fetchUrlAll)
        mutate(fetchUrlOpen)
        mutate(fetchUrlPaid)
        setLoading(false)
      }
    }
  }

  //Validate function
  const handleValidate = async () => {
    setLoading(true)
    const invoice = []
    invoice['is_valid'] = true
    const response = await onUpdateInvoice(idRow, invoice)
    if (response) {
      if (response.status === 200) {
        mutate(fetchUrl)
        mutate(fetchUrlDraft)
        mutate(fetchUrlUnsent)
        mutate(fetchUrlAll)
        mutate(fetchUrlOpen)
        mutate(fetchUrlPaid)
        enqueueSnackbar(dictionary["invoice_updated"], {
          variant: 'success'
        })
        setLoading(false)
        handleCloseInvoiceAction()
      }
      else {
        mutate(fetchUrl)
        mutate(fetchUrlDraft)
        mutate(fetchUrlUnsent)
        mutate(fetchUrlAll)
        mutate(fetchUrlOpen)
        mutate(fetchUrlPaid)
        enqueueSnackbar(dictionary["invoice_updated"], {
          variant: 'success'
        })
        setLoading(false)
        handleCloseInvoiceAction()
      }
    }
  }

  //Cancel function
  const handleCancel = async () => {
    setLoading(true)
    const invoice = []
    invoice['is_closed'] = true
    const response = await onUpdateInvoice(idRow, invoice)
    if (response) {
      if (response.status === 200) {
        mutate(fetchUrl)
        mutate(fetchUrlDraft)
        mutate(fetchUrlUnsent)
        mutate(fetchUrlAll)
        mutate(fetchUrlOpen)
        mutate(fetchUrlPaid)
        enqueueSnackbar(dictionary["invoice_updated"], {
          variant: 'success'
        })
        setLoading(false)
        handleCloseInvoiceAction()
      }
      else {
        mutate(fetchUrl)
        mutate(fetchUrlDraft)
        mutate(fetchUrlUnsent)
        mutate(fetchUrlAll)
        mutate(fetchUrlOpen)
        mutate(fetchUrlPaid)
        enqueueSnackbar(dictionary["invoice_updated"], {
          variant: 'success'
        })
        setLoading(false)
        handleCloseInvoiceAction()
      }
    }
  }

  //send function

  const handleSend = async () => {
    setLoading(true)
    const invoice = []
    invoice['is_sent'] = true
    const response = await onUpdateInvoice(idRow, invoice)
    if (response) {
      if (response.status === 200) {
        mutate(fetchUrl)
        mutate(fetchUrlDraft)
        mutate(fetchUrlUnsent)
        mutate(fetchUrlAll)
        mutate(fetchUrlOpen)
        mutate(fetchUrlPaid)
        enqueueSnackbar(dictionary["invoice_updated"], {
          variant: 'success'
        })
        setLoading(false)
        handleCloseInvoiceAction()
      }
      else {
        mutate(fetchUrl)
        mutate(fetchUrlDraft)
        mutate(fetchUrlUnsent)
        mutate(fetchUrlAll)
        mutate(fetchUrlOpen)
        mutate(fetchUrlPaid)
        enqueueSnackbar(dictionary["invoice_updated"], {
          variant: 'success'
        })
        setLoading(false)
        handleCloseInvoiceAction()
      }
    }
  }

  const actions = (id, status, operationId, data) => [

    status === "not_valid" && {
      name: dictionary['validate_invoice'],
      onClick: () => handleOpenInvoiceAction(id, "validate", data)
    },

    status === "not_valid" && {
      name: dictionary['delete'],
      onClick: () => handleOpenInvoiceAction(id, "delete", data)
    },

    status === "not_sent" && {
      name: dictionary['send_invoice'],
      onClick: () => goToSend(operationId, id)
    },

    status === "not_sent" && {
      name: dictionary['mark_sent'],
      onClick: () => handleOpenInvoiceAction(id, "send", data)
    },

    (status === "not_paid" || status === "partial" || status === "late") && {
      name: dictionary['pay_invoice'],
      onClick: () => handleOpenInvoiceAction(id, "pay", data)
    },

    (status !== "canceled" && status !== 'not_valid') && {
      name: dictionary['cancel'],
      onClick: () => handleOpenInvoiceAction(id, "cancel", data)
    }

  ]

  const columns = [
    {
      field: 'id',
      headerName: dictionary['invoice'],
      minWidth: 150,
      renderCell: (params) => (
        <>
          {
            params.row && params.row.operation &&
            <Link href={`/operations/${params.row.operation.id}/invoices/${params.row.id}`}>
              <a className={classes.link}>{params.row.code}</a>
            </Link>
          }
        </>
      ),
    },
    {
      field: 'operation',
      headerName: dictionary['operation'],
      minWidth: 160,
      valueGetter: ({ row }) => row?.operation?.display,
      renderCell: CellExpand
    },
    {
      field: 'tenant',
      headerName: dictionary['tenant'],
      minWidth: 160,
      flex: 1,
      valueGetter: ({ row }) => row?.tenant?.display,
      renderCell: CellExpand

    },
    {
      field: 'invoice_date',
      headerName: dictionary['invoice_date'],
      minWidth: 180,
    },
    {
      field: 'due_date',
      headerName: dictionary['due_date'],
      minWidth: 180,
      renderCell: (params) => (
        <>
          {params.row.due_date && UnformatDate(params.row.due_date) < new Date() && params.row.status === "late" ?
            <p className={classes.invoiceLate}>
              {params.row.due_date} (+ {differenceInDays(new Date(), UnformatDate(params.row.due_date))})
            </p>
            :
            <>
              {params.row.due_date}
            </>
          }
        </>
      )
    },
    {
      field: 'subtotal',
      headerName: dictionary['subtotal'],
      type: 'number',
      minWidth: 140,
      valueFormatter: (params) => numberWithSpaces(params.value)
    },
    {
      field: 'amount',
      headerName: dictionary['total'],
      type: 'number',
      minWidth: 140,
      valueFormatter: (params) => numberWithSpaces(params.value)
    },
    {
      field: 'balance',
      headerName: dictionary['balance'],
      type: 'number',
      minWidth: 140,
      valueFormatter: (params) => numberWithSpaces(params.value)
    },
    {
      field: 'status_display',
      headerName: dictionary['status'],
      minWidth: 130,
      renderCell: (params) => (
        <>
          {params.row.status &&
            <MLabel
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
              color={
                (params?.row.status === 'paid' && 'success') ||
                ((params?.row.status === 'late' || params?.row.status === "closed" || params?.row.status === "litigious") && 'error') ||
                ((params?.row.status === 'canceled' || params?.row.status === 'not_valid') && 'default') ||
                ((params?.row.status === 'not_sent') && 'info') ||
                ((params?.row.status === 'not_paid' || params?.row.status === 'partial') && 'warning') ||
                'default'
              }
            >
              {params.row.is_scheduled && !params.row.is_sent ? dictionary['scheduled'] : params.row.status_display}
              {params.row.is_scheduled && !params.row.is_sent ? params.row.send_date : ''}
            </MLabel>
          }
        </>
      ),
    },
    {
      field: 'action',
      headerName: dictionary['actions'],
      cellClassName: 'actions_cell',
      width: 24,
      sortable: false,
      renderCell: (params) => (params.row.status && (params.row.status === "not_paid" || params.row.status === "paid" || params.row.status === "partial" || params.row.status === "late" || params.row.status === "not_sent" || params.row.status === "not_valid")) ? <ActionTable actions={actions(params.row.id, params.row.status, params.row.operation.id, params.row)} /> : <></>,
    },
  ]

  const operation_columns = [
    {
      field: 'id',
      headerName: dictionary['invoice'],
      minWidth: 150,
      renderCell: (params) => (
        <Link href={`/operations/${operationId}/invoices/${params.row.id}`}>
          <a className={classes.link}>{params.row.code}</a>
        </Link>
      ),
    },
    {
      field: 'tenant',
      headerName: dictionary['tenant'],
      minWidth: 160,
      flex: 1,
      valueGetter: ({ row }) => row?.tenant?.display
    },
    {
      field: 'invoice_date',
      headerName: dictionary['invoice_date'],
      minWidth: 180,
    },
    {
      field: 'due_date',
      headerName: dictionary['due_date'],
      minWidth: 180,
      renderCell: (params) => (
        <>
          {params.row.due_date && UnformatDate(params.row.due_date) < new Date() && params.row.status === "late" ?
            <p className={classes.invoiceLate}>
              {params.row.due_date} (+ {differenceInDays(new Date(), UnformatDate(params.row.due_date))})
            </p>
            :
            <>
              {params.row.due_date}
            </>
          }
        </>
      )
    },
    {
      field: 'subtotal',
      headerName: dictionary['subtotal'],
      type: 'number',
      minWidth: 140,
      valueFormatter: (params) => numberWithSpaces(params.value)
    },
    {
      field: 'amount',
      headerName: dictionary['total'],
      type: 'number',
      minWidth: 140,
      valueFormatter: (params) => numberWithSpaces(params.value)
    },
    {
      field: 'balance',
      headerName: dictionary['balance'],
      type: 'number',
      minWidth: 140,
      valueFormatter: (params) => numberWithSpaces(params.value)
    },
    {
      field: 'status_display',
      headerName: dictionary['status'],
      minWidth: 130,
      renderCell: (params) => (
        <>
          {params.row.status &&
            <MLabel
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
              color={
                (params?.row.status === 'paid' && 'success') ||
                ((params?.row.status === 'late' || params?.row.status === "closed" || params?.row.status === "litigious") && 'error') ||
                ((params?.row.status === 'canceled' || params?.row.status === 'not_valid') && 'default') ||
                ((params?.row.status === 'not_sent') && 'info') ||
                ((params?.row.status === 'not_paid' || params?.row.status === 'partial') && 'warning') ||
                'default'
              }
            >
              {params.row.is_scheduled && !params.row.is_sent ? dictionary['scheduled'] : params.row.status_display}
              {params.row.is_scheduled && !params.row.is_sent ? params.row.send_date : ''}
            </MLabel>
          }
        </>
      ),
    },
    {
      field: 'action',
      headerName: dictionary['actions'],
      cellClassName: 'actions_cell',
      width: 24,
      sortable: false,
      renderCell: (params) => (params.row.status && (params.row.status === "not_paid" || params.row.status === "partial" || params.row.status === "late" || params.row.status === "not_sent" || params.row.status === "not_valid" || params.row.status === "paid")) ? <ActionTable actions={actions(params.row.id, params.row.status, operationId, params.row)} /> : <></>,
    },
  ]

  const handlePageChange = (params) => {
    setPageCount(params + 1)
  }

  return (
    <div className={classes.containerTable}>
      <TabContext value={value}>
        <div className={classes.tableNav}>
          <div className={classes.filterContainer}>

            <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              invisible={!isFiltersSelected}
              color='primary'
              className={classes.filterButton}
              variant='dot'
            >
              <Button
                type="button"
                endIcon={<FilterListIcon />}
                color={isFiltersSelected ? 'primary' : 'inherit'}
                onClick={handleOpenFiltersModal}
              >
                {!isMobile && dictionary['filters']}
              </Button>
            </Badge>
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              className={classes.tabList}
              onChange={handleChange}
            >
              {INVOICE_TAB.map((tab) => {
                return (
                  <Tab
                    className={classes.tab}
                    key={tab?.value}
                    label={tab?.label}
                    value={tab?.value}
                  />
                )
              })}
            </TabList>
          </div>
          <div className={classes.searchContainer}>
            <Search
              value={valueSearch}
              onChange={onFilterName}
            />
            <Button
              variant="contained"
              color="primary"
              type="button"
              startIcon={<AddIcon />}
              className={classes.submit}
              onClick={handleFormOpen}
            >
              {!isMobile && dictionary['add_invoice']}
            </Button>
          </div>

        </div>
        <Table
          className={classes.gridContainer}
          rows={data && data.results ? data.results : []}
          loading={isLoading}
          columns={isOperation ? operation_columns : columns}
          page={pageCount}
          handlePageChange={handlePageChange}
          count={data && data.count}
          components={{
            Footer: isOperation ? FooterRow : FooterRowGlobal,
          }}
        />
        {(isOpenForm) && (
          <ModalForm
            className={classes.modalContainer}
            type={dictionary['invoice']}
            onClose={handleFormClose}
          >
            <InvoicesForm
              handleClose={handleFormClose}
              fetchUrl={fetchUrl}
              leaseId={leaseId}
              tenantId={tenantId}
              isOperation={isOperation}
              operationId={operationId}
            />
          </ModalForm>
        )}
      </TabContext>

      {currentAction === "validate" &&
        <ModalDelete onClose={handleCloseInvoiceAction} description={dictionary["confirm_validate"]} isYellow onDelete={handleValidate} />
      }

      {currentAction === "cancel" &&
        <ModalDelete onClose={handleCloseInvoiceAction} description={dictionary["confirm_cancel"]} onDelete={handleCancel} />
      }

      {currentAction === "delete" &&
        <ModalDelete onClose={handleCloseInvoiceAction} description={dictionary["confirm_invoice_delete"]} onDelete={handleDelete} />
      }

      {currentAction === "pay" &&
        <InvoicePaymentsDetails onClose={handleCloseInvoiceAction} invoiceId={idRow} operationId={operationId ? operationId : invoiceData.operation.id} amount={invoiceData.amount} balance={invoiceData.balance} tenant={invoiceData.tenant} />
      }

      {currentAction === "send" &&
        <ModalDelete onClose={handleCloseInvoiceAction} description={dictionary["confirm_send"]} isYellow onDelete={handleSend} />

      }

      {isFiltersModalOpened && (
        <ModalForm type={dictionary['filters']} onClose={handleCloseFiltersModal}>
          <InvoicesFilterModal
            filterValues={selectedFilters}
            onFiltersApply={handleFiltersApply}
            leaseId={leaseId}
          />
        </ModalForm>
      )}

    </div>
  )
}
