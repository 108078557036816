import client from '@utils/client'
import axios from 'axios'

export const requestMetricsDashboard = async (operationId, landlordId) => {
  try {
    const { data: operations } = await client.get(`/operations/${operationId}/`)


    return {
      operations,
    }
  } catch (error) {
    console.error('ERROR:', error)
    return {}
  }
}

// export const requestMetricsFinancial = async (operationId) => {
//   try {
//     const dataFinancials = await client.get(`
//       /stats/indicators/financials/operation/${operationId}/
//     `)

//     console.log('dataFinancials', dataFinancials)
//     return {}
//   } catch (error) {
//     console.error('ERROR', error)
//     return {}
//   }
// }

export const requestMetricsOverviewLine = async (operationId, landlordId) => {
  try {
    const { data: billable } = await client.get(`
      /stats/indicators/billable/operation/${operationId}/
    `)

    const { data: receivable } = await client.get(`
      /stats/indicators/invoices/receivable/operation/${operationId}/
    `)

    const { data: payable } = await client.get(`
      /stats/indicators/expenses/payable/operation/${operationId}/
    `)

    // const {data: landlord} = await client.get(`
    //   /stats/indicators/landlord/balance/${landlordId}/
    // `)

    return {
      billable,
      receivable,
      payable,
      landlord,
    }
  } catch (error) {
    console.error('ERROR', error)
    return {}
  }
}

export const requestMetricsByOperationId = async (operationId) => {
  try {
    const { data: occupancy } = await client.get(
      `/stats/indicators/occupancy/operation/${operationId}/`,
    )
    const { data: rent } = await client.get(
      `/stats/indicators/rent/operation/${operationId}/`,
    )
    const { data: rentOccupancy } = await client.get(
      `/stats/indicators/rent/potential/operation/${operationId}/`,
    )
    const { data: actualRent } = await client.get(
      `/stats/indicators/rent/actual/operation/${operationId}/`,
    )
    const { data: receivable } = await client.get(
      `/stats/indicators/invoices/receivable/operation/${operationId}/`,
    )

    const { data: payable } = await client.get(
      `/stats/indicators/expenses/payable/operation/${operationId}/`,
    )

    const { data: revenue } = await client.get(
      `/stats/indicators/revenue/operation/${operationId}/`,
    )
    const { data: budget } = await client.get(
      `/stats/indicators/budget/operation/${operationId}/`,
    )
    const { data: expenses } = await client.get(
      `/stats/indicators/expenses/total/operation/${operationId}/`,
    )
    return {
      occupancy,
      rent,
      actualRent,
      receivable,
      payable,
      rentOccupancy,
      revenue,
      budget,
      expenses,
    }
  } catch (error) {
    return {}
  }
}

export const requestRevenueMetrics = async () => {
  try {
    const { data } = await client.get(`/stats/indicators/revenue`)
    return data
  } catch (error) {
    return {}
  }
}
export const requestPayableMetrics = async () => {
  try {
    const { data } = await client.get(`/stats/indicators/expenses/payable/`)
    return data
  } catch (error) {
    return {}
  }
}
export const requestReceivableMetrics = async () => {
  try {
    const { data } = await client.get(`/stats/indicators/invoices/receivable/`)
    return data
  } catch (error) {
    return {}
  }
}

export const requestVacantMetrics = async () => {
  try {
    const { data } = await client.get(`/stats/indicators/vacant_rent`)
    return data
  } catch (error) {
    return {}
  }
}
