import React from 'react'
import IncomeExpenseDetail from '@components/dashboard/IncomeExpense/IncomeExpenseDetail'


function IncomeExpenseView({ operationId, changeYear, year, currentYear }) {

  return <IncomeExpenseDetail operationId={operationId} changeYear={changeYear} year={year} currentYear={currentYear} />
}

export default IncomeExpenseView
