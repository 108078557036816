import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { mutate } from 'swr';
import Router from 'next/router'


import { makeStyles } from '@material-ui/core/styles'
import { Card, Grid, List, ListItem, ListItemText, Collapse, Paper, Switch } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'

import { useFetchData } from '@api/fetchData';
import { onUpdateOrganization } from '@api/organizations';

import { OrganizationSelect } from '.';
import { CountriesSelect, CurrenciesSelectOrga } from '@components/form';

import { useTranslation } from '@contexts/translation'
import useBreakpoints from '@hooks/useBreakpoints'
import { BanksTable } from '@components/banks'
import { StyleguidesTable } from '@components/styleguides'
import { TemplatesView } from '@components/templates';
import { SMTPView } from '@components/smtp';
import ManagerTable from './ManagerTable';
import CurrenciesTable from './CurrenciesTable';



const useStyles = makeStyles((theme) => ({
  form: {
    margin: 20,
    marginTop: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  tabNav: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

export default function OrganizationsSettings({ orgId }) {
  const { dictionary } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false)
  const [selectedId, setId] = useState(1)
  const isMobile = useBreakpoints('down', 'sm')
  const { register, handleSubmit, setError, errors, setValue, control } =
    useForm({
      mode: 'onBlur',
      shouldUnregister: false
    });

  const [isFinanceEnabled, setIsFinanceEnabled] = useState(false);

  const { data, isLoading, isError } = useFetchData(`/organizations/${orgId}/`);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    setLoading(true);

    let response = await onUpdateOrganization(orgId, data);
    if (response.status === 200) {
      enqueueSnackbar(dictionary['organization_updated'], {
        variant: 'success',
      });
      mutate(`/organizations/${orgId}/`);
      setLoading(false);
    }
    if (response.status === 400) {
      setLoading(false);
      const { errors } = response.data;
      if (errors.length > 0) {
        errors.map((error) => {
          setError(error.field, {
            message: error.message,
          });
        });
      }
    }
  };

  const handleFinanceEnabled = (e) => {
    setIsFinanceEnabled(e.target.checked)
  }

  useEffect(() => {
    if (data) {
      setValue('name', data?.name);
      setValue('country', data?.country);
      setValue('currency', data?.currency);
      setIsFinanceEnabled(data?.finance_enabled);
    }
  }, [data]);

  const MENU = [
    {
      id: 1,
      name: dictionary["info"],
      onClick: () => setId(1),
      isExpand: false,
      disabled: false,
    },
    {
      id: 2,
      name: dictionary['banks'],
      onClick: () => setId(2),
      isExpand: false,
      disabled: false,
    },
    {
      id: 3,
      name: dictionary['styleguide'],
      onClick: () => setId(3),
      isExpand: false,
      disabled: false,
    },
    {
      id: 4,
      name: dictionary["email_templates"],
      onClick: () => setId(4),
      isExpand: false,
      disabled: false,
    },
    {
      id: 5,
      name: dictionary["smtp_settings"],
      onClick: () => setId(5),
      isExpand: false,
      disabled: false,
    },
    {
      id: 6,
      name: dictionary["manager_rules"],
      onClick: () => setId(6),
      isExpand: false,
      disabled: false,
    },
    {
      id: 7,
      name: dictionary["currencies"],
      onClick: () => setId(7),
      isExpand: false,
      disabled: false,
    }
  ]


  return (
    <Grid container spacing={3}>
      {!isMobile && (
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <Card>
              {MENU && (
                <List
                  component="nav"
                  aria-labelledby=""
                  className={classes.tabNav}
                >
                  {MENU.map((item, index) => (
                    <>
                      <ListItem
                        button
                        key={item.name}
                        onClick={item?.onClick}
                        disabled={item?.disabled}
                      >
                        <ListItemText primary={item?.name} />
                        {item?.isExpand && (
                          <>{open ? <ExpandLess /> : <ExpandMore />}</>
                        )}
                      </ListItem>
                      {item?.expandItem && (
                        <Collapse
                          in={open}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {item?.expandItem?.map((item, index) => (
                              <ListItem
                                button
                                className={classes.nested}
                                key={index}
                              >
                                <ListItemText primary={item?.name} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      )}
                    </>
                  ))}
                </List>
              )}
            </Card>
          </Paper>
        </Grid>
      )}
      <Grid item xs={isMobile ? 12 : 9}>
        {selectedId === 1 &&
          <>
            <OrganizationSelect orgName={data?.name} orgId={orgId} />
            <Card className={classes.root}>
              <form
                className={classes.form}
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    name="name"
                    label={dictionary["name"]}
                    type="text"
                    id="name"
                    fullWidth
                    inputRef={register}
                    error={errors.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={errors.name && errors.name.message}
                    autoComplete="name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CountriesSelect control={control} fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <CurrenciesSelectOrga control={control} defaultValue={data && data.currency} fullWidth />
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  {`Finance enabled `}
                  <Switch
                    checked={isFinanceEnabled}
                    onChange={handleFinanceEnabled}
                    name="finance_enabled"
                    id="finance_enabled"
                    inputRef={register}
                    size="normal"
                    color="primary"
                  />
                </Grid>
                <Grid xs="12" className={classes.buttonContainer}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    startIcon={<SaveIcon />}
                    className={classes.submit}
                  >
                    {dictionary["submit"]}
                  </Button>
                </Grid>
              </form>
            </Card>
          </>
        }
        {selectedId === 2 &&
          <BanksTable />
        }
        {selectedId === 3 &&
          <StyleguidesTable />
        }
        {selectedId === 4 &&
          <TemplatesView />
        }
        {selectedId === 5 &&
          <SMTPView />
        }
        {selectedId === 6 &&
          <ManagerTable />
        }
        {selectedId === 7 &&
          <CurrenciesTable />
        }
      </Grid>
    </Grid>
  )
}
