import { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { Box, IconButton, Typography } from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'
import { MyAvatar, ModalForm } from '@components/common'

const useStyles = makeStyles((theme) => ({
  root: {
    '&:before': {
      top: 0,
      zIndex: 9,
      width: '100%',
      content: "''",
      height: '100%',
      position: 'absolute',
      backdropFilter: 'blur(3px)',
      backgroundColor: alpha(theme.palette.primary.darker, 0.72),
    },
  },
  userInfo: {
    left: 0,
    right: 0,
    zIndex: 99,
    position: 'absolute',
    marginTop: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      right: 'auto',
      display: 'flex',
      alignItems: 'center',
      left: theme.spacing(3),
      bottom: theme.spacing(3),
      marginTop: theme.spacing(5),
    },
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1)
  },
  nameTitle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
}))

const ProfileCover = ({ profile, className, form, type }) => {
  const classes = useStyles()
  const { display, company_name, first_name, last_name } = profile

  const [isEditOpen, setIsEditOpen] = useState(false)

  const handleEditToggle = () => setIsEditOpen(!isEditOpen)
  const handleEditClose = () => setIsEditOpen(false)
  const name = () => {
    return display
  }

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.userInfo}>
        <MyAvatar
          sx={{
            mx: 'auto',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'common.white',
            width: { xs: 80, md: 128 },
            height: { xs: 80, md: 128 },
          }}
          name={name()}
        />
        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: 'common.white',
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Typography className={classes.nameContainer} variant="h4">
            <span className={classes.nameTitle}> {name()}</span>
            <IconButton
              color="inherit"
              aria-label="Edit"
              component="span"
              onClick={handleEditToggle}
            >
              <EditIcon />
            </IconButton>
          </Typography>
        </Box>
      </div>
      {/* <LazySize
        alt="profile cover"
        src={cover.small}
        size={`${cover.small} 600w, ${cover.medium} 960w`}
        sx={{ zIndex: 8, height: '100%', position: 'absolute' }}
      /> */}

      {isEditOpen && (
        <ModalForm
          className={classes.modalContainer}
          isEdit={isEditOpen}
          type={type}
          onClose={handleEditClose}
        >
          {form(handleEditClose)}
        </ModalForm>
      )}
    </div>
  )
}

ProfileCover.propTypes = {
  profile: PropTypes.object,
  authUser: PropTypes.object,
  className: PropTypes.string,
}

export default ProfileCover
