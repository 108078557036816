import React, { useState, useEffect } from 'react';
import { Button, Tab, Tabs, Typography, Card } from '@material-ui/core';
import { useTranslation } from '@contexts/translation';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";
import UploadView from "@components/profile/Import/UploadView";
import SelectType from "@components/profile/Import/SelectType";
import FinishView from "@components/profile/Import/FinishView";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24
  },
  titleContainer: {
    paddingBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  titleText: {
    marginRight: theme.spacing(0.5)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export default function NewImport({ onBackBtnClick, operationId }) {
  const { dictionary } = useTranslation();
  const classes = useStyles();
  const stepList = [
    { key: 'select_type', label: dictionary['select_type'] },
    { key: 'upload', label: dictionary['upload_file'] },
    // {key: 'confirm', label: dictionary['confirm_data']},
    { key: 'finish', label: dictionary['finish'] },
  ];
  const [currentStep, setCurrentStep] = useState(stepList[0].key);
  const [importTypeObj, setImportTypeObj] = useState({}); // {key: "", label: ""}
  const [importOperationObj, setImportOperationObj] = useState({}); // {id: "", name: ""}
  const [importPayloadData, setImportPayloadData] = useState(null);
  const [file, setFile] = useState()

  useEffect(() => {
    if (operationId) {
      setImportOperationObj({ id: operationId })
    }
  }, [operationId])

  // set type & operation; go to "upload file" step
  const handleTypeConfirm = (typeObj, operationObj) => {
    setImportTypeObj(typeObj);
    setImportOperationObj(operationObj);
    setCurrentStep(stepList[1].key);
  }

  const handleGoBackToType = () => {
    setCurrentStep(stepList[0].key);
  };

  // TODO replace "go to FINISH" with "go to CONFIRM"
  // set file; go to "FINISH" step
  const handleUploadConfirm = file => {
    let payload = new FormData();
    payload.append('type', importTypeObj.key);
    payload.append('file', file);
    if (importOperationObj.id) {
      payload.append('operation', importOperationObj.id);
    }
    setImportPayloadData(payload);
    setFile(file)
    setCurrentStep(stepList[2].key);
  };

  const stepComponentMap = {
    [stepList[0].key]:
      <SelectType
        onTypeConfirm={handleTypeConfirm}
        selectedType={importTypeObj}
        operationId={operationId}
        selectedOperation={operationId ? { id: operationId } : importOperationObj}
      />,
    [stepList[1].key]:
      <UploadView
        importType={importTypeObj.key}
        onGoBack={handleGoBackToType}
        onUploadConfirm={handleUploadConfirm}
      />,
    // TODO replace indexes; add confirm_data
    [stepList[2].key]:
      <FinishView payload={importPayloadData} file={file} />
  }

  return (
    <Card className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant='h4' className={classes.titleText}>
          {dictionary['import']}
          {currentStep !== stepList[0].key &&
            <>
              {importTypeObj.label && `: ${importTypeObj.label}`}
              {importOperationObj.name && ` (${importOperationObj.name})`}
            </>
          }
        </Typography>
      </div>

      <div className={classes.header}>
        <Tabs
          value={currentStep}
        >
          {stepList.map((step, index) => (
            <Tab
              key={step.key}
              label={`${index + 1}. ${step.label}`}
              value={step.key}
            />
          ))}
        </Tabs>
        <Button
          onClick={onBackBtnClick}
          startIcon={<ArrowBackIosIcon fontSize='small' />}
        >
          {dictionary['back_to_imports']}
        </Button>
      </div>
      {stepComponentMap[currentStep]}
    </Card>
  )
}
