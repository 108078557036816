import { useState } from 'react';
import { mutate } from 'swr';
import { useSnackbar } from 'notistack';
import Link from 'next/link';
import { useFetchData } from '@api/fetchData';
import { onDeleteInvestorShare } from '@api/investors';
import { ActionTable, ModalDelete, ModalForm, Table } from '@components/common';
import { useTranslation } from '@contexts/translation';
import { Button, Container, Grid, makeStyles } from '@material-ui/core';
import { SharesForm } from './SharesForm';
import { getParam, SHARE_URL } from './helpers';
import Page404View from 'pages/404';
import { TotalRow } from './SharesTotal';
import Loader from '../Loader';
import AddIcon from "@material-ui/icons/Add";
import useBreakpoints from "@hooks/useBreakpoints";
import { numberWithSpacesNoDecimalAdded } from '@utils/numberWithSpaces'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  submit: {
    '@media (max-width: 600px)': {
      minWidth: 'initial',
      padding: theme.spacing(1),
      '& span': {
        marginRight: '0',
        marginLeft: '0',
      },
    },
  },
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
  },
  numeric: {
    width: '100%',
    textAlign: 'right',
  },
}));

const getTableData = (array, tableConfig = {}) => {
  const param = getParam(tableConfig);
  return (
    array
      .filter(
        (item) => item[tableConfig.location]?.id === Number(tableConfig.id)
      )
      .map((item) => ({
        ...item,
        [`${[param]}Name`]: item[param]?.name || '',
        [`${[param]}Id`]: item[param]?.id || '',
      })) || [{ id: 0, ownership: '', operationName: '', investorName: '' }]
  );
};

/**
 *
 * @param {location} string 'investor' ||  'operation'
 * @param {id} number
 * @returns JSX
 */

const Shares = ({ location = '', id = 0 }) => {
  const classes = useStyles();
  const { dictionary } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useBreakpoints('down', 'sm');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [idShare, setIdShare] = useState(null);
  const [isDelete, setIsDelete] = useState(false);

  const tableConfig = { location, id };

  const { data, isLoading, isError } = useFetchData(SHARE_URL);

  const rows = data && data.results && getTableData(data.results, tableConfig);

  const actions = (idShare) => [
    {
      name: dictionary.edit,
      onClick: () => {
        setIsEdit(true);
        setIdShare(idShare);
      },
    },
    {
      name: dictionary.delete,
      onClick: () => {
        setIsDelete(true);
        setIdShare(idShare);
      },
    },
  ];

  const firstColumn = getParam(tableConfig);

  const columns = [
    {
      field: [`${[firstColumn]}Name`],
      headerName: `${dictionary[firstColumn]}`,
      minWidth: 180,
      flex: 1,
      renderCell: (params) =>
        params.value !== 'Total' ? (
          <Link href={`/${firstColumn}s/${params.row[`${[firstColumn]}Id`]}`}>
            {params.value}
          </Link>
        ) : (
          <span>{params.value}</span>
        ),
    },
    {
      field: 'ownership',
      headerName: `${dictionary.routes.investors.ownership}`,
      minWidth: 180,
      type: 'number',
      renderCell: (params) => (
        <span className={classes.numeric}>{numberWithSpacesNoDecimalAdded(params.value)}%</span>
      ),
    },
    {
      field: 'action',
      headerName: dictionary.actions,
      width: 24,
      flex: 1,
      cellClassName: 'actions_cell',
      sortable: false,
      renderCell: (params) => {
        const id = params.row.id;
        return id && id !== 'Total' && <ActionTable actions={actions(id)} />;
      },
    },
  ];

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsEdit(false);
    // setIdShare(null);
  };

  const handleDeleteToggle = () => setIsDelete(!isDelete);

  const deleteShare = async () => {
    const response = await onDeleteInvestorShare(idShare);
    if (response.status === 204) {
      mutate(SHARE_URL);
      enqueueSnackbar(dictionary.deleted, {
        variant: 'success',
      });
      handleDeleteToggle();
    }
  };

  if (isLoading) return <Loader />;
  if (isError) return <Page404View />;

  return (
    <>
      <Grid container wrap="nowrap" justifyContent="flex-end">
        <Button
          variant="contained"
          className={classes.button}
          className={classes.submit}
          startIcon={<AddIcon />}
          onClick={() => setIsModalOpen(true)}
        >
          {!isMobile && dictionary.routes.investors.add_share}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {rows && (
          <Table
            loading={isLoading}
            columns={columns}
            rows={rows}
            components={{
              Footer: TotalRow,
            }}
          />
        )}
      </Grid>

      {(isModalOpen || isEdit) && (
        <ModalForm
          className={classes.modalContainer}
          isEdit={isEdit}
          type={dictionary.shares}
          onClose={handleModalClose}
        >
          <SharesForm
            handleClose={handleModalClose}
            isEdit={isEdit}
            idShare={idShare}
            tableConfig={tableConfig}
          />
        </ModalForm>
      )}

      {isDelete && (
        <ModalDelete onClose={handleDeleteToggle} onDelete={deleteShare} />
      )}
    </>
  );
};
export default Shares;
