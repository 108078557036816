import useSWR from 'swr'
import { fetcher } from '@lib/fetcher'

export const useFetchData = (url, id, options) => {
  const { data, error } = useSWR(`${url}${id ? `${id}/` : ''}`, fetcher, options)

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}
