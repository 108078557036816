import client from '@utils/client'

export const onCreateEmail = (payload) => {
  return client
    .post('/emails/', payload)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onDeleteEmail = (id) => {
  return client
    .delete(`/emails/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onUpdateEmail = (id, payload) => {
  return client
    .patch(`/emails/${id}/`, payload)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetEmail = (id) => {
  return client
    .get(`/emails/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onSendTemplateTest = (id) => {
  return client
    .get(`/emails/${id}/send-test/`)
    .then((response) => response)
    .catch((error) => { return error.response })
}