import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'

import { Modal } from '.'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'

import { useTranslation } from '@contexts/translation'

import { DatePicker, TimePicker } from '@components/form'

import { useFetchData } from '@api/fetchData'

import UnformatDate from '@utils/unformatDate'
import { unformatTime } from '@utils/formatDate'

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerButtons: {
    height: 'fit-content',
    display: "flex",
    padding: theme.spacing(4),
  },
  container: {
    height: 'fit-content',
    padding: theme.spacing(4)
  },
  buttonCancel: {
    marginRight: theme.spacing(8),
  },
  form: {
    display: 'flex',
    flexDirection: "column",
    zIndex: 99999,
  }
}))

export default function ModalScheduleDate({ onClose, fetchUrl, onSubmit, invoiceId, loading }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()

  const {
    handleSubmit,
    errors,
    setValue,
    control
  } = useForm({ mode: 'onBlur' })

  const { data: prevData, isLoading, isError } = useFetchData('/invoices/', invoiceId)

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        if (prevData?.send_on_date) {
          setValue('send_on_date', UnformatDate(prevData?.send_on_date))
        }
        if (prevData?.send_on_time) {
          setValue('send_on_time', unformatTime(prevData?.send_on_time))
        }
        if (prevData?.send_on_date && prevData?.send_on_time) {
          setValue('send_on_time', unformatTime(prevData?.send_on_time))
          setValue('send_on_date', UnformatDate(prevData?.send_on_date))
        }
      }, 1)
    }
  }, [prevData]);



  return (
    <Modal className={classes.modalContainer} onClose={onClose}>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.container}>
          <DatePicker
            id="send_on_date"
            name="send_on_date"
            required
            label={dictionary["send_on_date"]}
            variant="outlined"
            margin="normal"
            fullWidth
            control={control}
            error={errors.send_on_date}
            errorMessage={errors.send_on_date && errors.send_on_date.message}
            clearable={true}
          />
          <TimePicker
            id="send_on_time"
            name="send_on_time"
            required
            label={dictionary["send_on_time"]}
            variant="outlined"
            margin="normal"
            fullWidth
            control={control}
            error={errors.send_on_time}
            errorMessage={errors.send_on_time && errors.send_on_time.message}
            clearable={true}
          />

          <div className={classes.containerButtons}>
            <Button
              className={classes.buttonCancel}
              onClick={onClose}
              type="button"
              variant="contained"
              color="inherit"
            >
              {dictionary["cancel"]}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              {dictionary["schedule"]}
            </Button>
          </div>
        </Paper>
      </form>
    </Modal>
  )
}
