import client from '@utils/client'


export const onCreatePayment = (payload) => {
  return client
    .post('/payments/', payload)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onDeletePayment = (id) => {
  return client
    .delete(`/payments/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onUpdatePayment = (id, payload) => {
  return client
    .patch(`/payments/${id}/`, payload)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetPayment = (id) => {
  return client
    .get(`/payments/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}


export const onCreatePaymentInvoice = (payload) => {
  return client
    .post('/payments/invoice-payment/', payload)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onDeletePaymentInvoice = (id) => {
  return client
    .delete(`/payments/invoice-payment/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onUpdatePaymentInvoice = (id, payload) => {
  return client
    .patch(`/payments/invoice-payment/${id}/`, payload)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}


export const onGetPaymentInvoice = (id) => {
  return client
    .get(`/payments/invoice-payment/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
