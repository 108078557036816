// ----------------------------------------------------------------------

export default function Badge({ theme }) {

  return {
    MuiBadge: {
      styleOverrides: {
        dot: {
          width: 10,
          height: 10,
          backgroundColor: theme.palette.primary.main_dark,
          borderRadius: '50%'
        }
      }
    }
  };
}
