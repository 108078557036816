import client from '@utils/client'
import useSWR from 'swr'
import { fetcher } from '@lib/fetcher'

export const onLogin = (payload) => {
  return client
    .post('/auth/jwt/create/', {
      ...payload,
    })
    .then((response) => response.data)
    .catch((error) => {
      return error.response
    })
}

export const onRefreshToken = (payload) => {
  return client
    .post('/auth/jwt/refresh/', {
      refresh: payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onSignup = (payload) => {
  return client
    .post('/auth/users/', {
      ...payload,
    })
    .then((response) => response.data)
    .catch((error) => {
      return error.response
    })
}

export const onForgotPassword = (payload) => {
  return client
    .post('/auth/users/reset_password/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetMyProfile = () => {
  return client
    .get('/auth/users/me/')
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const useGetMyProfile = () => {
  const { data, error } = useSWR(`/auth/users/me/`, fetcher)

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}


export const onResetPassword = (payload) => {
  return client
    .post('/auth/users/reset_password_confirm/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onActivation = (payload) => {
  return client
    .post('/auth/users/activation/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onEditProfile = (payload) => {
  return client
    .put('/auth/users/me/', { ...payload })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}


export const onChangePassword = (payload) => {
  return client
    .post('/auth/users/set_password/', { ...payload })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

