import React, { useState } from 'react'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import { useFetchData } from '@api/fetchData'

import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import { ModalDelete, ModalForm, Table, ActionTable } from '@components/common'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import { useTranslation } from '@contexts/translation'
import useBreakpoints from '@hooks/useBreakpoints'
import Page404View from 'pages/404'
import { onDeleteRules } from '@api/fees'
import ManagerForm from './ManagerForm'


const useStyles = makeStyles((theme) => ({
  containerTable: {
    width: '100%',
  },
  tableNav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },

  gridContainer: {
    border: 'none',
  },
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerDelete: {
    height: 'fit-content',
    padding: theme.spacing(4),
  },
  buttonDelete: {
    marginRight: theme.spacing(8),
  },
  submit: {
    '@media (max-width: 600px)': {
      minWidth: 'initial',
      padding: theme.spacing(1),
      '& span': {
        marginRight: '0',
        marginLeft: '0',
      },
    },
  },
}))

export default function ManagerTable() {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [isOpenForm, setIsOpenForm] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [idRow, setIdRow] = useState(null)
  const [pageCount, setPageCount] = useState(1)
  const isMobile = useBreakpoints('down', 'sm')

  const fetchUrl = `/fees/?page=${pageCount}`

  const { data, isLoading, isError } = useFetchData(fetchUrl)

  const handlePageChange = (params) => {
    setPageCount(params + 1)
  };


  const handleFormOpen = () => {
    setIsOpenForm(true)
  }
  const handleFormClose = () => {
    setIsOpenForm(false)
  }

  const handleEditOpen = () => {
    setIsEdit(true)
  }
  const handleEditClose = () => {
    setIsEdit(false)
  }

  const handleDeleteOpen = () => {
    setIsDelete(true)
  }

  const handleDeleteClose = () => {
    setIsDelete(false)
  }

  const onDelete = async () => {
    const response = await onDeleteRules(idRow)

    if (response) {
      if (response.status === 204) {
        mutate(fetchUrl)
        enqueueSnackbar(dictionary['rules_deleted'], {
          variant: 'success',
        })
        handleDeleteClose()
      }
    }
  }

  const actions = (id) => [
    {
      name: dictionary['edit'],
      onClick: () => {
        handleEditOpen()
        setIdRow(id)
      },
    },
    {
      name: dictionary['delete'],
      onClick: () => {
        handleDeleteOpen()
        setIdRow(id)
      },
    },
  ]

  const columns = [
    {
      field: 'domain',
      headerName: dictionary['domain'],
      minWidth: 280,
    },
    {
      field: 'fee_value',
      headerName: dictionary['value'],
      minWidth: 160,
      renderCell: (params) => (
        <>
          {params.row.fee_type && params.row.fee_type === "percentage" ? `${params.row.fee_value}%` : params.row.fee_value}

        </>
      ),
    },
    {
      field: 'start_date',
      headerName: dictionary['start_date'],
      minWidth: 240,
    },
    {
      field: 'end_date',
      headerName: dictionary['end_date'],
      minWidth: 240,
    },
    {
      field: 'is_bounded',
      headerName: dictionary['bounded'],
      minWidth: 160,
      renderCell: (param) =>
        param.row.is_bounded ? (
          <CheckIcon />
        ) : (
          <ClearIcon />
        ),
    },
    {
      field: 'lower_bound',
      headerName: dictionary['lower_bound'],
      minWidth: 160,
    },
    {
      field: 'upper_bound',
      headerName: dictionary['upper_bound'],
      minWidth: 160,
    },
    {
      field: 'action',
      headerName: dictionary['actions'],
      flex: 1,
      cellClassName: 'actions_cell',
      width: 24,
      sortable: false,
      renderCell: (params) => <ActionTable actions={actions(params.row.id)} />,
    },
  ]

  if (isError) return <Page404View />


  return (
    <div className={classes.containerTable}>
      <div className={classes.tableNav}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          className={classes.submit}
          onClick={handleFormOpen}
        >
          {!isMobile && dictionary['add_rules']}
        </Button>
      </div>
      <Table
        className={classes.gridContainer}
        rows={data && data.results ? data.results : []}
        loading={isLoading}
        columns={columns}
        page={pageCount}
        handlePageChange={handlePageChange}
        count={data && data.count}
      />
      {(isOpenForm || isEdit) && (
        <ModalForm
          className={classes.modalContainer}
          isEdit={isEdit}
          type={dictionary['rules']}
          onClose={isEdit ? handleEditClose : handleFormClose}
        >
          <ManagerForm
            handleClose={isEdit ? handleEditClose : handleFormClose}
            isEdit={isEdit}
            id={idRow}
            fetchUrl={fetchUrl}
          />
        </ModalForm>
      )}
      {isDelete && (
        <ModalDelete onClose={handleDeleteClose} onDelete={onDelete} />
      )}
    </div>
  )
}
