import React from 'react'

import { useFetchData } from '@api/fetchData'

import MenuItem from '@material-ui/core/MenuItem'

import { Select } from '.'

import { useTranslation } from '@contexts/translation'

export default function MandatesSelect({
  control, error, errorMessage, fullWidth, defaultValue, children
}) {
  const { dictionary } = useTranslation()
  const { data, isLoading, isError } = useFetchData(`/mandates/`)

  if (isError) return <p>{dictionary["failed_to_load_data"]}...</p>

  return (
    <Select
      id="mandate"
      name="mandate"
      label={dictionary["mandate"]}
      control={control}
      variant="outlined"
      margin="normal"
      error={error}
      isLoading={isLoading}
      fullWidth={fullWidth}
      defaultValue={defaultValue}
      errorMessage={errorMessage}
    >
      {children}
      {data &&
        data.results.map((mandate) => {
          return (
            <MenuItem key={mandate.id} value={mandate.id}>
              {mandate.start_date}
            </MenuItem>
          )
        })}
    </Select>
  )
}
