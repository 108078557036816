import React, { useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'

import { Modal } from '.'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'

import { useTranslation } from '@contexts/translation'
import Upload from '@components/files/Upload';
import { onUpdateOperation } from '@api/operations'


const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerButtons: {
    height: 'fit-content',
    display: "flex",
    justifyContent: 'space-around',
    padding: theme.spacing(4),
  },
  container: {
    height: 'fit-content',
    padding: theme.spacing(4)
  },
  buttonCancel: {
    marginRight: theme.spacing(8),
  },
  form: {
    display: 'flex',
    flexDirection: "column",
    zIndex: 99999,
  }
}))

export default function ModalPaidDate({ onClose, fetchUrl, id }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const formRef = useRef(null);
  const [file, setFile] = useState(null)

  const {
    handleSubmit,
  } = useForm({ mode: 'onBlur' })

  const onSubmit = async (data) => {
    setLoading(true)
    const formData = new FormData(formRef.current);
    if (file) {
      formData.append("photo", file.fileData)
    }
    const response = await onUpdateOperation(id, formData)

    if (response) {
      if (response.status === 200) {
        setLoading(false)
        mutate(fetchUrl)
        onClose()
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <Modal className={classes.modalContainer} onClose={onClose}>
      <form ref={formRef} className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.container}>
          <Upload file={file} preview={file && file.preview} setFile={setFile} acceptFiles={'image/jpeg, image/png'} />

          <div className={classes.containerButtons}>
            <Button
              className={classes.buttonCancel}
              onClick={onClose}
              type="button"
              variant="contained"
              color="inherit"
            >
              {dictionary["cancel"]}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              {dictionary["confirm"]}
            </Button>
          </div>
        </Paper>
      </form>
    </Modal>
  )
}
