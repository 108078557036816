import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import clsx from 'clsx'
import Link from 'next/link'
import {Box} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import ChevronDownIcon from '@material-ui/icons/ChevronDown'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useBreakpoints from '@hooks/useBreakpoints'

import { DarkModeSwitch, ModalDelete } from '@components/common'
import { UsersSelect } from '@components/profile'

import { useTranslation } from '@contexts/translation'
import { useFetchData } from '@api/fetchData'
import { generateDemo } from '@api/profile'
import { useRouter } from 'next/router'
import { getOrganization } from '@redux/slices/organizations'
import { onGetOrganizationDetails } from '@api/organizations'

import { RecentActivityDialog } from '@components/common'
import { ActionsTimeline } from '@components/dashboard'
import { useAuth } from '@contexts/auth'
import {
  Typography
} from "@material-ui/core";


const drawerWidth = 260

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    textAlign: 'left',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.primary.main,
    color: 'white',
    transition: theme.transitions.create(['width', 'margin', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    // alignItems: 'flex-end',
    transition: theme.transitions.create(['width', 'margin', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  downArrow: {
    paddingTop: '10px'
  },
  operationSwitch: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'left',
  },
  operationSwitchIcon: {
    borderRadius: '20%',
  },
  appBarMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  menuContainer: {
    display: 'flex',
    // flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  menuContainerMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  containerAvatar: {
    display: 'block',
    width: '24px',
    height: '24px',
    backgroundColor: 'rgba(0, 0, 0, .2)',
    borderRadius: '50%',
    cursor: 'pointer',
    marginLeft: theme.spacing(2),
  },
  noOrganizationContainer: {
    justifyContent: 'flex-end',
  },
  loaderContainer: {
    position: 'absolute',
    height: "100vh",
    width: "100vw",
    backgroundColor: 'white'
  }
}))

export default function Navbar({ open, handleDrawerOpen, isPortal, logout, noSidebar = false }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const router = useRouter()
  const { createDemo, organization } = useAuth()
  const [organizationName, setOrganizationName] = useState('')

  const [anchorEl, setAnchorEl] = useState(null)

  const [anchorElOps, setAnchorElOps] = useState(null)

  // const { organization } = useSelector((state) => state.organizations)
  // const isFinanceEnabled = organization?.finance_enabled

  const [isDemoLoading, setIsDemoLoading] = useState(true)

  const getOrganizationName = async () => {
    const response = await onGetOrganizationDetails(organization)
    if (response.status === 200) {
      setOrganizationName(response.data.display)
    }
  }

  const onGenerateDemo = async () => {
    const response = await generateDemo()
    if (response) {
      setIsDemoLoading(true)
      createDemo()
      handleClose()
    }
  }



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickOps = (event) => {
    setAnchorElOps(event.currentTarget)
  }

  const handleCloseOps = () => {
    setAnchorElOps(null)
  }

  const [isOpenLogout, setIsOpenLogout] = useState(false)

  const handleOpenLogout = () => {
    setIsOpenLogout(true)
  }

  const handleOpenCloseLogout = () => {
    setIsOpenLogout(false)
  }

  const isMobile = useBreakpoints('down', 'sm')

  const fetchUrl = `/organizations/`

  const { data, isLoading, isError } = useFetchData(fetchUrl, null, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  const { data: dataProfile, isLoading: isLoadingProfile, isError: isErrorProfile } = useFetchData('/profiles/profile/', null, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  useEffect(() => {
    getOrganizationName()

    !isLoading && data && dataProfile && dispatch(getOrganization({ data, dataProfile }))
  }, [data, isLoading, dataProfile, operations])

  const { data: operations, isLoading: isLoadingOperations, isError: isErrorOperation } = useFetchData('/operations/?is_archived=false&page_size=999', null, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  const { id: operationId } = router.query;
  const selectedOperation = operations?.results?.filter(op => op.id === Number(operationId))

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar
        className={clsx(classes.toolbar, isMobile && classes.appBarMobile)}
      >
        {isPortal &&
          <Button
            startIcon={<ArrowBackIcon />}
            color="inherit"
            onClick={() => router.back()}
          >
            {dictionary['go_back']}
          </Button>
        }
        {!noSidebar &&
          <IconButton
            edge="start"
            color="inherit"
            aria-label={dictionary['open_drawer']}
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <ChevronRightIcon />
          </IconButton>
        }

      
          <Box className={clsx(classes.operationSwitch)}>
            <IconButton
                className={clsx(classes.operationSwitchIcon)}
                edge="start"
                color="inherit"
                aria-label={dictionary['more']}
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClickOps}
              >
              <Typography>
                {!selectedOperation?.length ? organizationName : selectedOperation[0]?.display}
                <ArrowDropDownIcon className={classes.downArrow} />
              </Typography>
            </IconButton>
            <Menu
                anchorEl={anchorElOps}
                keepMounted
                open={Boolean(anchorElOps)}
                onClose={handleCloseOps}
              >
                {organization && !isErrorOperation && (
                  <>
                    <Link href={`/`} passHref>
                        <MenuItem onClick={handleClose} component="a">
                          {organizationName}
                        </MenuItem>
                    </Link>
                    {operations?.results && operations?.results.map((operation) => (
                      <Link href={`/operations/${operation.id}`} passHref>
                        <MenuItem onClick={handleClose} component="a" selected={true ? operation.id == operationId : false}>
                          {operation.display}
                        </MenuItem>
                      </Link>
                     ))}
                  </>
                )}
            </Menu>
          </Box>
        

        <div
          className={clsx(
            classes.menuContainer,
            isMobile && classes.menuContainerMobile,
            (!organization || isError) && classes.noOrganizationContainer,
          )}
        > 
          {!isMobile &&
            <DarkModeSwitch />
          }
          <RecentActivityDialog
            content={<ActionsTimeline path={`/actions`} />}
          />
          <IconButton
            aria-label={dictionary['more']}
            aria-controls="long-menu"
            aria-haspopup="true"
            color="inherit"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {organization && !isError && (
              <>
                <Link href="/profile" passHref>
                  <MenuItem onClick={handleClose} component="a">
                    {dictionary['settings']}
                  </MenuItem>
                </Link>
                <UsersSelect generateDemo={onGenerateDemo} onOpen={handleClose} />
                <Link href="/actions" passHref>
                  <MenuItem onClick={handleClose} component="a">
                    {dictionary['actions']}
                  </MenuItem>
                </Link>
              </>
            )}
            <MenuItem onClick={handleOpenLogout}>
              {dictionary['logout']}
            </MenuItem>
          </Menu>
        </div>
        {isOpenLogout && (
          <ModalDelete onClose={handleOpenCloseLogout} onDelete={logout} />
        )}
      </Toolbar>
    </AppBar>
  )
}
