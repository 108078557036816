import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack';

import { useFetchData } from '@api/fetchData'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'

import { InvoiceSelect } from "@components/form"

import { useTranslation } from '@contexts/translation'
import { Grid } from '@material-ui/core';
import { onCreatePaymentInvoice, onUpdatePaymentInvoice } from '@api/payments';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
  formRadioGroupContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: theme.spacing(4),
  },
  formCheckbox: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
  },
}))

export default function PaymentsForm({ id, paymentId, isEdit, handleClose, operationId, fetchUrl, paymentFetchUrl }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    setError,
    errors,
    setValue,
    control
  } = useForm({
    mode: 'onBlur',
  })

  const { data, isLoading, isError } = isEdit ? useFetchData('/payments/invoice-payment/', id)
    : {
      data: null,
      isLoading: null,
      isError: null,
    }

  useEffect(() => {
    if (isEdit && data) {
      setValue('invoice', data?.invoice?.id)
      setValue('amount', data?.amount)
    }
  }, [data, isEdit]);

  const onSubmit = async (data) => {
    setLoading(true)
    data['payment'] = Number(paymentId)

    let response = await onCreatePaymentInvoice(data)
    if (response) {
      if (response.status === 201) {
        setLoading(false)
        handleClose()
        enqueueSnackbar(dictionary["payment_created"], {
          variant: "success"
        })
        mutate(fetchUrl)
        mutate(paymentFetchUrl)

      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  const onSubmitEdit = async (data) => {
    setLoading(true)
    let response = await onUpdatePaymentInvoice(id, data)
    if (response) {
      if (response.status === 200) {
        handleClose()
        enqueueSnackbar(dictionary["payment_edited"], {
          variant: "success"
        })
        mutate(fetchUrl)
        mutate(paymentFetchUrl)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(isEdit ? onSubmitEdit : onSubmit)}>

      <Grid container>

        {/* Invoice */}
        <Grid xs={12}>
          <InvoiceSelect
            operationId={operationId}
            control={control}
            defaultValue=''
            error={errors.invoice}
            fullWidth
            filter={'&status=open'}
            helperText={errors.invoice && errors.invoice.message}
          />
        </Grid>

        {/* Amount */}
        <Grid xs="12">
          <TextField
            variant="outlined"
            required
            margin="normal"
            fullWidth
            id="amount"
            label={dictionary["amount"]}
            name="amount"
            type="number"
            inputRef={register}
            error={errors.amount}
            helperText={errors.amount && errors.amount.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid xs="12" className={classes.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
            startIcon={<SaveIcon />}
            className={classes.submit}
          >
            {dictionary["submit"]}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
