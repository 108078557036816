import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'

import { Modal } from '.'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { useTranslation } from '@contexts/translation'
import TextField from '@material-ui/core/TextField'
import { onUpdateInvoice } from '@api/invoices'


const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerButtons: {
    height: 'fit-content',
    display: "flex",
    padding: theme.spacing(4),
  },
  container: {
    height: 'fit-content',
    padding: theme.spacing(4)
  },
  buttonCancel: {
    marginRight: theme.spacing(8),
  },
  form: {
    display: 'flex',
    flexDirection: "column",
    zIndex: 99999,
  }
}))

export default function ModalCode({ onClose, idInvoice, code, fetchUrl }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const formRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar()

  const {
    handleSubmit,
    errors,
    setValue,
    register,
    setError,
  } = useForm({
    mode: 'onBlur', shouldUnregister: false
  })

  useEffect(() => {
    if (code) {
      setValue('code', code)
    }
  }, [code])


  const onSubmit = async (data) => {
    setLoading(true)
    const response = await onUpdateInvoice(idInvoice, data)
    if (response) {
      if (response.status === 200) {
        enqueueSnackbar(dictionary["invoice_updated"], {
          variant: "success"
        })
        mutate(fetchUrl)
        onClose()
      }
      if (response.status === 400) {
        setLoading(false)
        mutate(fetchUrl)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError('code', {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <Modal className={classes.modalContainer} onClose={onClose}>
      <form ref={formRef} className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.container}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="code"
            label={dictionary["invoice_code"]}
            name="code"
            error={errors.code}
            inputRef={register({ maxLength: 25 })}
            helperText={errors.code && errors.code.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div className={classes.containerButtons}>
            <Button
              className={classes.buttonCancel}
              onClick={onClose}
              type="button"
              variant="contained"
              color="inherit"
            >
              {dictionary["cancel"]}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              {dictionary["confirm"]}
            </Button>
          </div>
        </Paper>
      </form>
    </Modal>
  )
}
