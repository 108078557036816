import React from 'react'

import { useFetchData } from '@api/fetchData'

import MenuItem from '@material-ui/core/MenuItem'

import { Select } from '.'
import { Loader } from '@components/common'

import { useTranslation } from '@contexts/translation'

export default function CategoriesSelect({
  control,
  error,
  errorMessage,
  fullWidth,
  defaultValue,
  id,
  children
}) {
  const { dictionary } = useTranslation()
  const { data, isLoading, isError } = useFetchData(
    `/categories/?operation=${id}&page_size=9999`,
  )
  if (isError) return <p>{dictionary["failed_to_load_data"]}...</p>

  return (
    <Select
      id="category"
      name="category"
      label={dictionary["category"]}
      control={control}
      variant="outlined"
      margin="normal"
      isLoading={isLoading}
      error={error}
      fullWidth={fullWidth}
      errorMessage={errorMessage}
      defaultValue={defaultValue}
    >
      {children}
      {data &&
        data.results.map((category) => {
          return (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          )
        })}
    </Select>
  )
}
