import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { useFetchData } from '@api/fetchData'

import { Select } from '.'
import { Loader } from '@components/common'

import { useTranslation } from '@contexts/translation'

export default function FloorSelect({ control, error, errorMessage, building, fullWidth, children }) {
  const { dictionary } = useTranslation()
  const { data, isLoading, isError } = useFetchData(`/floors/?building=${building}`)

  if (isError) return <p>{dictionary["failed_to_load_data"]}...</p>
  return (
    <Select
      id="floor"
      name="floor"
      label={dictionary["floor"]}
      control={control}
      defaultValue={''}
      variant="outlined"
      margin="normal"
      error={error}
      isLoading={isLoading}
      fullWidth={fullWidth}
      errorMessage={errorMessage}
    >
      {children}
      {data &&
        data.results.map((floor) => {
          return (
            <MenuItem key={floor.id} value={floor.id}>
              {floor.name}
            </MenuItem>
          )
        })}
    </Select>
  )
}
