import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from "@contexts/translation";

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  dropZone: {
    outline: 'none',
    display: 'flex',
    overflow: 'hidden',
    textAlign: 'center',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(5, 0),
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('padding'),
    backgroundColor: theme.palette.background.neutral,
    border: `1px dashed ${theme.palette.grey[500_32]}`,
    '&:hover': {
      opacity: 0.72,
      cursor: 'pointer'
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      flexDirection: 'row'
    }
  },
  hasFile: {
    padding: '12% 0'
  },
  isDragActive: {
    opacity: 0.72
  },
  isDragReject: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.light,
    backgroundColor: theme.palette.error.lighter
  },
  isDragAccept: {}
}));

function UploadFile({
  caption,
  error = false,
  file,
  setFile,
  className,
  setName,
  preview,
  acceptFiles,
  ...other
}) {
  const classes = useStyles();
  const { dictionary } = useTranslation();


  const handleDrop = useCallback(
    (acceptedFiles) => {
      const formData = new FormData();
      let file = acceptedFiles[0];
      formData.append('file', acceptedFiles[0], 'new');
      formData.append('type', 'normal')
      if (file) {
        if (setName) {
          setName(file.name)
        }
        setFile({
          ...file,
          fileData: file,
          preview: URL.createObjectURL(file),
          blob: formData
        });
      }
    },
    [setFile]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept
  } = useDropzone({ onDrop: handleDrop, multiple: false, accept: acceptFiles });

  useEffect(
    () => () => {
      if (file) {
        URL.revokeObjectURL(file.preview);
      }
    },
    [file]
  );

  return (
    <div className={clsx(classes.root, className)} {...other}>
      <div
        className={clsx(classes.dropZone, {
          [classes.isDragActive]: isDragActive,
          [classes.isDragAccept]: isDragAccept,
          [classes.isDragReject]: isDragReject || error,
          [classes.hasFile]: file
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />

        <Box
          component="img"
          alt='select file'
          src={preview ? preview : '/static/illustrations/illustration_upload.svg'}
          sx={{ height: 160, maxWidth: '40%' }}
        />

        <Box sx={{ ml: { md: 5 } }}>
          {!file &&
            <Box className={classes.dropZoneText}>
              <Typography gutterBottom variant="h5" className={classes.dropZoneTextItem}>
                {dictionary['drop_or_select_file']}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} className={classes.dropZoneTextItem}>
                {dictionary['drop_file_here_or_click']}&nbsp;
                <Typography
                  variant="body2"
                  component="span"
                  sx={{ color: 'primary.main' }}
                >
                  {dictionary['browse']}
                </Typography>
                &nbsp;{dictionary['thorough_your_machine']}
              </Typography>
            </Box>
          }

          {file?.path &&
            <Typography variant='h3' className={classes.fileName}>
              {dictionary['file']}: {file?.path}
            </Typography>
          }

        </Box>
      </div>
    </div >
  );
}

export default UploadFile;
