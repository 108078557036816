import React, { useState } from 'react'
import clsx from 'clsx'

import { Typography } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { MainListItems } from '@components/dashboard/listItems'
import IconButton from '@material-ui/core/IconButton'
import useBreakpoints from '@hooks/useBreakpoints'

import { useFetchData } from '@api/fetchData'
import { onGetOrganizationDetails } from '@api/organizations'
import Scrollbars from '@components/common/Scrollbars'

const drawerWidth = 260

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    height: 1,
    backgroundColor: 'white',
  },
  overlay: {
    position: 'fixed',
    top: '0',
    right: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, .4)',
    zIndex: '1',
  },
  toolbar: {
    paddingRight: 42, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 20px',
    ...theme.mixins.toolbar,
  },
  toolbarClose: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  account: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'break-spaces',
    padding: theme.spacing(2, 2.5),
    minHeight: '54px',
    margin: theme.spacing(0, 2, 2),
    borderRadius: theme.shape.borderRadiusSm,
    background:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 500_16],
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: '10',
    height: 1,
    minHeight: '100vh',
  },
  infoText: {
    cursor: 'pointer',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(8.5),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  list: {
    // overflowY: 'hidden',
    // overflowX: 'hidden',
    // '&:hover': {
    //   overflowY: 'scroll',
    // },
  },
}))

export default function Sidebar({ open, handleDrawerClose, operationId }) {
  const classes = useStyles()

  const isMobile = useBreakpoints('down', 'sm')
  const [organizationName, setOrganizationName] = useState('')

  const fetchUrl = operationId
    ? `/operations/${operationId}/`
    : '/profiles/profile/'

  const { data, isLoading, isError } = useFetchData(fetchUrl, null, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  const getOrganizationName = async () => {
    if (!operationId && data) {
      const response = await onGetOrganizationDetails(data?.selected_org)
      if (response.status === 200) {
        setOrganizationName(response.data.display)
      }
    }
  }

  const organization = getOrganizationName()

  return (
    <div className={classes.container}>
      {open && (
        <div
          className={clsx(isMobile && classes.overlay)}
          onClick={handleDrawerClose}
        />
      )}
      <Drawer
        variant={!isMobile ? 'permanent' : 'temporary'}
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={open ? classes.toolbarIcon : classes.toolbarClose}>
          <div>Keysy</div>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />

        <Scrollbars>
          <List className={!isMobile && classes.list}>
            <MainListItems open={open} organizationName={organizationName} data={data} operationId={operationId} />
          </List>
        </Scrollbars>
      </Drawer>
    </div>
  )
}
