import React from 'react'

import { useFetchData } from '@api/fetchData'

import MenuItem from '@material-ui/core/MenuItem'

import { Select } from '.'


import { useTranslation } from '@contexts/translation'

export default function CurrenciesSelectOrga({
  className,
  control,
  error,
  errorMessage,
  fullWidth,
  defaultValue,
}) {
  const { dictionary } = useTranslation()
  const { data, isLoading, isError } = useFetchData('/currencies/')
  if (isError) return <p>{dictionary['failed_to_load_data']}...</p>

  return (
    <Select
      className={className}
      id="currency"
      name="currency"
      label={dictionary['currency']}
      control={control}
      variant="outlined"
      margin="normal"
      error={error}
      isLoading={isLoading}
      fullWidth={fullWidth}
      defaultValue={defaultValue ? defaultValue : 0}
      errorMessage={errorMessage}
    >
      {data &&
        data.results.map((currency) => {
          return (
            <MenuItem key={currency.id} value={currency.id}>
              {currency.display} - {currency.symbol}
            </MenuItem>
          )
        })
      }
    </Select >
  )
}
