import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "@contexts/translation";
import { Button, CircularProgress, Container, MenuItem, TextField } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import ClearIcon from "@material-ui/icons/Clear";
import { MobileDateRangePicker } from '@material-ui/lab';
import { onGetOperationList } from "@api/operations";
import { useRouter } from "next/router";
import { onGetTenants, onGetTenantsOperation } from '@api/tenants';
import GetFormattedDate from "@utils/formatDate";
import { unformattDate } from '@utils/formatDate'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2.5),
    paddingTop: theme.spacing(4),
    overflowY: 'auto',
    height: 'calc(100% - 80px)',
    marginTop: theme.spacing(-4)
  },
  margin: {
    marginBottom: theme.spacing(3)
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4)
  }
}));

export default function PaymentsFilterModal({ filterValues, onFiltersApply, tenantId }) {
  const { dictionary } = useTranslation();
  const router = useRouter();
  const { id: operationId } = router.query;
  const [isLoading, setIsLoading] = useState(false);
  const [operations, setOperations] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [selectedOperationId, setSelectedOperationId] = useState(operationId || filterValues.operation || 0);
  const [selectedTenantId, setSelectedTenantId] = useState(filterValues.tenant || 0);
  const [selectedRangeDate, setSelectedRangeDate] = useState([null, null]);

  const classes = useStyles();

  useEffect(() => {
    setIsLoading(true);
    Promise.all([onGetOperationList(), operationId || selectedOperationId ? onGetTenantsOperation(operationId || selectedOperationId) : onGetTenants()])
      .then(response => {
        setOperations(response[0]?.data?.results || []);
        setTenants(response[1]?.data?.results || [])
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleChangeOperationFilter = event => {
    setSelectedOperationId(event.target.value);
  }

  const handleChangeTenantFilter = event => {
    setSelectedTenantId(event.target.value);
  }


  const handleChangeDateRangeFilter = value => {
    setSelectedRangeDate(value)
  }

  useEffect(() => {
    if (filterValues.start_date && !filterValues.end_date) {
      setSelectedRangeDate([unformattDate(filterValues.start_date), null])
    }
    if (!filterValues.start_date && filterValues.end_date) {
      setSelectedRangeDate([null, unformattDate(filterValues.end_date)])
    }
    if (filterValues.start_date && filterValues.end_date) {
      setSelectedRangeDate([unformattDate(filterValues.start_date), unformattDate(filterValues.end_date)])
    }

  }, [filterValues]);



  const handleClearButtonClick = () => {
    setSelectedOperationId(operationId || 0);
    setSelectedTenantId(tenantId || 0);
    setSelectedRangeDate([null, null])
  }

  const handleApplyClick = () => {
    const filter = {};
    if (selectedOperationId) {
      filter.operation = selectedOperationId;
    }
    if (selectedTenantId) {
      filter.tenant = selectedTenantId;
    }
    if (selectedRangeDate) {
      if (selectedRangeDate[0]) filter.start_date = GetFormattedDate(selectedRangeDate[0])
      if (selectedRangeDate[1]) filter.end_date = GetFormattedDate(selectedRangeDate[1])
    }
    onFiltersApply(filter);
  }


  return (
    <Container className={classes.root}>
      <TextField
        select
        fullWidth
        label={dictionary['operation']}
        variant='outlined'
        value={selectedOperationId}
        disabled={operationId || isLoading}
        onChange={handleChangeOperationFilter}
        className={classes.margin}
      >
        <MenuItem value={0}>{dictionary['all_operations']}</MenuItem>
        {operations.map(operation =>
          <MenuItem value={operation.id} key={`operation-${operation.id}`}>
            {operation.name}
          </MenuItem>
        )}
      </TextField>
      <TextField
        select
        fullWidth
        label={dictionary['tenant']}
        variant='outlined'
        disabled={tenantId || isLoading}
        value={selectedTenantId}
        onChange={handleChangeTenantFilter}
      >
        <MenuItem value={0}>{dictionary['all_tenants']}</MenuItem>
        {tenants.map(tenant =>
          <MenuItem value={tenant.id} key={`tenant-${tenant.id}`}>
            {tenant.display}
          </MenuItem>
        )}
      </TextField>
      <MobileDateRangePicker
        inputFormat={'dd/MM/yyyy'}
        value={selectedRangeDate}
        readOnly={isLoading}
        onChange={(newValue) => {
          handleChangeDateRangeFilter(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <>
            <TextField
              {...startProps}
              label={dictionary['start_date']}
              disabled={isLoading}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              margin="normal" />

            <TextField
              {...endProps}
              label={dictionary['end_date']}
              disabled={isLoading}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              margin="normal" />
          </>
        )}
      />
      <div className={classes.actionButtons}>
        {isLoading ?
          <CircularProgress /> :
          <Button
            type='button'
            variant='contained'
            color='primary'
            startIcon={<SaveIcon />}
            onClick={handleApplyClick}
          >
            {dictionary['apply']}
          </Button>
        }
        {((!!selectedOperationId && !operationId) || (!!selectedTenantId && !tenantId) || !!selectedRangeDate) &&
          <Button
            variant='contained'
            color='primary'
            type='button'
            startIcon={<ClearIcon />}
            onClick={handleClearButtonClick}
          >
            {dictionary['clear']}
          </Button>
        }
      </div>
    </Container>
  );
}
