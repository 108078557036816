export const SECTIONS = {
  investor: 'investor',
  operation: 'operation',
};

export const getParam = (tableConfig) =>
  tableConfig.location === SECTIONS.investor
    ? SECTIONS.operation
    : SECTIONS.investor;

export const SHARE_URL = '/investors/share/';
