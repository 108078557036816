import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'

import { Modal } from '.'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { Select } from "@components/form"

import { useFetchData } from '@api/fetchData'

import { useTranslation } from '@contexts/translation'
import { updateLot } from '@api/lots'

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerButtons: {
    height: 'fit-content',
    display: "flex",
    padding: theme.spacing(4),
  },
  container: {
    height: 'fit-content',
    maxWidth: 350,
    padding: theme.spacing(4)
  },
  buttonCancel: {
    marginRight: theme.spacing(8),
  },
  form: {
    display: 'flex',
    flexDirection: "column",
    zIndex: 99999,
  }
}))

export default function ModalPaidDate({ onClose, fetchUrl, operationId, lots, id }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()


  const lotsUrl = `/lots/?operation=${operationId}&common_type=linked&page_size=999`
  const { data, isLoading, isError } = useFetchData(lotsUrl)


  const {
    handleSubmit,
    errors,
    setValue,
    register,
    control,
    setError
  } = useForm({
    mode: 'onBlur',
    shouldUnregister: false
  })

  const lotsId = () => {
    let lotsArray = []
    data && data.results && data.results.length > 1 && data.results.map(lot => {
      lotsArray.push(lot.id)
      return lotsArray
    })
    return lotsArray
  }

  const commonLotsIdArray = lotsId()

  const getLots = (lots) => lots.filter(lot => lot.id).map(lot => lot.id)

  useEffect(() => {
    if (lots) {
      setValue('common_lots', getLots(lots))
    }
  }, [lots]);

  const onSubmit = async (data) => {
    setLoading(true)

    const response = await updateLot(data, id)

    if (response) {
      if (response.status === 200) {
        enqueueSnackbar(dictionary[`lot_edited`], {
          variant: "success"
        })
        mutate(fetchUrl)
        onClose()
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <Modal className={classes.modalContainer} onClose={onClose}>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.container}>
          <Select
            variant="outlined"
            required
            margin="normal"
            fullWidth
            isLoading={isLoading}
            id="common_lots"
            label={dictionary["linked_lots"]}
            name="common_lots"
            inputRef={register}
            error={errors.common_lots}
            control={control}
            helperText={errors.common_lots && errors.common_lots.message}
            multiple={true}
            arrayValue={commonLotsIdArray}
            defaultValue={[]}
            MenuProps={{
              getContentAnchorEl: () => null,
            }}
          >
            {data && data.results && data.results?.map(lot => (
              <MenuItem key={lot?.id} value={lot?.id}>{lot?.name}</MenuItem>
            ))}
          </Select>
          <div className={classes.containerButtons}>
            <Button
              className={classes.buttonCancel}
              onClick={onClose}
              type="button"
              variant="contained"
              color="inherit"
            >
              {dictionary["cancel"]}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              {dictionary["confirm"]}
            </Button>
          </div>
        </Paper>
      </form>
    </Modal>
  )
}
