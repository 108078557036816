import isBefore  from 'date-fns/isBefore'

const today = new Date()

export const transformMetricValues = (metrics, key) => {
    if (metrics && metrics[key] && metrics[key].operations && metrics[key].operations.length > 0 && metrics?.[key]?.operations[0]?.time_series) {
        return metrics[key].operations[0].time_series.filter(({ date }) => isBefore(new Date(date), today)).map(({ value }) => value)
    }
    return []
}
export const transformMetricValuesForOperation = (metrics, key) => metrics?.[key]?.time_series?.filter(({ date }) => isBefore(new Date(date), today)).map(({ value }) => value)

export const transformMetricDataForOperation= (metrics, key) => metrics?.[key]?.time_series?.filter(({ date }) => isBefore(new Date(date), today))
export const transformMetricData = (metrics, key) => {
    if (metrics && metrics[key] && metrics[key].operations && metrics[key].operations.length > 0 && metrics?.[key]?.operations[0]?.time_series) {
        return metrics[key].operations[0].time_series.filter(({ date }) => isBefore(new Date(date), today))
    }
    return []
}
