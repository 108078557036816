import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack';
import Router from 'next/router'
import { differenceInCalendarQuarters, getQuarter, getYear, lastDayOfQuarter, getDate, getMonth } from "date-fns";


import { useFetchData } from '@api/fetchData'
import { onCreateInvoice, onUpdateInvoice } from '@api/invoices'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'
import { Grid, MenuItem } from '@material-ui/core'

import GetFormattedDate, { getFormattedDateRange } from '@utils/formatDate'
import UnformatDate from '@utils/unformatDate'
import AddIcon from '@material-ui/icons/Add';

import { DatePicker, LeasesSelect, OperationsSelect, Select, DateRangePicker, BankSelect } from '@components/form'

import { useTranslation } from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
  formRadioGroupContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: theme.spacing(4),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  formCheckbox: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
  },
}))

export default function InvoicesForm({ handleClose, tenantId, isEdit, id, operationId, fetchUrl, isOperation, leaseId }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [lease, setLease] = useState(null)
  const [startLeaseDate, setStartLeaseDate] = useState(null)
  const [endLeaseDate, setEndLeaseDate] = useState(null)
  const [periodStartDate, setPeriodStartDate] = useState(null)
  const [periodEndDate, setPeriodEndDate] = useState(null)
  const [quarterSelect, setQuarterSelect] = useState([])
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    setError,
    errors,
    control,
    setValue,
    watch
  } = useForm({
    mode: 'onBlur',
    shouldUnregister: false,
    defaultValues: {
      invoice_date: new Date(),
    }
  })


  const watchOperation = watch('operation')
  const watchPeriod = watch('periods')

  const { data, isLoading, isError } = isEdit
    ? useFetchData('/invoices/', id)
    : {
      data: null,
      isLoading: null,
      isError: null,
    }
  // TODO replace with basic axios
  let leaseFilter = lease ? `${lease}/` : ''
  const { data: dataLease } = useFetchData(`/leases/${leaseFilter}`, null, { shouldRetryOnError: false })

  useEffect(() => {
    if (isEdit && data) {
      if (data?.invoice_date) setValue('invoice_date', UnformatDate(data?.invoice_date))
      setValue('tax_rate', data?.tax_rate)
    }
  }, [data, isEdit]);

  useEffect(() => {
    if (leaseId) {
      setLease(leaseId)
    }
  }, [leaseId]);


  useEffect(() => {
    if (lease) {
      setQuarterSelect([])
      setValue('periods', '')
    }
  }, [lease]);



  useEffect(() => {
    if (dataLease) {
      setQuarterSelect([])
      getLeaseDate(dataLease)
      setValue('tax_rate', dataLease?.last_invoice?.tax_rate)
      setValue('language', dataLease?.last_invoice?.language)
      setValue('billing_option', dataLease?.last_invoice?.billing_option)
      setValue('references', dataLease?.last_invoice?.references)
      setValue('bank', dataLease?.last_invoice?.bank)
      // setValue('period_start_date', dataLease?.last_invoice?.period_start_date)
      // setValue('period_end_date', dataLease?.last_invoice?.period_end_date)
    }
  }, [dataLease]);

  //TODO : Replace by switch case
  const getQuarterMonth = quarter => {
    if (quarter === 1) {
      return '01'
    }
    if (quarter === 2) {
      return '04'
    }
    if (quarter === 3) {
      return '07'
    }
    if (quarter === 4) {
      return '10'
    }
  }

  const formatMonth = month => {
    if (month + 1 < 10) {
      return `0${month + 1}`
    }
    else {
      return month + 1
    }
  }

  useEffect(() => {
    if (watchPeriod === 0 || watchPeriod) {
      if (quarterSelect[watchPeriod].quarter) {
        const quarter = quarterSelect[watchPeriod].quarter
        const year = quarterSelect[watchPeriod].year
        const startPeriod = `01/${getQuarterMonth(quarter)}/${year}`
        const startDate = new Date(`${getQuarterMonth(quarter)}/01/${year}`)
        const endDay = lastDayOfQuarter(startDate)
        const endPeriod = `${getDate(endDay)}/${formatMonth(getMonth(endDay))}/${year}`
        setPeriodStartDate(startPeriod)
        setPeriodEndDate(endPeriod)
        setValue('period_start_date', startPeriod)
        setValue('period_end_date', endPeriod)
      }
      else {
        const year = quarterSelect[watchPeriod].year
        const startPeriod = `01/01/${year}`
        const endPeriod = `31/12/${year}`
        setPeriodStartDate(startPeriod)
        setPeriodEndDate(endPeriod)
        setValue('period_start_date', startPeriod)
        setValue('period_end_date', endPeriod)
      }

    }
  }, [watchPeriod]);

  useEffect(() => {
    if (startLeaseDate && endLeaseDate) {
      const startDate = new Date(UnformatDate(startLeaseDate))
      const endDate = new Date(UnformatDate(endLeaseDate))
      const getCurrentQuarter = getQuarter(startDate)
      const diffQuarter = differenceInCalendarQuarters(endDate, startDate)
      const diffWithToday = differenceInCalendarQuarters(endDate, new Date())

      quarterSelect.push({
        quarter: getCurrentQuarter === 1 ? 4 : getCurrentQuarter - 1,
        year: getCurrentQuarter === 1 ? getYear(startDate) - 1 : getYear(startDate)
      })

      let startYear = getYear(startDate)
      for (let i = 1; i < diffWithToday + 1; i++) {

        if (quarterSelect[i - 1].quarter === 4) {
          startYear = startYear + 1;
        }

        setQuarterSelect([...quarterSelect])

        quarterSelect.push({
          year: startYear,
          quarter:
            quarterSelect[i - 1].quarter === 4
              ? 1
              : quarterSelect[i - 1].quarter + 1
        })
      }

      const startYearQuarter = getYear(startDate)
      let newQuarter = []

      const addYear = quarterSelect && quarterSelect.length > 0 && quarterSelect.map((quarter, index) => {
        if (index === 0) {
          newQuarter.push({
            year: startYearQuarter
          })
        }
        if (quarter.year !== startYearQuarter && quarter.quarter === 1) {
          newQuarter.push({
            year: quarter.year
          })
          newQuarter.push(quarter)

        }
        else {
          newQuarter.push(quarter)
        }
      })

      setQuarterSelect([...newQuarter])
    }
  }, [startLeaseDate, endLeaseDate]);

  const onSubmit = async (data) => {
    setLoading(true)
    if (isOperation) data['operation'] = Number(operationId)
    //Type 1 => Invoice (force type)
    data['type'] = 1
    if (data.invoice_date) data["invoice_date"] = GetFormattedDate(data.invoice_date)

    if (data.period_start_date && data.period_end_date) {
      data['period_start_date'] = getFormattedDateRange(data.period_start_date)
      data['period_end_date'] = getFormattedDateRange(data.period_end_date)
    }

    const response = await onCreateInvoice(data)
    if (response) {
      if (response.status === 201) {
        enqueueSnackbar(dictionary["invoice_created"], {
          variant: 'success'
        })
        Router.push(`/operations/${response.data.operation.id}/invoices/${response.data.id}/`)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  const getLeaseDate = (data) => {
    setStartLeaseDate(data.start_date)
    setEndLeaseDate(data.end_date)
  }

  const getLeaseId = (id) => {
    setLease(id)
  }

  const formatQuarter = (quarter, year) => {
    return `Q${quarter} ${year}`
  }

  const onSubmitEdit = async (data) => {
    setLoading(true)
    data['operation'] = Number(operationId)
    if (data.invoice_date) data["invoice_date"] = GetFormattedDate(data.invoice_date)

    const response = await onUpdateInvoice(id, data)
    if (response) {
      if (response.status === 200) {
        handleClose()
        enqueueSnackbar(dictionary["invoice_updated"], {
          variant: 'success'
        })
        mutate(fetchUrl)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(isEdit ? onSubmitEdit : onSubmit)}>
      <Grid container>
        {/* Operation */}

        {!isOperation &&
          <Grid xs="12" className={classes.center}>
            <OperationsSelect
              fullWidth
              control={control}
              error={errors.operation}
              helperText={errors.operation && errors.operation.message}
              defaultValue=''
            />
          </Grid>

        }

        {/* Leases */}
        {((!isOperation && watchOperation) || isOperation) &&
          <>
            <Grid xs="12" className={classes.center}>
              <LeasesSelect
                id={operationId ? operationId : watchOperation}
                fullWidth
                disabled={leaseId}
                control={control}
                error={errors.lease}
                onChange={getLeaseId}
                tenantId={tenantId}
                helperText={errors.lease && errors.lease.message}
                defaultValue={leaseId ? leaseId : ''}
              />
            </Grid>

            {/* Invoice date */}
            <Grid xs="12">
              <DatePicker
                id="invoice_date"
                name="invoice_date"
                required
                label={dictionary["invoice_date"]}
                variant="outlined"
                margin="normal"
                fullWidth
                control={control}
                error={errors.invoice_date}
                errorMessage={errors.invoice_date && errors.invoice_date.message}
                clearable={true}
              />
            </Grid>


            {/* Tax rate */}
            <Grid xs="12">
              <TextField
                required
                type="number"
                variant="outlined"
                margin="normal"
                fullWidth
                id="tax_rate"
                label={dictionary["tax_rate"]}
                name="tax_rate"
                inputRef={register}
                error={errors.tax_rate}
                helperText={errors.tax_rate && errors.tax_rate.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            {/* Billing Options */}
            <Grid item xs="12">
              <Select
                id="billing_option"
                name="billing_option"
                label={dictionary["billing_option"]}
                control={control}
                defaultValue={''}
                variant="outlined"
                margin="normal"
                error={errors.billing_option}
                fullWidth
                errorMessage={errors.billing_option && errors.billing_option.message}
              >
                <MenuItem value="30_days">{dictionary["30_days"]}</MenuItem>
                <MenuItem value="45_days">{dictionary["45_days"]}</MenuItem>
                <MenuItem value="60_days">{dictionary["60_days"]}</MenuItem>
                <MenuItem value="75_days">{dictionary["75_days"]}</MenuItem>
                <MenuItem value="90_days">{dictionary["90_days"]}</MenuItem>
                <MenuItem value="end_of_month">{dictionary["end_of_month"]}</MenuItem>
                <MenuItem value="on_reception">{dictionary["on_reception"]}</MenuItem>
                <MenuItem value="due_on">{dictionary["due_on"]}</MenuItem>
                <MenuItem value="free">{dictionary["free"]}</MenuItem>
              </Select>
            </Grid>


            {/* Language  */}
            <Grid item xs="12">
              <Select
                id="language"
                name="language"
                label={dictionary["lang"]}
                control={control}
                defaultValue={''}
                variant="outlined"
                margin="normal"
                error={errors.language}
                fullWidth
                errorMessage={errors.language && errors.language.message}
              >
                <MenuItem value="en">{dictionary["english"]}</MenuItem>
                <MenuItem value="fr">{dictionary["french"]}</MenuItem>
              </Select>
            </Grid>


            <Grid xs='12'>
              <BankSelect
                control={control}
                error={errors.bank}
                errorMessage={errors.bank && errors.bank.message}
                fullWidth
                defaultValue={''}
              >
                <MenuItem key={293983} value={null} onClick={() => setOpen(true)}>
                  <AddIcon /> {dictionary["add_a_bank"]}
                </MenuItem>
              </BankSelect>
            </Grid>


            {/* References  */}
            <Grid xs="12">
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="references"
                label={dictionary["references"]}
                name="references"
                inputRef={register}
                error={errors.references}
                helperText={errors.references && errors.references.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            {/* Periods */}
            <Grid item xs="12">
              <Select
                id="periods"
                name="periods"
                label={dictionary["invoice_period"]}
                control={control}
                variant="outlined"
                defaultValue={''}
                margin="normal"
                error={errors.periods}
                fullWidth
                errorMessage={errors.periods && errors.periods.message}
              >
                {quarterSelect && quarterSelect.map((quarter, index) => {
                  return (
                    <MenuItem key={index} value={index}>{quarter.quarter ? formatQuarter(quarter.quarter, quarter.year) : quarter.year}</MenuItem>
                  )
                })}
              </Select>
            </Grid>

            <Grid xs="12">
              <DateRangePicker
                idStart="period_start_date"
                idEnd="period_end_date"
                nameStart="period_start_date"
                nameEnd="period_end_date"
                labelStart={dictionary["start_date"]}
                labelEnd={dictionary["end_date"]}
                variant="outlined"
                margin="normal"
                register={register}
                startDate={periodStartDate}
                endDate={periodEndDate}
                errorStart={errors.period_start_date}
                errorMessageStart={errors.period_start_date && errors.period_start_date.message}
                errorEnd={errors.period_end_date}
                errorMessageEnd={errors.period_end_date && errors.period_end_date.message}
              />
            </Grid>
          </>
        }



        <Grid xs="12" className={classes.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
            startIcon={<SaveIcon />}
            className={classes.submit}
          >
            {dictionary["submit"]}
          </Button>
        </Grid>
      </Grid>
    </form >
  )
}
