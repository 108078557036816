import clsx from 'clsx'
import React from 'react'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import { fPercent } from '@utils/formatNumber'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Typography, Box, Card, CardContent } from '@material-ui/core'
import { useTranslation } from '@contexts/translation'
import { numberWithSpaceInt } from '@utils/numberWithSpaces'
import { useFetchData } from '@api/fetchData'

const useStyles = makeStyles((theme, props) => ({
  content: {
    padding: `0 ${theme.spacing(3)}`,
  },
  information: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  title: {
    fontWeight: 700,
  },
  trending: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
  },
  trendingIcon: {
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    color: '#7FC3B1',
    backgroundColor: 'rgba(127, 195, 177, 0.2)',
  },
  isTrendingDown: {
    color: theme.palette.error.main,
    backgroundColor: 'rgba(237, 125, 120, 0.2)',
  },
  trendContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  trendIncome: {
    marginTop: theme.spacing(2),
    fontSize: '32px',
    fontWeight: '700',
    '& span': {
      fontSize: '12px',
    },
  },
  incomeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  income: {
    '& h6': {
      fontSize: '20px',
      fontWeight: '700',
    },
  },
  incomeRevenue: {
    marginBottom: theme.spacing(3),
    '& p': {
      fontSize: '18px',
      color: '#7FC3B1',

      '& span': {
        fontSize: '12px',
      },
    },
  },
  incomeExpenses: {
    '& p': {
      fontSize: '18px',
      color: '#ED7D78',
      '& span': {
        fontSize: '12px',
      },
    },
  },
  marginContainer: {
    height: '100%'
  },
  progress: {},
  progressTitle: {
    color: theme.palette.grey[500],
    opacity: 0.8,
    marginBottom: theme.spacing(3),
  },
  progressContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  progressContentTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  bar: {
    position: 'relative',
    width: '100%',
    height: '8px',
    borderRadius: '50rem',
  },
  barProgress: {
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'inline-block',
    height: '8px',
    borderRadius: '50rem',
  },
  barRevenue: {
    backgroundColor: 'rgba(61, 130, 159, 0.2)',
  },
  barExpenses: {
    backgroundColor: 'rgba(237, 125, 120, 0.2)',
  },
  barIncome: {
    backgroundColor: 'rgba(127, 195, 177, 0.2)',
  },
}))

function MarginLine({ className, operationId, year }) {
  const classes = useStyles()
  const theme = useTheme()
  const { dictionary } = useTranslation()

  const PERCENT = 1.2

  const { data, isLoading, isError } = useFetchData(`/stats/indicators/financials/operation/${operationId}/?year=${year}`, null, { revalidateOnFocus: false, revalidateOnReconnect: false })

  return (
    <Card className={classes.marginContainer}>
      <CardContent className={classes.content}>
        <Box className={classes.information}>
          <div className={classes.trendContainer}>
            <Typography className={classes.title} variant="h6">
              {dictionary.gross_margin}
            </Typography>
            {/*          <div className={classes.trending}>
              <div
                className={clsx(classes.trendingIcon, {
                  [classes.isTrendingDown]: PERCENT < 0,
                })}
              >
                {PERCENT >= 0 ? (
                  <TrendingUpIcon style={{ width: '16px', height: '16px' }} />
                ) : (
                  <TrendingDownIcon style={{ width: '16px', height: '16px' }} />
                )}
              </div>
              <Typography
                variant="h6"
                color={PERCENT >= 0 ? '#7FC3B1' : 'error'}
              >
                {PERCENT > 0 && '+'}
                {fPercent(PERCENT)}
              </Typography>
            </div> */}

            <Typography className={classes.trendIncome} variant={'h5'}>
              {data && numberWithSpaceInt(data.income_actual)} <span>€</span>
            </Typography>
          </div>

          <div className={classes.incomeContainer}>
            <div className={clsx(classes.income, classes.incomeRevenue)}>
              <Typography variant={'h6'}>
                {dictionary['revenue']}
              </Typography>
              {data && (
                <Typography>
                  {numberWithSpaceInt(data.revenue_actual)}{' '}
                  <span>€</span>
                </Typography>
              )}
            </div>
            <div className={clsx(classes.income, classes.incomeExpenses)}>
              <Typography variant={'h6'}>
                {dictionary['expenses']}
              </Typography>
              {data && (
                <Typography>
                  {numberWithSpaceInt(data.expenses_actual)}{' '}
                  <span>€</span>
                </Typography>
              )}
            </div>
          </div>
        </Box>

        <Box className={classes.progress}>
          <Typography className={classes.progressTitle} variant="h6">
            {dictionary.projected}
          </Typography>
          <div>
            <div className={classes.progressContent}>
              <div className={classes.progressContentTitle}>
                <Typography variant="h6">{dictionary.revenue}</Typography>
                {data && (
                  <Typography>
                    {numberWithSpaceInt(data.revenue_total)}€
                  </Typography>
                )}
              </div>
              <div className={clsx(classes.bar, classes.barRevenue)}>
                {data &&
                  <span
                    className={classes.barProgress}
                    style={{
                      width: `${data.revenue_progress}%`,
                      background: '#3D829F',
                    }}
                  />
                }
              </div>
            </div>
            <div className={classes.progressContent}>
              <div className={classes.progressContentTitle}>
                <Typography variant={'h6'}>{dictionary.expenses}</Typography>
                {data && (
                  <Typography>
                    {numberWithSpaceInt(data.expenses_total)}€
                  </Typography>
                )}
              </div>
              <div className={clsx(classes.bar, classes.barExpenses)}>
                {data &&
                  <span
                    className={classes.barProgress}
                    style={{
                      width: `${data.expenses_progress}%`,
                      background: '#ED7D78',
                    }}
                  />
                }
              </div>
            </div>
            <div className={classes.progressContent}>
              <div className={classes.progressContentTitle}>
                <Typography variant="h6">{dictionary.margin}</Typography>
                {data && (
                  <Typography>
                    {numberWithSpaceInt(data.income_total)}€
                  </Typography>
                )}
              </div>
              <div className={clsx(classes.bar, classes.barIncome)}>
                {data &&
                  <span
                    className={classes.barProgress}
                    style={{
                      width: `${data.income_progress}%`,
                      background: '#7FC3B1',
                    }}
                  />}{' '}
              </div>
            </div>
          </div>
        </Box>
      </CardContent>
    </Card>
  )
}

export default MarginLine
