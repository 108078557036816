import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'

import { Modal } from '.'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { MenuItem } from '@material-ui/core'
import { Select } from '@components/form'

import { useTranslation } from '@contexts/translation'
import { useFetchData } from '@api/fetchData'
import { editUser } from '@api/operations';


const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerButtons: {
    height: 'fit-content',
    display: "flex",
    padding: theme.spacing(4),
  },
  container: {
    height: 'fit-content',
    padding: theme.spacing(4)
  },
  buttonCancel: {
    marginRight: theme.spacing(8),
  },
  form: {
    display: 'flex',
    flexDirection: "column",
    zIndex: 99999,
  }
}))

export default function ModalEditRole({ onClose, id, operationId }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const formRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar()

  const {
    handleSubmit,
    errors,
    setValue,
    control
  } = useForm({ mode: 'onBlur' })

  const { data: prevData, isLoading, isError } = useFetchData(`/operations/users/`, id)

  useEffect(() => {
    if (prevData) {
      setValue('role', prevData.role)
    }
  }, [prevData]);

  const onSubmit = async (data) => {
    setLoading(true)
    data['orguser'] = prevData.orguser
    data['operation'] = operationId
    const response = await editUser(id, data)
    if (response) {
      if (response.status === 200) {
        enqueueSnackbar(dictionary["role_edited"], {
          variant: "success"
        })
        onClose()
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <Modal className={classes.modalContainer} onClose={onClose}>
      <form ref={formRef} className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.container}>
          <Select
            fullWidth
            id="role"
            name="role"
            label={dictionary['role']}
            control={control}
            defaultValue={''}
            variant="outlined"
            margin="normal"
            error={errors.type}
            errorMessage={errors.type && errors.type.message}
          >
            <MenuItem value="tenant">
              {dictionary['tenant']}
            </MenuItem>
            <MenuItem value="supplier">
              {dictionary['supplier']}
            </MenuItem>
            <MenuItem value="owner">
              {dictionary['owner']}
            </MenuItem>
            <MenuItem value="manager">
              {dictionary['manager']}
            </MenuItem>
            <MenuItem value="accountant">
              {dictionary['accountant']}
            </MenuItem>
          </Select>

          <div className={classes.containerButtons}>
            <Button
              className={classes.buttonCancel}
              onClick={onClose}
              type="button"
              variant="contained"
              color="inherit"
            >
              {dictionary["cancel"]}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              {dictionary["confirm"]}
            </Button>
          </div>
        </Paper>
      </form>
    </Modal>
  )
}
