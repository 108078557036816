import React from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import { Tooltip } from '@material-ui/core'
const ReactApexChart = dynamic(() => import('react-apexcharts'), { ssr: false })
import { numberWithSpaceInt } from '@utils/numberWithSpaces'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from '@contexts/translation'

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => { })

function BudgetRadialChart({ className, operationId, total, expenses, budget, ...other }) {
  const classes = useStyles()
  const theme = useTheme()
  const { dictionary } = useTranslation()

  const series = [Math.round(total * 100) / 100 || 0]

  const options = {
    chart: {
      height: 100,
    },

    fill: {
      colors: [theme.palette.secondary.main],
    },

    plotOptions: {
      radialBar: {
        hollow: {
          size: '40%',
        },

        dataLabels: {
          showOn: 'always',
          value: {
            color: theme.palette.primary.main,
            fontSize: '14px',
            show: true,
            offsetY: -12,
          },
        },
      },
    },

    stroke: {
      lineCap: 'round',
    },
    labels: [''],
  }

  return (
    <Tooltip
      enterTouchDelay={200}
      title={
        <>
          {dictionary["actual"]} : {expenses ? numberWithSpaceInt(expenses) : 0}€
          <br />
          {dictionary["budgeted"]} : {budget ? numberWithSpaceInt(budget) : 0}€
        </>
      }
      placement="top"
    >
      <span>
        <ReactApexChart
          type="radialBar"
          series={series}
          options={options}
          height={100}
          className={classes.chart}
        />
      </span>
    </Tooltip>
  )
}

BudgetRadialChart.propTypes = {
  className: PropTypes.string,
}

export default BudgetRadialChart
