import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Menu, Typography, CircularProgress } from '@material-ui/core';
import { MButton } from 'theme';
import CheckIcon from '@material-ui/icons/Check';
import { useTranslation } from '@contexts/translation'
import Router from 'next/router'
import { ModalDelete, ModalForm } from '.';
import useBreakpoints from '@hooks/useBreakpoints'
import { HistoryTimeline, InvoiceSettingsForm, InvoicePaymentsDetails } from '@components/invoices';
import { onDownloadInvoicePDF, onGetInvoicePDF } from '@api/invoices';
import ModalCode from './ModalCode';




// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  invoiceBalance: {
    marginRight: theme.spacing(1)
  },
  main_action: {
    display: 'flex',
    flexGrow: '1',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginLeft: 0
  },
  secondary_action: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: "wrap",
    rowGap: theme.spacing(1),
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },

}));

// ----------------------------------------------------------------------

Toolbar.propTypes = {
  className: PropTypes.string
};

function Toolbar({ isScheduled, isSent, tenantId, isCompany, isFree, code, status, loading, subtotal, taxes, className, handleSend, invoiceId, handleValidate, handleDelete, handleCancel, handlePay, operationId, fetchUrl, tenant, amount, balance, onUnschedule, ...other }) {
  const classes = useStyles();
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [payOpen, setPayOpen] = useState(false)
  const [codeOpen, setCodeOpen] = useState(false)
  const [historyOpen, setHistoryOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [cancelOpen, setCancelOpen] = useState(false)
  const [validateOpen, setValidateOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { dictionary } = useTranslation()

  const isMobile = useBreakpoints('down', 'sm')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenHistory = () => {
    setHistoryOpen(true)
  }

  const handleCloseHistory = () => {
    setHistoryOpen(false)
    handleClose()
  }

  const handleOpenCode = () => {
    setCodeOpen(true)
  }

  const handleCloseCode = () => {
    setCodeOpen(false)
    handleClose()
  }


  const handleOpenPay = () => {
    setPayOpen(true)
  }

  const handleClosePay = () => {
    setPayOpen(false)
    handleClose()
  }

  const handleOpenDelete = () => {
    setDeleteOpen(true)
  }

  const handleCloseDelete = () => {
    setDeleteOpen(false)
    handleClose()
  }


  const handleOpenCancel = () => {
    setCancelOpen(true)
  }

  const handleCloseCancel = () => {
    setCancelOpen(false)
    handleClose()
  }

  const handleOpenValidate = () => {
    setValidateOpen(true)
  }

  const handleCloseValidate = () => {
    setValidateOpen(false)
    handleClose()
  }

  const handlePreview = async () => {
    setIsLoading(true)
    handleClose()
    const response = await onGetInvoicePDF(invoiceId)
    if (response) {
      setIsLoading(false)
    }
  };

  const handleExport = async () => {
    setIsLoading(true)
    handleClose()
    const response = await onDownloadInvoicePDF(invoiceId, code)
    if (response) {
      setIsLoading(false)
    }
  }

  const handleOpenSettings = () => {
    setSettingsOpen(true)
  }

  const handleCloseSettings = () => {
    setSettingsOpen(false)
    handleClose()
  }

  useEffect(() => {
    if (loading === false) {
      handleCloseCancel()
      handleCloseValidate()
    }
  }, [loading])



  const goToSend = () => {
    Router.push(`/operations/${operationId}/invoices/${invoiceId}/send`)
  }


  const INVOICE_ACTIONS = [
    (status === "not_paid" || status === "late") && {
      id: 'cancel',
      name: dictionary['mark_unsent'],
      onClick: () => handleSend(),
    },
    isSent && {
      id: 'payments',
      name: dictionary['payments'],
      onClick: () => handleOpenPay(),
    },
    {
      id: 'preview',
      name: dictionary['preview'],
      onClick: () => handlePreview(),
    },
    {
      id: 'export',
      name: dictionary['export'],
      onClick: () => handleExport()
    },
    {
      id: 'history',
      name: dictionary['history'],
      onClick: () => handleOpenHistory(),

    },
    {
      id: 'code',
      name: dictionary['edit_code'],
      onClick: () => handleOpenCode(),
    },
    {
      id: 'settings',
      name: dictionary['settings'],
      onClick: () => handleOpenSettings(),
    },
    status === "not_valid" && {
      id: 'delete',
      name: dictionary['delete'],
      onClick: () => handleOpenDelete(),
    },
    (status === "not_sent" || status === "paid" || status === "not_paid" || status === "partial" || status === "late") && {
      id: 'cancel',
      name: dictionary['cancel'],
      onClick: () => handleOpenCancel(),
    }
  ]

  return (
    <div className={clsx(classes.root, className)} {...other}>

      {isMobile ? (
        <>
          <div className={classes.main_action}>
            <Typography className={classes.invoiceBalance} variant="body1">{dictionary["invoice_balance"]}: {balance}</Typography>

            <MButton
              variant="contained"
              color="inherit"
              aria-controls="actions"
              aria-haspopup="true"
              disabled={isLoading}
              size="small"
              onClick={handleClick}
            >
              {isLoading ? <CircularProgress size={22} /> : dictionary["actions"]}
            </MButton>
          </div>
          <div className={classes.secondary_action}>
            {status === "not_valid" &&
              <MButton
                color="grey"
                size="small"
                disabled={loading}
                variant="contained"
                onClick={() => handleOpenValidate()}
                endIcon={<CheckIcon />}

              >
                {dictionary["validate"]}
              </MButton>
            }
            {(status === "not_paid" || status === "partial" || status === "late") &&
              <MButton
                color="grey"
                size="small"
                disabled={loading}
                variant="contained"
                onClick={() => handleOpenPay()}
                endIcon={<CheckIcon />}

              >
                {dictionary["pay"]}
              </MButton>
            }
            {status === "not_sent" &&
              <MButton
                color="grey"
                size="small"
                disabled={loading}
                variant="contained"
                onClick={() => goToSend()}
                endIcon={<CheckIcon />}

              >
                {dictionary["send"]}
              </MButton>
            }
            {status === "not_sent" && isScheduled &&
              <MButton
                color="grey"
                size="small"
                disabled={loading}
                variant="contained"
                onClick={onUnschedule}
                endIcon={<CheckIcon />}

              >
                {dictionary["unschedule"]}
              </MButton>
            }
            {status === "not_sent" &&
              <MButton
                color="grey"
                size="small"
                disabled={loading}
                variant="contained"
                onClick={handleSend}
                endIcon={<CheckIcon />}

              >
                {dictionary["mark_sent"]}
              </MButton>
            }
            {status === "canceled" &&
              <MButton
                color="grey"
                size="small"
                disabled={loading}
                variant="contained"
                onClick={() => handleOpenCancel()}
                endIcon={<CheckIcon />}

              >
                {dictionary['uncancel']}
              </MButton>
            }
          </div>
        </>

      )
        :
        (
          <>      <Typography className={classes.invoiceBalance} variant="body1">{dictionary["invoice_balance"]}: {balance}</Typography>
            {status === "not_valid" &&
              <MButton
                color="grey"
                size="small"
                disabled={loading}
                variant="contained"
                onClick={() => handleOpenValidate()}
                endIcon={<CheckIcon />}

              >
                {dictionary["validate"]}
              </MButton>
            }
            {(status === "not_paid" || status === "partial" || status === "late") &&
              <MButton
                color="grey"
                size="small"
                disabled={loading}
                variant="contained"
                onClick={() => handleOpenPay()}
                endIcon={<CheckIcon />}

              >
                {dictionary["pay"]}
              </MButton>
            }
            {status === "not_sent" &&
              <MButton
                color="grey"
                size="small"
                disabled={loading}
                variant="contained"
                onClick={() => goToSend()}
                endIcon={<CheckIcon />}

              >
                {dictionary["send"]}
              </MButton>
            }
            {status === "not_sent" && isScheduled &&
              <MButton
                color="grey"
                size="small"
                disabled={loading}
                variant="contained"
                onClick={onUnschedule}
                endIcon={<CheckIcon />}

              >
                {dictionary["unschedule"]}
              </MButton>
            }
            {status === "not_sent" &&
              <MButton
                color="grey"
                size="small"
                disabled={loading}
                variant="contained"
                onClick={handleSend}
                endIcon={<CheckIcon />}

              >
                {dictionary["mark_sent"]}
              </MButton>
            }
            {status === "canceled" &&
              <MButton
                color="grey"
                size="small"
                disabled={loading}
                variant="contained"
                onClick={() => handleOpenCancel()}
                endIcon={<CheckIcon />}

              >
                {dictionary['uncancel']}
              </MButton>
            }

            <MButton
              variant="contained"
              color="inherit"
              aria-controls="actions"
              aria-haspopup="true"
              disabled={isLoading}
              size="small"
              onClick={handleClick}
            >
              {isLoading ? <CircularProgress size={22} /> : dictionary["actions"]}
            </MButton></>
        )}

      <Menu
        id="lease-actions"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {INVOICE_ACTIONS.map((action) => (
          <div>
            {action.id &&
              <MenuItem
                key={action.id}
                value={action.id}
                onClick={action?.onClick}
              >
                {action.name}
              </MenuItem>
            }
          </div>
        ))}
      </Menu>


      {
        historyOpen &&
        <ModalForm customTitle={dictionary["history"]} onClose={handleCloseHistory}>
          <HistoryTimeline invoiceId={invoiceId} />
        </ModalForm>
      }

      {
        settingsOpen &&
        <ModalForm isEdit onClose={handleCloseSettings} type={dictionary["settings"]}>
          <InvoiceSettingsForm fetchUrl={fetchUrl}
            handleClose={handleCloseSettings}
            invoiceId={invoiceId}
            isFree={isFree}
            tenantId={tenantId}
            isCompany={isCompany}
          />
        </ModalForm>

      }

      {
        codeOpen &&
        <ModalCode onClose={handleCloseCode} idInvoice={invoiceId} code={code} fetchUrl={fetchUrl} />
      }

      {
        payOpen &&
        <>
          <InvoicePaymentsDetails onClose={handleClosePay} operationId={operationId} amount={amount} balance={balance} tenant={tenant} invoiceId={invoiceId} fetchInvoice={fetchUrl} />
        </>
      }
      {deleteOpen &&
        <ModalDelete onClose={handleCloseDelete} description={dictionary["confirm_invoice_delete"]} onDelete={handleDelete} />
      }

      {
        cancelOpen &&
        <ModalDelete onClose={handleCloseCancel} description={status === "canceled" ? dictionary["confirm_uncancel"] : dictionary["confirm_cancel"]} onDelete={handleCancel} />
      }

      {
        validateOpen &&
        <ModalDelete onClose={handleCloseValidate} description={dictionary["confirm_validate"]} isYellow onDelete={handleValidate} />
      }
    </div>
  );
}

export default Toolbar;
