import clsx from 'clsx'
import React, { useState } from 'react'
import { merge } from 'lodash'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

const ReactApexChart = dynamic(() => import('react-apexcharts'), { ssr: false })
import { fNumber } from '@utils/formatNumber'

import ApexChartsOption from '@components/dashboard/charts/ApexChartsOption'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Typography, Card, Skeleton } from '@material-ui/core'
import { useTranslation } from '@contexts/translation'
import { numberWithSpaceInt } from '@utils/numberWithSpaces'
import LocalParkingIcon from '@material-ui/icons/LocalParking'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap'

// ----------------------------------------------------------------------

function OccupancyRadialChart({
  className,
  occupancyRate,
  occupancyRateSurface,
  actualRent,
  potentialRent,
  occupiedSurface,
  occupancySurface,
  surfaceArea,
  surfaceAreaManual,
  lotsAvailable,
  parkingAvailable,
  total,
  isLoading,
  ...other
}) {
  const theme = useTheme()
  const { dictionary } = useTranslation()

  const [navIsActive, setNavIsActive] = useState('occupation')
  const [isChecked, setIsChecked] = useState(false)
  const chartData = [
    isChecked
      ? occupancyRateSurface && Math.trunc(occupancyRateSurface)
      : (occupancyRate && Math.trunc(occupancyRate)) || 0,
  ]

  const useStyles = makeStyles((theme) => {
    const chartHeight = navIsActive === 'occupation' ? 210 : 132
    const legendHeight = 80
    return {
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      },
      chart: {
        width: '100%',
        height: chartHeight,
        marginTop: theme.spacing(2),
        '& .apexcharts-canvas svg': { height: chartHeight },
        '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
          overflow: 'visible',
        },
        '& .apexcharts-legend': {
          height: 0,
          display: 'none',
          alignContent: 'center',
          position: 'relative !important',
          borderTop: `solid 1px ${theme.palette.divider}`,
          top: `calc(${chartHeight - legendHeight}px) !important`,
        },
      },

      container: {
        display: 'flex',
      },
      chartContainer: {
        width: '50%',
      },
      contentContainer: {
        width: '50%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        padding: theme.spacing(2),
      },
      toggleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      content: {
        alignSelf: 'center',
      },
      contentTitle: {
        color: theme.palette.primary.main,
        fontSize: '24px',
        fontWeight: 700,
      },
      contentPrice: {
        color: theme.palette.common.black,
        fontSize: '14px',
        fontWeight: 500,
      },
      contentText: {
        color: 'rgba(4, 49, 118, 0.6)',
        fontSize: '14px',
        fontWeight: 500,
      },

      nav: {
        display: 'flex',
        alignItems: 'stretch',
      },
      navContent: {
        width: '100%',
        padding: theme.spacing(2),
        background: theme.palette.grey[200],

        '&:last-of-type': {
          textAlign: 'right',
        },
        '& h6': {
          opacity: 0.7,
        },
        '& p': {
          opacity: 0.6,
        },
      },
      navIsActiveStyle: {
        background: 'rgba(4, 49, 118, 0.08)',
        color: theme.palette.primary.main,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        '& h6': {
          opacity: 1,
        },
        '& p': {
          opacity: 1,
        },
      },

      inputContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',

        '& input:checked + label:before': {
          backgroundColor: theme.palette.secondary.main,
          borderColor: theme.palette.secondary.main,
        },
        '& input:checked + label:after': {
          backgroundColor: theme.palette.common.white,
          transform: 'translateX(1em)',
        },
        '& input:focus + label:after': {
          boxShadow: '0 0 0 0.1em #C4DBF9',
          borderColor: theme.palette.secondary.main,
        },
      },
      inputToggle: {
        position: 'absolute',
        opacity: 0,
      },
      inputLabel: {
        display: 'grid',
        gridTemplateColumns: '2em 1fr',
        gridTemplateAreas: "'checkbox' 'label'",
        gap: '0 .5em',
        marginLeft: '.5em',

        '&:before': {
          content: "''",
          gridArea: 'checkbox',
          display: 'block',
          width: '100%',
          height: '1em',
          borderRadius: '1em',
          border: `1px solid ${theme.palette.grey[500]}`,
          marginTop: '.1em',
          transition: 'background-color .3s, border-color .3s, box-shadow .3s',
        },

        '&:after': {
          position: 'relative',
          top: '2px',
          left: '2px',
          content: "''",
          display: 'block',
          gridArea: 'checkbox',
          width: 'calc(1em - 2px)',
          height: 'calc(1em - 2px)',
          borderRadius: '50%',
          marginTop: '.05em',
          transition: 'background-color .3s, transform .3s',
          backgroundColor: theme.palette.secondary.main,
        },
      },
      informationVacation: {
        display: 'flex',
        alignItem: 'center',
        marginBottom: theme.spacing(2),
      },
      informationVacationContainer: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      },
      informationVacationContent: {
        display: 'flex',
        alignItems: 'center',

        '& svg': {
          fill: theme.palette.primary.main,
        },
      },
      informationVacationTitle: {
        color: theme.palette.primary.main,
      },

      informationVacationText: {
        color: 'rgba(4, 49, 118, 0.6)',
        marginLeft: theme.spacing(1),
      },
    }
  })

  const classes = useStyles()

  const chartOptions = merge(ApexChartsOption(), {
    title: {
      text: navIsActive === 'occupation' ? "" : dictionary['vacancy'],
      align: 'center',
      floating: true,
      offsetY: 20,
      style: {
        fontSize: 'bold',
        color: '#A6936B',
        fontSize: '1.5rem'
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          [
            {
              offset: 0,
              color:
                navIsActive === 'occupation'
                  ? theme.palette.secondary.light
                  : theme.palette.warning.light,
            },
            {
              offset: 100,
              color:
                navIsActive === 'occupation'
                  ? theme.palette.primary.main
                  : theme.palette.warning.light,
            },
          ],
          [
            {
              offset: 0,
              color:
                navIsActive === 'occupation'
                  ? theme.palette.warning.light
                  : theme.palette.warning.light,
            },
            {
              offset: 100,
              color:
                navIsActive === 'occupation'
                  ? theme.palette.warning.main
                  : theme.palette.warning.dark,
            },
          ],
        ],
      },
    },
    plotOptions: {
      radialBar: {
        hollow: { size: '60%' },
        dataLabels: {
          value: { offsetY: 0 },
          total: {
            show: false,
            formatter: function (w) {
              return fNumber(2324)
            },
          },
        },
      },
    },
    labels: [''],
    // labels: ["Taux d'occupation"],
  })

  const chartDataVancation = [
    100 - ((occupancyRate && Math.trunc(occupancyRate)) || 0),
  ]

  const handleChange = () => {
    setIsChecked(!isChecked)
  }

  return (
    <Card className={clsx(classes.root, className)} {...other}>
      <div className={classes.container}>
        <div className={classes.chartContainer}>
          <ReactApexChart
            type="radialBar"
            series={
              navIsActive === 'occupation' ? chartData : chartDataVancation
            }
            options={chartOptions}
            height={320}
            className={classes.chart}
          />
        </div>

        <div className={classes.contentContainer}>
          <div className={classes.toggleContainer}>
            {navIsActive === 'occupation' && (
              <div className={classes.inputContainer}>
                <label htmlFor={'occupation'}>
                  <Typography
                    sx={{
                      color: isChecked
                        ? 'rgba(4, 49, 118, 0.6)'
                        : theme.palette.primary.main,
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                  >
                    €
                  </Typography>
                </label>
                <input
                  className={classes.inputToggle}
                  type="checkbox"
                  id={'occupation'}
                  htmlFor={'occupation'}
                  value={isChecked}
                  onChange={handleChange}
                />
                <label className={classes.inputLabel} htmlFor={'occupation'}>
                  <Typography
                    sx={{
                      color: isChecked
                        ? theme.palette.primary.main
                        : 'rgba(4, 49, 118, 0.6)',
                      fontSize: '12px',
                      fontWeight: '600',
                    }}
                  >
                    {dictionary['m2']}
                  </Typography>
                </label>
              </div>
            )}
          </div>

          {navIsActive === 'occupation' && (
            <div className={classes.content}>
              <Typography variant="h4" className={classes.contentTitle}>
                {isLoading ? (
                  <Skeleton variant="text" />
                ) : isChecked ? (
                  `${numberWithSpaceInt(occupancySurface)} ${dictionary['m2']
                  }` ?? `432m2`
                ) : (
                  `${numberWithSpaceInt(actualRent)}€` ?? `0€`
                )}
              </Typography>
              <Typography className={classes.contentPrice}>
                {isLoading ? (
                  <Skeleton variant="text" />
                ) : isChecked ? (
                  `${numberWithSpaceInt(actualRent)}€` ?? '600 000€'
                ) : (
                  `${numberWithSpaceInt(occupancySurface)} ${dictionary['m2']
                  }` ?? `432m2`
                )}
              </Typography>
              <Typography className={classes.contentText}>
                {dictionary['potential']}{' '}
                {isLoading ? (
                  <Skeleton variant="text" />
                ) : isChecked ? (
                  `${numberWithSpaceInt(surfaceAreaManual)} ${dictionary['m2']
                  }` ?? `432m2`
                ) : (
                  `${numberWithSpaceInt(potentialRent)}€ ` ?? '800 000€'
                )}
              </Typography>
            </div>
          )}

          {navIsActive === 'vacation' && (
            <div className={classes.content}>
              <Typography variant="h4" className={classes.contentTitle}>
                {isLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  `${numberWithSpaceInt(potentialRent - actualRent)}€` ??
                  '600 000€'
                )}
              </Typography>
              <Typography className={classes.contentPrice}>
                {isLoading ? (
                  <Skeleton variant="text" />
                ) :
                  (
                    `${numberWithSpaceInt(
                      surfaceAreaManual - occupancySurface,
                    )} ${dictionary['m2']}` ?? `432m2`
                  )}
              </Typography>
              <Typography className={classes.contentText}>
                {dictionary['potential']}{' '}
                {isLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  `${numberWithSpaceInt(potentialRent)}€ ` ?? '800 000€'
                )}
              </Typography>
            </div>
          )}
        </div>
      </div>

      {navIsActive === 'vacation' && (
        <div className={classes.informationVacation}>
          <div className={classes.informationVacationContainer}>
            <Typography
              className={classes.informationVacationTitle}
              variant="h5"
              sx={{
                fontSize: '32px',
              }}
            >
              {lotsAvailable}
            </Typography>
            <div className={classes.informationVacationContent}>
              <ZoomOutMapIcon />
              <Typography
                className={classes.informationVacationText}
                variant="h5"
                sx={{
                  fontSize: '14px',
                }}
              >
                {dictionary['lots']}
              </Typography>
            </div>
          </div>

          <div className={classes.informationVacationContainer}>
            <Typography
              className={classes.informationVacationTitle}
              variant="h5"
              sx={{
                fontSize: '32px',
              }}
            >
              {parkingAvailable}
            </Typography>
            <div className={classes.informationVacationContent}>
              <LocalParkingIcon />
              <Typography
                className={classes.informationVacationText}
                variant="h5"
                sx={{
                  fontSize: '14px',
                }}
              >
                {dictionary['parking']}
              </Typography>
            </div>
          </div>
        </div>
      )}

      <div className={classes.nav}>
        <div
          onClick={() => setNavIsActive('occupation')}
          className={clsx(
            classes.navContent,
            navIsActive === 'occupation' ? classes.navIsActiveStyle : false,
          )}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: '14px',
            }}
          >
            {dictionary['occupancy_rate']}
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
            }}
          >
            {isLoading ? (
              <Skeleton variant="text" />
            ) : isChecked ? (
              `${occupancySurface.toFixed(2)} ${dictionary['m2']}` ?? '432m2'
            ) : (
              `${numberWithSpaceInt(actualRent)}€` ?? '600 000€'
            )}
          </Typography>
        </div>

        <div
          onClick={() => setNavIsActive('vacation')}
          className={clsx(
            classes.navContent,
            navIsActive === 'vacation' ? classes.navIsActiveStyle : false,
          )}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: '14px',
            }}
          >
            {dictionary['vacancy']}
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
            }}
          >
            {isLoading ? (
              <Skeleton variant="text" />
            ) : isChecked ? (
              `${numberWithSpaceInt(surfaceAreaManual - occupancySurface)} ${dictionary['m2']
              }` ?? '820m2'
            ) : (
              `${numberWithSpaceInt(potentialRent - actualRent)}€` ?? '600 000€'
            )}
          </Typography>
        </div>
      </div>
    </Card>
  )
}

OccupancyRadialChart.propTypes = {
  className: PropTypes.string,
}

export default OccupancyRadialChart
