import React, { useState, useEffect } from 'react';

import { TextField } from '@material-ui/core'
import { MobileDateRangePicker } from '@material-ui/lab';
import { unformatRange } from '@utils/formatDate'

const DateRangePicker = ({
  nameStart,
  nameEnd,
  labelStart,
  labelEnd,
  errorStart,
  errorEnd,
  errorMessageStart,
  errorMessageEnd,
  idStart,
  idEnd,
  control,
  register,
  children,
  fullWidth,
  required,
  clearable,
  startDate,
  endDate,
  disabled,
  ...props
}) => {
  const [value, setValue] = useState([null, null]);

  useEffect(() => {
    if (startDate && endDate) {
      setValue([unformatRange(startDate), unformatRange(endDate)])
    }
  }, [startDate, endDate]);

  return (
    <MobileDateRangePicker
      inputFormat={'dd/MM/yyyy'}
      value={value}
      readOnly={disabled}
      inputRef={register}
      onChange={(newValue) => {
        setValue(newValue);
      }}
      renderInput={(startProps, endProps) => (
        <>
          <TextField
            {...startProps}
            label={labelStart}
            id={idStart}
            disabled={disabled}
            name={nameStart}
            error={errorStart}
            helperText={errorMessageStart}
            required={required}
            inputRef={register}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal" />

          <TextField
            {...endProps}
            label={labelEnd}
            id={idEnd}
            name={nameEnd}
            error={errorEnd}
            disabled={disabled}
            helperText={errorMessageEnd}
            required={required}
            inputRef={register}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal" />
        </>
      )}
    />
  )
}

export default DateRangePicker



