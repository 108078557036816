import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
    '&:hover': {
      background: 'none'
    }
  },

}))

export default function ActionTable({ actions }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles()


  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="action-menu"
        aria-haspopup="true"
        className={classes.button}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {actions.map((option) => (
          <div>
            {option &&
              <MenuItem
                key={option.name}
                onClick={() => {
                  option.onClick();
                  handleClose();
                }}
              >
                {option.name}
              </MenuItem>
            }
          </div>
        ))}
      </Menu>
    </>
  );
}
