import React, { useEffect, useState } from 'react'
import { Grid, TextField, InputAdornment, IconButton } from '@material-ui/core'
import { useTranslation } from '@contexts/translation'
import useOnclickOutside from "react-cool-onclickoutside";
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search';
import { onSearchCompany } from '@api/contacts';

const useStyles = makeStyles((theme) => ({
  suggestionList: {
    borderRadius: '8px',
    border: `1px solid ${theme.palette.grey[500_32]}`,
    padding: theme.spacing(1),
    listStyle: 'none',
    '& li:last-child': {
      borderBottom: 'none !important',
    }
  },
  suggestionItem: {
    borderBottom: `1px solid ${theme.palette.grey[500_32]}`,
    cursor: 'pointer',
    padding: theme.spacing(1, 0),
    '&:hover': { backgroundColor: theme.palette.action.hover }
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const CompanyNameAutocomplete = ({
  register,
  error,
  setValue,
  helperText,
  ...props
}) => {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [companyName, setCompanyName] = useState('')
  const [suggestions, setSuggestions] = useState(null)

  const clearSuggestion = () => setSuggestions(null)

  const handleInput = (e) => {
    // Update the keyword of the input element
    setCompanyName(e.target.value);
  };

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestion();
  });


  const handleSelect = ({ nom_entreprise, siren, domaine_activite, capital, siege, forme_juridique }) => () => {
    setValue('company_name', nom_entreprise)
    setValue('siren', siren)
    setValue('activity_type', domaine_activite)
    if (forme_juridique) {
      if (forme_juridique === 'Entrepreneur individuel') {
        setValue('entity_type', 'Indi')
      }
      if (forme_juridique === "SARL, société à responsabilité limitée") {
        setValue('entity_type', 'SARL')
      }
      if (forme_juridique === "SA à conseil d'administration (s.a.i.)") {
        setValue('entity_type', 'SA')
      }
      if (forme_juridique === "SAS, société par actions simplifiée") {
        setValue('entity_type', 'SAS')
      }
      if (forme_juridique === "Société en nom collectif") {
        setValue('entity_type', 'SNC')
      }
    }
    if (capital) setValue('capital', capital)
    if (siege) setValue('headquarters_address', `${siege.adresse_ligne_1} ${siege.code_postal} ${siege.ville}`)
    if (siege) setValue('address', `${siege.adresse_ligne_1} ${siege.code_postal} ${siege.ville}`)

    clearSuggestion();
  }

  const renderSuggestions = () =>
    suggestions.map((suggestion) => {
      const {
        nom_entreprise,
        siren,
      } = suggestion;

      return (
        <li className={classes.suggestionItem} key={siren} onClick={handleSelect(suggestion)}>
          <strong>{nom_entreprise}</strong> <small>{siren}</small>
        </li>
      );
    });



  const handleClickSearch = async () => {
    const data = {}
    data['company_name'] = companyName
    const response = await onSearchCompany(data)
    if (response) {
      if (response.status === 200) {
        setSuggestions(response.data.result.resultats_nom_entreprise)
      }

    }
  };

  const handleMouseDownSearch = (event) => {
    event.preventDefault();
  };

  return (
    <Grid xs="12" ref={ref}>
      <TextField
        variant="outlined"
        required
        margin="normal"
        fullWidth
        id="company_name"
        label={dictionary['company_name']}
        name="company_name"
        inputRef={register}
        error={error}
        onChange={handleInput}
        helperText={helperText}
        InputProps={{
          endAdornment: companyName.length >= 6 && <InputAdornment position="end">
            <IconButton
              onClick={handleClickSearch}
              onMouseDown={handleMouseDownSearch}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {suggestions && suggestions.length > 0 && <ul className={classes.suggestionList}>{renderSuggestions()}</ul>}

    </Grid>

  )
}
export default CompanyNameAutocomplete
