import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'

import { Modal } from '.'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'

import { useTranslation } from '@contexts/translation'

import { DatePicker } from '@components/form'

import { onUpdateExpense } from '@api/expenses'
import { useFetchData } from '@api/fetchData'

import GetFormattedDate from '@utils/formatDate'
import UnformatDate from '@utils/unformatDate'

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerButtons: {
    height: 'fit-content',
    display: "flex",
    padding: theme.spacing(4),
  },
  container: {
    height: 'fit-content',
    padding: theme.spacing(4)
  },
  buttonCancel: {
    marginRight: theme.spacing(8),
  },
  form: {
    display: 'flex',
    flexDirection: "column",
    zIndex: 99999,
  }
}))

export default function ModalPaidDate({ onClose, idExpense, operationId, fetchUrl }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const formRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar()

  const {
    handleSubmit,
    errors,
    setValue,
    control
  } = useForm({ mode: 'onBlur' })

  const { data: prevData, isLoading, isError } = useFetchData('/expenses/', idExpense)

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        if (prevData?.date_paid) {
          setValue('date_paid', UnformatDate(prevData?.date_paid))
          return
        }
        setValue('date_paid', new Date())
      }, 1)
    }
  }, [prevData]);

  const onSubmit = async (data) => {
    setLoading(true)

    const formData = new FormData(formRef.current);

    if (data.date_paid) formData.append("date_paid", GetFormattedDate(data.date_paid))

    const response = await onUpdateExpense(idExpense, formData)
    if (response) {
      if (response.status === 200) {
        enqueueSnackbar(dictionary["expense_edited"], {
          variant: "success"
        })
        mutate(fetchUrl)
        onClose()
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <Modal className={classes.modalContainer} onClose={onClose}>
      <form ref={formRef} className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Paper className={classes.container}>
          <DatePicker
            id="date_paid"
            name="date_paid"
            required
            label={dictionary["paid_date"]}
            variant="outlined"
            margin="normal"
            fullWidth
            control={control}
            error={errors.date_paid}
            errorMessage={errors.date_paid && errors.date_paid.message}
            clearable={true}
          />

          <div className={classes.containerButtons}>
            <Button
              className={classes.buttonCancel}
              onClick={onClose}
              type="button"
              variant="contained"
              color="inherit"
            >
              {dictionary["cancel"]}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              {dictionary["confirm"]}
            </Button>
          </div>
        </Paper>
      </form>
    </Modal>
  )
}
