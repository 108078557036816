import client from '@utils/client'

const cacheBuster = (url) => `${url}?cb=${Date.now()}`;


export const onFetchInvoices = (params = {}) => {
  return client
    .get('/invoices/', { params })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onCreateInvoice = (payload) => {
  return client
    .post('/invoices/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onDeleteInvoice = (id) => {
  return client
    .delete(`/invoices/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onUpdateInvoice = (id, payload) => {
  return client
    .patch(`/invoices/${id}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetInvoice = (id) => {
  return client
    .get(`/invoices/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onCreateInvoiceItem = (invoiceId, payload) => {
  return client
    .post(`/invoices/${invoiceId}/items/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onDeleteInvoiceItem = (invoiceId, id) => {
  return client
    .delete(`/invoices/${invoiceId}/items/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onUpdateInvoiceItem = (invoiceId, id, payload) => {
  return client
    .patch(`/invoices/${invoiceId}/items/${id}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetInvoiceItem = (invoiceId, id) => {
  return client
    .get(`/invoices/${invoiceId}/items/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}


export const onGetInvoicePDF = (invoiceId) => {
  return client
    .get(cacheBuster(`/invoices/${invoiceId}/preview/`), { responseType: 'blob' })
    .then((response) => {
      if (response?.data) {
        const invoicePdf = new Blob(
          [response.data],
          { type: 'application/pdf' });
        const invoiceUrl = URL.createObjectURL(invoicePdf);
        window.open(invoiceUrl);
        return response
      }
    })
    .catch((error) => {
      return error.response
    })
}

export const onDownloadInvoicePDF = (invoiceId, invoiceName) => {
  return client
    .get(cacheBuster(`/invoices/${invoiceId}/preview/`), { responseType: 'blob' })
    .then((response) => {
      if (response?.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${invoiceName}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return response
      }
    })
    .catch((error) => {
      return error.response
    })
}
export const onUpdateInvoiceSchedule = (id, payload) => {
  return client
    .patch(`/invoices/${id}/schedule`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGenerateInvoiceItems = (id, action) => {
  return client
    .post(`/invoices/automation/${id}/`, {
      action: action,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onSendMail = (id) => {
  return client
    .post(`/invoices/${id}/send_email/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
