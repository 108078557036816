import React from 'react'
import Link from 'next/link'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { useTranslation } from '@contexts/translation'
import PersonIcon from '@material-ui/icons/Person'
import { numberWithSpaceInt } from '@utils/numberWithSpaces'
import Scrollbars from '@components/common/Scrollbars'

const useStyles = makeStyles((theme, props) => ({
  container: {
    height: '100%',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  description: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: theme.palette.grey[500],
  },
  content: {
    padding: 0,
    maxHeight: '240px',
    overflow: 'scroll',
  },

  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    height: '100%',
    minHeight: '140px',
    maxHeight: '240px',
  },
  listItem: {
    width: '48%',
    overflow: 'hidden',
  },
  listItemName: {
    ' & span': {
      fontSize: '14px',
      opacity: 0.8,
      whiteSpace: 'pre'
    },
  },
  listItemPrice: {
    '& span': {
      fontSize: '14px',
      color: theme.palette.primary.main,
    },
  },
}))

function TenantsDetail({ className, data, operationId }) {
  const classes = useStyles()
  const theme = useTheme()
  const { dictionary } = useTranslation()

  function getRandomColor() {
    var letters = '0123456789ABCDEF'
    var color = '#'
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  return (
    <Card className={classes.container}>
      <div className={classes.titleContainer}>
        <CardHeader title={dictionary['tenants']} />
      </div>
      <Typography className={classes.description}>
        {dictionary['overview_tenants_order']}
      </Typography>
      <CardContent className={classes.content}>
        <Box>
          <Scrollbars>
            {data && (
              <List className={classes.list}>
                {data.filter(tenant => tenant.balance > 0).sort((a, b) => b.balance - a.balance).map(({ display, balance, id }) => (
                  <ListItem className={classes.listItem} key={display}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50rem',
                        padding: theme.spacing(1),
                        marginRight: theme.spacing(1),
                        backgroundColor: getRandomColor(),
                      }}
                    >
                      
                    </div>
                    <div>
                      <Link href={`/operations/${operationId}/tenants/${id}`} passHref>
                        <ListItemText className={classes.listItemName}>
                          {display}
                        </ListItemText>
                      </Link>
                      <ListItemText className={classes.listItemPrice}>
                        {numberWithSpaceInt(balance)} <span>€</span>
                      </ListItemText>
                    </div>
                  </ListItem>
                ))}
              </List>
            )}
          </Scrollbars>
        </Box>
      </CardContent>
    </Card>
  )
}

export default TenantsDetail
