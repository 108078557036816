import { Fragment } from 'react'
import { useRouter } from 'next/router'
import { alpha, makeStyles } from '@material-ui/core/styles'

import Link from 'next/link'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { useTranslation } from '@contexts/translation'
import { useSelector } from 'react-redux'
import { NAV_HOME_CONFIG, NAV_OPERATION_CONFIG } from '@config/navbar'
import { MAvatar } from '@theme/@material-extend'


const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: '12px',
    marginBottom: '12px',
  },
  menuItem: {
    '& span ': {
      fontFamily: theme.typography.menu.fontFamily,
      opacity: 0.8,
      fontSize: theme.typography.menu.fontSize,
    },
  },
  listLot: {
    height: 48,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    marginBottom: `${theme.spacing(2)} !important`,
    fontSize: '0.875em',
    fontFamily: theme.typography.menu.fontFamily,
    color: theme.palette.menu.text,
  },
  isActiveLot: {
    height: 48,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    marginBottom: `${theme.spacing(2)} !important`,
    fontSize: '0.875em',
    fontFamily: theme.typography.menu.fontFamily,
    color: theme.palette.primary.main_dark,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main_dark,
      theme.palette.action.selectedOpacity,
    ),
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      position: 'absolute',
      backgroundColor: theme.palette.primary.main_dark,
    },
    '& span': {
      opacity: 1,
    },
  },
  listItem: {
    height: 48,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    fontSize: '0.875em',
    fontFamily: theme.typography.menu.fontFamily,
    color: theme.palette.menu.text,
  },
  isActiveListItem: {
    height: 48,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    fontSize: '0.875em',
    fontFamily: theme.typography.menu.fontFamily,
    color: theme.palette.primary.main_dark,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main_dark,
      theme.palette.action.selectedOpacity,
    ),
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      position: 'absolute',
      backgroundColor: theme.palette.primary.main_dark,
    },
    '& span': {
      opacity: 1,
    },
  },
  list: {
    '& a:last-of-type': {
      marginBottom: theme.spacing(4),
    },
  },
  listItemSubHeader: {
    display: 'initial',

    paddingLeft: 0,
    paddingRight: 0,
  },
  listItemSubHeaderClose: {
    display: 'none',
  },
  subheaderStyle: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),

    fontSize: '0.95em',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),

    '& span': {
      fontWeight: 700,
      fontSize: '0.75rem',
      fontFamily: `${theme.typography.menu.fontFamily}`,
    },
  },
  listItemIcon: {
    opacity: 0.8,
    width: '24px',
    fontSize: '22px',
    height: '24px',
  },
  listItemSettings: {
    display: 'flex',
    width: 'initial',
    height: 'initial',
    background: theme.palette.menu.backgroundSettings,
    padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
    margin: theme.spacing(2, 0),
    borderRadius: '8px',
    textDecoration: 'none',
    alignItems: 'center',
    color: theme.palette.menu.text,
  },
  listItemSettingsClose: {
    background: theme.palette.menu.backgroundSettings,
    margin: `${theme.spacing(2)} ${theme.spacing(3.5)}`,
    borderRadius: '50rem',
    width: '24px',
    height: '24px',
    display: 'table',
  },
  listItemPhoto: {
    display: 'table',
    width: '40px',
    height: '40px',
    background: theme.palette.grey['400'],
    borderRadius: '50rem',
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
  listItemPhotoClose: {
    '& div': {
      width: '24px',
      height: '24px',
      cursor: 'pointer',
    },
  },
  listItemContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontFamily: `${theme.typography.menu.fontFamily}`,

    '& a:last-of-type': {
      padding: 0,
      marginBottom: 0,
      height: 'auto',
      cursor: 'pointer',
      '& div:first-of-type': {
        marginRight: theme.spacing(1),
      },
    },
  },

  listItemContentClose: {
    display: 'none',
  },

  listItemName: {
    display: 'inline-block',
    marginBottom: theme.spacing(1),
    padding: 0,
    fontSize: theme.typography.menu.fontSize,
    fontWeight: 600,
    textDecoration: 'none',
    color: theme.palette.text.primary,
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    whiteSpace: 'break-spaces',
    cursor: 'pointer',
  },
}))

const isActive = (match, location, includes) => {
  if (!match) {
    return false
  }

  if (includes) {
    const isMatch = location.includes(match)
    return isMatch
  }
  const isMatch = match === location

  return isMatch
}

export const MainListItems = ({ open, data, operationId, organizationName }) => {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const router = useRouter()
  const { organization } = useSelector((state) => state.organizations)
  const isFinanceEnabled = organization && organization.length > 0 && organization[0].finance_enabled

  const renderOperationContent = (operationId) => (
    <>
      <ul>
        {NAV_OPERATION_CONFIG?.map((nav) => {
          return (
            <>
              {nav?.subheader && (
                <ListItem
                  className={
                    open
                      ? classes.listItemSubHeader
                      : classes.listItemSubHeaderClose
                  }
                  component="li"
                >
                  <ListItemText
                    className={classes.subheaderStyle}
                    primary={dictionary[nav?.subheader]}
                  />
                </ListItem>
              )}
              <ul className={classes.list}>
                {nav?.items?.map((item) => {
                  const linkHref = item?.isOperation
                    ? `/operations/${operationId}${item?.href}`
                    : `${item?.href}`

                  // const isActiveStyle = item?.isOperation
                  // ? `/operations/[id]${item?.href}`
                  // : `/operations`

                  if (item.title[0] === 'settings') {
                    return (
                      <>
                        <div
                          className={
                            open
                              ? classes.listItemSettings
                              : classes.listItemSettingsClose
                          }
                        >
                          <div
                            className={
                              open
                                ? classes.listItemPhoto
                                : classes.listItemPhotoClose
                            }
                          >
                            <MAvatar
                              onClick={() =>
                                router.push(`/operations/${operationId}`)
                              }
                              sx={{
                                mx: 'auto',
                                width: { xs: 40, md: 40 },
                                height: { xs: 40, md: 40 },
                              }}
                            >
                              <img src={data?.photo} />
                            </MAvatar>
                          </div>
                          <div
                            className={
                              open
                                ? classes.listItemContent
                                : classes.listItemContentClose
                            }
                          >
                            <Link href={`/operations/${operationId}`} passHref>
                              <ListItem
                                className={classes.listItemName}
                                component="a"
                              >
                                {data?.name}
                              </ListItem>
                            </Link>

                          </div>
                        </div>
                      </>
                    )
                  }
                  if (item.title[0] === 'lot_units') {
                    return (
                      <Fragment key={dictionary[item?.title]}>
                        <Link href={linkHref} passHref>
                          <ListItem
                            className={
                              isActive(
                                `/operations/[id]${item?.href}`,
                                router.pathname,
                                item?.isInside,
                              )
                                ? classes.isActiveLot
                                : classes.listLot
                            }
                            button
                            component="a"
                            disabled={
                              !isFinanceEnabled && item?.isFinance ? true : false
                            }
                          >
                            {item?.icon && (
                              <ListItemIcon className={classes.listItemIcon}>
                                {item?.icon}
                              </ListItemIcon>
                            )}
                            <ListItemText
                              className={classes.menuItem}
                              primary={dictionary[item?.title]}
                            />
                          </ListItem>
                        </Link>
                      </Fragment>
                    )
                  }
                  if (item.isFinance && !isFinanceEnabled) {
                    return
                  }
                  else {
                    return (
                      <Fragment key={dictionary[item?.title]}>
                        <Link href={linkHref} passHref>
                          <ListItem
                            className={
                              isActive(
                                `/operations/[id]${item?.href}`,
                                router.pathname,
                                item?.isInside,
                              )
                                ? classes.isActiveListItem
                                : classes.listItem
                            }
                            button
                            component="a"
                            disabled={
                              !isFinanceEnabled && item?.isFinance ? true : false
                            }
                          >
                            {item?.icon && (
                              <ListItemIcon className={classes.listItemIcon}>
                                {item?.icon}
                              </ListItemIcon>
                            )}
                            <ListItemText
                              className={classes.menuItem}
                              primary={dictionary[item?.title]}
                            />
                          </ListItem>
                        </Link>
                      </Fragment>
                    )
                  }
                })}
              </ul>
            </>
          )
        })}
      </ul>
    </>
  )

  const renderHomeContent = () => (
    <>
      <ul>
        {NAV_HOME_CONFIG?.map((nav) => {
          return (
            <>
              {nav?.subheader && (
                <ListItem
                  className={
                    open
                      ? classes.listItemSubHeader
                      : classes.listItemSubHeaderClose
                  }
                  component="li"
                >
                  <ListItemText
                    className={classes.subheaderStyle}
                    primary={dictionary[nav?.subheader]}
                  />
                </ListItem>
              )}
              <ul className={classes.list}>
                {nav?.items?.map((item) => {
                  const linkHref = `${item?.href}`
                  if (item.title[0] === 'setting') {
                    return (
                      <>
                        <div
                          className={
                            open
                              ? classes.listItemSettings
                              : classes.listItemSettingsClose
                          }
                        >
                          <div
                            className={
                              open
                                ? classes.listItemPhoto
                                : classes.listItemPhotoClose
                            }
                          >
                            <MAvatar
                              onClick={() =>
                                router.push(`/`)
                              }
                              sx={{
                                mx: 'auto',
                                width: { xs: 40, md: 40 },
                                height: { xs: 40, md: 40 },
                              }}
                            >
                              <img src={data?.photo} />
                            </MAvatar>
                          </div>
                          <div
                            className={
                              open
                                ? classes.listItemContent
                                : classes.listItemContentClose
                            }
                          >
                            <Link href={`/`} passHref>
                              <ListItem
                                className={classes.listItemName}
                                component="a"
                              >
                                {organizationName}
                              </ListItem>
                            </Link>
                          </div>
                        </div>
                      </>
                    )
                  }
                  if (item.isFinance && !isFinanceEnabled) {
                    return
                  }
                  else {
                    return (
                      <Fragment key={dictionary[item?.title]}>
                        <Link href={linkHref} passHref>
                          <ListItem
                            className={
                              isActive(
                                `${item?.href}`,
                                router.pathname,
                                item?.isInside,
                              )
                                ? classes.isActiveListItem
                                : classes.listItem
                            }
                            button
                            component="a"
                            disabled={
                              item.disabled ? true : !isFinanceEnabled && item?.isFinance ? true : false
                            }
                          >
                            {item?.icon && (
                              <ListItemIcon className={classes.listItemIcon}>
                                {item?.icon}
                              </ListItemIcon>
                            )}
                            <ListItemText
                              className={classes.menuItem}
                              primary={dictionary[item?.title]}
                            />
                          </ListItem>
                        </Link>
                      </Fragment>
                    )
                  }
                })}
              </ul>
            </>
          )
        })}
      </ul>
    </>
  )

  const OperationItems = ({ operationId }) => {
    return (
      <div>
        {renderOperationContent(operationId)}
      </div>
    )
  }

  const HomeItem = () => {
    return (
      <div>
        {renderHomeContent()}
      </div>
    )
  }

  return (
    <>
      {operationId ? (
        <OperationItems operationId={operationId} />
      ) : (
        <HomeItem />
      )}
    </>
  )
}
