import React from 'react';
import { Controller } from 'react-hook-form'

import { FormControl, FormHelperText, FormControlLabel, Switch } from '@material-ui/core'

const ReactHookFormSwitch = ({
  name,
  label,
  control,
  error,
  errorMessage,
  children,
  fullWidth,
  labelPlacement,
  switchColor,
  inputRef,
  id,
  ...props
}) => {
  return (
    <FormControl
      {...props}
      margin="normal"
      size="medium"
      error={error}
      helperText={errorMessage}
      style={{ display: "block" }}
      control={control}
    >
      <FormControlLabel
        name={name}
        style={{ marginLeft: 0 }}
        label={label}
        labelPlacement={labelPlacement}
        control={<Controller
          name={name}
          control={control}
          render={({ onChange, value }) => {
            return (
              <Switch
                color={switchColor}
                id={id}
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
            );
          }}

        />}
      />
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

export default ReactHookFormSwitch