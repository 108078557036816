import clsx from 'clsx'
import React from 'react'
import {merge} from 'lodash'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import {Loader} from '@components/common'

const ReactApexChart = dynamic(() => import('react-apexcharts'), {ssr: false})
import {fNumber, fPercent} from '@utils/formatNumber'
import {fMonthAndYeah} from '@utils/formatTime'

import ApexChartsOption from '@components/dashboard/charts/ApexChartsOption'

import {alpha, makeStyles} from '@material-ui/core/styles'
import {Box, Card, Typography} from '@material-ui/core'

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    maxHeight: 120,
  },
  trending: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  trendingIcon: {
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.16),
  },
  isTrendingDown: {
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.main, 0.16),
  },
}))

// ----------------------------------------------------------------------

MetricCardWithLine.propTypes = {
  className: PropTypes.string,
}

function MetricCardWithLine({
  hasMargins,
  isLoading,
  hideChart = false,
  className,
  data,
  values,
  title,
  total,
  icon = null,
  ...other
}) {
  const classes = useStyles()

  // const chartData = [
  //   { data: values || [] }
  // ];
  // const chartOptions = merge(ApexChartsOption(), {
  //   chart: { animations: { enabled: true }, sparkline: { enabled: true } },
  //   stroke: { width: 2 },
  //   tooltip: {
  //     x: { show: false },
  //     y: {
  //       formatter: (seriesName, metric) => {
  //         if (data) {
  //           const dateArr = data[metric.dataPointIndex].date.split('-');
  //           const newDateArr = [dateArr[1], dateArr[0], dateArr[2]]
  //           return `${fMonthAndYeah(newDateArr.join('-'))} - ${fNumber(seriesName)}`
  //         } else {
  //           return `${data && data[metric.dataPointIndex].date} - ${fNumber(seriesName)}`
  //         }
  //       },
  //       title: {
  //         formatter: function (seriesName) {
  //           return '';
  //         }
  //       }
  //     },
  //     fixed: {
  //       enabled: true,
  //       position: 'topLeft',
  //       offsetX: -50,
  //       offsetY: 0,
  //     },
  //     marker: { show: false }
  //   }
  // });

  const styles = hasMargins
    ? {
        marginRight: 1,
      }
    : {}
  return (
    <Card className={clsx(classes.root, className)} {...other} sx={styles}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box sx={{flexGrow: 1}}>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{whiteSpace: 'nowrap'}}
            >
              {title}
            </Typography>
            <Typography variant="h3" gutterBottom>
              {fNumber(total)} €
            </Typography>
          </Box>
          {icon}
          {/* {!hideChart && <ReactApexChart
          type="line"
          series={chartData}
          options={chartOptions}
          width={80}
          height={80}
        />} */}
        </>
      )}
    </Card>
  )
}

export default MetricCardWithLine
