import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack';
import { mutate } from 'swr'

import { makeStyles } from '@material-ui/core/styles'
import { Card, Grid, MenuItem } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'

import { onUpdateProfile, onUpdateUser } from '@api/profile'
import { useFetchData } from '@api/fetchData'

import { useTranslation } from '@contexts/translation'
import { Select } from '@components/form';

const useStyles = makeStyles((theme) => ({
  form: {
    margin: 20,
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  root: {
    padding: theme.spacing(3)
  }
}))

export default function ProfileInfoForm() {
  const { dictionary, setLang } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, setError, errors, setValue, watch, control } = useForm({
    mode: 'onBlur',
  })
  const { data, isLoading, isError } = useFetchData(`/profiles/user/`)
  const { data: langData } = useFetchData(`/profiles/profile/`)
  const { enqueueSnackbar } = useSnackbar();

  const watchProfileField = watch(["email", "first_name", "last_name"])
  const watchLangField = watch(["locale"])


  const onSubmit = async () => {
    setLoading(true)

    let responseUser = await onUpdateUser(watchProfileField)
    let responseProfile = await onUpdateProfile(watchLangField)
    if (responseUser.status === 200 && responseProfile.status === 200) {
      enqueueSnackbar(dictionary["profile_updated"], {
        variant: 'success'
      })
      mutate('/profiles/user/')
      mutate('/profiles/profile/')
      setLang(watchLangField.locale)
      setLoading(false)
    }
    if (responseUser.status === 400 || responseProfile.status === 400) {
      setLoading(false)
      const { errors } = response.data
      if (errors.length > 0) {
        errors.map((error) => {
          setError(error.field, {
            message: error.message,
          })
        })
      }
    }
  }

  useEffect(() => {
    if (data) {
      setValue('first_name', data?.first_name)
      setValue('last_name', data?.last_name)
      setValue('email', data?.email)
    }
    if (langData) {
      setValue('locale', langData?.locale)

    }
  }, [data, langData]);

  return (
    <Card className={classes.root}>
      <form
        className={classes.form}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            name="email"
            label={dictionary["email"]}
            type="email"
            id="email"
            fullWidth
            inputRef={register}
            disabled
            error={errors.email}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors.email && errors.email.message}
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            name="first_name"
            label={dictionary["first_name"]}
            type="text"
            id="first_name"
            fullWidth
            inputRef={register}
            error={errors.first_name}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors.first_name && errors.first_name.message}
            autoComplete="first_name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            name="last_name"
            label={dictionary["last_name"]}
            type="text"
            id="last_name"
            fullWidth
            inputRef={register}
            error={errors.last_name}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={errors.last_name && errors.last_name.message}
            autoComplete="last_name"
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            variant="outlined"
            margin="normal"
            fullWidth
            id="locale"
            label={dictionary["lang"]}
            name="locale"
            defaultValue=""
            inputRef={register}
            error={errors.locale}
            control={control}
            helperText={errors.locale && errors.locale.message}
          >
            <MenuItem value="fr">FR</MenuItem>
            <MenuItem value="en">EN</MenuItem>
          </Select>
        </Grid>
        <Grid xs="12" className={classes.buttonContainer}>
          <Button
            type="button"
            onClick={() => onSubmit()}
            variant="contained"
            color="secondary"
            disabled={loading}
            startIcon={<SaveIcon />}
            className={classes.submit}
          >
            {dictionary["submit"]}
          </Button>
        </Grid>
      </form>
    </Card>
  )
}


