import clsx from 'clsx';
import React from 'react';
import sortBy from 'lodash/sortBy';

import PropTypes from 'prop-types';
import { fDate } from '@utils/formatTime';
import Scrollbars from '@components/common/Scrollbars'
import { fShortenNumber } from '@utils/formatNumber';
import InboxIcon from '@material-ui/icons/Inbox';
import differenceInMonths from 'date-fns/differenceInMonths'

import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardHeader,
  Typography,
  CardContent
} from '@material-ui/core';
import { useFetchData } from '@api/fetchData';
import { Loader } from '@components/common'
import { useRouter } from 'next/router';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    width: '600px',
    height: 'fit-content',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:not(:first-child)': { marginTop: theme.spacing(3) }
  }
}));

const today = new Date()

function TableItem({ item, valueKey, withLink, nameKey, path }) {
  const classes = useStyles();
  const router = useRouter()
  let value = '';
  if (valueKey === 'end_date') {
    if (item[valueKey]) {
      const monthes = differenceInMonths(new Date(item[valueKey]), today)
      value = `${fDate(item[valueKey])} ${monthes ? `(${monthes} months)` : ''}`;
    } else {
      value = '-';
    }
  } else {
    if (item[valueKey] === 0) return null
    value = fShortenNumber(item[valueKey])
  }
  return (
    <div className={classes.listItem} style={withLink ? { cursor: 'pointer' } : {}} onClick={withLink ? () => router.push(path) : () => { }}>
      <div >
        <Typography variant="subtitle2">{item[nameKey]}</Typography>
      </div>
      <div>
        <Typography variant="body2">
          {value}
        </Typography>
      </div>
    </div>
  );
}

TableCard.propTypes = {
  className: PropTypes.string
};

function TableCard({ className, withLink, title, nameKey, valueKey, path, operationId, ...other }) {
  const classes = useStyles();
  const { data, isLoading, isError } = useFetchData(path)
  let results = (data && data.results || []).filter(item => item[valueKey] !== 0)
  if (valueKey !== 'end_date') {
    results = sortBy(results, valueKey).reverse()
  }
  return (
    <Card className={clsx(classes.root, className)} {...other}>
      <CardHeader title={title} />
      <CardContent>
        {isLoading ? <div style={{ display: 'flex' }}><Loader /></div> : (<Scrollbars>
          {results.map((item) => (
            <TableItem withLink={withLink} path={`${path}/${item.id}`} key={`${item[nameKey]}-${item.id}`} item={item} nameKey={nameKey} valueKey={valueKey} />
          ))}
        </Scrollbars>)}
        {!isLoading && results.length === 0 ? <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}
        ><InboxIcon sx={{ fontSize: 100 }} color="disabled" /></Box> : null}
      </CardContent>
    </Card>
  );
}

export default TableCard;
